import React from 'react'
import gantt from 'highcharts/highcharts-gantt'
import { Box, Card, Typography } from '@mui/material'
import styled from '@emotion/styled'
import HighchartsReact from 'highcharts-react-official'

const MainCard = styled(Card)(() => ({
  '.chart-title': {
    fontSize: 18,
    fontWeight: '600',
    padding: 18
  },
  '.parent-bar-chart': {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '15px',
    background: '#F0F6FF',
    border: '1px solid #DEDEDE',
    margin: '10px'
  },
  '.Overall': {
    fontSize: '16px',
    fontWeight: '600'
  },
  '.percentile': {
    fontSize: '18px',
    fontWeight: '700'
  }
}))

const GanntChart = (props) => {
  const { title, type = 'gantt', data, legend = true } = props
  const options = {
    chart: {
      type: 'gantt'
    },

    credits: {
      enabled: false
    },
    yAxis: {
      uniqueNames: true,
      useHTML: true,
      visible: true
    },
    navigator: {
      enabled: false,
      liveRedraw: true,
      series: {
        type: 'gantt',
        pointPlacement: 0.5,
        pointPadding: 0.25,
        accessibility: {
          enabled: false
        }
      },
      yAxis: {
        min: 0,
        max: 3,
        reversed: true,
        categories: []
      }
    },
    scrollbar: {
      enabled: false,
      useHTML: true
    },

    rangeSelector: {
      enabled: false,
      useHTML: true,
      selected: 0
    },
    accessibility: {
      point: {
        descriptionFormat:
          '{yCategory}. ' +
          '{#if completed}Task {(multiply completed.amount 100):.1f}% completed. {/if}' +
          'Start {x:%Y-%m-%d}*****, end {x2:%Y-%m-%d}.',
        useHTML: true
      },
      series: {
        descriptionFormat: '{name}',
        useHTML: true
      }
    },
    lang: {
      accessibility: {
        axis: {
          xAxisDescriptionPlural:
            'The chart has a two-part X axis showing time in both week numbers and days.',
          yAxisDescriptionPlural:
            'The chart has one Y axis showing task categories.',
          useHTML: true
        }
      }
    },
    labels: {
      format: '{value:%W}'
    },
    xAxis: {
      currentDateIndicator: {
        enabled: true,
        useHTML: true,
        labels: {
          format: '{value:%w}'
        }
      },
      useHTML: true,
      // type: 'datetime',
      gridLineWidth: 1,
      gridZIndex: 0,
      tickInterval: 1000 * 60 * 60,
      style: {
        backgroundColor: 'red'
      },
      plotBands: [
        {
          from: 1613361600000,
          to: 1612841640000
        }
      ]
    },

    series: data,
    legend: {
      enabled: legend,
      layout: 'horizontal',
      align: 'center',
      verticalAlign: 'bottom',
      borderWidth: 1
      // backgroundColor: 'rgba(255,255,255,0.85)',
    }
  }
  return (
    <div className="App">
      <MainCard variant="standerd" sx={{ border: '1px solid #E6EAEF' }}>
        {type === 'bar' && (
          <Box className="parent-bar-chart">
            <Typography className="Overall">Overall utilization</Typography>
            <Typography className="percentile">85%</Typography>
          </Box>
        )}
        <Typography className="chart-title">{title}</Typography>
        <HighchartsReact
          constructorType={'ganttChart'}
          highcharts={gantt}
          options={options}
          allowChartUpdate={true}
        />
      </MainCard>
    </div>
  )
}
export default GanntChart
