import React from 'react'
import { Box, Grid, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import CustomSelect from '../../leastcomponent/customselect/customselect'
import { MdCircle } from 'react-icons/md'
// TODO::  temporary comment
// import { TfiMenuAlt } from 'react-icons/tfi'

const HeadBox = styled(Box)(() => ({
  '.field-title': {
    fontSize: '14px!important',
    fontWeight: '600!important',
    padding: '10px 45px 10px 45px !important',
    color: '#000000',
    textAlign: 'center',
    marginTop: '20px',
    borderRadius: '0px 0px 8px 8px'

  },
  '.field-title1': {
    fontSize: '14px!important',
    fontWeight: '600!important',
    padding: '10px 60px 10px 60px !important',
    color: '#0160B9',
    textAlign: 'center',
    marginTop: '20px',
    borderRadius: '0px 0px 8px 8px'
  },
  '.machine-title': {
    color: '#265AA8',
    fontSize: '14px',
    fontWeight: '600',
    margin: '0px 0px 0px 0px',
    padding: '10px'
  },
  '.title-flex': {
    display: 'flex'
  },
  '.fild-name': {
    color: 'rgba(138, 138, 138, 1)',
    fontWeight: '400',
    margin: '0px 0px 0px 10px',
    padding: '0px 0px 0px 0px',
    fontSize: '13px'
  },
  '.fild-name-ans': {
    color: 'rgba(2, 2, 2, 1)',
    fontWeight: '600',
    fontSize: '13px',
    overflow: 'hidden',
    textOverflow: 'clip',
    display: '-webkit-box',
    WebkitLineClamp: '1',
    WebkitBoxOrient: 'vertical',
    whiteSpace: 'break-spaces'
  },
  '.box-1': {
    padding: '20px 10px 20px 10px '
  },
  '.box-2': {
    backgroundColor: 'rgba(255, 255, 255, 1)',
    margin: '0px 0px 30px 0px',
    padding: '10px 10px 20px 10px ',
    borderLeft: '4px solid #0160B9'
  },
  '.custom-select-machine': {
    width: '256px !important',
    height: '40px !important',
    color: '#000 !important'
  },
  '.timeline': {
    display: 'flex',
    justifyContent: 'space-between'
  },
  '.container-main': {
    // width: '100vw',

    '@media(min-width: 425px) and (max-width: 1024px)': {
      width: '100vw'
    },
    '@media(min-width: 1025px) and (max-width: 1440px)': {
      width: '100vw'
    }
  },
  '.container-main1': {
    // width: '100vw',
    overFlow: 'auto',
    '@media(min-width: 425px) and (max-width: 1024px)': {
      width: '100vw',
      overFlow: 'auto'
    },
    '@media(min-width: 1025px) and (max-width: 1440px)': {
      width: '100vw',
      overFlow: 'auto'
    }
  },

  '.timeline-2': {
    display: 'flex',
    justifyContent: 'space-around',
    marginLeft: '10px'
  },
  '.timeline-3': {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-around',
    marginLeft: '10px',
    gap: '10px'

  },
  '.apply-1': {
    display: 'flex',
    justifyContent: 'start'
  },
  '.machine-hedding': {
    fontSize: '18px!important',
    fontWeight: '600!important',
    color: '#000000',
    lineHeight: '28px!important'
  },
  '.button-box': {
    display: 'flex',
    border: '1px solid rgba(210, 209, 209, 1)',
    boxShadow: 'rgba(40, 40, 40, 0.08) 0px -6px 4px -6px'
  },
  '.status-header': {
    border: '2px solid #E3E3E3',
    borderRadius: '8px',
    padding: '6px',
    fontWeight: 500,
    fontSize: '14px',
    display: 'flex',
    alignItems: 'center',
    gap: '6px'
  },
  '.icons-border': {
    border: '1px solid rgba(198, 221, 255, 1)',
    borderRadius: '100px',
    padding: '12px',
    fontWeight: 500,
    backgroundColor: 'rgba(214, 231, 255, 1)',
    color: 'rgba(38, 90, 168, 1)',
    fontSize: '16px',
    display: 'flex',
    alignItems: 'center',
    gap: '16px',
    marginLeft: '30px'
  },
  '.kanban-summary': {
    fontWeight: '600 !important',
    alignItems: 'center',
    display: 'flex',
    marginTop: '5px',
    fontSize: '14px',
    '@media(min-width: 425px) and (max-width: 1024px)': {
      marginTop: '10px'
    }

  },
  '.common-kanban-main-box': {
    gap: '50px',
    whiteSpace: 'nowrap'

  },
  '.common-kanban-inline-block': {
    width: '268px',
    marginRight: '20px',
    display: 'inline-block',
    verticalAlign: 'top'

  },
  '.common-kanban-heading-name': {
    display: 'flex',
    justifyContent: 'space-between',

    '@media(min-width: 425px) and (max-width: 1024px)': {
      display: 'block',
      marginTop: '10px'
    }
  },
  '.timeline10': {

    '@media(min-width: 425px) and (max-width: 1024px)': {
      marginTop: '10px'
    }
  }
}))

const CommonKanbanView = ({ machineName, options, taskData, isSelect = false, handleChange, machineId = '' }) => {
  return (
    <Box>
      <Box>
        <HeadBox>

           {isSelect
             ? <Grid className="timeline" sx={{ marginTop: '20px' }} paddingRight={'10px'}>
            <Typography className="machine-hedding">{machineName}</Typography>
             <Box
              sx={{ display: 'flex', gap: '10px', alignItems: 'center' }}
              className="timeline"
            >
              <Box className="timeline" paddingRight={'15px'}>
                <Box className="timeline-3">
                  <CustomSelect
                    name="machines"
                    placeholder="Select Machine"
                    isRequired={false}
                    islabel={false}
                    options={options}
                    value={machineId}
                    handleSelectChange={(e) => {
                      if (typeof handleChange === 'function') {
                        handleChange(e.target.value)
                      }
                    }}
                    className="default-select-new"
                  />
                </Box>
                {/* TODO::  temporary comment */}
                {/* <Box className="icons-border">
                  <TfiMenuAlt />
                </Box> */}
              </Box>
            </Box>
             </Grid>
             : <Grid className="common-kanban-heading-name" sx={{ marginTop: '20px' }} paddingRight={'10px'}>
            <Typography className="machine-hedding">{machineName}</Typography>
             <Box
            sx={{ display: 'flex', gap: '10px', alignItems: 'center' }}
            className="timeline timeline10"
          >
            <Box className="parent-status timeline" paddingRight={'15px'}>
              <Typography className='kanban-summary'
              >
                Summary:
              </Typography>
              <Box className="timeline-3">
                <Typography className="status-header">
                  {' '}
                  <MdCircle color="rgba(151, 71, 255, 1)" /> Upcoming{' '}
                </Typography>
                <Typography className="status-header">
                  {' '}
                  <MdCircle color="#FFCD29" /> Queue{' '}
                </Typography>
                <Typography className="status-header">
                  {' '}
                  <MdCircle color="#FF9900" /> Overdue{' '}
                </Typography>
                <Typography className="status-header">
                  {' '}
                  <MdCircle color="#1AD04C" /> Completed{' '}
                </Typography>
              </Box>
              {/* TODO::  temporary comment */}
              {/* <Box className="icons-border">
                <TfiMenuAlt />
              </Box> */}
            </Box>
          </Box>

          </Grid>
           }
          <Box className="common-kanban-main-box" style={{ overflowX: 'auto', height: 'calc(100vh - 150px)' }}>
            {taskData?.map((tasks, index) => {
              return (
                <Box key={index} className="common-kanban-inline-block">
                  <Box>
                  <Typography
                   className={`field-title ${
                    tasks.name ? 'field-title1' : ''
                  }`}
                   backgroundColor={tasks.backgroundColor || '#D7EBFF'}>{tasks.name || tasks?.title}</Typography>
                  <Grid className="box-1" style={{ height: '100vh', overflowY: 'auto', backgroundColor: 'rgba(247, 249, 251, 1)' }}>
                    {tasks?.task?.map((taskItem, taskIndex) => {
                      return (
                        <Box key={taskIndex} className="box-2">
                          <Typography className="machine-title">{taskItem.taskNickName || taskItem?.taskName}</Typography>
                          <Box className="title-flex">
                            <Typography className="fild-name">Task Number :</Typography>
                            <Typography className="fild-name-ans">{taskItem.taskNumber || taskItem?.taskNumber}</Typography>
                          </Box>
                           <Box className="title-flex">
                            <Typography className="fild-name">Task Type :</Typography>
                            <Typography className="fild-name-ans">{taskItem?.taskType?.name || taskItem?.taskType}</Typography>
                          </Box>
                          <Box className="title-flex">
                            <Typography className="fild-name">Job Card Number :</Typography>
                            <Typography className="fild-name-ans">{taskItem?.jobCardId?.jobCardNumber || taskItem?.jobCardNumber}</Typography>
                          </Box>
                         {taskItem.text && <Box className="title-flex">
                            <Typography className="fild-name">Text :</Typography>
                            <Typography className="fild-name-ans">{taskItem.text || taskItem?.text}</Typography>
                          </Box>}
                        </Box>
                      )
                    })}
                  </Grid>
                  </Box>
                </Box>
              )
            })}
          </Box>
        </HeadBox>
      </Box>
    </Box>
  )
}

export default CommonKanbanView
