import { healthApi } from '../api/health'
import { useQuery } from '@tanstack/react-query'
import { health } from '../querykeys/health'

export const useGetHealth = (isEnable = false, page) => {
  return useQuery(
    [health.HEALTH],
    healthApi.health,
    {
      enabled: isEnable,
      select: (data) => data?.data,
      // ! TODO: for future implementation toast
      // onSuccess: () => {
      //   // const data = res?.data;
      // },
      onError: (err) => {
        return err
        // const errData = err?.data;
        // toast.error(errData.message);
      }
    }
  )
}
