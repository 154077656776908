import { Box, Grid, Typography } from '@mui/material'
import React from 'react'
import './jobcard.css'
import styled from '@emotion/styled'
const TextFiled = styled(Typography)(() => ({
  color: 'gray',
  paddingTop: '0px',
  fontSize: '14px !important',
  marginRight: '2px'
}))
const SingleJobcardComn = (props) => {
  const { shiftheding, title, ans1, ans2, ans3, ans4, ans5 } = props

  return (
        <React.Fragment>
            <Grid item xs={12} md={12} lg={6} >
                <Box className='details-box-border padding-bottom' mr={'10px'}>

                    <Typography id='modal-modal-title' className='details-sub-title'>
                        {shiftheding}
                    </Typography>
                    <Grid display={'flex'} mt={'10px'} className='margin-1'>
                        <Grid item xs={12} md={12} className='border-details-card'>
                            <Typography className='span-color '>
                                {title}
                            </Typography>
                            <Box display={'flex'}>
                                {/* todo */}
                                {/* <SingleJobcardFild fild='Description45'></SingleJobcardFild> */}
                                <TextFiled>Description: </TextFiled>
                                <Typography className='span-color  details-card'>
                                    {ans1}
                                </Typography>
                            </Box>
                            <Box display={'flex'}>
                                <TextFiled>Type: </TextFiled>
                                <Typography className='span-color  details-card'>
                                    {ans2}
                                </Typography>
                            </Box>
                            <Box display={'flex'} mt={'10px'}>
                                <TextFiled>Setup time per piece: </TextFiled>
                                <Typography className='span-color  details-card'>
                                    {ans3}
                                </Typography>
                            </Box>
                            <Box display={'flex'}>
                                <TextFiled>Production time per piece: </TextFiled>
                                <Typography className='span-color  details-card'>
                                    {ans4}
                                </Typography>
                            </Box>
                            <Box display={'flex'}>
                                <TextFiled>Changeover time per piece: </TextFiled>
                                <Typography className='span-color  details-card'>
                                    {ans5}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={12} className='border-details-card' ml={'10px'}>
                            <Typography className='span-color '>
                                Task Nickname - 7246712
                            </Typography>
                            <Box display={'flex'}>
                                <TextFiled>Description: </TextFiled>
                                <Typography className='span-color  details-card'>
                                    Lorem ipsum ..
                                </Typography>
                            </Box>
                            <Box display={'flex'}>
                                <TextFiled>Type :: </TextFiled>
                                <Typography className='span-color  details-card'>
                                    Lorem ipsum ..
                                </Typography>
                            </Box>
                            <Box display={'flex'} mt={'10px'}>
                                <TextFiled>Setup time per piece: </TextFiled>
                                <Typography className='span-color  details-card'>
                                    Lorem ipsum ..
                                </Typography>
                            </Box>
                            <Box display={'flex'}>
                                <TextFiled>Production time per piece: </TextFiled>
                                <Typography className='span-color  details-card'>
                                    Lorem ipsum ..
                                </Typography>
                            </Box>
                            <Box display={'flex'}>
                                <TextFiled>Changeover time per piece: </TextFiled>
                                <Typography className='span-color  details-card'>
                                    Lorem ipsum ..
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
        </React.Fragment>
  )
}

export default SingleJobcardComn
