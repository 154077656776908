import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'
import React from 'react'
import './jobcard.css'
import { Box, Grid } from '@mui/material'
// import BreadCrumb from "../../leastcomponent/breadcrumb/breadcrumb";
import TableComponent from '../../leastcomponent/customtable/customtable'
import EditIconNew from '../../assets/svg/EditIconNew'
import BreadcrumbNavigation from '../commoncomponents/breadcrumbnavigation'

const OnGoingJobCard = () => {
  const breadCrumbData = [
    {
      title: 'Job Cards',
      link: '/job-card'
    },
    {
      title: 'Job Card Name',
      link: ''
    }
  ]
  const rows = [
    {
      id: 1,
      name: 'Process/departName',
      code: '123432534',
      action: 'action'
    },
    {
      id: 2,
      name: 'Process/departName',
      code: '123432534',
      action: 'action'
    },
    {
      id: 3,
      name: 'Process/departName',
      code: '123432534',
      action: 'action'
    },
    {
      id: 4,
      name: 'Process/departName',
      code: '123432534',
      action: 'action'
    }
  ]
  const columns = [
    { field: 'id', headerName: '#', flex: 0.2 },
    { field: 'name', headerName: 'Process/depart Name', flex: 1 },
    { field: 'code', headerName: 'Code', flex: 1 },
    {
      field: 'actions',
      headerName: 'Actions',
      sortable: false,
      disableClickEventBubbling: true,
      flex: 1,
      renderCell: () => {
        return (
          <Box className="resource-table-btn-main">
            <span className="process-delete">
              <EditIconNew />
            </span>
          </Box>
        )
      }
    }
  ]
  return (
    <React.Fragment>
      <BreadcrumbNavigation
        breadCrumbData={breadCrumbData}
        pageTitle={'Job Card Name , 098-9273-1783-111-018'}
        backToLink={null}
      />

      <Box paddingTop={'30px'}>
        <Box className="padding-5">
          {/* <BreadCrumb
            breadCrumbData={breadCrumbData}
            className="padding-y-20"
            title="Job Card Name , 098-9273-1783-111-018"
          /> */}
          <Grid lg={5} paddingRight={'20px'} className="align-center-with-gap">
            {' '}
            Status
            <span className="align-center">
              <FiberManualRecordIcon
                sx={{ fontSize: '12px' }}
                color="warning"
              />
            </span>
            <span className="font-size-14-with-semibold"> Pending </span>
          </Grid>
        </Box>
        <Box className="padding-x-60">
          <Box className="border-border-radius">
            <Box className="padding-2-border-bg">
              <Box className="font-size-16-with-semibold">Basic Details</Box>
            </Box>
            <Box className="padding-10-2">
              <Grid container className="padding-x-40">
                <Grid lg={2}>
                  <Box className="font-size-14">Job card Name</Box>
                  <Box className="font-size-14-with-semibold">
                    Job card Name
                  </Box>
                </Grid>
                <Grid lg={2}>
                  <Box className="font-size-14">Job Card Number</Box>
                  <Box className="font-size-14-with-semibold">
                    098-9273-1783-111-018
                  </Box>
                </Grid>
                <Grid lg={2}>
                  <Box className="font-size-14">OC number</Box>
                  <Box className="font-size-14-with-semibold">
                    098-9273-1783-111-018
                  </Box>
                </Grid>
                <Grid lg={2}>
                  <Box className="font-size-14">Date</Box>
                  <Box className="font-size-14-with-semibold">11/11/11</Box>
                </Grid>
              </Grid>
              <Grid container className="padding-10">
                <Grid lg={2}>
                  <Box className="font-size-14">Job card Name</Box>
                  <Box className="font-size-14-with-semibold">
                    Job card Name
                  </Box>
                </Grid>
                <Grid lg={2}>
                  <Box className="font-size-14">Job Card Number</Box>
                  <Box className="font-size-14-with-semibold">
                    098-9273-1783-111-018
                  </Box>
                </Grid>
                <Grid lg={2}>
                  <Box className="font-size-14">Part Name</Box>
                  <Box className="font-size-14-with-semibold">Part Name</Box>
                </Grid>
                <Grid lg={2}>
                  <Box className="font-size-14">Part Code</Box>
                  <Box className="font-size-14-with-semibold">123456789</Box>
                </Grid>
              </Grid>
              <Grid container className="padding-10">
                <Grid lg={7} wrap="nowrap" spacing={2}>
                  <Box className="font-size-14">Description</Box>
                  <Box className="font-size-14-with-semibold">
                    Lorem ipsum dolor sit amet consectetur. Nibh mauris montes
                    quis ut purus elit nulla. Viverra ultrices congue imperdiet
                    tortor a aliquet vitae enim mattis. Blandit integer volutpat
                    turpis turpis ultrices lacus non fermentum. Eu viverra amet
                    egestas purus mauris semper.
                  </Box>
                </Grid>
                <Grid lg={5}>
                  <Box className="parent-com">
                    <Box className="font-size-14">Quantity</Box>
                    <Box className="font-size-14-with-semibold">11</Box>
                  </Box>{' '}
                  {/* <Box className="parent-com">
                    <Box className="font-size-14">Produced Quantity</Box>
                    <Box className="font-size-14-with-semibold">11</Box>
                  </Box>{' '}
                  <Box className="parent-com">
                    <Box className="font-size-14">Balance Quantity</Box>
                    <Box className="font-size-14-with-semibold">11</Box>
                  </Box> */}
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
        <Box className="padding-x-60">
          <Box className="border-border-radius">
            <Box className="padding-2-border-bg">
              <Box className="font-size-16-with-semibold">1. Part Name</Box>
            </Box>
            <Box className="padding-10-2">
              <Grid container className="padding-x-40">
                <Grid lg={2}>
                  <Box className="font-size-14">Item Code</Box>
                  <Box className="font-size-14-with-semibold">
                    098-9273-1783-111-018
                  </Box>
                </Grid>
                <Grid lg={2}>
                  <Box className="font-size-14">Drawing number</Box>
                  <Box className="font-size-14-with-semibold">
                    098-9273-1783-111-018
                  </Box>
                </Grid>
                <Grid lg={2}>
                  <Box className="font-size-14">Weight</Box>
                  <Box className="font-size-14-with-semibold">12</Box>
                </Grid>
                <Grid lg={2}>
                  <Box className="font-size-14">Raw Material</Box>
                  <Box className="font-size-14-with-semibold">Raw Material</Box>
                </Grid>
              </Grid>
              <Grid container className="padding-10">
                <Grid lg={2}>
                  <Box className="font-size-14">
                    Raw Material Rough Size - OD
                  </Box>
                  <Box className="font-size-14-with-semibold">12</Box>
                </Grid>
                <Grid lg={2}>
                  <Box className="font-size-14">
                    Raw Material Rough Size - ID
                  </Box>
                  <Box className="font-size-14-with-semibold">12</Box>
                </Grid>
                <Grid lg={2}>
                  <Box className="font-size-14">
                    Raw Material Rough Size - L
                  </Box>
                  <Box className="font-size-14-with-semibold">12</Box>
                </Grid>
              </Grid>
              <Grid container className="padding-10">
                <Grid lg={7} wrap="nowrap" spacing={2}>
                  <Box className="font-size-14">Description</Box>
                  <Box className="font-size-14-with-semibold">
                    Lorem ipsum dolor sit amet consectetur. Nibh mauris montes
                    quis ut purus elit nulla. Viverra ultrices{' '}
                  </Box>
                </Grid>
              </Grid>
              <Grid container className="padding-10">
                <Grid lg={7} wrap="nowrap" spacing={2}>
                  <Box className="font-size-14">Process details</Box>
                  <Box width={'70%'}>
                    {' '}
                    <TableComponent
                      rows={rows}
                      columns={columns}
                      isShowFiveRecords={true}
                    />{' '}
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
        <Box className="padding-x-60">
          <Box className="border-border-radius">
            <Box className="padding-2-border-bg">
              <Box className="font-size-16-with-semibold">2. Part Name</Box>
            </Box>
            <Box className="padding-10-2">
              <Grid container className="padding-x-40">
                <Grid lg={2}>
                  <Box className="font-size-14">Item Code</Box>
                  <Box className="font-size-14-with-semibold">
                    098-9273-1783-111-018
                  </Box>
                </Grid>
                <Grid lg={2}>
                  <Box className="font-size-14">Drawing number</Box>
                  <Box className="font-size-14-with-semibold">
                    098-9273-1783-111-018
                  </Box>
                </Grid>
                <Grid lg={2}>
                  <Box className="font-size-14">Weight</Box>
                  <Box className="font-size-14-with-semibold">12</Box>
                </Grid>
                <Grid lg={2}>
                  <Box className="font-size-14">Raw Material</Box>
                  <Box className="font-size-14-with-semibold">Raw Material</Box>
                </Grid>
              </Grid>
              <Grid container className="padding-10">
                <Grid lg={2}>
                  <Box className="font-size-14">
                    Raw Material Rough Size - OD
                  </Box>
                  <Box className="font-size-14-with-semibold">12</Box>
                </Grid>
                <Grid lg={2}>
                  <Box className="font-size-14">
                    Raw Material Rough Size - ID
                  </Box>
                  <Box className="font-size-14-with-semibold">12</Box>
                </Grid>
                <Grid lg={2}>
                  <Box className="font-size-14">
                    Raw Material Rough Size - L
                  </Box>
                  <Box className="font-size-14-with-semibold">12</Box>
                </Grid>
              </Grid>
              <Grid container className="padding-10">
                <Grid lg={7} wrap="nowrap" spacing={2}>
                  <Box className="font-size-14">Description</Box>
                  <Box className="font-size-14-with-semibold">
                    Lorem ipsum dolor sit amet consectetur. Nibh mauris montes
                    quis ut purus elit nulla. Viverra ultrices{' '}
                  </Box>
                </Grid>
              </Grid>
              <Grid container className="padding-10">
                <Grid lg={7} wrap="nowrap" spacing={2}>
                  <Box className="font-size-14">Process details</Box>
                  <Box width={'70%'}>
                    {' '}
                    <TableComponent
                      rows={rows}
                      columns={columns}
                      isShowFiveRecords={true}
                    />{' '}
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
      </Box>
    </React.Fragment>
  )
}
export default OnGoingJobCard

// To Do future:
// import React, { useState } from 'react';
// import Box from '@mui/material/Box';
// import Collapse from '@mui/material/Collapse';
// import Table from '@mui/material/Table';
// import TableBody from '@mui/material/TableBody';
// import TableCell from '@mui/material/TableCell';
// import TableContainer from '@mui/material/TableContainer';
// import TableHead from '@mui/material/TableHead';
// import TableRow from '@mui/material/TableRow';
// import Paper from '@mui/material/Paper';

// function Row({ row }) {
//   const [open, setOpen] = useState(false);

//   return (
//     <React.Fragment>
//       <TableRow>
//         <TableCell onClick={() => setOpen(!open)}>
//           {open ? (
//             <TableCell component="th" scope="row">
//               {row.name}
//             </TableCell>
//           ) : (
//             <TableCell component="th" scope="row">
//               {row.name}
//             </TableCell>
//           )}
//         </TableCell>
//       </TableRow>
//       <TableRow>
//         <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
//           <Collapse in={open} timeout="auto" unmountOnExit>
//             <Box sx={{ margin: 1 }}>
//               <Table size="small" aria-label="purchases">
//                 <TableHead>
//                   <TableRow>
//                     <TableCell>Date</TableCell>
//                     <TableCell>Customer</TableCell>
//                     <TableCell align="right">Amount</TableCell>
//                     <TableCell align="right">Total price ($)</TableCell>
//                   </TableRow>
//                 </TableHead>
//                 <TableBody>
//                   {row.history.map((historyRow) => (
//                     <TableRow key={historyRow.date}>
//                       <TableCell component="th" scope="row">
//                         {historyRow.date}
//                       </TableCell>
//                       <TableCell>{historyRow.customerId}</TableCell>
//                       <TableCell align="right">{historyRow.amount}</TableCell>
//                       <TableCell align="right">
//                         {Math.round(historyRow.amount * row.price * 100) / 100}
//                       </TableCell>
//                     </TableRow>
//                   ))}
//                 </TableBody>
//               </Table>
//             </Box>
//           </Collapse>
//         </TableCell>
//       </TableRow>
//     </React.Fragment>
//   );
// }

// const CollapsibleTable = ({ tableData, tableHeader }) => {
//   return (
//     <TableContainer component={Paper}>
//       <Table>
//         <TableHead>
//           <TableRow>
//             <TableCell>{tableHeader}</TableCell>
//           </TableRow>
//         </TableHead>
//         <TableBody>
//           {tableData.map((row) => (
//             <Row key={row.name} row={row} />
//           ))}
//         </TableBody>
//       </Table>
//     </TableContainer>
//   );
// };
// function createData(
//     name,
//     calories,
//     fat,
//     carbs,
//     protein,
//     price,
//   ) {
//     return {
//       name,
//       calories,
//       fat,
//       carbs,
//       protein,
//       price,
//       history: [
//         {
//           date: '2020-01-05',
//           customerId: '11091700',
//           amount: 3,
//         },
//         {
//           date: '2020-01-02',
//           customerId: 'Anonymous',
//           amount: 1,
//         },
//       ],
//     };
//   }

// export default function App() {
//   const rows = [
//     createData('Frozen yoghurt', 159, 6.0, 24, 4.0, 3.99),
//     createData('Ice cream sandwich', 237, 9.0, 37, 4.3, 4.99),
//     createData('Eclair', 262, 16.0, 24, 6.0, 3.79),
//     createData('Cupcake', 305, 3.7, 67, 4.3, 2.5),
//     createData('Gingerbread', 356, 16.0, 49, 3.9, 1.5),
//   ];

//   return <CollapsibleTable tableData={rows} tableHeader="Dessert (100g serving)" />;
// }
