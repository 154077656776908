import { Box, Typography } from '@mui/material'
import React from 'react'
// import BreadCrumb from "../../leastcomponent/breadcrumb/breadcrumb";
import CustomButton from '../../leastcomponent/custombutton/custombutton'
import TableComponent from '../../leastcomponent/customtable/customtable'
import AddIcon from '@mui/icons-material/Add'
import DeleteIconNew from '../../assets/svg/DeleteIconNew'
import { useNavigate } from 'react-router-dom'
import './createreport.css'
import EditIconNew from '../../assets/svg/EditIconNew'
import BreadcrumbNavigation from '../commoncomponents/breadcrumbnavigation'

const ReportsPage = () => {
  const navigate = useNavigate()

  const rows = [
    {
      id: 1,
      name: 'Report Name',
      createdOn: '10/10/10, 10:10',
      createdBy: 'Lorem Ipsum',
      recurring: '10/10/10, 10:10',
      timeline: '11/11/11-11/11/11',
      action: 'action'
    },
    {
      id: 2,
      name: 'Report Name',
      createdOn: '10/10/10, 10:10',
      createdBy: 'Lorem Ipsum',
      recurring: '10/10/10, 10:10',
      timeline: '11/11/11-11/11/11',
      action: 'action'
    },
    {
      id: 3,
      name: 'Report Name',
      createdOn: '10/10/10, 10:10',
      createdBy: 'Lorem Ipsum',
      recurring: '10/10/10, 10:10',
      timeline: '11/11/11-11/11/11',
      action: 'action'
    },
    {
      id: 4,
      name: 'Report Name',
      createdOn: '10/10/10, 10:10',
      createdBy: 'Lorem Ipsum',
      recurring: '10/10/10, 10:10',
      timeline: '11/11/11-11/11/11',
      action: 'action'
    },
    {
      id: 5,
      name: 'Report Name',
      createdOn: '10/10/10, 10:10',
      createdBy: 'Lorem Ipsum',
      recurring: '10/10/10, 10:10',
      timeline: '11/11/11-11/11/11',
      action: 'action'
    }
  ]
  // const finalData = [
  //   {
  //     no: 1,
  //     jobCardNumber: 123456789,
  //     jobCardName: "Job Card Name",
  //     product: "Product",
  //     qty: 10,
  //     customerName: "Customer",
  //     submittedon: 10,
  //     dueDate: 10,
  //     overallCompletion: 10,
  //     history: [
  //       {
  //         partName: "Part Name",
  //         partCode: 123456,
  //         drawingNumber: 123456,
  //         qty: 10,
  //         deptSequence: 2,
  //         precedingDept: "Department",
  //         followingDept: "Department",
  //         completion: 10,
  //         action: "action",
  //       },
  //     ],
  //   },
  //   {
  //     no: 2,
  //     jobCardNumber: 123456789,
  //     jobCardName: "Job Card Name",
  //     product: "Product",
  //     qty: 10,
  //     customerName: "Customer",
  //     submittedon: 10,
  //     dueDate: 10,
  //     overallCompletion: 10,
  //     history: [
  //       {
  //         partName: "Part Name",
  //         partCode: 123456,
  //         drawingNumber: 123456,
  //         qty: 10,
  //         deptSequence: 2,
  //         precedingDept: "Department",
  //         followingDept: "Department",
  //         completion: 10,
  //         action: "action",
  //       },
  //     ],
  //   },
  //   {
  //     no: 3,
  //     jobCardNumber: 123456789,
  //     jobCardName: "Job Card Name",
  //     product: "Product",
  //     qty: 10,
  //     customerName: "Customer",
  //     submittedon: 10,
  //     dueDate: 10,
  //     overallCompletion: 10,
  //     // history: [
  //     // {
  //     //     jobCardNumber: 147,
  //     //     jobCardName: "Frozen yoghurt",
  //     //     qty: 8,
  //     //     product: 6,
  //     //     customerName: 24,
  //     //   },
  //     // ],
  //   },
  // ];

  // const finalDataColumns = [
  //   { field: "id", headerName: "#", flex: 0.2 },
  //   { field: "Part Name", headerName: "Part Name", flex: 1 },
  //   { field: " Part Code", headerName: " Part Code", flex: 1 },
  //   { field: "Drawing Number", headerName: "Drawing Number", flex: 1 },
  //   { field: "Qty", headerName: "Qty", flex: 1 },
  //   { field: "Dept. sequence", headerName: "Dept. sequence", flex: 1 },
  //   { field: "Preceding dept.", headerName: "Preceding dept.", flex: 1 },
  //   { field: "Following dept.", headerName: "Following dept.", flex: 1 },
  //   { field: "Completion", headerName: "Completion", flex: 1 },
  //   {
  //     field: "actions",
  //     headerName: "Actions",
  //     sortable: false,
  //     disableClickEventBubbling: true,
  //     flex: 1,
  //     renderCell: () => {
  //       return (
  //         <Box className="report-table-btn-main">
  //           <span className="report-delete">
  //             <EditIconNew />
  //           </span>
  //           <span className="report-delete">
  //             <DeleteIconNew />
  //           </span>
  //         </Box>
  //       );
  //     },
  //   },
  // ];

  const columns = [
    { field: 'id', headerName: '#', flex: 0.2 },
    { field: 'name', headerName: 'Report Name', flex: 1 },
    { field: 'createdOn', headerName: 'Created on', flex: 1 },
    { field: 'createdBy', headerName: 'Created by', flex: 1 },
    { field: 'recurring', headerName: 'Recurring On', flex: 1 },
    { field: 'timeline', headerName: 'Timeline', flex: 1 },
    {
      field: 'actions',
      headerName: 'Actions',
      sortable: false,
      disableClickEventBubbling: true,
      flex: 1,
      renderCell: () => {
        return (
          <Box className="report-table-btn-main">
            <span className="report-delete">
              <EditIconNew />
            </span>
            <span className="report-delete">
              <DeleteIconNew />
            </span>
          </Box>
        )
      }
    }
  ]

  return (
    <Box className="production-team-head-overflow-hidden">
      <BreadcrumbNavigation
        breadCrumbData={null}
        pageTitle={'Reports'}
        backToLink={'/resource'}
      />
      <Box className="common-parent-class-overflow-auto">
        {/* <BreadCrumb title="Reports" /> */}
        <Box>
          <Box className="common-parent-haeding-btn-div">
            <Typography className="common-tabel-heading">Reports </Typography>
            <CustomButton
              variant="contained"
              //   disabled = false,
              title="Create Report"
              onClick={() => {
                navigate('/createreport')
              }}
              type="button"
              endIcon=""
              startIcon={<AddIcon />}
            />
          </Box>
          <TableComponent rows={rows} columns={columns} />
        </Box>
      </Box>
    </Box>
  )
}

export default ReportsPage
