import * as React from 'react'
import { Box } from '@mui/system'
import {
  Divider,
  Modal,
  Typography
  , Grid
} from '@mui/material'
import { styled } from '@mui/material/styles'
import CloseIcon from '@mui/icons-material/Close'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { Button } from '@mui/base'
import dayjs from 'dayjs'
import TextField from '@mui/material/TextField'
import { Form, Formik } from 'formik'
import CustomInput from '../../leastcomponent/custominput/input'
// import Radio from '@mui/material/Radio';
// import RadioGroup from '@mui/material/RadioGroup';
// import FormControlLabel from '@mui/material/FormControlLabel';
// import FormControl from '@mui/material/FormControl';
import CustomButton from '../../leastcomponent/custombutton/custombutton'
import JobCardConfirmModal from './jobCardConfirmModal'
import { useCompleteTask } from '../../hooks/jobcardHooks'
import * as Yup from 'yup'
// import { AiOutlineInfoCircle } from 'react-icons/ai'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 900,
  height: 500,
  overflow: 'auto',
  bgcolor: 'background.paper',
  borderRadius: '8px',
  boxShadow: 24,
  overFlow: 'auto',
  padding: '20px 20px 20px 9px'
}
const TextFiled = styled(Typography)(() => ({
  color: 'gray',
  paddingTop: '10px',
  fontSize: '14px !important',
  padding: '24px 0px 0px 24px'
}))
const HeadBox = styled(Box)(() => ({
  '.btn-box': {
    display: 'flex',
    justifyContent: 'end'
    // margin: '3px 20px 30px 0px'
  },
  '.customFormControl': {
    fontSize: '1px !important',
    fontWeight: '800'
  },
  '.customRadioGroup': {
    fontSize: '14px',
    fontWeight: '400',
    display: 'flex'
  },
  '.css-1t1j96h-MuiPaper-root-MuiDialog-paper': {
    borderRadius: '32px !important'
  },
  '.validation-error': {
    color: 'red'
  },
  '.validation-errors': {
    color: 'red',
    paddingTop: '20px !important',
    fontSize: '12px !important'
  },
  '.jobcardform': {
    // padding: '24px 0px 0px 24px',
    display: 'flex',
    justifyContent: 'end'
  },
  '.date-picker-fieldset': {
    width: '100%',
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#8A8A8A'
      }
    },
    '.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input': {
      padding: '9.5px !important'
    },
    '.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input': {
      padding: '9.5px 12px !important'
    },
    '.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root': {
      borderRadius: '4px !important'
    }
  },
  '.date-picker': {
    width: '100%',
    '.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input': {
      padding: '9.5px 12px'
    },
    '.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root': {
      borderRadius: '4px'
    }
  },
  '.btn-text-transform': {
    textTransform: 'capitalize !important'
  }
}))
function ActionList (props) {
  const { onAccept, onCancel, onSetToday } = props
  const actions = [
    { text: 'Apply', method: onAccept },
    { text: 'Cancel', method: onCancel },
    // { text: 'Clear', method: onClear },
    { text: 'Today', method: onSetToday }
  ]
  return (
    <Box className='datepicker-btn-wrapper-factory-layout'>
      <Button className='blue-color datepicker-btn-factory-layout' onClick={() => onCancel()}>
        {actions[1].text}
      </Button>
      <Button className='blue-bg datepicker-btn' variant='outlined' onClick={() => onAccept()}>
        {actions[0].text}
      </Button>
    </Box>
  )
}
const JobCardDetailsModal = (props) => {
  const { mutate: completeTask } = useCompleteTask()
  // const { processData } = props
  const [newopen, setNewOpen] = React.useState(false)
  const handleDetailsClose = () => {
    setNewOpen(false)
  }
  const {
    open,
    DetailsHandleClose,
    tasks
  } = props
  const selectDepartmentid = localStorage.getItem('selectDepartmentid')
  const newData = props?.data2?.[0]?.bomprocess?.find((data) => data?.department?.id === selectDepartmentid)
  const initialValue = tasks?.history?.map((item) => {
    return {
      taskName: item?.taskNickName,
      dateTime: '',
      duration: '',
      remarks: '',
      taskId: item?.taskId
    }
  })
  // const icon = <AiOutlineInfoCircle color="red" />

  // const validationSchema = Yup.object().shape({

  //   dateTime: Yup.date().required('Date and time are required'),
  //   duration: Yup.number()
  //     .required('Duration is required')
  //     .min(1, 'Duration must be at least 1 minute')
  //     .max(1440, 'Duration cannot be more than 1440 minutes')

  // })
  const validationSchema = Yup.object().shape({
    task: Yup.array().of(
      Yup.object().shape({

        dateTime: Yup.date().required('Date and time are required'),
        duration: Yup.number()
          .required('Duration is required')
          .min(1, 'Duration must be at least 1 minute')
          .max(1440, 'Duration cannot be more than 1440 minutes').typeError('Duration must be a number'),
        remarks: Yup.string()
      })
    )
  })

  return (
    <React.Fragment>
      <Modal
        open={open}
        onClose={DetailsHandleClose}
        aria-labelledby=''
        aria-describedby='modal-modal-description'
      >
        <HeadBox sx={style}>
          <Box className='title-head-box'>
            <Typography className='top-up-name'>Update Task Status</Typography>
            <CloseIcon className='close-icon' onClick={DetailsHandleClose} />
          </Box>
          <Box mx={'25px !important'}>
          </Box>
          <Grid item xs={6}>
            <Box className='padding-bottom'>
              <Grid container display={'flex'}>
                <Grid item xs={3}>
                  <TextFiled>Department name </TextFiled>
                  <Typography className='span-color pl-sub-text'>
                    {tasks?.name}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <TextFiled>Process Code </TextFiled>
                  <Typography className='span-color pl-sub-text'>
                  {tasks?.code}
                  </Typography>
                </Grid>
                {/* <Grid item xs={3}>
                  <TextFiled>Process/ Department </TextFiled>
                  <Typography className='span-color pl-sub-text'>
                    {props?.data1?.length > 0 ? props?.data2?.[0]?.bomprocess?.[0]?.department?.departmentgroups?.title : processData?.data?.departmentGroupName}
                  </Typography>
                </Grid> */}
                <Grid item xs={3}>
                  <TextFiled>Sequence number </TextFiled>
                  <Typography className='span-color pl-sub-text'>
                    {newData?.numericIndex}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Box padding={'0px 24px'}>
            <Divider></Divider>
          </Box>
          <Formik
            initialValues={{
              task: initialValue
            }}
            validationSchema={validationSchema}
            enableReinitialize={true}
            onSubmit={(values) => {
              completeTask(values)
            }}
          >
            {({ handleChange, values, setFieldValue, touched, errors }) => (
              <Form>
                {values?.task?.map((task, index) => {
                  return (
                      <Grid key={`task-${index}`} spacing={2} container paddingTop={2} className='jobcardform'>
                        <Grid item xs={12} className='title-head-box' display={'flex'}>
                          <Typography className='top-up-name' paddingLeft={'15px'}>{index + 1}. {task.taskName}</Typography>
                          <Box display={'flex'} >
                            <TextFiled className=' pl-sub-text'>
                              Machine/Workstation
                            </TextFiled>
                            <Typography className='span-color pl-sub-text'>
                              {newData?.task?.[index]?.resource?.name}
                            </Typography>
                            <TextFiled className=' pl-sub-text'>
                              Machine/Workstation code
                            </TextFiled>
                            <Typography className='span-color pl-sub-text'>
                            {newData?.task?.[index]?.resource?.code}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={12} md={3.9} xl={3.9}>
                          <Typography className='field-title1'>
                          End date and time
                          </Typography>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              orientation='portrait'
                              dayOfWeekFormatter={(day) => `${day}`}
                              className='date-picker'
                              disablePast
                              views={['year', 'day', 'month']}
                              closeOnSelect={false}
                              value={task.dateTime || dayjs(new Date())}
                              inputFormat='DD/MM/YYYY'
                              name={`task.${index}.dateTime`}
                              onChange={(newValue) => {
                                setFieldValue(`task.${index}.dateTime`, newValue?.toDate())
                              }}
                              components={{
                                ActionBar: ActionList
                                // OpenPickerIcon: ClockIcon,
                              }}
                              componentsProps={{
                                actionBar: {
                                  actions: ['today', 'cancel', 'clear']
                                }
                              }}
                              renderInput={(params) => {
                                if (task?.dateTime) {
                                  return (
                                    <TextField
                                      {...params}
                                      placeholder='Select date'
                                      className='date-picker-fieldset'
                                    />
                                  )
                                } else {
                                  delete params.inputProps
                                  return (
                                    <TextField
                                      {...params}
                                      disabled={true}
                                      placeholder='Select date'
                                      className='date-picker-fieldset'
                                      error={false}
                                    />
                                  )
                                }
                              }}
                            />

                          </LocalizationProvider>
                          {errors?.task?.[index]?.dateTime && (
                      <Typography className='validation-errors'>{errors?.task?.[index]?.dateTime}</Typography>
                          )}
                        </Grid>
                        <Grid item xs={12} md={3.9} xl={3.9}>
                          <Box paddingRight={'10px'}>
                            <CustomInput
                              label="Remarks"
                              placeholder="Enter Remarks"
                              handleChange={handleChange}
                              value={task?.remarks}
                              name={`task.${index}.remarks`}
                              isRequired={false}
                            />
                          </Box>
                        </Grid>
                        <Grid item xs={12} md={3.9} xl={3.9}>
                          <Box paddingRight={'10px'}>
                            <CustomInput
                              label="Duration (mins)"
                              placeholder="Enter duration"
                              handleChange={handleChange}
                              value={task?.duration}
                              name={`task.${index}.duration`}
                              isRequired={false}
                              // InputProps={{
                              //   endAdornment:
                              //   touched?.task?.[index]?.duration && errors?.task?.[index]?.duration
                              // }}
                              errors={errors?.task?.[index]?.duration}
                              touched={touched?.task?.[index]?.duration}
                            />
                          </Box>
                          {/* {errors?.duration && (
                       <Typography className='validation-error'>{errors?.duration}</Typography>
                          )} */}
                        </Grid>
                        {/* TODO */}
                        {/* <Grid item xs={12} md={3.9} xl={3.9} mr={'15px'}>
                          <Typography className='field-title'>
                            Durarion
                          </Typography>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              orientation='portrait'
                              dayOfWeekFormatter={(day) => `${day}`}
                              className='date-picker'
                              disablePast
                              views={['year', 'day', 'month']}
                              closeOnSelect={false}
                              value={values.duration || dayjs(new Date())}
                              name={`task.${index}.dateTime`}
                              inputFormat='DD/MM/YYYY'
                              onChange={(newValue) => {
                                setFieldValue('dateTime', newValue?.toDate())
                              }}
                              components={{
                                ActionBar: ActionList
                                // OpenPickerIcon: ClockIcon,
                              }}
                              componentsProps={{
                                actionBar: {
                                  actions: ['today', 'cancel', 'clear']
                                }
                              }}
                              renderInput={(params) => {
                                if (values?.dateTime) {
                                  return (
                                    <TextField
                                      {...params}
                                      placeholder='Select date'
                                      className='date-picker-fieldset'
                                    />
                                  )
                                } else {
                                  delete params.inputProps
                                  return (
                                    <TextField
                                      {...params}
                                      disabled={true}
                                      placeholder='Select date'
                                      className='date-picker-fieldset'
                                      error={false}
                                    />
                                  )
                                }
                              }}
                            />
                          </LocalizationProvider>
                        </Grid> */}
                        {/* TODO */}
                        {/* <Grid item xs={12} md={2} xl={2} >
                          <Box paddingRight={"10px"}>
                            <CustomInput
                              label="Completion %"
                              color='gray'
                              placeholder="Enter Remarks"
                              handleChange={handleChange}
                              value={values?.remarks}
                              name="remarks"
                              isRequired={false}
                            />
                          </Box>
                        </Grid> */}
                      </Grid>
                  )
                })}
                <Box className="btn-box" paddingTop={'70px'}>
                  <CustomButton title="Confirm" type="submit"
                  // onClick={() => setNewOpen(true)}
                  />
                </Box>
              </Form>
            )}
          </Formik>
        </HeadBox>
      </Modal>
      {newopen && (
        <JobCardConfirmModal
          open={newopen}
          DetailsHandleClose={handleDetailsClose}
          setOpen={() => setNewOpen(false)}
        />
      )}
    </React.Fragment>
  )
}
export default JobCardDetailsModal
