import React from 'react'
import { Box, Typography } from '@mui/material'
import NoAddedYet from '../assets/svg/NoAddedYet'

const NoData = (props) => {
  const {
    label = ''

  } = props
  return (
    <>
        <Box display={'flex'} justifyContent={'center'} alignItems={'center'} maxHeight={'5000px'}>
                <Box textAlign={'center'}>
                  <NoAddedYet />
                  <Typography sx={{ color: '#000000DE' }}>{label}</Typography>
                  {/* <Typography>{'No added yet'}</Typography> */}
                </Box>
              </Box>
    </>
  )
}

export default NoData
