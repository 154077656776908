import * as React from 'react'
import Box from '@mui/material/Box'
import './approvedmodal.css'
import CustomButton from '../../leastcomponent/custombutton/custombutton'
import CustomizedModal from '../../leastcomponent/custommodal/commonmodal'
import { Grid, useMediaQuery, useTheme } from '@mui/material'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import { useUpdateSalesMember } from '../../hooks/jobcardHooks'

const JobSlipUpdateModal = (props) => {
  const theme = useTheme()
  const { mutate: updateSalesMember } = useUpdateSalesMember()
  const isMdScreen = useMediaQuery(theme.breakpoints.only('md'))
  const {
    open,
    handleClose
    // id,
  } = props

  const validationSchema = Yup.object().shape({
    priority: Yup.string().required('Priority is required')
  })

  return (
        <>
            <CustomizedModal
                isHeadingName={false}
                open={open}
                onClick={handleClose}
                width={isMdScreen ? '40%' : '30%'}
            >
                <Formik
                    initialValues={{
                      priority: ''
                    }}
                    enableReinitialize={true}
                    validationSchema={validationSchema}
                    onSubmit={(values) => {
                      updateSalesMember({
                        ...props.data,
                        ...values
                      })
                      handleClose()
                    }}
                >
                    {({ handleSubmit }) => (
                        <Form>
                            <Box display="flex" flexDirection="column" alignItems="center">
                                <Box className="approved-padding">
                                    <Box className="approved-job-card-box">
                                        <Box className="approved-job-card-main-box" sx={{ whiteSpace: 'nowrap' }}>
                                            Job slips updated
                                        </Box>
                                    </Box>
                                </Box>
                                <Box className="process-main-box">
                                </Box>
                                <Grid container justifyContent="center" spacing={2}>
                                    <Grid item>
                                        <CustomButton
                                            variant="outlined"
                                            className="button-box"
                                            type="button"
                                            title="Okay"
                                            onClick={() => {
                                              handleSubmit('')
                                            }}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <CustomButton
                                            className="button-box"
                                            type="button"
                                            title="View Job slips"
                                            onClick={() => {
                                              handleSubmit('')
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </Box>
                        </Form>
                    )}
                </Formik>
            </CustomizedModal>
        </>
  )
}
export default JobSlipUpdateModal
