import { Box, CircularProgress, Tab, Typography } from '@mui/material'
import React, { useState } from 'react'
import Tabs from '@mui/material/Tabs'
import { TabContext, TabPanel } from '@mui/lab'
import AddIcon from '@mui/icons-material/Add'
import TableComponent from '../../leastcomponent/customtable/customtable'
import { useLocation, useNavigate } from 'react-router-dom'
import DeleteIconNew from '../../assets/svg/DeleteIconNew'
import DeleteWarningModal from '../../modals/deletewarningmodal'
import EditIconNew from '../../assets/svg/EditIconNew'
import CustomButton from '../../leastcomponent/custombutton/custombutton'
import BreadcrumbNavigation from '../commoncomponents/breadcrumbnavigation'
import { useDeleteUser, useGetUser } from '../../hooks/userHooks'
import { useGetRole, useDeleteRole } from '../../hooks/roleHooks'
import moment from 'moment'
import ErrorPopup from '../../modals/errorpopup'
const UserManagement = () => {
  const [isErrorModalOpen, setIsErrorModalOpen] = React.useState(false)
  const [errorMessage, setErrorMessage] = React.useState('')
  const handleErrorModalOpen = () => setIsErrorModalOpen(true)
  const handleErrorModalClose = () => setIsErrorModalOpen(false)
  const [page, setPage] = useState(1)
  const [userPage, setUserPage] = useState(1)
  const location = useLocation()
  const [value, setValue] = useState(location?.state?.activeTab || '1')
  const [deleteRecord, setDeleteRecord] = useState(false)
  const handleDeleteOpen = () => setDeleteRecord(true)
  const handleDeleteClose = () => setDeleteRecord(false)
  const [currentId, setCurrentId] = useState('')
  const handleChange = (event, newValue) => {
    setValue(newValue)
  }
  const {
    data: userListInfo,
    isFetching: isUserRes
  } = useGetUser(value === '1', userPage)

  const userList = userListInfo?.results
  const {
    data: roleListInfo,
    isFetching: isRoleRes
  } = useGetRole(value === '2', page)
  const roleList = roleListInfo?.results
  const navigate = useNavigate()

  const rows = userList?.map((data, ind) => {
    const startIndex = (userPage - 1) * 10
    return {
      no: startIndex + ind + 1,
      id: data?.id,
      firstName: data?.firstName,
      userRole: data?.userRole?.name,
      number: data?.number,
      departmentGroup: data?.userRole?.departmentGroup?.title ? data?.userRole?.departmentGroup?.title : '-',
      status: data?.isOnline
        ? 'Online'
        : data?.lastOnline
          ? `Last seen on ${moment(data?.lastOnline).format('hh:mm')}, ${moment(
          data?.lastOnline
        ).format('DD-MM-YY')}`
          : '-',
      createdAt: moment(data?.createdAt).format('hh:mm a, DD MMM, YYYY')
    }
  })

  const { mutate: deleteUser } = useDeleteUser()
  const columns = [
    { field: 'no', headerName: '#', flex: 0.3 },
    { field: 'firstName', headerName: 'User’s Name', flex: 1 },
    { field: 'userRole', headerName: 'Role', flex: 1 },
    { field: 'number', headerName: 'Id', flex: 1 },
    { field: 'departmentGroup', headerName: 'Department Group', flex: 1 },
    { field: 'status', headerName: 'Status', flex: 1 },
    { field: 'createdAt', headerName: 'Created On', flex: 1 },
    {
      field: 'actions',
      headerName: 'Actions',
      sortable: false,
      disableClickEventBubbling: true,
      flex: 1,
      renderCell: (params) => {
        return (
          <Box className="resource-table-btn-main">
            <span
              className="process-delete"
              onClick={() => navigate(`/edit-user/${params.row.id}`)}
            >
              <EditIconNew />
            </span>
            <span
              onClick={() => {
                setCurrentId(params.row.id)
                handleDeleteOpen()
              }}
              className="process-delete"
            >
              <DeleteIconNew />
            </span>
          </Box>
        )
      }
    }
  ]
  const roleRows = roleList?.map((data, ind) => {
    const startIndex = (page - 1) * 10
    return {
      no: startIndex + ind + 1,
      id: data.id,
      name: data.name,
      usersCount: data.usersCount,
      // createdAt: moment(data?.createdAt).format('hh:MM a, DD MMM, YYYY'),
      createdAt: moment(data?.createdAt).format('hh:mm a, DD MMM, YYYY'),
      updatedAt: moment(data?.updatedAt).format('hh:mm a, DD MMM, YYYY')
    }
  })

  const { mutate: deleteRole } = useDeleteRole()
  const roleColumns = [
    { field: 'no', headerName: '#', flex: 0.3 },
    { field: 'name', headerName: 'Roles', flex: 1 },
    { field: 'usersCount', headerName: 'No. of users', flex: 1 },
    { field: 'createdAt', headerName: 'Created On', flex: 1 },
    { field: 'updatedAt', headerName: 'Updated On', flex: 1 },
    {
      field: 'actions',
      headerName: 'Actions',
      sortable: false,
      disableClickEventBubbling: true,
      flex: 1,
      renderCell: (params) => {
        return (
          <Box className="resource-table-btn-main">
            <span
              className="process-delete"
              onClick={() => navigate(`/edit-role/${params.row.id}`)}
            >
              <EditIconNew />
            </span>
            <span
              onClick={() => {
                if (params.row.usersCount <= 0) {
                  setCurrentId(params.row.id)
                  handleDeleteOpen()
                } else {
                  setErrorMessage(
                    `You can not able to delete this role, this role is assigned to ${params.row.usersCount} users`
                  )
                  handleErrorModalOpen()
                }
              }}
              className="process-delete"
            >
              <DeleteIconNew />
            </span>
          </Box>
        )
      }
    }
  ]

  return (
    <Box className="production-team-head-overflow-hidden">
      <BreadcrumbNavigation
        breadCrumbData={null}
        pageTitle={'User Management'}
        backToLink={null}
      />

      <Box className="common-parent-class-overflow-auto-padding-10-50">
        <TabContext value={value}>
          <Box sx={{ width: '100%', typography: 'body1' }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="lab API tabs example"
            >
              <Tab className="tab-head" label="Users" value="1" />
              <Tab className="tab-head" label="Roles" value="2" />
            </Tabs>
          </Box>

          <TabPanel value="1" className="tab-panel" sx={{ padding: '0px' }}>
            <Box>
              <Box className="common-parent-haeding-btn-div">
                <Typography className="common-tabel-heading">
                  Users List
                </Typography>
                <CustomButton
                  variant="contained"
                  title="Add User"
                  onClick={() => navigate('/add-user')}
                  type="button"
                  endIcon=""
                  startIcon={<AddIcon />}
                />
              </Box>
              {
                !isUserRes
                  ? <TableComponent
                    rows={rows}
                    columns={columns}
                    page={userPage}
                    setPage={setUserPage}
                    totalResults={userListInfo?.totalResults}
                    paginationData={userListInfo}
                    detailsRedirection={'/edit-user'}

                  />
                  : <Box className='progress-parent'>
                    <CircularProgress />
                  </Box>
              }
            </Box>
          </TabPanel>
          <TabPanel value="2" className="tab-panel" sx={{ padding: '0px' }}>
            <Box>
              <Box className="common-parent-haeding-btn-div">
                <Typography className="common-tabel-heading">
                  Roles List
                </Typography>
                <CustomButton
                  variant="contained"
                  title="Add Role"
                  onClick={() => navigate('/add-role')}
                  type="button"
                  endIcon=""
                  startIcon={<AddIcon />}
                />
              </Box>
              {
                !isRoleRes
                  ? <TableComponent
                paginationData={roleListInfo}
                rows={roleRows}
                columns={roleColumns}
                page={page}
                setPage={setPage}
                totalResults={roleListInfo?.totalResults}
                    detailsRedirection={'/edit-role'}

              />
                  : <Box className='progress-parent'>
                    <CircularProgress />
                  </Box>}
            </Box>
          </TabPanel>
        </TabContext>
        {value === '1' && deleteRecord && currentId && (
          <DeleteWarningModal
            open={deleteRecord}
            handleOpen={handleDeleteOpen}
            handleClose={handleDeleteClose}
            handleDelete={deleteUser}
            id={currentId}
          />
        )}
        {value === '2' && deleteRecord && currentId && (
          <DeleteWarningModal
            open={deleteRecord}
            handleOpen={handleDeleteOpen}
            handleClose={handleDeleteClose}
            handleDelete={deleteRole}
            id={currentId}
          />
        )}
        {isErrorModalOpen && (
        <ErrorPopup
          isOpen={isErrorModalOpen}
          handleClose={handleErrorModalClose}
          errorDetails={errorMessage}
        />
        )}
      </Box>
    </Box>
  )
}

export default UserManagement
