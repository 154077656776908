import { deleteRequest, get, patch } from '../utils/index'

export const jobslipTask = async (data) => {
  const id = data?.jobId
  delete data.jobId
  return patch(`/task/jobslip/change-status/${id}`, data).then((res) => res.data)
}
export const overdueTask = async (data) => {
  const id = data?.jobId
  delete data.jobId
  return patch(`/task/overdue/change-status/${id}`, data).then((res) => res.data)
}
export const getDepartmentWiseKanban = async (data) => {
  // const page = data?.queryKey[1] || 1
  const departmentId = data?.queryKey[1]
  const payload = { departmentId }
  return get('/task/list-machine-wise', payload).then((res) => res.data)
}
export const getMachineWiseKanbanView = async (data) => {
  // const page = data?.queryKey[1] || 1
  const id = data?.queryKey[1]
  const departmentId = data?.queryKey[2]
  const payload = { departmentId }
  return get(`/task/list-machine-wise/${id}`, payload).then((res) => res.data)
}
export const restoreTask = async (id) => {
  return patch(`/task/restore/${id}`)
}

export const hardDeleteTask = async (id) => {
  return deleteRequest(`/task/hard-delete/${id}`)
}
export const taskStatusApi = {
  jobslipTask,
  overdueTask,
  getDepartmentWiseKanban,
  getMachineWiseKanbanView,
  restoreTask,
  hardDeleteTask
}
