import { Box, Grid, Typography } from '@mui/material'
import React from 'react'
// import BreadCrumb from "../../leastcomponent/breadcrumb/breadcrumb";
import './reports.css'
import TextField from '@mui/material/TextField'
import CustomInput from '../../leastcomponent/custominput/input'
import Required from '../commoncomponents/required'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import dayjs from 'dayjs'
import { Button } from '@mui/base'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { Form, Formik, FieldArray } from 'formik'
import { styled } from '@mui/material/styles'
import CustomButton from '../../leastcomponent/custombutton/custombutton'
import Plus from '../../assets/svg/Plus'
import BreadcrumbNavigation from '../commoncomponents/breadcrumbnavigation'

const RawCustomButton = styled(Button)(() => ({
  padding: '10px, 12px, 10px, 12px !important',
  justify: 'space-between',
  borderRadius: '4px !important',
  width: '150px',
  fontSize: '14px !important',
  fontWeight: '400 !important',
  textTransform: 'capitalize',
  height: '40px !important',
  color: '#2F2F2F',
  border: '1px solid #265AA8',
  backgroundColor: 'white',
  font: 'openSans'
}))
const KpiCustomButton = styled(Button)(() => ({
  padding: '10px, 12px, 10px, 12px !important',
  justify: 'space-between',
  borderRadius: '4px !important',
  width: '100%',
  fontSize: '14px !important',
  fontWeight: '400 !important',
  textTransform: 'capitalize',
  height: '40px !important',
  color: '#2F2F2F',
  border: '1px solid #8A8A8A',
  backgroundColor: 'white'
}))
const HeadBox = styled(Box)(() => ({
  '.css-1t1j96h-MuiPaper-root-MuiDialog-paper': {
    borderRadius: '32px !important'
  },
  '.jobcardform': {
    padding: '24px 0px 0px 24px'
  },
  '.date-picker-fieldset': {
    width: '100%',
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#8A8A8A'
      }
    },
    '.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input': {
      padding: '9.5px !important'
    },
    '.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input': {
      padding: '9.5px 14px !important'
    },
    '.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root': {
      borderRadius: '4px !important'
    }
  },
  '.date-picker': {
    width: '100%',
    '.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input': {
      padding: '9.5px 14px'
    },
    '.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root': {
      borderRadius: '4px'
    }
  },
  '.btn-text-transform': {
    textTransform: 'capitalize !important'
    // '1weekutilization': "10%",
  }
}))
const CreateReports = () => {
  function ActionList (props) {
    const { onAccept, onCancel, onSetToday } = props
    const actions = [
      { text: 'Apply', method: onAccept },
      { text: 'Cancel', method: onCancel },
      // { text: 'Clear', method: onClear },
      { text: 'Today', method: onSetToday }
    ]
    return (
      <Box className="datepicker-btn-wrapper-factory-layout">
        <Button
          className="blue-color datepicker-btn-factory-layout"
          onClick={() => onCancel()}
        >
          {actions[1].text}
        </Button>
        <Button
          className="blue-bg datepicker-btn"
          variant="outlined"
          onClick={() => onAccept()}
        >
          {actions[0].text}
        </Button>
      </Box>
    )
  }
  return (
    <React.Fragment>
      <BreadcrumbNavigation
        breadCrumbData={null}
        pageTitle={'Reports'}
        backToLink={'/resource'}
      />

      <Box className="common-parent-class">
        <Box className="common-parent-class">
          {/* <BreadCrumb title="Reports" /> */}
          <Box>
            <Box className="common-parent-haeding-btn-div">
              <Typography className="common-tabel-heading">
                Generate Report
              </Typography>
            </Box>
          </Box>
          <Box ml={'30px'}>
            <Typography className="common-tabel-heading" mb={'20px'}>
              Select KPI
            </Typography>
            <Box className="add-reports-border">
              <Grid spacing={2} container paddingTop={2}>
                <Grid item xs={12} md={6} xl={3}>
                  <KpiCustomButton
                    variant="outlined"
                    // className='mr-16'
                    // onClick={(newValue) => {
                    //   setFieldValue(`batch.${index}.isInspection`, false);
                    // }}
                    // disabled={values?.batch?.[index]?.isInspection === false}
                  >
                    KPI 1
                  </KpiCustomButton>
                </Grid>
                <Grid item xs={12} md={6} xl={3}>
                  <KpiCustomButton
                    variant="outlined"
                    className="mr-16"
                    // onClick={(newValue) => {
                    //   setFieldValue(`batch.${index}.isInspection`, false);
                    // }}
                    // disabled={values?.batch?.[index]?.isInspection === false}
                  >
                    KPI 2
                  </KpiCustomButton>
                </Grid>
                <Grid item xs={12} md={6} xl={3}>
                  <KpiCustomButton
                    variant="outlined"
                    className="mr-16"
                    // onClick={(newValue) => {
                    //   setFieldValue(`batch.${index}.isInspection`, false);
                    // }}
                    // disabled={values?.batch?.[index]?.isInspection === false}
                  >
                    KPI 3
                  </KpiCustomButton>
                </Grid>
                <Grid item xs={12} md={6} xl={3}>
                  <KpiCustomButton
                    variant="outlined"
                    className="mr-16"
                    // onClick={(newValue) => {
                    //   setFieldValue(`batch.${index}.isInspection`, false);
                    // }}
                    // disabled={values?.batch?.[index]?.isInspection === false}
                  >
                    KPI 4
                  </KpiCustomButton>
                </Grid>
              </Grid>
              <Grid spacing={2} container paddingTop={2}>
                <Grid item xs={12} md={6} xl={3}>
                  <KpiCustomButton
                    variant="outlined"
                    // className='mr-16'
                    // onClick={(newValue) => {
                    //   setFieldValue(`batch.${index}.isInspection`, false);
                    // }}
                    // disabled={values?.batch?.[index]?.isInspection === false}
                  >
                    KPI 5
                  </KpiCustomButton>
                </Grid>
                <Grid item xs={12} md={6} xl={3}>
                  <KpiCustomButton
                    variant="outlined"
                    className="mr-16"
                    // onClick={(newValue) => {
                    //   setFieldValue(`batch.${index}.isInspection`, false);
                    // }}
                    // disabled={values?.batch?.[index]?.isInspection === false}
                  >
                    KPI 6
                  </KpiCustomButton>
                </Grid>
                <Grid item xs={12} md={6} xl={3}>
                  <KpiCustomButton
                    variant="outlined"
                    className="mr-16"
                    // onClick={(newValue) => {
                    //   setFieldValue(`batch.${index}.isInspection`, false);
                    // }}
                    // disabled={values?.batch?.[index]?.isInspection === false}
                  >
                    KPI 7
                  </KpiCustomButton>
                </Grid>
                <Grid item xs={12} md={6} xl={3}>
                  <KpiCustomButton
                    variant="outlined"
                    className="mr-16"
                    // onClick={(newValue) => {
                    //   setFieldValue(`batch.${index}.isInspection`, false);
                    // }}
                    // disabled={values?.batch?.[index]?.isInspection === false}
                    // '1weekutilization': "10%",
                  >
                    KPI 8
                  </KpiCustomButton>
                  {/* <CustomInput
              placeholder="Enter Name"
              isRequired={false}
              name="name"
            /> */}
                </Grid>
              </Grid>
            </Box>

            <Typography className="common-tabel-heading" mt={'20px'}>
              Select Timeline
            </Typography>
          </Box>
        </Box>
        <HeadBox>
          <Box>
            <Formik
              initialValues={{
                startDate: ''
              }}
              enableReinitialize={true}
              // validationSchema={validationDialogSchema}
              onSubmit={(values) => {
                console.log('values===', values)
              }}
            >
              {({ handleChange, values, setFieldValue }) => (
                <Form>
                  <Box className="common-parent-class" ml={'30px'}>
                    <Grid spacing={2} container paddingTop={2}>
                      <Grid item xs={12} md={6} xl={4}>
                        <Typography className="field-title">
                          Start Date & Time
                          <Required />
                        </Typography>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            // disablePast={currentSchedule.id ? false : false}
                            // hideTabs
                            orientation="portrait"
                            dayOfWeekFormatter={(day) => `${day}`}
                            className="date-picker"
                            disablePast
                            // openTo='year'
                            views={['year', 'day', 'month']}
                            closeOnSelect={false}
                            value={values.startDate || dayjs(new Date())}
                            // value={values.startDate}
                            inputFormat="DD/MM/YYYY"
                            onChange={(newValue) => {
                              setFieldValue('startDate', newValue?.toDate())
                            }}
                            components={{
                              ActionBar: ActionList
                              // OpenPickerIcon: ClockIcon,
                            }}
                            componentsProps={{
                              actionBar: {
                                actions: ['today', 'cancel', 'clear']
                              }
                            }}
                            renderInput={(params) => {
                              if (values?.startDate) {
                                return (
                                  <TextField
                                    {...params}
                                    placeholder="Select date"
                                    className="date-picker-fieldset"
                                  />
                                )
                              } else {
                                delete params.inputProps
                                return (
                                  <TextField
                                    {...params}
                                    disabled={true}
                                    placeholder="Select date"
                                    className="date-picker-fieldset"
                                    error={false}
                                  />
                                )
                              }
                            }}
                          />
                        </LocalizationProvider>
                      </Grid>
                      <Grid item xs={12} md={6} xl={4}>
                        <Typography className="field-title">
                          End Date & Time
                          <Required />
                        </Typography>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            // disablePast={currentSchedule.id ? false : false}
                            // hideTabs
                            orientation="portrait"
                            dayOfWeekFormatter={(day) => `${day}`}
                            className="date-picker"
                            disablePast
                            // openTo='year'
                            views={['year', 'day', 'month']}
                            closeOnSelect={false}
                            value={values.startDate || dayjs(new Date())}
                            // value={values.startDate}
                            inputFormat="DD/MM/YYYY"
                            onChange={(newValue) => {
                              setFieldValue('startDate', newValue?.toDate())
                            }}
                            components={{
                              ActionBar: ActionList
                              // OpenPickerIcon: ClockIcon,
                            }}
                            componentsProps={{
                              actionBar: {
                                actions: ['today', 'cancel', 'clear']
                              }
                            }}
                            renderInput={(params) => {
                              if (values?.startDate) {
                                return (
                                  <TextField
                                    {...params}
                                    placeholder="Select date"
                                    className="date-picker-fieldset"
                                  />
                                )
                              } else {
                                delete params.inputProps
                                return (
                                  <TextField
                                    {...params}
                                    disabled={true}
                                    placeholder="Select date"
                                    className="date-picker-fieldset"
                                    error={false}
                                  />
                                )
                              }
                            }}
                          />
                        </LocalizationProvider>
                      </Grid>
                    </Grid>
                    <Box mt={'20px'}>
                      <Typography className="common-tabel-heading">
                        Recurring
                      </Typography>
                    </Box>
                    <Grid spacing={0} container paddingTop={2}>
                      <Box className="buttonFlex">
                        <RawCustomButton
                          variant="outlined"
                          // onClick={(newValue) => {
                          //   setFieldValue(`batch.${index}.isInspection`, true);
                          // }}
                          // disabled={values?.batch?.[index]?.isInspection === true}
                          onClick={(newValue) => {
                            setFieldValue(console.log(newValue))
                          }}
                          disabled={values?.newValue === true}
                        >
                          Yes
                        </RawCustomButton>
                        <RawCustomButton
                          variant="outlined"
                          className="mr-15"
                          // onClick={(newValue) => {
                          //   setFieldValue(`batch.${index}.isInspection`, false);
                          // }}
                          // disabled={values?.batch?.[index]?.isInspection === false}
                          onClick={(newValues) => {
                            setFieldValue(console.log('54545454', newValues))
                          }}
                          disabled={values?.newValues}
                        >
                          No
                        </RawCustomButton>
                      </Box>
                    </Grid>
                    <Box mt={'20px'}>
                      <Typography className="common-tabel-heading">
                        Email
                      </Typography>
                    </Box>
                    <FieldArray name="tasks">
                      {({ push }) => (
                        <React.Fragment>
                          <Box>
                            <Grid spacing={0} container>
                              {values?.tasks?.length > 0 &&
                                values?.tasks?.map((tasks, index) => (
                                  <Grid item xs={12} md={4} xl={4} key={index}>
                                    <Box paddingRight={'10px'}>
                                      <CustomInput
                                        label="Email"
                                        placeholder="Enter Email"
                                        handleChange={handleChange}
                                        value={values?.email}
                                        name="email"
                                        // InputProps={{
                                        //   endAdornment: touched?.name && errors?.name && icon,
                                        // }}
                                        // errors={errors?.name}
                                        // touched={touched?.name}
                                      />
                                    </Box>
                                  </Grid>
                                ))}
                              <Grid paddingLeft={'0px'}>
                                <Box padding={'30px 0px 0px'}>
                                  <CustomButton
                                    variant="outlined"
                                    className="button-box"
                                    type="button"
                                    endIcon={<Plus fill={'#0160B9'} />}
                                    title="Add"
                                    onClick={() =>
                                      push({
                                        departmentName: ''
                                      })
                                    }
                                  />
                                </Box>
                              </Grid>
                            </Grid>
                          </Box>
                        </React.Fragment>
                      )}
                    </FieldArray>
                  </Box>
                  <Box className="button-child">
                    <CustomButton title="Publish Report" type="submit" />
                  </Box>
                </Form>
              )}
            </Formik>
          </Box>
        </HeadBox>
      </Box>
    </React.Fragment>
  )
}

export default CreateReports
