import React, { useState, useEffect } from 'react'
import moment from 'moment'
import { Box } from '@mui/material'

const DateTimeComponent = () => {
  const [currentTime, setCurrentTime] = useState(new Date())

  useEffect(() => {
    const timerID = setInterval(() => tick(), 1000)

    return () => {
      clearInterval(timerID)
    }
  }, [])

  const tick = () => {
    setCurrentTime(new Date())
  }

  return (
    <>
        <Box className="date-box">
          {' '}
          <Box className="date-child-box">
            {moment(currentTime).format('dddd DD MMM')}
          </Box>
          <Box className="time-child-box">{moment(currentTime).format('LT')}</Box>
        </Box>
    </>
  )
}

export default DateTimeComponent
