import { useQuery } from '@tanstack/react-query'
import { dashboardApi } from '../api/dashboard'
import { dashboard } from '../querykeys/dashboard'

export const useDashboardProgressReport = (isEnable, date) => {
  return useQuery([dashboard.DASHBOARD, date], dashboardApi.getDashboardProgressReport, {
    enabled: isEnable,
    onSuccess: (data) => {
      // queryClient.setQueryData([taskStatus.MACHINEWISEKANBAN], 'hi')
    },
    keepPreviousData: true,
    // enabled: isEnabled,
    refetchOnWindowFocus: false
  })
}
export const useDashboardRecentActivity = (isEnable, date) => {
  return useQuery([dashboard.DASHBOARDRECENT, date], dashboardApi.getDashboardRecentActivity, {
    enabled: isEnable,
    onSuccess: (data) => {
      // queryClient.setQueryData([taskStatus.MACHINEWISEKANBAN], 'hi')
    },
    keepPreviousData: true,
    // enabled: isEnabled,
    refetchOnWindowFocus: false
  })
}
export const useDashboardTaskStatus = (isEnable, page, status, date, selectedDepartment) => {
  return useQuery([dashboard.DASHBOARDTASKSTATUS, status, page, date, selectedDepartment], dashboardApi.getDashboardTaskStatus, {
    enabled: isEnable,
    select: (res) => res?.data,
    // ! TODO: for future implementation toast
    // onSuccess: () => {
    //   // const data = res?.data;
    // },
    // onError: (err) => {
    //   return err;
    //   // const errData = err?.data;
    //   // toast.error(errData.message);
    // },
    refetchOnWindowFocus: false
  })
}
export const useDashboardJobcardStatus = (isEnable, page, status, date, selectedDepartment) => {
  return useQuery([dashboard.DASHBOARDJOBSTATUS, status, page, date, selectedDepartment], dashboardApi.getDashboardJobcardStatus, {
    enabled: isEnable,
    select: (res) => res?.data,
    // ! TODO: for future implementation toast
    // onSuccess: () => {
    //   // const data = res?.data;
    // },
    // onError: (err) => {
    //   return err;
    //   // const errData = err?.data;
    //   // toast.error(errData.message);
    // },
    refetchOnWindowFocus: false
  })
}
export const useDashboardJobcardTaskStatus = (isEnable, page, status, date) => {
  return useQuery([dashboard.DASHBOARDJOBCARDTASKSTATUS, status, page, date], dashboardApi.getDashboardJobcardTaskStatus, {
    enabled: isEnable,
    select: (res) => res?.data,
    // ! TODO: for future implementation toast
    // onSuccess: () => {
    //   // const data = res?.data;
    // },
    // onError: (err) => {
    //   return err;
    //   // const errData = err?.data;
    //   // toast.error(errData.message);
    // },
    refetchOnWindowFocus: false
  })
}
