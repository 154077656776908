import { get, post, patch, deleteRequest } from '../utils/index'

export const addDepartment = async (data) => {
  return post('/department', data).then((res) => res.data)
}
export const getSingleDepartment = async (data) => {
  const id = data?.queryKey[1]
  return get(`/department/${id}`).then((res) => res.data)
}
export const getFullDepartment = async () => {
  return get('/department/full-list')
}
export const getFullDepartmentGroup = async () => {
  return get('/department-group/full-list')
}
export const getDepartments = async (data) => {
  const page = data?.queryKey[1] || 1
  const payload = { page, limit: 10 }
  return get('/department', payload).then((res) => res.data)
}
export const updateDepartment = async (data) => {
  const id = data?.id
  // const id = data?.queryKey[1];
  delete data.id
  return patch(`/department/${id}`, data).then((res) => res.data)
}

export const deleteDepartment = async (id) => {
  return deleteRequest(`/department/${id}`)
}
export const restoreDepartment = async (id) => {
  return patch(`/department/restore/${id}`)
}
export const getSingleDeletedDepartment = async (data) => {
  const id = data?.queryKey[1]
  return get(`/department/deleted/${id}`)
}

export const hardDeleteDepartment = async (id) => {
  return deleteRequest(`/department/hard-delete/${id}`)
}

// ! DepartmentGroup

export const addDepartmentGroup = async (data) => {
  return post('/department-group', data).then((res) => res.data)
}
export const getDepartmentsGroup = async () => {
  return get('/department-group')
}
export const getSingleDepartmentGroup = async (data) => {
  const id = data?.queryKey[1]
  return get(`/department-group/${id}`)
}
export const updateDepartmentGroup = async (data) => {
  const id = data?.id
  // const id = data?.queryKey[1];
  delete data.id
  return patch(`/department-group/${id}`, data).then((res) =>
    console.log('res.data===', res)
  )
}
export const deleteDepartmentGroup = async (id) => {
  return deleteRequest(`/department-group/${id}`)
}
export const restoreDepartmentGroup = async (id) => {
  return patch(`/department-group/restore/${id}`)
}

export const hardDeleteDepartmentGroup = async (id) => {
  return deleteRequest(`/department-group/hard-delete/${id}`)
}
export const getDeleteDepartmentGroup = async (data) => {
  const id = data?.queryKey[1]
  return get(`/department-group/deleted/${id}`)
}
export const validateDepartment = async (data) => {
  // delete data.scheduleData
  return post('/department/validate', data)
}
export const departmentApi = {
  addDepartment,
  getSingleDepartment,
  getDepartments,
  getFullDepartment,
  getFullDepartmentGroup,
  deleteDepartment,
  updateDepartment,
  restoreDepartment,
  getSingleDeletedDepartment,
  hardDeleteDepartment,
  addDepartmentGroup,
  getDepartmentsGroup,
  getSingleDepartmentGroup,
  updateDepartmentGroup,
  deleteDepartmentGroup,
  restoreDepartmentGroup,
  hardDeleteDepartmentGroup,
  getDeleteDepartmentGroup,
  validateDepartment
}
