import React from 'react'

const Arrows = () => {
  return (
    <React.Fragment>
      <div>
        <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1.125 6.375L14.875 6.375C15.2202 6.375 15.5 6.65482 15.5 7C15.5 7.34518 15.2202 7.625 14.875 7.625L1.125 7.625C0.779822 7.625 0.5 7.34518 0.5 7C0.5 6.65482 0.779822 6.375 1.125 6.375Z" fill="#C0C0C0" />
          <path d="M15.3169 7.44194L9.69194 13.0669C9.57473 13.1841 9.41576 13.25 9.25 13.25C9.08424 13.25 8.92527 13.1842 8.80806 13.0669C8.69085 12.9497 8.625 12.7908 8.625 12.625C8.625 12.4592 8.69085 12.3003 8.80806 12.1831L13.9911 7L8.80806 1.81694C8.69085 1.69973 8.625 1.54076 8.625 1.375C8.625 1.20924 8.69085 1.05027 8.80806 0.933058C8.92527 0.815848 9.08424 0.75 9.25 0.75C9.41576 0.75 9.57473 0.815848 9.69194 0.933058L15.3169 6.55806C15.561 6.80214 15.561 7.19786 15.3169 7.44194Z" fill="#C0C0C0" />
        </svg>
      </div>
    </React.Fragment>
  )
}

export default Arrows
