const part = {
  PART: 'PART',
  PARTS: 'PARTS',
  PARTSFULL: 'PARTSFULL'
}
const taskWorkOrder = {
  TASKWORKORDER: 'TASKWORKORDER',
  TASKWORKORDERS: 'TASKWORKORDERS',
  TASKWORKORDERSFULL: 'TASKWORKORDERSFULL'
}
const accessory = {
  ACCESSORY: 'ACCESSORY',
  ACCESSORYS: 'ACCESSORYS',
  ACCESSORYSFULL: 'ACCESSORYSFULL'
}
const customer = {
  CUSTOMER: 'CUSTOMER',
  CUSTOMERS: 'CUSTOMERS',
  CUSTOMERS_CONFIRM: 'CUSTOMERS_CONFIRM',
  CUSTOMERS_COMPLETE: 'CUSTOMERS_COMPLETE',
  CUSTOMERSFULL: 'CUSTOMERSFULL'
}
const raw = {
  RAW: 'RAW',
  RAWS: 'RAWS',
  RAWSFULL: 'RAWSFULL'
}
const finishedGood = {
  FINISHEDGOOD: 'FINISHEDGOOD',
  FINISHEDGOODS: 'FINISHEDGOODS',
  FINISHEDGOODSFULL: 'FINISHEDGOODSFULL'
}
const consumable = {
  CONSUMABLE: 'CONSUMABLE',
  CONSUMABLES: 'CONSUMABLES',
  CONSUMABLESFULL: 'CONSUMABLESFULL'
}
const rawMaterial = {
  RAWMATERIAL: 'RAWMATERIAL',
  RAWMATERIALS: 'RAWMATERIALS',
  RAWMATERIALSFull: 'RAWMATERIALSFull'
}

const product = {
  PRODUCT: 'PRODUCT',
  PRODUCTS: 'PRODUCTS',
  PRODUCTSFULL: 'PRODUCTSFULL'
}

const supplier = {
  SUPPLIER: 'SUPPLIER',
  SUPPLIERS: 'SUPPLIERS'
}

const searchOptions = {
  SEARCH: 'SEARCH'
}
const finshedGood = {
  FINSHEDGOOD: 'FINSHEDGOOD',
  FINSHEDGOODS: 'FINSHEDGOODS'
}

export {
  part,
  rawMaterial,
  product,
  supplier,
  searchOptions,
  finshedGood,
  accessory,
  customer,
  consumable,
  finishedGood,
  raw,
  taskWorkOrder
}
