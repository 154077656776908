import React from 'react'
import TableComponent from '../customtable/customtable'
import { Box, Typography } from '@mui/material'
import '../../pages/dashboard/dashboard.css'

const TableWrapper = ({ rows, columns, title, isShowFiveRecords = false }) => {
  return (
    <React.Fragment>
      <Box className="parent-table-wrapper">
        <Typography className="wrapper-title">{title}</Typography>
        <TableComponent
          rows={rows}
          isShowFiveRecords={isShowFiveRecords}
          columns={columns}
        />
      </Box>
    </React.Fragment>
  )
}

export default TableWrapper
