import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'
import React from 'react'
import './jobcard.css'
import { Box, Divider, Grid, Typography } from '@mui/material'
// import BreadCrumb from "../../leastcomponent/breadcrumb/breadcrumb";
import { FieldArray, Form, Formik } from 'formik'
import CustomInput from '../../leastcomponent/custominput/input'
import { AiOutlineInfoCircle } from 'react-icons/ai'
import CustomButton from '../../leastcomponent/custombutton/custombutton'
import Plus from '../../assets/svg/Plus'
import * as Yup from 'yup'
import CustomSelect from '../../leastcomponent/customselect/customselect'
import CustomTimePicker from '../../leastcomponent/customtimepicker/customtimepicker'
import DeleteIconNew from '../../assets/svg/DeleteIconNew'
import BreadcrumbNavigation from '../commoncomponents/breadcrumbnavigation'

const ProductionAddTask = () => {
  const icon = <AiOutlineInfoCircle color="red" />

  const validationSchema = Yup.object().shape({
    parts: Yup.array().of(
      Yup.object().shape({
        taskName: Yup.string().required('Item Code is required'),
        workstations: Yup.string().required('Part Name is required'),
        setupTime: Yup.string().required('Part Description is required'),
        productionTime: Yup.string().required('Part Description is required'),
        changeoverTime: Yup.string().required('Part Description is required')
      })
    )
  })
  const options = [
    { value: 'workstations1', label: 'Workstations1' },
    { value: 'workstations2', label: 'Workstations2' },
    { value: 'workstations3', label: 'Workstations3' },
    { value: 'workstations4', label: 'Workstations4' }
  ]
  return (
    <React.Fragment>
      <BreadcrumbNavigation
        breadCrumbData={null}
        pageTitle={'Job Card - Department wise'}
        backToLink={null}
      />

      <Box paddingTop={'30px'}>
        <Grid container className="padding-20-75">
          <Grid lg={1.75}>
            <Box className="font-size-14">Job Card Name</Box>
            <Box className="font-size-14-with-semibold-primary">Job Card</Box>
          </Grid>
          <Grid lg={1.75}>
            <Box className="font-size-14">Job Card Number</Box>
            <Box className="font-size-14-with-semibold">1234657489</Box>
          </Grid>
          <Grid lg={1.75}>
            <Box className="font-size-14">Part Name</Box>
            <Box className="font-size-14-with-semibold">Part Name</Box>
          </Grid>
          <Grid lg={1.75}>
            {' '}
            <Box className="font-size-14">Part Code</Box>
            <Box className="font-size-14-with-semibold">123456789</Box>
          </Grid>
          <Grid lg={5} className="align-center-with-gap">
            Status{' '}
            <span className="align-center">
              <FiberManualRecordIcon
                sx={{ fontSize: '12px' }}
                color="warning"
              />
            </span>{' '}
            <span className="font-size-14-with-semibold">Pending</span>
          </Grid>
          <Grid lg={12}>
            <Divider />
            {/* <Grid lg={5} className="align-center-with-gap">
          Status{" "}
          <span className="align-center">
            <FiberManualRecordIcon sx={{ fontSize: "12px" }} color="warning" />
          </span>{" "}
          <span className="font-size-14-with-semibold">Pending</span> */}
          </Grid>
        </Grid>
        <Formik
          enableReinitialize={true}
          initialValues={{
            tasks: [
              {
                taskName: '',
                workstations: '',
                setupTime: '',
                productionTime: '',
                changeoverTime: ''
              }
            ]
          }}
          onSubmit={(values) => console.log('values======', values)}
          validationSchema={validationSchema}
        >
          {({ errors, setFieldValue, handleChange, touched, values }) => (
            <React.Fragment>
              <Form className="form-perent">
                <Box className="form-child">
                  <Typography className="form-heading-1" padding={'20px 75px'}>
                    Add Task
                  </Typography>
                  <FieldArray name="tasks">
                    {({ push, remove }) => (
                      <React.Fragment>
                        <Box className="gap-20">
                          {values?.tasks?.length > 0 &&
                            values?.tasks?.map((tasks, index) => (
                              <Box key={index} padding={'0px 75px 10px'}>
                                <Box borderBottom={'1px solid #dedede'}>
                                  <Typography className="form-heading-1 pb-20">
                                    Task {index + 1}
                                    <span
                                      onClick={() => remove(index)}
                                      className="process-delete"
                                    >
                                      <DeleteIconNew />
                                    </span>
                                  </Typography>
                                  <Grid
                                    spacing={2}
                                    key={index}
                                    paddingBottom={'20px'}
                                    container
                                  >
                                    <Grid item xs={12} md={6} xl={4}>
                                      <CustomInput
                                        label="Task Name"
                                        placeholder="Enter Task Name"
                                        handleChange={handleChange}
                                        value={values?.tasks?.[index]?.taskName}
                                        name={`tasks.${index}.taskName`}
                                        InputProps={{
                                          endAdornment:
                                            touched?.tasks?.[index]?.taskName &&
                                            errors?.tasks?.[index]?.taskName &&
                                            icon
                                        }}
                                        errors={
                                          errors?.tasks?.[index]?.taskName
                                        }
                                        touched={
                                          touched?.tasks?.[index]?.taskName
                                        }
                                      />
                                    </Grid>
                                    <Grid item xs={12} md={6} xl={4}>
                                      <CustomSelect
                                        name={`tasks.${index}.workstations`}
                                        label="Workstation Name/Code"
                                        placeholder="Select Department"
                                        className="default-select-new"
                                        options={options}
                                        value={
                                          values?.tasks?.[index]?.workstations
                                        }
                                        handleSelectChange={(e) =>
                                          setFieldValue(
                                            `tasks.${index}.workstations`,
                                            e?.target?.value
                                          )
                                        }
                                        errors={
                                          errors?.tasks?.[index]?.workstations
                                        }
                                        touched={
                                          touched?.tasks?.[index]?.workstations
                                        }
                                      />
                                    </Grid>
                                  </Grid>
                                  <Grid
                                    spacing={2}
                                    key={index}
                                    paddingBottom={'30px'}
                                    container
                                  >
                                    <Grid item xs={12} md={6} xl={4}>
                                      <CustomTimePicker
                                        name={`tasks.${index}.setupTime`}
                                        label="Setup time (hh:mm)"
                                        className="default-time-picker"
                                        handleDateChange={(e) => {
                                          setFieldValue(
                                            `tasks.${index}.setupTime`,
                                            e
                                          )
                                        }}
                                        views={['hours', 'minutes']}
                                        value={
                                          values?.tasks?.[index]?.setupTime
                                        }
                                        errors={
                                          errors?.tasks?.[index]?.setupTime
                                        }
                                        touched={
                                          touched?.tasks?.[index]?.setupTime
                                        }
                                      />
                                    </Grid>
                                    <Grid item xs={12} md={6} xl={4}>
                                      <CustomTimePicker
                                        name={`tasks.${index}.productionTime`}
                                        label="Production time (hh:mm)"
                                        className="default-time-picker"
                                        handleDateChange={(e) => {
                                          setFieldValue(
                                            `tasks.${index}.productionTime`,
                                            e
                                          )
                                        }}
                                        views={['hours', 'minutes']}
                                        value={
                                          values?.tasks?.[index]?.productionTime
                                        }
                                        errors={
                                          errors?.tasks?.[index]?.productionTime
                                        }
                                        touched={
                                          touched?.tasks?.[index]
                                            ?.productionTime
                                        }
                                      />
                                    </Grid>
                                    <Grid item xs={12} md={6} xl={4}>
                                      <CustomTimePicker
                                        name={`tasks.${index}.changeoverTime`}
                                        label="Changeover time (hh:mm)"
                                        className="default-time-picker"
                                        handleDateChange={(e) => {
                                          setFieldValue(
                                            `tasks.${index}.changeoverTime`,
                                            e
                                          )
                                        }}
                                        views={['hours', 'minutes']}
                                        value={
                                          values?.tasks?.[index]?.changeoverTime
                                        }
                                        errors={
                                          errors?.tasks?.[index]?.changeoverTime
                                        }
                                        touched={
                                          touched?.tasks?.[index]
                                            ?.changeoverTime
                                        }
                                      />
                                    </Grid>
                                  </Grid>
                                </Box>
                              </Box>
                            ))}
                        </Box>
                        <Box padding={'20px 75px'}>
                          {' '}
                          <CustomButton
                            variant="outlined"
                            className="button-box"
                            type="button"
                            title="Add Part"
                            endIcon={<Plus fill={'#0160B9'} />}
                            onClick={() =>
                              push({
                                taskName: '',
                                workstations: '',
                                setupTime: '',
                                productionTime: '',
                                changeoverTime: ''
                              })
                            }
                          />
                        </Box>
                      </React.Fragment>
                    )}
                  </FieldArray>
                </Box>

                <Box className="button-child">
                  <CustomButton title="Submit" type="submit" />
                </Box>
              </Form>
            </React.Fragment>
          )}
        </Formik>
      </Box>
    </React.Fragment>
  )
}

export default ProductionAddTask
