import { get, post, deleteRequest, patch } from '../utils/index'

// export const getUser = async (data) => {
//   const page = data?.queryKey[1] || 1;
//   const payload = { page, limit: 10 };
//   return get(`/user`, payload).then((res) => res.data);
// };
export const getUser = async (
  data
) => {
  const page = data?.queryKey[1] || 1
  const payload = { page, limit: 10 }
  return get('/user', payload).then((res) => {
    return res.data
  })
}
export const addUser = async (data) => {
  return post('/user', data).then((res) => res.data)
}
export const updateUser = async (data) => {
  const id = data?.id
  delete data.id
  delete data.supplierId
  return patch(`/user/${id}`, data).then((res) => res.data)
}
export const deleteUser = async (id) => {
  return deleteRequest(`/user/${id}`)
}
export const getSingleUser = async (data) => {
  const id = data?.queryKey[1]
  return get(`/user/${id}`).then((res) => res.data)
}
export const restoreUser = async (id) => {
  return patch(`/user/restore/${id}`)
}

export const hardDeleteUser = async (id) => {
  return deleteRequest(`/user/hard-delete/${id}`)
}
export const getSingleUserDelete = async (data) => {
  const id = data?.queryKey[1]
  return get(`/user/deleted/${id}`)
}
export const userApi = {
  getUser,
  addUser,
  deleteUser,
  getSingleUser,
  updateUser,
  restoreUser,
  hardDeleteUser,
  getSingleUserDelete
}
