import React from 'react'

const BookIcon = () => {
  return (
    <svg
      width='18'
      height='14'
      viewBox='0 0 18 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      style={{ marginRight: '12.5px' }}
    >
      <path
        d='M9 2.21244V13.0458M9 2.21244C8.02675 1.56584 6.70541 1.16846 5.25 1.16846C3.79459 1.16846 2.47325 1.56584 1.5 2.21244V13.0458C2.47325 12.3992 3.79459 12.0018 5.25 12.0018C6.70541 12.0018 8.02675 12.3992 9 13.0458M9 2.21244C9.97325 1.56584 11.2946 1.16846 12.75 1.16846C14.2054 1.16846 15.5268 1.56584 16.5 2.21244V13.0458C15.5268 12.3992 14.2054 12.0018 12.75 12.0018C11.2946 12.0018 9.97325 12.3992 9 13.0458'
        stroke='#1D1B1B'
        strokeWidth='1.4'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default BookIcon
