import React from 'react'
import './createTaskType.css'
import { Box, CircularProgress, Grid, Typography } from '@mui/material'
// import BreadCrumb from "../../leastcomponent/breadcrumb/breadcrumb";
import { Form, Formik } from 'formik'
import CustomInput from '../../leastcomponent/custominput/input'
import CustomButton from '../../leastcomponent/custombutton/custombutton'
import * as Yup from 'yup'
import { AiOutlineInfoCircle } from 'react-icons/ai'
import BreadcrumbNavigation from '../commoncomponents/breadcrumbnavigation'
import {
  useAddTask,
  useGetSingleTaskTypeDelete,
  useGetSingleTasks,
  useHardDeleteTaskType,
  useRestoreTaskType,
  useUpdateTasks
} from '../../hooks/tasktype'
import { useLocation, useParams } from 'react-router-dom'
import ErrorPopup from '../../modals/errorpopup'

const CreateTaskType = () => {
  //  delete items
  const location = useLocation()
  const isDeleted = location.pathname.startsWith('/deleted-items')
  const isRestore = location.pathname.startsWith('/deleted-items')
  const { id } = useParams()

  const [isErrorModalOpen, setIsErrorModalOpen] = React.useState(false)
  const handleErrorModalClose = () => setIsErrorModalOpen(false)
  const [errorMessage, setErrorMessage] = React.useState('')

  const icon = <AiOutlineInfoCircle color="red" />

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Task type is required')
  })

  const {
    mutate: addTask,
    isError: isAddDepartmentError,
    error: addDepartmentError,
    isLoading
    // isSuccess: isAddDepartmentSuccess,
  } = useAddTask()
  const {
    mutate: updateTask,
    isError: isUpdateDepartmentError,
    error: updateDepartmentError
    // isSuccess: isAddDepartmentSuccess,
  } = useUpdateTasks()
  const { mutate: restoreTaskType } = useRestoreTaskType()
  const { mutate: hardDeleteTaskType } = useHardDeleteTaskType()

  const handlePartDelete = () => {
    if (isDeleted) {
      hardDeleteTaskType(id)
    } else {
      // deleteSinglePart(id)
    }
  }

  const handlePartRestore = () => {
    if (isRestore) {
      restoreTaskType(id)
    } else {
      // nothing to restore
    }
  }
  const { data: singleTask, isFetching: isSingleTask } = useGetSingleTasks(id, !!id && !isDeleted)
  const { data: getTaskTypeDeleted } = useGetSingleTaskTypeDelete(
    id,
    !!id && isDeleted
  )
  const singleTaskData = isDeleted ? getTaskTypeDeleted?.data : singleTask
  React.useEffect(() => {
    if (isAddDepartmentError || isUpdateDepartmentError) {
      setErrorMessage(
        isAddDepartmentError
          ? addDepartmentError?.data?.message
          : updateDepartmentError?.data?.message
      )
      setIsErrorModalOpen(true)
    }
  }, [
    isAddDepartmentError,
    isUpdateDepartmentError,
    addDepartmentError,
    updateDepartmentError
  ])
  const breadCrumbData = []
  return (
    <Box className="production-team-head-overflow-hidden">
      <BreadcrumbNavigation
        {...(isDeleted && { breadCrumbData })}
        pageTitle={isDeleted ? 'Deleted Task type' : id ? 'Edit Task type' : 'Create Task type'}
        backToLink={'/task-type'}
        isRestore={true}
        isDeleted={true}
        handleDelete={handlePartDelete}
        handleRestore={handlePartRestore}
      />

     { !isSingleTask
       ? <Box className="production-team-head-overflow-auto ">
        <Formik
          enableReinitialize={true}
          initialValues={{
            name: id ? singleTaskData?.name : ''
          }}
          onSubmit={(values) => {
            const addTaskValues = {
              name: values.name
              // code: "123",
            }
            if (id) {
              updateTask({ ...addTaskValues, id })
            } else {
              addTask(addTaskValues)
            }
          }}
          validationSchema={validationSchema}
        >
          {({ errors, handleChange, touched, values }) => (
            <React.Fragment>
              <Form className="create-task-form-perent">
                <Box className="create-task-form-child">
                  <Box>
                    <Box>
                      <Typography className="create-task-form-heading-main">
                      {isDeleted ? 'Deleted Task type' : id ? 'Edit Task type' : 'Create Task type'}
                      </Typography>
                    </Box>
                    <Box className="create-task-form-padding">
                      <Grid spacing={2} paddingBottom={'20px'} container>
                        <Grid item xs={12} md={6} xl={4}>
                          <Box>
                            <CustomInput
                              label="Task type"
                              placeholder="Enter Task type"
                              handleChange={handleChange}
                              value={values?.name}
                              name="name"
                              InputProps={{
                                endAdornment:
                                  touched?.name && errors?.name && icon
                              }}
                              errors={errors?.name}
                              touched={touched?.name}
                            />
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                </Box>
              { !isDeleted && <Box className="create-task-button-child">
                  <CustomButton
                    disabled={isLoading}
                    title={id ? 'Update' : 'Submit'}
                    type="submit"
                  />
                </Box>}
              </Form>
            </React.Fragment>
          )}
        </Formik>
      </Box>
       : <Box className='machine-kanban-progress'>
        <CircularProgress />
      </Box>}
      {isErrorModalOpen && (
        <ErrorPopup
          isOpen={isErrorModalOpen}
          handleClose={handleErrorModalClose}
          errorDetails={errorMessage}
        />
      )}
    </Box>
  )
}

export default CreateTaskType
