import * as React from 'react'
import CloseIcon from '@mui/icons-material/Close'
import Typography from '@mui/material/Typography'
import { Box, Grid, IconButton, Modal } from '@mui/material'
import './../custommodal/commonmodal.css'

const CustomizedModal = (props) => {
  const {
    children,
    onClick,
    open,
    heading,
    isHeadingName,
    Icon,
    isIcon,
    width = 'auto',
    isCrossIcon = true
  } = props
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width,
    maxHeight: '70% !important',
    overflow: 'auto',
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: '8px',
    outline: 'none',
    padding: '1% 2% 1% 2%'
  }

  return (
    <Box>
      <Modal
        open={open}
        onClose={onClick}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box className="modal-heading" sx={isHeadingName ? { borderBottom: '1px solid #DEDEDE', marginBottom: '30px' } : {}}>
            <Grid>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                {isIcon
                  ? (
                  <Icon style={{ marginRight: '10px', color: '#020202' }} />
                    )
                  : (
                  <React.Fragment></React.Fragment>
                    )}{' '}
                {isHeadingName ? heading : ''}
              </Typography>
            </Grid>
            <Grid>
              {isCrossIcon
                ? (
                <IconButton
                  aria-label="close"
                  onClick={onClick}
                  sx={{
                    color: (theme) => theme.palette.grey[500]
                  }}
                >
                  <CloseIcon
                    style={{ color: '#020202' }}
                    width="24px"
                    height="24px"
                  />
                </IconButton>
                  )
                : (
                <React.Fragment></React.Fragment>
                  )}
            </Grid>
          </Box>
          {children}
        </Box>
      </Modal>
    </Box>
  )
}
export default CustomizedModal
