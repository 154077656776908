import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'
import React, { useState } from 'react'
import './jobcard.css'
import { Box, Grid, MenuItem, Select, Typography } from '@mui/material'
import { FieldArray, Form, Formik } from 'formik'
import CustomInput from '../../leastcomponent/custominput/input'
import { AiOutlineInfoCircle } from 'react-icons/ai'
import CustomButton from '../../leastcomponent/custombutton/custombutton'
import Plus from '../../assets/svg/Plus'
import * as Yup from 'yup'
import FormControl from '@mui/material/FormControl'
import DeleteIconNew from '../../assets/svg/DeleteIconNew'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import BreadcrumbNavigation from '../commoncomponents/breadcrumbnavigation'
import {
  useAddTask,
  useGetJobDetailsSingleTask,
  useGetTaskData
} from '../../hooks/jobcardHooks'
import Required from '../commoncomponents/required'
import { styled } from '@mui/material/styles'
import { useGetFullTasks } from '../../hooks/tasktype'
import _ from 'lodash'
import { useLocation, useParams } from 'react-router-dom'
import { useQueryClient } from '@tanstack/react-query'
import { user } from '../../querykeys/usermanagement'

const Selects = styled(Select)(() => ({
  borderRadius: '4px !important',
  '.MuiOutlinedInput-input': {
    padding: '8.5px 14px',
    fontSize: '14px !important'

  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: '#8A8A8'
  }
}))

const AddTaskProductionTeamMember = () => {
  const icon = <AiOutlineInfoCircle color="red" />
  const { id, partId } = useParams()
  const [taskRemoveId, setTaskRemoveId] = useState([])
  const { state } = useLocation()
  const selectDepartmentid = localStorage.getItem('selectDepartmentid')
  const { data: getTaskData } = useGetTaskData(state?.partId, !!state?.partId, selectDepartmentid)
  const getTaskDataResponse = getTaskData?.data?.results
  const { data: getJobDetails } = useGetJobDetailsSingleTask(id, !!id)
  const singleJobDetails = getJobDetails?.data
  const finalBompartData = singleJobDetails?.bompart?.filter((data) => data?.id === state?.partId && data)
  const queryClient = useQueryClient()
  const userData = queryClient.getQueryData([user.LOGGED_IN_USER])
  const bompart = singleJobDetails?.bompart?.find((item) => item.id === partId)
  const finalBOMProcess = bompart?.bomprocess?.find((item) => {
    return item.department.id === selectDepartmentid
  })
  const departmentGroupTitle = userData?.data?.data?.user?.roleId?.departmentGroup?.title
  console.log('departmentGroupTitle', departmentGroupTitle)
  const validationSchema = Yup.object().shape({
    parts: Yup.array().of(
      Yup.object().shape({
        taskNickName: Yup.string().required('Task Nick Name is required'),
        tasktype: Yup.string().required('Task Type is required'),
        description: Yup.string()
      })
    )
  })

  const { data: tasktypetData } = useGetFullTasks()
  const { mutate: addTask } = useAddTask()

  const [openIndex, setOpenIndex] = useState(null)
  const initialTaskData = getTaskDataResponse?.map((task) => ({
    bomPartId: task?.bomPartId,
    taskNickName: task?.taskNickName,
    taskType: task?.taskType,
    description: task?.description,
    // taskId: task?.id
    id: task?.id
  }))
  return (
    <Box className="production-team-head-overflow-hidden">
      <BreadcrumbNavigation
        breadCrumbData={null}
        pageTitle={` Job Card ${singleJobDetails?.jobCardNumber ? '#' + singleJobDetails?.jobCardNumber : '-'}`}
        backToLink={'/job-card-department'}
      />
      <Box className="padding-24-50">
        <Grid container className="padding-20-40-border">
          <Grid lg={1.75} md={3.75}>
            <Box className="add-task-header">Job Card Name</Box>
            <Box className="font-size-14-with-semibold-primary">
              {_.get(singleJobDetails, 'name', '')}
            </Box>
          </Grid>
          <Grid lg={1.75} md={3.75}>
            <Box className="add-task-header">Job Card Number</Box>
            <Box className="font-size-14-with-semibold">
              {_.get(singleJobDetails, 'jobCardNumber', '')}
            </Box>
          </Grid>
          <Grid lg={8.5} md={4.5} className="align-center-with-gap">
            Status:{' '}
            {singleJobDetails?.jobCardStatus === 'approved' && <>
                <span className="align-center">
                  <FiberManualRecordIcon
                    sx={{ fontSize: '14px', color: '#C89AFC' }}
                  />
                </span>{' '}
                <span className="font-size-14-with-semibold">Approved</span>
                </>}
                {singleJobDetails?.jobCardStatus === 'completed' && <>
                <span className="align-center">
                  <FiberManualRecordIcon
                    sx={{ fontSize: '14px', color: '#1AD04C' }}
                  />
                </span>{' '}
                <span className="font-size-14-with-semibold">Completed</span>
                </>}
          </Grid>
        </Grid>
      </Box>
      {finalBompartData?.map((part, partIndex) => (
        <React.Fragment key={partIndex}>
          {part.bomprocess.map((bomprocess, bomprocessIndex) => (
      <Formik
        enableReinitialize={true}
              key={`${partIndex}-${bomprocessIndex}`}
          initialValues={{
            task: [initialTaskData]
          }}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            const flattenedTasks = values.task.flat().map(task => ({
              ...task,
              jobCardId: id,
              departmentId: selectDepartmentid,
              bomProcessId: finalBOMProcess?.id
            }))
            // TODO:: future use task id
            // const taskFinalData = flattenedTasks?.map((data) => {
            //   const { taskId, ...rest } = data
            //   return rest
            // })
            addTask({
              ...values,
              task: flattenedTasks,
              removedTask: taskRemoveId
            })
          }}

      >
        {({ errors, setFieldValue, handleChange, touched, values }) => (
          <React.Fragment>
            <Form>
              <Box className="common-parent-class-overflow-auto-285">
                {finalBompartData?.map((part, index) => (
                  <Box
                    className="form-child-border"
                    style={{ padding: '0px 20px' }}
                    mb={'30px'}
                    key={index}
                  >
                    <Grid container className="padding-20-75-border-bottom">
                      <Grid lg={1.75} md={2.75}>
                        <Box className="add-task-header">Part Name</Box>
                        <Box className="add-task-header-with-semibold">{part.partName}</Box>
                      </Grid>
                      <Grid lg={1.75} md={2.75}>
                        <Box className="add-task-header">Part Code</Box>
                        <Box className="add-task-header-with-semibold">{part.itemCode}</Box>
                      </Grid>
                      <Grid lg={1.75} md={2.75}>
                        <Box className="add-task-header">Drawing no.</Box>
                        <Box className="add-task-header-with-semibold">{part.drawingNumber}</Box>
                      </Grid>
                      <Grid lg={6.5} md={3.7} className="align-center-with-gap">
                        <span onClick={() => setOpenIndex(openIndex === index ? null : index)} style={{ cursor: 'pointer' }}>
                          {openIndex === index ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </span>
                      </Grid>
                    </Grid>

                    {openIndex === index && (
                      <Box className="form-child-border11" style={{ borderTop: '1px solid #dedede' }}>
                        <Typography className="form-heading-1" padding={'20px'}>
                          Add task
                        </Typography>

                        <FieldArray name={`task.${index}`}>
                          {({ push, remove }) => (
                            <React.Fragment>
                              <Box className="gap-20">
                                {values?.task?.[index]?.length > 0 &&
                                  values?.task?.[index]?.map((task, Taskindex) => (
                                    <Box key={Taskindex} padding={'20px'}>
                                         <Grid lg={4} md={3} >
                                        <Typography className="form-heading-1 pb-20">
                                         {Taskindex + 1}{'.'} {'Task'}
                                        </Typography>
                                      </Grid>
                                      <Box borderBottom={index === values?.task?.[index]?.length - 1 ? '' : '1px solid #dedede'}>
                                        <Typography className="form-heading-1 pb-20" display={'flex'} justifyContent={'end'}>
                                          <span
                                            onClick={() => {
                                              remove(Taskindex)
                                              if (task?.id) {
                                                setTaskRemoveId((prev) => [...prev, task?.id])
                                              }
                                            }}
                                            className="process-delete"
                                          >
                                            <DeleteIconNew />
                                          </span>
                                        </Typography>
                                        <Grid
                                          spacing={2}
                                          paddingBottom={'20px'}
                                          container
                                        >
                                          <Grid item xs={12} md={4} xl={4}>
                                            <CustomInput
                                              label="Task Name"
                                              placeholder="Enter Task Name"
                                              handleChange={handleChange}
                                              value={values?.task?.[index]?.[Taskindex]?.taskNickName || ''}
                                              name={`task.${index}.${Taskindex}.taskNickName`}
                                              InputProps={{
                                                endAdornment:
                                                  touched?.task?.[index]?.[Taskindex]?.taskNickName &&
                                                  errors?.task?.[index]?.[Taskindex]?.taskNickName &&
                                                  icon
                                              }}
                                              errors={
                                                errors?.task?.[index]?.[Taskindex]?.taskNickName
                                              }
                                              touched={
                                                touched?.task?.[index]?.[Taskindex]?.taskNickName
                                              }
                                            />
                                          </Grid>
                                          <Grid item xs={4} md={4} lg={4}>
                                            <Typography className="field-title">
                                              Task Type
                                              <Required />
                                            </Typography>
                                            <FormControl fullWidth>
                                              <Selects
                                                name={`task.${index}.${Taskindex}.taskType`}
                                                variant="outlined"
                                                value={values?.task?.[index]?.[Taskindex]?.taskType || ''}
                                                onChange={(e) => {
                                                  setFieldValue(`task.${index}.${Taskindex}.taskType`, e.target.value)
                                                }}
                                                displayEmpty
                                              >
                                                <MenuItem value="">
                                                  <span className="menu-item-span">Select Task Type</span>
                                                </MenuItem>
                                                {tasktypetData?.data?.map((data, tasktypeind) => {
                                                  return (
                                                    <MenuItem value={data?.id} key={tasktypeind}>
                                                      {data?.name}
                                                    </MenuItem>
                                                  )
                                                })}
                                              </Selects>
                                            </FormControl>
                                            {touched.department && errors.department && (
                                              <Typography className="validation-text">
                                                {typeof errors.department === 'string'
                                                  ? errors.department
                                                  : ''}
                                              </Typography>
                                            )}
                                          </Grid>
                                          <Grid item xs={12} md={4} xl={4}>
                                            <CustomInput
                                              label="Description"
                                              placeholder="Enter Description"
                                              handleChange={handleChange}
                                              isRequired={false}
                                              value={values?.task?.[index]?.[Taskindex]?.description || ''}
                                              name={`task.${index}.${Taskindex}.description`}
                                              InputProps={{
                                                endAdornment:
                                                  touched?.task?.[index]?.[Taskindex]?.description &&
                                                  errors?.task?.[index]?.[Taskindex]?.description &&
                                                  icon
                                              }}
                                              errors={
                                                errors?.task?.[index]?.[Taskindex]?.description
                                              }
                                              touched={
                                                touched?.task?.[index]?.[Taskindex]?.description
                                              }
                                            />
                                          </Grid>
                                        </Grid>
                                      </Box>
                                    </Box>
                                  ))}
                              </Box>
                              <Box padding={'20px 25px'}>
                                <CustomButton
                                  variant="outlined"
                                  className="button-box"
                                  type="button"
                                  title="Add"
                                  endIcon={<Plus fill={'#0160B9'} />}
                                  onClick={() =>
                                    push({
                                      taskNickName: '',
                                      taskType: '',
                                      description: '',
                                      bomPartId: part.id
                                    })
                                  }
                                />
                              </Box>
                            </React.Fragment>
                          )}
                        </FieldArray>
                      </Box>
                    )}
                  </Box>
                ))}
              </Box>

              <Box className="button-child">
                <CustomButton title="Submit" type="submit" />
              </Box>
            </Form>
          </React.Fragment>
        )}
      </Formik>
          ))}
        </React.Fragment>
      ))}
    </Box>
  )
}

export default AddTaskProductionTeamMember
