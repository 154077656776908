import { Box, Tab, Typography } from '@mui/material'
import React, { useState } from 'react'
import Tabs from '@mui/material/Tabs'
import { TabContext, TabPanel } from '@mui/lab'
import AddIcon from '@mui/icons-material/Add'
import CustomButton from '../../leastcomponent/custombutton/custombutton'
import { useNavigate } from 'react-router-dom'
import DeleteIconNew from '../../assets/svg/DeleteIconNew'
import DeleteWarningModal from '../../modals/deletewarningmodal'
import BreadcrumbNavigation from '../commoncomponents/breadcrumbnavigation'
import NestedTable from '../../leastcomponent/customtable/nestedTable'
import EditIconNew from '../../assets/svg/EditIconNew'
import { useGetproductionTeamMember } from '../../hooks/jobcardHooks'
import moment from 'moment'
import { useQueryClient } from '@tanstack/react-query'

const ProductionTeamHead = ({ user }) => {
  const [value, setValue] = React.useState('1')
  const [deleteRecord, setDeleteRecord] = useState(false)
  const handleDeleteOpen = () => setDeleteRecord(true)
  const handleDeleteClose = () => setDeleteRecord(false)
  const handleChange = (event, newValue) => {
    setValue(newValue)
  }
  const navigate = useNavigate()
  const { data: productionTeamMemberListInfo } = useGetproductionTeamMember()
  const productionTeamMemberList = productionTeamMemberListInfo?.results
  const finalDataOne = productionTeamMemberList?.map((data, ind) => ({
    no: ind + 1,
    name: data.name,
    number: data.number,
    saleOrderNumber: data.saleOrderNumber,
    productName: data.productName,
    quantity: data.quantity,
    customerName: data.customerName,
    // submitted: data.submitted ? moment(data?.dateBought).format('hh:mm A, DD MMM, YYYY') : '-',
    submitted: moment(data?.submitted).format('hh:mm a, DD MMM, YYYY'),
    // date: data.date,
    date: moment(data?.date).format('hh:mm a, DD MMM, YYYY'),
    jobCardStatus: data.jobCardStatus,
    history: [
      {
        partName: data.bomparts?.[0]?.partName,
        itemCode: data.bomparts?.[0]?.itemCode,
        drawingNumber: data.bomparts?.[0]?.drawingNumber,
        jobCardId: data.bomparts?.[0]?.jobCardId,
        id: data.bomparts?.[0]?.id
      }
    ]
  })) || []
  const mainCols = [
    { field: '#', headerName: '#', flex: 0.2 },
    { field: 'Job Card Number', headerName: 'Job Card Number', flex: 1 },
    { field: 'Job Card Name', headerName: 'Job Card Name', flex: 1 },
    { field: 'Product', headerName: 'Product', flex: 1 },
    { field: 'Qty', headerName: 'Qty', flex: 1 },
    { field: 'Customer Name', headerName: 'Customer Name', flex: 1 },
    { field: 'Submitted on', headerName: 'Submitted on', flex: 1 },
    { field: 'Due Date', headerName: 'Due Date', flex: 1 },
    { field: 'Overall completion', headerName: 'Overall completion', flex: 1 }
  ]

  const finalDataColumns = [
    { field: 'id', headerName: '#', flex: 0.2 },
    { field: 'Part Name', headerName: 'Part Name', flex: 1 },
    { field: ' Part Code', headerName: ' Part Code', flex: 1 },
    { field: 'Drawing Number', headerName: 'Drawing Number', flex: 1 },
    { field: 'Qty', headerName: 'Qty', flex: 1 },
    { field: 'Dept. sequence', headerName: 'Dept. sequence', flex: 1 },
    { field: 'Preceding dept.', headerName: 'Preceding dept.', flex: 1 },
    { field: 'Following dept.', headerName: 'Following dept.', flex: 1 },
    { field: 'Completion', headerName: 'Completion', flex: 1 },
    {
      field: 'actions',
      headerName: 'Actions',
      sortable: false,
      disableClickEventBubbling: true,
      flex: 1,
      renderCell: () => {
        return (
          <Box className="report-table-btn-main">
            <span className="report-delete">
              <EditIconNew />
            </span>
            <span className="report-delete">
              <DeleteIconNew />
            </span>
          </Box>
        )
      }
    }
  ]
  const finalData = [
    {
      no: 1,
      number: 123112125,
      name: 'jobCard 87',
      productName: 'intex',
      quantity: 12,
      customerName: 'Customer',
      date: 10,
      dueDate: 10,
      description: 10,
      history: [
        {
          partName: 'Part Name',
          partCode: 123456,
          drawingNumber: 123456,
          qty: 10,
          deptSequence: 2,
          precedingDept: 'Department',
          followingDept: 'Department',
          completion: 10,
          action: 'action'
        }
      ]
    },
    {
      no: 2,
      number: 123456789,
      name: 'Job Card Name',
      productName: 'Product',
      quantity: 10,
      customerName: 'Customer',
      date: 10,
      dueDate: 10,
      description: 10,
      history: [
        {
          partName: 'Part Name',
          partCode: 123456,
          drawingNumber: 123456,
          qty: 10,
          deptSequence: 2,
          precedingDept: 'Department',
          followingDept: 'Department',
          completion: 10,
          action: 'action'
        }
      ]
    },
    {
      no: 3,
      number: 123456789,
      name: 'Job Card Name',
      productName: 'Product',
      quantity: 10,
      customerName: 'Customer',
      date: 10,
      dueDate: 10,
      description: 10
    }
  ]
  const queryClient = useQueryClient()
  const userData = queryClient.getQueryData([user.LOGGED_IN_USER])
  const departmentGroupTitle = userData?.data?.data?.user?.roleId?.departmentGroup?.title
  return (
    <React.Fragment>
      <Box className="production-team-head-overflow-hidden">
        <BreadcrumbNavigation
          breadCrumbData={null}
          pageTitle={`Job Cards - ${userData?.data?.data?.user?.userRoleType === 'administrator' ? 'All' : departmentGroupTitle}`}
          backToLink={null}
        />
        <Box className="production-team-head-overflow-auto">
          <TabContext value={value}>
            <Box
              sx={{
                typography: 'body1'
              }}
              className="tab-content-padding"
            >
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="lab API tabs example"
              >
                <Tab className="tab-head" label="Approved" value="1" />
                <Tab className="tab-head" label="Pending" value="2" />
                <Tab className="tab-head" label="Ongoing" value="3" />
                <Tab className="tab-head" label="Completed" value="4" />
              </Tabs>
            </Box>
            <TabPanel value="1" className="tab-panel-padding">
              <Box>
                <Box className="common-parent-haeding-btn-div">
                  <Typography className="common-tabel-heading">
                    Approved Job cards
                  </Typography>
                  <CustomButton
                    variant="contained"
                    title="Add Job Card"
                    onClick={() => {
                      navigate('/add-job-card')
                    }}
                    type="button"
                    endIcon=""
                    startIcon={<AddIcon />}
                  />
                </Box>

                <NestedTable
                  rows={finalDataOne}
                  columns={finalDataColumns}
                  mainCols={mainCols}
                  user={user}

                />
              </Box>
            </TabPanel>
            <TabPanel value="2" className="tab-panel-padding">
              <Box>
                <Box className="common-parent-haeding-btn-div">
                  <Typography className="common-tabel-heading">
                    Pending Job cards
                  </Typography>
                  <CustomButton
                    variant="contained"
                    title="Add Job Card"
                    onClick={() => {
                      navigate('/add-job-card')
                    }}
                    type="button"
                    endIcon=""
                    startIcon={<AddIcon />}
                  />
                </Box>

                <NestedTable
                  rows={finalData}
                  columns={finalDataColumns}
                  mainCols={mainCols}
                />
              </Box>

              {/* To do future  */}
              {/* <Box>
              <Box className="common-parent-haeding-btn-div">
                <Typography className="common-tabel-heading">
                  Approved Job cards
                </Typography>
              </Box>
              <TableComponent  rows={rows} columns={columns}/>
            </Box> */}
            </TabPanel>
            <TabPanel value="3" className="tab-panel-padding">
              <Box>
                <Box className="common-parent-haeding-btn-div">
                  <Typography className="common-tabel-heading">
                    Ongoing Job cards
                  </Typography>
                  <CustomButton
                    variant="contained"
                    title="Add Job Card"
                    onClick={() => {
                      navigate('/add-job-card')
                    }}
                    type="button"
                    endIcon=""
                    startIcon={<AddIcon />}
                  />
                </Box>
                <NestedTable
                  rows={finalData}
                  columns={finalDataColumns}
                  mainCols={mainCols}

                />
              </Box>
            </TabPanel>
            <TabPanel value="4" className="tab-panel-padding">
              <Box>
                <Box className="common-parent-haeding-btn-div">
                  <Typography className="common-tabel-heading">
                    Completed Job cards
                  </Typography>
                  <CustomButton
                    variant="contained"
                    title="Add Job Card"
                    onClick={() => {
                      navigate('/add-job-card')
                    }}
                    type="button"
                    endIcon=""
                    startIcon={<AddIcon />}
                  />
                </Box>
                <NestedTable
                  rows={finalData}
                  columns={finalDataColumns}
                  mainCols={mainCols}
                />
              </Box>
            </TabPanel>
          </TabContext>
        </Box>
        {deleteRecord && (
          <DeleteWarningModal
            open={deleteRecord}
            handleOpen={handleDeleteOpen}
            handleClose={handleDeleteClose}
          />
        )}
      </Box>
    </React.Fragment>
  )
}

export default ProductionTeamHead
