import React, { useEffect, useState } from 'react'
import ReactFlow, { MiniMap, ReactFlowProvider } from 'reactflow'
import 'reactflow/dist/style.css'
import { Box, Typography, useMediaQuery } from '@mui/material'
import { useGetJobDetailsSingleSchedule } from '../../hooks/jobcardHooks'
import { useParams } from 'react-router-dom'
import moment from 'moment'
import BreadcrumbNavigation from '../commoncomponents/breadcrumbnavigation'
import { MdCircle } from 'react-icons/md'
import CustomNode from './CustomeNode'
import './styles.css'

const transformPartsToNodesAndEdges = (data, expandedNodes, matches, screen2560, parentId = null, level = 0) => {
  const nodes = []
  const edges = []
  let yOffset = 0

  data.forEach((job, jobIndex) => {
    const jobNodeId = `${parentId || 'root'}-job-${jobIndex}-${level}`
    const jobNodeY = yOffset
    nodes.push({
      id: jobNodeId,
      type: 'customNode',
      data: {
        name: job?.name ? job?.name : '-',
        number: job?.jobCardNumber ? job?.jobCardNumber : '-',
        type: 'Job Card',
        date: job?.createdAt ? moment(job?.createdAt).format('DD/MM/YY') : '-',
        border: job?.name,
        children: job?.bompart?.map((part, partIndex) => `${jobNodeId}-part-${partIndex}-${level + 1}`),
        jobCardStatus: job?.jobCardStatus
      },
      position: { x: screen2560 ? level * 480 : matches ? level * 380 : level * 280, y: jobNodeY },
      sourcePosition: 'right',
      targetPosition: 'left',
      draggable: false,
      style: { cursor: 'pointer' }
    })

    yOffset += 100

    job.bompart.forEach((part, partIndex) => {
      const partNodeId = `${jobNodeId}-part-${partIndex}-${level + 1}`
      const partNodeY = yOffset
      nodes.push({
        id: partNodeId,
        type: 'customNode',
        data: {
          name: part?.partName,
          number: part?.itemCode,
          type: 'Part',
          round: part?.partName,
          children: part?.bomprocess?.map((process, processIndex) => `${partNodeId}-process-${processIndex}-${level + 2}`),
          jobCardStatus: part?.partStatus
        },
        position: { x: screen2560 ? (level + 1) * 480 : matches ? (level + 1) * 380 : (level + 1) * 280, y: partNodeY },
        sourcePosition: 'right',
        targetPosition: 'left',
        draggable: false,
        style: { cursor: 'pointer' }
      })

      edges.push({
        id: `${jobNodeId}-${partNodeId}`,
        source: jobNodeId,
        target: partNodeId,
        type: 'smoothstep',
        style: { stroke: '#0160B9', strokeWidth: 2 }
      })

      yOffset += 100

      part.bomprocess.forEach((process, processIndex) => {
        const processNodeId = `${partNodeId}-process-${processIndex}-${level + 2}`
        const processNodeY = yOffset
        nodes.push({
          id: processNodeId,
          type: 'customNode',
          data: {
            name: process?.department?.name,
            number: process?.department?.code,
            type: 'Department',
            round: process?.department?.name,
            children: process?.task?.map((task, taskIndex) => `${processNodeId}-task-${taskIndex}-${level + 3}`),
            // jobCardStatus: process?.processStatus
            processStatus: process?.processStatus

          },
          position: { x: screen2560 ? (level + 2) * 480 : matches ? (level + 2) * 380 : (level + 2) * 280, y: processNodeY },
          sourcePosition: 'right',
          targetPosition: 'left',
          draggable: false,
          style: { cursor: 'pointer' }
        })

        edges.push({
          id: `${partNodeId}-${processNodeId}`,
          source: partNodeId,
          target: processNodeId,
          type: 'smoothstep',
          style: { stroke: '#0160B9', strokeWidth: 2 }
        })

        yOffset += 100

        process.task.forEach((task, taskIndex) => {
          const taskNodeId = `${processNodeId}-task-${taskIndex}-${level + 3}`
          const taskNodeY = yOffset
          nodes.push({
            id: taskNodeId,
            type: 'customNode',
            data: {
              name: task?.taskNickName,
              number: task?.taskType.name,
              type: 'Task',
              round: task?.taskNickName,
              jobCardStatus: task?.taskStatus
            },
            position: { x: screen2560 ? (level + 3) * 480 : matches ? (level + 3) * 380 : (level + 3) * 280, y: taskNodeY },
            sourcePosition: 'right',
            targetPosition: 'left',
            draggable: false,
            style: { cursor: 'pointer' }
          })

          edges.push({
            id: `${processNodeId}-${taskNodeId}`,
            source: processNodeId,
            target: taskNodeId,
            type: 'smoothstep',
            style: { stroke: '#0160B9', strokeWidth: 2 }
          })

          yOffset += 125
        })

        yOffset += 100
      })

      yOffset += 100
    })

    yOffset += 100
  })

  return { nodes, edges, totalHeight: yOffset }
}

const ScheduleNewOneNew = () => {
  const [nodes, setNodes] = useState([])
  const [edges, setEdges] = useState([])
  const [expandedNodes, setExpandedNodes] = useState([])
  const [containerHeight, setContainerHeight] = useState()
  const matches = useMediaQuery('(min-width:1870px)')
  const screen2560 = useMediaQuery('(min-width:2560px)')
  const { id } = useParams()

  const { data: singleJobDetails, error: scheduleError } = useGetJobDetailsSingleSchedule(id, !!id)
  const getJobDetailsSchedule = singleJobDetails?.data

  useEffect(() => {
    document.body.className = 'currentapp_hr teams'
    if (scheduleError) {
      // console.error('Error fetching job details schedule:', scheduleError)
    } else if (getJobDetailsSchedule && Array.isArray(getJobDetailsSchedule)) {
      const { nodes, edges, totalHeight } = transformPartsToNodesAndEdges(getJobDetailsSchedule, expandedNodes, matches, screen2560)
      setNodes(nodes)
      setEdges(edges)
      setContainerHeight(totalHeight)
    }
  }, [getJobDetailsSchedule, scheduleError, expandedNodes])

  const handleNodeClick = (nodeId) => {
    setExpandedNodes((prevExpandedNodes) =>
      prevExpandedNodes.includes(nodeId)
        ? prevExpandedNodes.filter((id) => id !== nodeId)
        : [...prevExpandedNodes, nodeId]
    )
  }

  return (
    <ReactFlowProvider>
      <React.Fragment>
        <Box className="production-team-head-overflow">
          <BreadcrumbNavigation pageTitle={'Department Kanban view '} backToLink={'/job-card'} />
          <Box className="production-team-head-overflow">
            <Box className="timeline" justifyContent={'space-between'} mt={'20px'} ml={'20px'} mr={'20px'}>
              <Typography sx={{ fontWeight: 600, alignItems: 'center', mt: '5px' }}>
                Workflow for Job Card : {getJobDetailsSchedule?.[0]?.name}
              </Typography>
              <Box display={'flex'} justifyContent={'space-around'} gap={'10px'}>
                <Typography className="" paddingTop="5px" fontWeight="600">
                  Summary:
                </Typography>
                <Typography className="status-header">
                  <MdCircle color="#C0C0C0" /> Not Started
                </Typography>
                <Typography className="status-header">
                  <MdCircle color="#FF9900" /> Ongoing
                </Typography>
                <Typography className="status-header">
                  <MdCircle color="#1AD04C" /> Completed
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box className="hr-teams" ml={'20px'} mt={'30px'} mr={'20px'}>
          <Box display={'flex'} justifyContent={'space-around'} mr={screen2560 ? '350px' : matches ? '100px' : '0px'} mb={'20px'} mt={'20px'} gap={'20px'}>
            <Typography className="card-heding" style={{ height: `${containerHeight}px` }}>Job Card</Typography>
            {/* //! for upcomming use */}
            {/* <Typography className="card-heding" style={{ height: `${containerHeight}px` }}>Parts</Typography> */}
            {/* <Typography className="card-heding" style={{ height: `${containerHeight}px` }}>Process/department</Typography> */}
            {/* <Typography className="card-heding" style={{ height: `${containerHeight}px` }} mr={'20px'}>
              Task
            </Typography> */}
          </Box>
          <Box ml={screen2560 ? '108px' : matches ? '55px' : '20px'} mt={'20px'} className='parent-react-flow'>
            <Box style={{ height: `${containerHeight}px`, overflow: 'visible' }}>
              <ReactFlow
                nodes={nodes}
                edges={edges}
                zoomOnScroll={false}
                zoomOnPinch={false}
                panOnScroll={false}
                panOnDrag={false}
                nodesDraggable={false}
                elementsSelectable={false}
                preventScrolling={true}
                connectOnClick={false}
                nodeTypes={{ customNode: CustomNode }}
                onNodeClick={(_, node) => handleNodeClick(node.id)}
                connectionLineStyle={{ display: 'none' }}
              >
                <MiniMap />
              </ReactFlow>
            </Box>
          </Box>
        </Box>
      </React.Fragment>
    </ReactFlowProvider>
  )
}

export default ScheduleNewOneNew

// import React, { useEffect, useState } from 'react'
// import ReactFlow, { MiniMap, ReactFlowProvider } from 'reactflow'
// import 'reactflow/dist/style.css'
// import { Box, Typography, useMediaQuery } from '@mui/material'
// import { useGetJobDetailsSingleSchedule } from '../../hooks/jobcardHooks'
// import { useParams } from 'react-router-dom'
// import moment from 'moment'
// import BreadcrumbNavigation from '../commoncomponents/breadcrumbnavigation'
// import { MdCircle } from 'react-icons/md'
// import CustomNode from './CustomeNode'
// import './styles.css'

// const transformPartsToNodesAndEdges = (data, expandedNodes, matches, screen2560, parentId = null, level = 0) => {
//   const nodes = []
//   const edges = []
//   let yOffset = 0

//   data.forEach((job, jobIndex) => {
//     const jobNodeId = `${parentId || 'root'}-job-${jobIndex}-${level}`
//     const jobNodeY = yOffset
//     nodes.push({
//       id: jobNodeId,
//       type: 'customNode',
//       data: {
//         name: job?.name ? job?.name : '-',
//         number: job?.jobCardNumber ? job?.jobCardNumber : '-',
//         type: 'Job Card',
//         date: job?.createdAt ? moment(job?.createdAt).format('DD/MM/YY') : '-',
//         border: job?.name,
//         children: job?.bompart?.map((part, partIndex) => `${jobNodeId}-part-${partIndex}-${level + 1}`)
//       },
//       position: { x: screen2560 ? level * 480 : matches ? level * 380 : level * 280, y: jobNodeY },
//       sourcePosition: 'right',
//       targetPosition: 'left',
//       draggable: false,
//       style: { cursor: 'pointer' }
//     })

//     yOffset += 100

//     job.bompart.forEach((part, partIndex) => {
//       const partNodeId = `${jobNodeId}-part-${partIndex}-${level + 1}`
//       const partNodeY = yOffset
//       nodes.push({
//         id: partNodeId,
//         type: 'customNode',
//         data: {
//           name: part?.partName,
//           number: part?.itemCode,
//           type: 'Part',
//           date: part?.createdAt ? moment(part?.createdAt).format('DD/MM/YY') : '-',
//           round: part?.partName,
//           children: part?.bomprocess?.map((process, processIndex) => `${partNodeId}-process-${processIndex}-${level + 2}`)
//         },
//         position: { x: screen2560 ? (level + 1) * 480 : matches ? (level + 1) * 380 : (level + 1) * 280, y: partNodeY },
//         sourcePosition: 'right',
//         targetPosition: 'left',
//         draggable: false,
//         style: { cursor: 'pointer' }
//       })

//       edges.push({
//         id: `${jobNodeId}-${partNodeId}`,
//         source: jobNodeId,
//         target: partNodeId,
//         type: 'smoothstep',
//         style: { stroke: '#0160B9', strokeWidth: 2 }
//       })

//       yOffset += 100

//       part.bomprocess.forEach((process, processIndex) => {
//         const processNodeId = `${partNodeId}-process-${processIndex}-${level + 2}`
//         const processNodeY = yOffset
//         nodes.push({
//           id: processNodeId,
//           type: 'customNode',
//           data: {
//             name: process?.department?.name,
//             number: process?.department?.code,
//             type: 'Department',
//             date: '-',
//             round: process?.department?.name,
//             children: process?.task?.map((task, taskIndex) => `${processNodeId}-task-${taskIndex}-${level + 3}`)
//           },
//           position: { x: screen2560 ? (level + 2) * 480 : matches ? (level + 2) * 380 : (level + 2) * 280, y: processNodeY },
//           sourcePosition: 'right',
//           targetPosition: 'left',
//           draggable: false,
//           style: { cursor: 'pointer' }
//         })

//         edges.push({
//           id: `${partNodeId}-${processNodeId}`,
//           source: partNodeId,
//           target: processNodeId,
//           type: 'smoothstep',
//           style: { stroke: '#0160B9', strokeWidth: 2 }
//         })

//         yOffset += 100

//         process.task.forEach((task, taskIndex) => {
//           const taskNodeId = `${processNodeId}-task-${taskIndex}-${level + 3}`
//           const taskNodeY = yOffset
//           nodes.push({
//             id: taskNodeId,
//             type: 'customNode',
//             data: {
//               name: task?.taskNickName,
//               number: task?.taskType.name,
//               type: 'Task',
//               date: task?.createdAt ? moment(task?.createdAt).format('DD/MM/YY') : '-',
//               round: task?.taskNickName
//             },
//             position: { x: screen2560 ? (level + 3) * 480 : matches ? (level + 3) * 380 : (level + 3) * 280, y: taskNodeY },
//             sourcePosition: 'right',
//             targetPosition: 'left',
//             draggable: false,
//             style: { cursor: 'pointer' }
//           })

//           edges.push({
//             id: `${processNodeId}-${taskNodeId}`,
//             source: processNodeId,
//             target: taskNodeId,
//             type: 'smoothstep',
//             style: { stroke: '#0160B9', strokeWidth: 2 }
//           })

//           yOffset += 125
//         })

//         yOffset += 100
//       })

//       yOffset += 100
//     })

//     yOffset += 100
//   })

//   return { nodes, edges }
// }

// const ScheduleNewOneNew = () => {
//   const [nodes, setNodes] = useState([])
//   const [edges, setEdges] = useState([])
//   const [expandedNodes, setExpandedNodes] = useState([])
//   const matches = useMediaQuery('(min-width:1870px)')
//   const screen2560 = useMediaQuery('(min-width:2560px)')
//   const { id } = useParams()

//   const { data: singleJobDetails, error: scheduleError } = useGetJobDetailsSingleSchedule(id, !!id)
//   const getJobDetailsSchedule = singleJobDetails?.data

//   useEffect(() => {
//     document.body.className = 'currentapp_hr teams'
//     if (scheduleError) {
//       // console.error('Error fetching job details schedule:', scheduleError)
//     } else if (getJobDetailsSchedule && Array.isArray(getJobDetailsSchedule)) {
//       const { nodes, edges } = transformPartsToNodesAndEdges(getJobDetailsSchedule, expandedNodes, matches, screen2560)
//       setNodes(nodes)
//       setEdges(edges)
//     }
//   }, [getJobDetailsSchedule, scheduleError, expandedNodes])

//   const handleNodeClick = (nodeId) => {
//     setExpandedNodes((prevExpandedNodes) =>
//       prevExpandedNodes.includes(nodeId)
//         ? prevExpandedNodes.filter((id) => id !== nodeId)
//         : [...prevExpandedNodes, nodeId]
//     )
//   }

//   return (
//     <ReactFlowProvider>
//       <React.Fragment>
//         <Box className="production-team-head-overflow">
//           <BreadcrumbNavigation pageTitle={'Department Kanban view '} backToLink={'/job-card'} />
//           <Box className="production-team-head-overflow">
//             <Box className="timeline" justifyContent={'space-between'} mt={'20px'} ml={'20px'} mr={'20px'}>
//               <Typography sx={{ fontWeight: 600, alignItems: 'center', mt: '5px' }}>
//                 Workflow for Job Card : {getJobDetailsSchedule?.[0]?.name}
//               </Typography>
//               <Box display={'flex'} justifyContent={'space-around'} gap={'10px'}>
//                 <Typography className="" paddingTop="5px" fontWeight="600">
//                   Summary:
//                 </Typography>
//                 <Typography className="status-header">
//                   <MdCircle color="rgba(192, 192, 192, 1)" /> Not Started
//                 </Typography>
//                 <Typography className="status-header">
//                   <MdCircle color="rgba(255, 153, 0, 1)" /> Ongoing
//                 </Typography>
//                 <Typography className="status-header">
//                   <MdCircle color="rgba(26, 208, 76, 1)" /> Completed
//                 </Typography>
//               </Box>
//             </Box>
//           </Box>
//         </Box>
//         <Box className="hr-teams" ml={'20px'} mt={'30px'} mr={'20px'}>
//           <Box display={'flex'} justifyContent={'space-around'} mr={screen2560 ? '350px' : matches ? '100px' : '0px'} mb={'20px'} mt={'20px'} gap={'20px'}>
//             <Typography className="card-heding">Job Card</Typography>
//             <Typography className="card-heding">Parts</Typography>
//             <Typography className="card-heding">Process/department</Typography>
//             <Typography className="card-heding" mr={'20px'}>
//               Task
//             </Typography>
//           </Box>
//           <Box ml={screen2560 ? '108px' : matches ? '55px' : '20px'} mt={'20px'} className='parent-react-flow'>
//             <Box style={{ height: '4000px', overflow: 'visible' }}>
//               <ReactFlow
//                 nodes={nodes}
//                 edges={edges}
//                 zoomOnScroll={false}
//                 zoomOnPinch={false}
//                 panOnScroll={false}
//                 panOnDrag={false}
//                 nodesDraggable={false}
//                 elementsSelectable={false}
//                 preventScrolling={true}
//                 connectOnClick={false}
//                 nodeTypes={{ customNode: CustomNode }}
//                 onNodeClick={(_, node) => handleNodeClick(node.id)}
//                 connectionLineStyle={{ display: 'none' }}
//               >
//                 <MiniMap />
//               </ReactFlow>
//             </Box>
//           </Box>
//         </Box>
//       </React.Fragment>
//     </ReactFlowProvider>
//   )
// }

// export default ScheduleNewOneNew
