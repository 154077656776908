import * as React from 'react'
import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import styled from '@emotion/styled'
// import "./../../src/style/modal.css";
import './../../../src/assets/css/modal.css'
import { Form, Formik } from 'formik'
import { Grid, TextField, Typography } from '@mui/material'
import * as Yup from 'yup'
import { AiOutlineInfoCircle } from 'react-icons/ai'
// import ErrorPopup from "./ErrorPopup";
import CustomButton from '../../leastcomponent/custombutton/custombutton'
import { useChangePassword } from '../../hooks/authhooks'
import ErrorPopup from '../errorpopup'

const LoginSchema = Yup.object().shape({
  currentPassword: Yup.string().required('Enter your Conform PassWord'),
  password: Yup.string().required('Enter your password is required'),
  ConfirmPassword: Yup.string()
    .required('Please retype your password.')
    .oneOf([Yup.ref('password')], 'Passwords do not match.')
})

const Input = styled(TextField)(() => ({
  borderRadius: '10px',
  '.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input': {
    height: '48px',
    padding: '1px 14px'
  },

  '.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input:-webkit-autofill': {
    borderRadius: '10px'
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#D2D1D1',
      borderRadius: '10px'
    }
  }
}))
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  // width: 510,
  bgcolor: 'background.paper',
  // border: '2px solid #000',
  borderRadius: '32px',
  boxShadow: 24,
  p: 4
}

const HeadBox = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  '.login-box': {
    // height: '450px',
    // width: '503px',
    justifyContent: 'center'
  },
  '.head-image': {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '101px'
  },
  '.validation-text': {
    color: 'red',
    fontSize: '12px'
  },
  '.field-title': {
    fontSize: 14,
    fontWeight: 500,
    padding: '0px 0px 6px 0px'
    // marginTop: '15px',
  },
  '.head-title': {
    fontSize: 21,
    fontWeight: 600,
    padding: '0px 0px 6px 0px',
    color: '#fff'
    // marginTop: '15px',
  },
  '.back-color': {
    background: '#FFFFFF',
    width: '400px',
    borderRadius: '10px'
  },
  '.login-btn-box': {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '40px'
  },
  //   '.error-box': {

  //   },
  '.left-box': {
    width: '10px',
    height: '84px',
    background: '#CC0000'
  },
  '.right-box': {
    width: '493px',
    height: '52px',
    background: '#F5CCCC'
  },
  '.close-icon': {
    cursor: 'pointer'
  },
  '.login-btn': {
    padding: '9px 20px 10px !important',
    borderRadius: '10px !important',
    fontSize: '16px !important',
    fontWeight: 500,
    textTransform: 'capitalize'
  },
  '.grid-item-direction': {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  }
}))

const ChangePasswordModal = (props) => {
  const { open, handleClose, handleProfileClose } = props
  const icon = <AiOutlineInfoCircle color="red" />
  const [errorBox, setErrorBox] = React.useState(false)
  const handleErrorClose = () => setErrorBox(false)
  const handleErrorOpen = () => setErrorBox(true)
  const [errorMessage, setErrorMessage] = React.useState('')
  const {
    mutate: changePassword,
    isSuccess,
    error,
    isError
  } = useChangePassword(handleErrorOpen)
  React.useEffect(() => {
    if (isSuccess) {
      handleClose()
      handleProfileClose()
    }
    if (isError) {
      setErrorMessage(error?.data?.message)
    }
  }, [isSuccess, isError])
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <HeadBox>
            <Formik
              initialValues={{
                currentPassword: '',
                password: '',
                ConfirmPassword: ''
              }}
              validationSchema={LoginSchema}
              onSubmit={(values) => {
                changePassword({
                  currentPassword: values.currentPassword,
                  newPassword: values.password
                })
              }}
            >
              {({
                errors,
                touched,
                values,
                handleSubmit,
                handleChange,
                isValid,
                dirty
              }) => (
                <Form onSubmit={handleSubmit}>
                  <Box className="login-box">
                    <Grid container>
                      <Grid item xs={12} className="grid-item-direction">
                        <Box>
                          <Typography className="field-title" mt={'15px'}>
                            Current Password
                            {/* <Require /> */}
                          </Typography>

                          <Input
                            placeholder="Enter Conform Password"
                            fullWidth
                            className="back-color"
                            onChange={handleChange}
                            value={values.currentPassword}
                            name="currentPassword"
                            type="password"
                            InputProps={{
                              endAdornment:
                                touched.currentPassword &&
                                errors.currentPassword &&
                                icon
                            }}
                          />
                          {touched.currentPassword &&
                            errors.currentPassword && (
                              <Typography className="validation-text">
                                {errors.currentPassword}
                              </Typography>
                          )}
                        </Box>
                        <Box>
                          <Typography className="field-title" mt={'15px'}>
                            Enter Password
                            {/* <Require /> */}
                          </Typography>

                          <Input
                            placeholder="Enter Password"
                            fullWidth
                            className="back-color"
                            onChange={handleChange}
                            name="password"
                            type="password"
                            InputProps={{
                              endAdornment:
                                touched.password && errors.password && icon
                            }}
                          />
                          {touched.password && errors.password && (
                            <Typography className="validation-text">
                              {errors.password}
                            </Typography>
                          )}
                        </Box>
                        <Box>
                          <Typography className="field-title" mt="15px">
                            ReEnter Password
                            {/* <Require /> */}
                          </Typography>
                          <Input
                            placeholder="Retype your Password"
                            fullWidth
                            type="password"
                            className="back-color"
                            onChange={handleChange}
                            name="ConfirmPassword"
                            InputProps={{
                              endAdornment:
                                touched.ConfirmPassword &&
                                errors.ConfirmPassword &&
                                icon
                            }}
                          />
                          {touched.ConfirmPassword &&
                            errors.ConfirmPassword && (
                              <Typography className="validation-text">
                                {errors.ConfirmPassword}
                              </Typography>
                          )}
                        </Box>
                      </Grid>
                    </Grid>
                    <Box className="login-btn-box">
                      <CustomButton
                        type="submit"
                        className={
                          !(isValid && dirty) ? 'disable-btn' : 'blue-bg'
                        }
                        // onClick={() => handleClose()}
                        variant="contained"
                        // disabled={!(isValid && dirty)}
                        // disabled={false}
                        title="Change Password"
                      />
                    </Box>
                    {errorBox && (
                      <ErrorPopup
                        isOpen={errorBox}
                        handleClose={handleErrorClose}
                        errorDetails={errorMessage}
                      />
                    )}
                  </Box>
                </Form>
              )}
            </Formik>
          </HeadBox>
        </Box>
      </Modal>
    </div>
  )
}
export default ChangePasswordModal
