import { Box, Typography } from '@mui/material'
import React, { useState } from 'react'
import TableComponent from '../../leastcomponent/customtable/customtable'
// import BreadCrumb from "../../leastcomponent/breadcrumb/breadcrumb";
import DeleteIconNew from '../../assets/svg/DeleteIconNew'
import EditIconNew from '../../assets/svg/EditIconNew'
import CustomButton from '../../leastcomponent/custombutton/custombutton'
import AddIcon from '@mui/icons-material/Add'
import DeleteWarningModal from '../../modals/deletewarningmodal'
import { useNavigate } from 'react-router-dom'
import BreadcrumbNavigation from '../commoncomponents/breadcrumbnavigation'

const Simulate = () => {
  const [deleteRecord, setDeleteRecord] = useState(false)
  const handleDeleteOpen = () => setDeleteRecord(true)
  const handleDeleteClose = () => setDeleteRecord(false)
  const navigate = useNavigate()
  const rows = [
    {
      id: 1,
      name: 'Simulation name',
      simulationNum: '123-456-789-0123 ',
      dateTime: '10/10/10, 10:10 ',
      action: 'action'
    },
    {
      id: 2,
      name: 'Simulation name ',
      simulationNum: '123-456-789-0123 ',
      dateTime: '10/10/10, 10:10 ',
      action: 'action'
    },
    {
      id: 3,
      name: 'Simulation name',
      simulationNum: '123-456-789-0123 ',
      dateTime: '10/10/10, 10:10 ',
      action: 'action'
    },
    {
      id: 4,
      name: 'Simulation name',
      simulationNum: '123-456-789-0123 ',
      dateTime: '10/10/10, 10:10 ',
      action: 'action'
    },
    {
      id: 5,
      name: 'Simulation name',
      simulationNum: '123-456-789-0123 ',
      dateTime: '10/10/10, 10:10 ',
      action: 'action'
    },
    {
      id: 6,
      name: 'Simulation name',
      simulationNum: '123-456-789-0123 ',
      dateTime: '10/10/10, 10:10 ',
      action: 'action'
    },
    {
      id: 7,
      name: 'Simulation name',
      simulationNum: '123-456-789-0123 ',
      dateTime: '10/10/10, 10:10 ',
      action: 'action'
    }
  ]
  const columns = [
    { field: 'id', headerName: '#', flex: 0.2 },
    { field: 'name', headerName: 'Simulation Name', flex: 1 },
    { field: 'simulationNum', headerName: 'Simulation Number', flex: 1 },
    {
      field: 'dateTime',
      headerName: 'Master schedule (date and time)',
      flex: 1
    },
    {
      field: 'actions',
      headerName: 'Actions',
      sortable: false,
      disableClickEventBubbling: true,
      flex: 1,
      renderCell: () => {
        return (
          <Box className="resource-table-btn-main">
            <span className="process-delete">
              <EditIconNew />
            </span>
            <span onClick={handleDeleteOpen} className="process-delete">
              <DeleteIconNew />
            </span>
          </Box>
        )
      }
    }
  ]
  return (
    <Box className="production-team-head-overflow-hidden">
      <BreadcrumbNavigation
        breadCrumbData={null}
        pageTitle={'Simulate'}
        backToLink={'/schedule'}
      />
      <Box className="common-parent-class-overflow-auto">
        {/* <BreadCrumb title="Simulate" /> */}
        <Box>
          <Box className="common-parent-haeding-btn-div">
            <Typography className="common-tabel-heading">
              Simulations
            </Typography>
            <CustomButton
              variant="contained"
              title="Create Scenario"
              onClick={() => navigate('/create-scenario')}
              type="button"
              endIcon=""
              startIcon={<AddIcon />}
            />
          </Box>
          <TableComponent rows={rows} columns={columns} />
        </Box>
        {deleteRecord && (
          <DeleteWarningModal
            open={deleteRecord}
            handleOpen={handleDeleteOpen}
            handleClose={handleDeleteClose}
          />
        )}
      </Box>
    </Box>
  )
}

export default Simulate
