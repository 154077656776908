import React from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { Box, Card, Typography } from '@mui/material'
import styled from '@emotion/styled'
// import CustomSelect from '../customselect/customselect'

const MainCard = styled(Card)(() => ({
  '.chart-title': {
    fontSize: 18,
    fontWeight: '600',

    padding: 7
    // padding: 18
  },
  '.parent-bar-chart': {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '12px 16px',
    background: '#F0F6FF',
    border: '1px solid #DEDEDE',
    // margin: "10px",
    width: '100%'
  },
  '.Overall': {
    fontSize: '16px',
    fontWeight: '600'
  },
  '.percentile': {
    fontSize: '18px',
    fontWeight: '700'
  }
}))

const BarChart = (props) => {
  const {
    title,
    type = 'column',
    data,
    isGridLine = false,
    height,
    barChartData = true
  } = props

  const options = {
    credits: {
      enabled: false
    },
    exporting: { enabled: false },
    chart: {
      type,
      height
    },
    title: {
      text: ''
    },
    xAxis: {
      type: 'category',
      labels: {
        enabled: type !== 'bar'
      }
      // enabled: false
    },

    yAxis: {
      labels: {
        enabled: type !== 'bar'
      },
      min: 0,
      max: type === 'column' ? 30 : 100,
      tickInterval: type === 'column' ? 5 : 25,
      gridLineColor: isGridLine ? '#E2E2E2' : 'transparent',
      lineColor: '#002242',
      title: {
        text: type === 'column' ? 'Time (hrs)' : ''
      }
    },
    legend: {
      enabled: true,
      align: type === 'column' ? 'center' : 'left'
    },

    plotOptions: {
      series: {
        pointPadding: 0,
        borderRadius: 10,
        dataLabels: {
          enabled: true
        }
      }
    },

    tooltip: {
      headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
      pointFormat:
        '<span style="color:{red}">{point.name}</span>: <b>{point.y:.2f}%</b> of total<br/>'
    },

    series: data
  }

  return (
    <div className="App">
      <MainCard
        variant="standerd"
        sx={{ border: '1px solid #E6EAEF', padding: '15px 15px 0px 15px', borderRadius: '0px' }}
      >
        {type === 'bar' && (
          <Box className="parent-bar-chart">
            <Typography className="Overall">Overall Utilization</Typography>
            <Typography className="percentile">-</Typography>
          </Box>
        )}
        <Box sx={{ display: 'flex', justifyContent: 'space-between', paddingTop: '10px' }}>
        <Typography className="chart-title">{title}</Typography>
         {/* TODO:: future use all workstations */}
        {/* <Box sx={{ width: '228px' }}>
        <CustomSelect
        name="department"
        isRequired={false}
        islabel={false}
        placeholder="All workstations"
        className="default-select-new"
        // options={options}
        // value={selectedDepartment}
        // handleSelectChange={handleSelectChange}
      />
        </Box> */}
        </Box>

        <Box
          className={`${
            barChartData
              ? 'bar-chart-padding-dash'
              : 'column-chart-padding-dash'
          }`}
        >
          <HighchartsReact highcharts={Highcharts} options={options} />
        </Box>
      </MainCard>
    </div>
  )
}
export default BarChart
