import * as React from 'react'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { Typography } from '@mui/material'

import './../customtimepicker/customtimepicker.css'
import Required from '../../pages/commoncomponents/required'
import { TimePicker } from '@mui/x-date-pickers'
import moment from 'moment'

const CustomTimePicker = (props) => {
  const [open, setOpen] = React.useState(false)
  const {
    value,
    handleDateChange,
    className = 'default-time-picker',
    label = '',
    isRequired = true,
    titleClassName = 'time-title',
    touched = false,
    errors = '',
    views
  } = props

  // Check if value is a Date object
  if (!(value instanceof Date)) {
    // Handle case where value is not a Date object
    return null // or handle this case appropriately
  }

  // Convert value to local time
  // const localTime = value.toLocaleTimeString();

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Typography className={titleClassName}>
        {label}&nbsp;
        {isRequired && <Required />}
      </Typography>
      <DemoContainer components={['TimePicker']}>
        <TimePicker
          // open={false}
          onOpen={() => setOpen(true)}
          ampm={false}
          views={views || ['hours', 'minutes']}
          value={open ? null : moment(value)}
          // value={null}
          onChange={(newValue) => {
            // handleDateChange(new Date(newValue));
            handleDateChange(newValue?.toDate())
            setOpen(true)
          }}
          className={className}
        />
      </DemoContainer>
      {touched && errors && (
        <Typography className="validation-text-select">{errors}</Typography>
      )}
    </LocalizationProvider>
  )
}

export default CustomTimePicker
