import React from 'react'
import CustomizedModal from '../../leastcomponent/custommodal/commonmodal'
import { Box } from '@mui/material'
import CustomButton from '../../leastcomponent/custombutton/custombutton'
import './jobslipsradiowithselect.css'
import { useChangeStatusTask } from '../../hooks/task'
import { useJobSlipsSingle } from '../../hooks/jobslipshooks'
import { useParams } from 'react-router-dom'

const ConfirmJobSlip = (props) => {
  const { open, handleClose, openJobSlip, jobId, jobIdValues } = props
  const { id } = useParams()
  const selectDepartmentid = localStorage.getItem('selectDepartmentid')
  const { data: getjobSlipsSingle } = useJobSlipsSingle(id, !!id, selectDepartmentid)
  const basicJobSlipsData = getjobSlipsSingle?.data?.basicDetail
  const { mutate: taskChangeStatus } = useChangeStatusTask()
  const handleConfirmClick = () => {
    if (jobId) {
      taskChangeStatus({ ...jobIdValues, jobId })
      handleClose()
    }
  }
  return (
    <Box>
      <CustomizedModal
        isHeadingName={false}
        //   heading="Process Details"
        open={open}
        onClick={() => {
          handleClose()
          openJobSlip()
        }}
      >
        <Box className="job-slips-box">
          {' '}
          <Box className="job-slips-main-box">
            {`All successor tasks will be moved to Job Slip ${basicJobSlipsData?.jobSlipNumber} as well.`}
          </Box>
        </Box>

        <Box className="job-slips-main-button">
          <CustomButton className="button-box" type="button" title="Confirm" onClick={handleConfirmClick}/>
        </Box>
      </CustomizedModal>
    </Box>
  )
}

export default ConfirmJobSlip
