/* eslint-disable react/react-in-jsx-scope */
import Button from '@mui/material/Button'
import './../custombutton/custombutton.css'

const CustomButton = ({
  onClick,
  variant = 'contained',
  disabled = false,
  title = '',
  className = 'btn1',
  type = 'button',
  endIcon,
  startIcon
}) => {
  const btnProps = {
    onClick,
    variant,
    disabled,
    className,
    type,
    ...(endIcon && { endIcon }),
    ...(startIcon && { startIcon })
  }
  return (
    <Button
      {...btnProps}
      sx={{
        fontSize: '14px',
        fontWeight: '600',
        textTransform: 'capitalize'
      }}
      onClick={onClick}
    >
      {title}
    </Button>
  )
}

export default CustomButton
