import React, { useState } from 'react'
import './simulate.css'
import { Box, Divider, Grid, Typography } from '@mui/material'
import { Form, Formik } from 'formik'
import CustomButton from '../../leastcomponent/custombutton/custombutton'
import CustomSelect from '../../leastcomponent/customselect/customselect'
import UnavialableScenario from './unavialableScenario'
import UpdatedScenario from './updatedScenario'
import StartTimeDelayedScenario from './startTimeDelayedScenario'
import ProductionScenario from './productionScenario'
import ManualScenario from './manualScenario'
import BreadcrumbNavigation from '../commoncomponents/breadcrumbnavigation'

const CreateScenario = () => {
  const [open, setOpen] = useState(false)
  const handleDetailsClose = () => {
    setOpen(false)
  }
  const breadCrumbData = [
    {
      title: 'Simulate',
      link: '/my-tickets'
    },
    {
      title: 'Create Scenario',
      link: ''
    }
  ]

  const scenarioOptions = [
    { value: '1', label: 'Start Time Delayed' },
    { value: '2', label: 'Production Efficiency Reduced' },
    { value: '3', label: 'Unavailable  Machines ' },
    { value: '4', label: 'Job Cards: Updated Quantities' },
    { value: '5', label: 'Manual Changes' }
  ]

  return (
    <Box className="production-team-head-overflow-hidden">
      <BreadcrumbNavigation
        breadCrumbData={breadCrumbData}
        pageTitle={'Simulate'}
        backToLink={'/my-tickets'}
      />
      <Box className="form-wrapper">
        {/* TODO:: may be breadcrump required in future */}
        {/* <Box className="padding-y-40">
          <BreadCrumb
            breadCrumbData={breadCrumbData}
            className="padding-y-20"
            title="Simulate"
          />
        </Box> */}
        <Typography className="form-heading">Create Scenario</Typography>
        <Formik
          enableReinitialize={true}
          initialValues={{
            scenario: ''
          }}
          onSubmit={(values) => console.log('values======', values)}
        >
          {({ errors, touched, values, setFieldValue }) => (
            <React.Fragment>
              <Form>
                <Box className="form-child-create-scenario">
                  <Box className="padding-y-60">
                    <Grid spacing={2} container paddingTop={2}>
                      <Grid item xs={12} md={6} xl={4}>
                        <CustomSelect
                          name="scenario"
                          label="Scenario"
                          placeholder="Select Tool Category"
                          className="default-select-new"
                          options={scenarioOptions}
                          value={values?.scenario}
                          handleSelectChange={(e) => {
                            setFieldValue('scenario', e.target.value)
                          }}
                          errors={errors?.scenario}
                          touched={touched?.scenario}
                        />
                      </Grid>
                    </Grid>
                    <Divider />
                  </Box>
                  <Box padding={'0px 60px 10px'}>
                    <Box>
                      {values?.scenario === '1' && <StartTimeDelayedScenario />}
                      {values?.scenario === '2' && <ProductionScenario />}
                      {values?.scenario === '3' && <UnavialableScenario />}
                      {values?.scenario === '4' && <UpdatedScenario />}
                      {values?.scenario === '5' && setOpen(true)}
                    </Box>
                  </Box>
                </Box>
                <Box className="button-child">
                  <CustomButton title="Save" type="submit" />
                </Box>
              </Form>
            </React.Fragment>
          )}
        </Formik>
        {open && (
          <ManualScenario
            open={open}
            DetailsHandleClose={handleDetailsClose}
            setOpen={() => setOpen(false)}
          />
        )}
      </Box>
    </Box>
  )
}

export default CreateScenario
