import { Box, CircularProgress, Grid, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import styled from '@emotion/styled'
import TableComponent from '../../leastcomponent/customtable/customtable'
import { useParams } from 'react-router-dom'
import BreadcrumbNavigation from '../commoncomponents/breadcrumbnavigation'
import ErrorPopup from '../../modals/errorpopup'
import { useGetOperatorSingle } from '../../hooks/resourceHooks'
const TextFiled = styled(Typography)(() => ({
  color: 'gray',
  paddingTop: '10px',
  fontSize: '14px !important',
  padding: '24px 0px 0px 24px'
}))
const OperatorDetails = () => {
  const [isErrorModalOpen, setIsErrorModalOpen] = React.useState(false)
  const handleErrorModalClose = () => setIsErrorModalOpen(false)
  const [errorMessage, setErrorMessage] = React.useState('')
  const { id } = useParams()
  const { data: getOperatorSingle, error, isFetching: isGetsingleOperator } = useGetOperatorSingle(id)
  const singleOperator = getOperatorSingle?.data

  // const rows = singleResource?.maintenanceDetails?.map((data, ind) => {
  //   return {
  //     no: ind + 1,
  //     id: data?._id,
  //     name: data?.name ? data?.name : '-',
  //     maintenanceCode: data?.maintenanceCode ? data?.maintenanceCode : '-',
  //     pmFrequency: data?.pmFrequency ? data?.pmFrequency : '-',
  //     lastDate: data?.lastDate
  //       ? moment(data?.lastDate)?.format('DD/MM/YYYY')
  //       : '-',
  //     nextDate: data?.nextDate
  //       ? moment(data?.nextDate)?.format('DD/MM/YYYY')
  //       : '-'
  //   }
  // })
  const rows = [
    {
      id: 1,
      no: 1,
      taskNo: 'AC Maintenance',
      jobCardNo: 'ACM001',
      dateCompleted: '11/11/11',
      allocatedTime: '12h',
      elapsedTime: '12h'
    },
    {
      id: 2,
      no: 2,
      taskNo: 'Generator Maintenance',
      jobCardNo: 'GEN002',
      dateCompleted: '11/11/11',
      allocatedTime: '12h',
      elapsedTime: '12h'
    },
    {
      id: 3,
      no: 3,
      taskNo: 'Lift Maintenance',
      jobCardNo: 'LFT003',
      dateCompleted: '11/11/11',
      allocatedTime: '12h',
      elapsedTime: '12h'
    }
  ]

  const columns = [
    { field: 'no', headerName: '#', flex: 0.2 },
    { field: 'taskNo', headerName: 'Task no.', flex: 1 },
    { field: 'jobCardNo', headerName: 'Job Card No.', flex: 1 },
    { field: 'dateCompleted', headerName: 'Date Completed', flex: 1 },
    { field: 'allocatedTime', headerName: 'Allocated Time (hh:mm)', flex: 1 },
    { field: 'elapsedTime', headerName: 'Elapsed Time (hh:mm)', flex: 1 }
    // TODO:: action field temporary comment
    // {
    //   field: 'actions',
    //   headerName: 'Actions',
    //   sortable: false,
    //   disableClickEventBubbling: true,
    //   flex: 1,
    //   renderCell: () => {
    //     return (
    //       <Box className="resource-table-btn-main">
    //         <span className="process-delete" onClick={() => setOpen(true)}>
    //           <EditIconNew />
    //         </span>
    //         <span
    //           // onClick={() => {
    //           //   handleDeleteOpen()
    //           // }}
    //           className="process-delete"
    //         >
    //           <DeleteIconNew />
    //         </span>
    //       </Box>
    //     )
    //   }
    // }
  ]
  const breadCrumbData = [
    {
      title: 'Setters',
      link: '/resource'
    },
    {
      title: singleOperator?.name,
      link: ''
    }
  ]
  useEffect(() => {
    if (error) {
      setIsErrorModalOpen(true)
      setErrorMessage(error?.data?.message)
    }
  }, [error])
  return (
    <Box className="production-team-head-overflow-hidden">
      <BreadcrumbNavigation
        breadCrumbData={breadCrumbData}
        pageTitle={singleOperator?.name ? singleOperator?.name : '-'}
        backToLink={'/resource'}
        activeTab="3"

      />
     {!isGetsingleOperator
       ? <Box className="common-parent-class-overflow-machine">
        <Grid item xs={12} md={12} container display={'flex'} mt={'20px'}>
          <Grid item xs={12} md={12}>
            <Box className="details-box-border padding-bottom" mr={'10px'}>
              <Typography id="modal-modal-title" className="details-sub-title">
                Basic Details
              </Typography>
              <Grid container display={'flex'}>

                <Grid item xs={2} md={2}>
                  <TextFiled>Name </TextFiled>
                  <Typography className="span-color pl-sub-text">
                    {singleOperator?.name ? singleOperator?.name : '-'}
                  </Typography>
                </Grid>
                <Grid item xs={2} md={2}>
                  <TextFiled>ID </TextFiled>
                  <Typography className="span-color pl-sub-text">
                    {singleOperator?.uniqId ? singleOperator?.uniqId : '-'}
                  </Typography>
                </Grid>
                <Grid item xs={2} md={2}>
                  <TextFiled>Department</TextFiled>
                  <Typography className="span-color pl-sub-text">
                  {singleOperator?.department?.name ? singleOperator?.department?.name : '-'}
                  </Typography>
                </Grid>
                <Grid item xs={2} md={2}>
                  <TextFiled>Tasks completed yesterday</TextFiled>
                  <Typography className="span-color pl-sub-text">
                  {singleOperator?.completedTaskYesterday ? singleOperator?.completedTaskYesterday : '-'}
                  </Typography>
                </Grid>
                <Grid item xs={2} md={2}>
                  <TextFiled>Tasks assigned yesterday</TextFiled>
                  <Typography className="span-color pl-sub-text">
                  {singleOperator?.assignedTaskYesterday ? singleOperator?.assignedTaskYesterday : '-'}
                  </Typography>
                </Grid>

              </Grid>
            </Box>
          </Grid>
        </Grid>

        <Box className="">
          <Grid container spacing={2} className="form-wrapper" wrap="nowrap">
            <Grid item lg={12} md={12} >
            {rows?.length > 0 &&
            <>
              <Box className="font-size-18" marginBottom={'20px'}>Tasks completed</Box>
              <Box className="department-table-width">
              <TableComponent rows={rows} columns={columns} />
              </Box>
              </>}
            </Grid>
          </Grid>
        </Box>
      </Box>
       : <Box className='progress-parent-machine-main'>
                <CircularProgress />
              </Box>}
   {/* TODO::  temporary comment */}
      {/* <DeleteWarningModal
        open={deleteRecord}
        handleOpen={handleDeleteOpen}
        handleClose={handleDeleteClose}
      /> */}
         {isErrorModalOpen && (
        <ErrorPopup
          isOpen={isErrorModalOpen}
          handleClose={handleErrorModalClose}
          errorDetails={errorMessage}
        />
         )}
    </Box>
  )
}

export default OperatorDetails
