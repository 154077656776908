import React from 'react'

const Copy = ({ color = '#0160B9' }) => {
  return (
    <div>
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16.5625 0.75H4.1875C3.8078 0.75 3.5 1.0578 3.5 1.4375C3.5 1.8172 3.8078 2.125 4.1875 2.125H15.875V13.8125C15.875 14.1922 16.1828 14.5 16.5625 14.5C16.9422 14.5 17.25 14.1922 17.25 13.8125V1.4375C17.25 1.0578 16.9422 0.75 16.5625 0.75Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.75 16.5625V4.1875C0.75 3.8078 1.0578 3.5 1.4375 3.5H13.8125C14.1922 3.5 14.5 3.8078 14.5 4.1875V16.5625C14.5 16.9422 14.1922 17.25 13.8125 17.25H1.4375C1.0578 17.25 0.75 16.9422 0.75 16.5625ZM13.125 15.875H2.125V4.875H13.125V15.875Z"
          fill={color}
        />
      </svg>
    </div>
  )
}

export default Copy
