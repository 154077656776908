import { Box, CircularProgress, Typography } from '@mui/material'
import React, { useState } from 'react'
import AddIcon from '@mui/icons-material/Add'
import TableComponent from '../../leastcomponent/customtable/customtable'
import { useNavigate } from 'react-router-dom'
import DeleteWarningModal from '../../modals/deletewarningmodal'
import CustomButton from '../../leastcomponent/custombutton/custombutton'
import BreadcrumbNavigation from '../commoncomponents/breadcrumbnavigation'
import { useDeleteTaskType, useGetTask } from '../../hooks/tasktype'
import DeleteIconNew from '../../assets/svg/DeleteIconNew'
import EditIconNew from '../../assets/svg/EditIconNew'
import ErrorPopup from '../../modals/errorpopup'

const TaskTypeFactoryLayout = () => {
  const [isErrorModalOpen, setIsErrorModalOpen] = React.useState(false)
  const [errorMessage, setErrorMessage] = React.useState('')
  const handleErrorModalOpen = () => setIsErrorModalOpen(true)
  const handleErrorModalClose = () => setIsErrorModalOpen(false)
  const [deleteRecord, setDeleteRecord] = useState(false)
  const handleDeleteOpen = () => setDeleteRecord(true)
  const handleDeleteClose = () => setDeleteRecord(false)
  const navigate = useNavigate()
  const [currentId, setCurrentId] = useState('')
  const {
    data: taskRes,
    isFetching: isTaskTypeRes
  } = useGetTask()
  const rows = taskRes?.data?.map((data, ind) => {
    return {
      no: ind + 1,
      id: data?.id,
      name: data?.name,
      resourceCount: data?.resourceCount,
      machine: data?.resources?.map((data) => {
        return data?.type
      })
    }
  })
  const { mutate: deleteTaskType } = useDeleteTaskType()
  const columns = [
    { field: 'no', headerName: '#', flex: 0.2 },
    { field: 'name', headerName: 'Task Type', flex: 1 },
    {
      field: 'machine',
      headerName: 'Machine/Workstation',
      flex: 1,
      renderCell: (params) => {
        return (
          <Box
            width={'100%'}
            style={{
              display: 'flex'
            }}
          >
            <div>
              {params?.row?.machine?.map((item, index) => {
                return <div key={index}> {item}</div>
              })}
            </div>
          </Box>
        )
      }
    },
    {
      field: 'actions',
      headerName: 'Actions',
      sortable: false,
      disableClickEventBubbling: true,
      flex: 1,
      renderCell: (params) => {
        return (
          <Box className="resource-table-btn-main">
            <span
              className="process-delete"
              onClick={() => navigate(`/edit-task-type/${params.row.id}`)}
            >
              <EditIconNew />
            </span>
            <span
              className="process-delete"
              onClick={() => {
                if (params.row.resourceCount === 0) {
                  setCurrentId(params.row.id)
                  handleDeleteOpen()
                } else {
                  setErrorMessage(
                   ` You can not able to delete this task type, this task type is assigned to ${params.row.resourceCount} resources`
                  )
                  handleErrorModalOpen()
                }
              }}
            >
              <DeleteIconNew />
            </span>
          </Box>
        )
      }
    }
  ]

  return (
    <React.Fragment>
      <Box className="production-team-head-overflow-hidden ">
        <BreadcrumbNavigation
          breadCrumbData={null}
          pageTitle={'Task type'}
          backToLink={null}
        />

        <Box className="common-parent-class-overflow-auto-padding-10-50">
          <Box className="common-parent-haeding-btn-div">
            <Typography className="common-tabel-heading">Task type</Typography>
            <CustomButton
              variant="contained"
              title="Create Task type"
              onClick={() => navigate('/create-task-type')}
              type="button"
              endIcon=""
              startIcon={<AddIcon />}
            />
          </Box>

          <Box className="department-table-width">
            {
              !isTaskTypeRes
                ? <TableComponent
              rows={rows}
              columns={columns}
              detailsRedirection={'/edit-task-type'}

              />
                : <Box className='progress-parent'>
                <CircularProgress />
              </Box>}
          </Box>
        </Box>

        {deleteRecord && (
          <DeleteWarningModal
            open={deleteRecord}
            handleOpen={handleDeleteOpen}
            handleClose={handleDeleteClose}
            handleDelete={deleteTaskType}
            id={currentId}
          />
        )}
          {isErrorModalOpen && (
        <ErrorPopup
          isOpen={isErrorModalOpen}
          handleClose={handleErrorModalClose}
          errorDetails={errorMessage}
        />
          )}
      </Box>
    </React.Fragment>
  )
}

export default TaskTypeFactoryLayout
