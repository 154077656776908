import { Box, Grid, Modal, Typography } from '@mui/material'
import styled from '@emotion/styled'
import React from 'react'
import { Form, Formik } from 'formik'
import CustomInput from '../../leastcomponent/custominput/input'
import CustomButton from '../../leastcomponent/custombutton/custombutton'
// import { Form } from 'react-router-dom';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  height: 305,
  overflow: 'auto',
  bgcolor: 'background.paper',
  borderRadius: '8px',
  boxShadow: 24,
  overFlow: 'auto'
}
const HeadBox = styled(Box)(() => ({
  '.heding': {
    fontSize: '18px!important',
    fontWeight: '600!important',
    alignContent: 'center',
    textAlign: 'center',
    padding: '30px 10px 10px 10px'

  }
}))
const JobCardConfirmModal = (props) => {
  const {
    open,
    DetailsHandleClose
  } = props
  return (
        <Modal
            open={open}
            onClose={DetailsHandleClose}
            aria-labelledby=''
            aria-describedby='modal-modal-description'
        >
            <HeadBox sx={style}>
                <Box className='heding'>
                    <Typography className='heding'>Difference between planned and actual times is too large. Why? </Typography>
                </Box>
                <Formik
                    initialValues={{
                      reson: ''
                    }}
                    enableReinitialize={true}
                    // validationSchema={validationDialogSchema}
                    onSubmit={(values) => {
                      console.log(values)
                    }}
                >
                    {({ handleChange, values }) => (
                        <Form>
                            <Grid spacing={2} container item xs={12} alignItems={'center'}>
                                <Grid item xs={12} md={8} xl={11} alignItems={'center'} ml={'20px'}>
                                    <Box paddingRight={'10px'} >
                                        <CustomInput
                                            label="Remarks"
                                            placeholder="Enter Remarks"
                                            handleChange={handleChange}
                                            value={values?.remarks}
                                            name="remarks"
                                            isRequired={false}
                                        />
                                    </Box>
                                </Grid>
                                {/* {errors.title && touched.title && (
                      <Typography className='validation-text'>{errors.title}</Typography>
                    )} */}
                            </Grid>
                            <Box className="" paddingTop={'20px'} display={'flex'} alignContent={'center'} justifyContent={'center'}>
                                <CustomButton title="Confirm" type="submit" />
                            </Box>
                        </Form>
                    )}
                </Formik>
            </HeadBox>
        </Modal >

  )
}

export default JobCardConfirmModal
