import * as React from 'react'
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import { Form, Formik } from 'formik'
import { Typography } from '@mui/material'
import * as Yup from 'yup'
import { AiOutlineInfoCircle } from 'react-icons/ai'
import { useLogin } from '../../hooks/authhooks'
import HighMarkError from '../../assets/svg/HighMarkError'
import CloseIcon from '@mui/icons-material/Close'
import { useNavigate } from 'react-router-dom'
import ReCAPTCHA from 'react-google-recaptcha'
import CustomInput from '../../leastcomponent/custominput/input'
import CustomButton from '../../leastcomponent/custombutton/custombutton'
// import ProdPlanLogo from '../../assets/svg/ProdPlanLogo'
import ProdPlanLogoWhite from '../../assets/svg/ProdPlanLogoWhite'

// TODO:: login page logo png format
// import SidebarLogo from '../../assets/svg/SidebarLogo.png'
// import ProdPlanLogo1 from '../../assets/svg/ProdPlanLogo.png'

const LoginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Enter correct email')
    .required('Email is Required'),
  password: Yup.string().required('Password is Required'),
  captchaToken: Yup.string().required('Captcha is Required')
})

const HeadBox = styled(Box)(() => ({
  '.left-contained': {
    background: '#0160B9',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center'
  },
  '.right-contained': {
    height: '100vh',
    display: 'flex',
    justifyContent: 'center !important',
    alignItems: 'center',
    padding: '50px'
  },
  '.vector-workflow-box': {
    marginTop: '114.92px',
    paddingRight: '12%'
  },
  '.workFlow-title-box': {
    position: 'absolute',
    bottom: '86px'
  },
  '.workFlow-title': {
    color: '#ffffff',
    fontSize: '56px',
    fontWeight: '700',
    lineHeight: '72px'
  },
  '.head-title': {
    fontSize: '24px',
    fontWeight: '600'
  },
  '.forgot-password': {
    color: '#0160B9',
    fontWeight: '600',
    fontSize: '14px',
    marginLeft: '32px',
    marginTop: '10px',
    cursor: 'pointer'
  },
  '.head-sub-title': {
    fontSize: '16px',
    fontWeight: '400',
    color: '#8A8A8A'
  },
  '.validation-text': {
    color: 'red',
    fontSize: '12px'
  },
  '.field-title': {
    fontSize: 14,
    fontWeight: 400,
    padding: '0px 0px 6px 0px',
    color: '#020202'
  },
  '.input-field': {
    background: '#FFFFFF',
    width: '400px',
    borderRadius: '10px'
  },
  '.login-btn-box': {
    display: 'flex',
    marginTop: '24px'
  },
  '.left-box': {
    width: '10px',
    height: '52px',
    background: '#CC0000'
  },
  '.right-box': {
    width: '493px',
    height: '52px',
    background: '#F5CCCC'
  },
  '.close-icon': {
    cursor: 'pointer'
  }
}))

const UserLogin = () => {
  const navigate = useNavigate()
  const [errorBox, setErrorBox] = React.useState(false)
  const handleClose = () => setErrorBox(false)
  const handleOpen = () => setErrorBox(true)
  const { mutate: addLogin, isError, error } = useLogin(handleOpen)
  const icon = <AiOutlineInfoCircle color="red" />
  return (
    <React.Fragment>
      <HeadBox>
        <Formik
          initialValues={{
            email: '',
            password: '',
            captchaToken: ''
          }}
          validationSchema={LoginSchema}
          onSubmit={(values) => {
            const finalValues = {
              email: values.email,
              password: values.password,
              ip: '49.36.88.152'
            }
            addLogin(finalValues)
          }}
        >
          {({
            errors,
            touched,
            handleSubmit,
            handleChange,
            isValid,
            dirty,
            values,
            setFieldValue
          }) => (
            <Grid container>
              <Grid item xs={6} className="left-contained">
                <Box className="vector-workflow-box">
                  {/* TODO:: login page logo png format */}
                    {/* <img src={SidebarLogo} height={'100%'} width={'100%'} /> */}
                    {/* <img src={ProdPlanLogo1} height={'200px'} width={'300px'} /> */}
                    <ProdPlanLogoWhite />
                  <Box className="workFlow-title-box">
                    <Typography className="workFlow-title">Powerful</Typography>
                    <Typography className="workFlow-title">
                      Automation
                    </Typography>
                    <Typography className="workFlow-title">
                      Made Simple.
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={6} className="right-contained">
                <Form onSubmit={handleSubmit}>
                  <Box className="login-box">
                    <Box>
                      <Typography className="head-title">Welcome</Typography>
                      <Typography className="head-sub-title">
                        Login to account using your User Id
                      </Typography>
                      <Typography className="head-sub-title">
                        and Password provided by your organization.
                      </Typography>
                    </Box>
                    <Grid container>
                      <Grid item xs={12}>
                        <Box width={400} mt={'33px'}>
                          <CustomInput
                            label="User ID"
                            placeholder="Enter your User Id"
                            handleChange={handleChange}
                            name="email"
                            value={values?.email}
                            InputProps={{
                              endAdornment:
                                touched.email && errors.email && icon
                            }}
                            errors={errors.email}
                            touched={touched.email}
                          />
                        </Box>
                        <Box width={400} mt="25px">
                          <CustomInput
                            label="Password"
                            type="password"
                            placeholder="Enter Password"
                            value={values?.password}
                            handleChange={handleChange}
                            name="password"
                            InputProps={{
                              endAdornment:
                                touched.password && errors.password && icon
                            }}
                            errors={errors.password}
                            touched={touched.password}
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={12} mt={3}>
                        <Box>
                          <ReCAPTCHA
                            name="captchaToken"
                            value={values.captchaToken}
                            // sitekey={`6LePXbgmAAAAAGlLGyNGM_3AHakRLF3svHzsMG9P`}
                            sitekey={'6LfHwownAAAAALxlXimEY9us8iwuXstOhN7IVRXi'}
                            onChange={(e) => {
                              setFieldValue('captchaToken', e)
                            }}
                          />
                          {touched.captchaToken && errors.captchaToken && (
                            <Typography className="validation-text">
                              {errors.captchaToken}
                            </Typography>
                          )}
                        </Box>
                      </Grid>
                    </Grid>
                    <Box className="login-btn-box">
                      {(errorBox)
                        ? (
                          <React.Fragment>
                            <Box display={'flex'}>
                              <Box className="left-box"></Box>
                              <Box className="right-box" p={2}>
                                <Box
                                  display={'flex'}
                                  justifyContent={'space-between'}
                                >
                                  <Box>
                                    <Box display={'flex'}>
                                      <HighMarkError />
                                      <Typography className="field-title" ml={2}>
                                        {isError ? error?.data?.message : ''}
                                      </Typography>
                                    </Box>
                                  </Box>
                                  <Box
                                    onClick={() => handleClose()}
                                    className="close-icon"
                                  >
                                    <CloseIcon sx={{ marginBottom: '10px' }} />
                                  </Box>
                                </Box>
                              </Box>
                            </Box>
                          </React.Fragment>
                          )
                        : (
                            <React.Fragment>
                              <CustomButton
                                type="submit"
                                className={
                                  !(isValid && dirty) ? 'disable-btn' : 'blue-bg'
                                }
                                onClick={() => handleClose()}
                                variant="contained"
                                title="Login"
                              >
                                Login
                              </CustomButton>
                              <Typography
                                className="forgot-password"
                                onClick={() => navigate('/forgot-password')}
                              >
                                Forgot password?
                              </Typography>
                            </React.Fragment>
                          )}
                    </Box>
                  </Box>
                </Form>
              </Grid>
            </Grid>
          )}
        </Formik>
      </HeadBox>
    </React.Fragment>
  )
}
export default UserLogin
