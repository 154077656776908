import React from 'react'
import { Handle } from 'reactflow'
import './styles.css'
import { Box } from '@mui/material'

const CustomNode = ({ data }) => {
  return (
    <div className="custom-node" onClick={data.onClick}>
      <Box className="">
        {data.round && (
          <Box className='title-round-borders'>{data.round}</Box>
        )}
         {/* TODO:: status wise border */}
        <div className="test">
              {data?.type === 'Job Card' && data.jobCardStatus === 'completed' && (
                <Box className='border-left1'>{data.border}</Box>
              )}
              {data?.type === 'Job Card' && data.jobCardStatus === 'submitted' && (
                <Box className='border-left2'>{data.border}</Box>
              )}
              {data?.type === 'Job Card' && data.jobCardStatus === 'approved' && (
                <Box className='border-left2'>{data.border}</Box>
              )}
              {data?.type === 'Job Card' && data.jobCardStatus === 'ongoing' && (
                <Box className='border-left'>{data.border}</Box>
              )}
          <Box>
            <Box className="herdername">
              <Box>{data.name}</Box>
             {/* TODO:: status wise round */}
              {data.jobCardStatus === 'completed' && (
                <Box className='title-round-border-completed'>{data.round}</Box>
              )}
              {data.jobCardStatus === 'submitted' && (
                <Box className='title-round-border-not-started'>{data.round}</Box>
              )}
              {data.jobCardStatus === 'approved' && (
                <Box className='title-round-border-not-started'>{data.round}</Box>
              )}
              {data.jobCardStatus === 'ongoing' && (
                <Box className='title-round-border-ongoing'>{data.round}</Box>
              )}
               {data.jobCardStatus === 'pending' && (
                <Box className='title-round-border-ongoing'>{data.round}</Box>
               )}
               {data.jobCardStatus === 'scheduled' && (
                <Box className='title-round-border-ongoing'>{data.round}</Box>
               )}
               {data.processStatus === 'pending' && (
                <Box className='title-round-border-not-started'>{data.round}</Box>
               )}
               {data.processStatus === 'completed' && (
                <Box className='title-round-border-completed'>{data.round}</Box>
               )}
            </Box>
            <Box className="border"></Box>
            <Box display={'flex'} sx={{ paddingTop: '3px' }}>
              <Box className="titlename">Number:</Box>
              <Box className="title-ans">{data.number}</Box>
            </Box>
            <Box display={'flex'}>
              <Box className="titlename">Type:</Box>
              <Box className="title-ans">{data.type}</Box>
            </Box>
           {data?.type === 'Job Card' && <Box display={'flex'}>
              <Box className="titlename">Date:</Box>
              <Box className="title-ans">{data.date}</Box>
            </Box>}
          </Box>
        </div>
      </Box>
      <Handle type="source" position="right" style={{ background: '#555' }} />
      <Handle type="target" position="left" style={{ background: '#555' }} />
    </div>
  )
}

export default CustomNode
