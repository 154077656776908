import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom'
import { userRole } from '../querykeys/userRole'
import { workorder } from '../querykeys/workorders'
import { userRoleAPI } from '../api/userRole'

export const useAddRole = () => {
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  return useMutation(userRoleAPI.addRole, {
    onSuccess: () => {
      queryClient.invalidateQueries([workorder.WORKORDER])
      // toast.success(data.message);
      // navigate(`/role-details/${res?.userRole?.id}`);
      navigate('/user-management', { state: { activeTab: '2' } })
    },
    onError: (err) => {
      return err
      // toast.error(errData.message);
    }
  })
}

export const useGetRoles = (isEnabled = true, page) => {
  return useQuery([userRole.GET_ROLES, page], userRoleAPI.getRoles, {
    select: (data) => data.data?.userRoles,
    enabled: isEnabled,
    // onSuccess: (res: any) => {
    //   const data = res?.data;
    // },
    // onError: (err: any) => {
    //   const errData = err?.data;
    //   // toast.error(errData.message);
    // },
    refetchOnWindowFocus: false
  })
}

export const useGetRole = (id, isEnabled = true) => {
  return useQuery([userRole.GET_ROLE, id], userRoleAPI.getSingleRole, {
    select: (data) => data?.data,
    enabled: isEnabled,
    // onSuccess: (res: any) => {
    //   const data = res?.data;
    // },
    // onError: (err: any) => {
    //   const errData = err?.data;
    //   // toast.error(errData.message);
    // },
    refetchOnWindowFocus: false
  })
}
export const useGetFullRole = (isEnabled = true) => {
  return useQuery([userRole.GET_ROLE + 'FULL'], userRoleAPI.getFullRoles, {
    select: (data) => data?.data?.userRoles,
    enabled: isEnabled,
    // onSuccess: (res: any) => {
    //   const data = res?.data;
    // },
    // onError: (err: any) => {
    //   const errData = err?.data;
    //   // toast.error(errData.message);
    // },
    refetchOnWindowFocus: false
  })
}

export const useUpdateRole = () => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  return useMutation(userRoleAPI.updateRole, {
    onSuccess: (res, id) => {
      // const data = res.data;
      // toast.success(data.message);
      queryClient.invalidateQueries([userRole.GET_ROLES])
      queryClient.invalidateQueries([userRole.GET_ROLE, id])
      // navigate(`/role-details/${data.userRole.id}`);
      navigate('/user-management', { state: { activeTab: '2' } })
    },
    onError: (err) => {
      return err
      // const errData = err?.data;
      // toast.error(errData.message);
    }
  })
}

export const useDeleteRole = () => {
  const queryClient = useQueryClient()
  const navigate = useNavigate()

  return useMutation(userRoleAPI.deleteRole, {
    onSuccess: (res) => {
      const data = res.data
      queryClient.invalidateQueries([userRole.GET_ROLES])
      queryClient.removeQueries([userRole.GET_ROLE, data?.userRole?.id])
      // toast.success(data.message);
      navigate('/user-management', { state: { activeTab: '2' } })
    }
    // onError: (err: any) => {
    //   const errData = err?.data;
    //   // toast.error(errData.message);
    // },
  })
}

export const useGetDeletedRole = (id, isEnable = true) => {
  return useQuery([userRole.GET_ROLE + 'DELETED', id], userRoleAPI.getSingleDeletedRole, {
    enabled: isEnable,
    select: (data) => data?.data,
    // onSuccess: (res: any) => {
    //   const data = res?.data;
    // },
    // onError: (err: any) => {
    //   const errData = err?.data;
    //   //toast.error(errData.message);
    // },
    refetchOnWindowFocus: false
  })
}
export const useRestoreRole = () => {
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  return useMutation(userRoleAPI.restoreRole, {
    onSuccess: () => {
      queryClient.invalidateQueries([userRole.GET_ROLES])
      // queryClient.invalidateQueries([part.PART, id]);
      // toast.success(data.message);
      navigate('/deleted-items')
    }
    // onError: (err: any) => {
    //   const errData = err?.data;
    //   //toast.error(errData.message);
    // },
  })
}

export const useHardDeleteRole = () => {
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  return useMutation(userRoleAPI.hardDeleteRole, {
    onSuccess: () => {
      queryClient.invalidateQueries([userRole.GET_ROLES])
      // queryClient.invalidateQueries([part.PART, id]);
      navigate('/deleted-items')
    }
    // onError: (err: any) => {
    //   const errData = err?.data;
    //   //toast.error(errData.message);
    // },
  })
}
