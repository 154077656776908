import React from 'react'
import SingleJobcard from './singleJobcard'
import { Box, Grid } from '@mui/material'

const SingleJobcardNew = () => {
  return (
        <Box>
            <Grid>
                <SingleJobcard shiftheding='Day shift - tomorrow' title='Task Nickname - 7246712' ans1='fast' ans2='second'></SingleJobcard>
            </Grid>
        </Box>
  )
}

export default SingleJobcardNew
