import React from 'react'

const BlackDownArrow = () => {
  return (
    <svg
      width="15"
      height="9"
      viewBox="0 0 15 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.3337 1.5L7.50033 7.33333L1.66699 1.5"
        stroke="#1C1C1C"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default BlackDownArrow
