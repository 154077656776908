const factorySchedule = {
  FACTORYSCHEDULE: 'FACTORYSCHEDULE',
  FACTORYSCHEDULES: 'FACTORYSCHEDULES',
  DESKTOP_USERS: 'DESKTOP_USERS',
  DESKTOP_USER: 'DESKTOP_USER',
  MOBILE_USERS: 'MOBILE_USERS',
  MOBILE_USER: 'MOBILE_USER',
  LOGGED_IN_USER: 'LOGGED_IN_USER',
  USER_ROLES: 'USER_ROLES'
}

export { factorySchedule }
