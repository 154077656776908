import { Box, Button, Grid } from '@mui/material'
import React, { useState } from 'react'
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'
import './jobcard.css'
import SingleJobcardComn from './singleJobcardComen'
import CustomButton from '../../leastcomponent/custombutton/custombutton'
import JobSlipUpdateModal from '../../modals/jobcardmodal/JobSlipUpdateModal'
import { useGetJobDetailsSingleTask } from '../../hooks/jobcardHooks'
import { useParams } from 'react-router-dom'
import _ from 'lodash'

const SingleJobcard = () => {
  const [showAdditionInfo, setShowAdditionInfo] = useState(false)
  const handleShow = () => {
    setShowAdditionInfo(!showAdditionInfo)
  }
  const handleDetailsClose = () => {
    setOpen(false)
  }
  const [open, setOpen] = useState(false)
  const { id } = useParams()
  const { data: getJobDetails } = useGetJobDetailsSingleTask(id, !!id)
  const singleJobDetails = getJobDetails?.data
  return (

        <><Grid className='common-parent-class'>
            <Box className='border-status' display={'flex'}>
                <Grid lg={12} md={12} container className='jobdetails-padding'>
                    <Grid lg={2} md={2}>
                        <Box className="font-size-13">Job Card Name</Box>
                      <Box className="font-size-14-with-semibold-primary">{_.get(singleJobDetails, 'name', '')}</Box>
                    </Grid>
                    <Grid lg={2} md={2}>
                        <Box className="font-size-13">Job Card Number</Box>
                      <Box className="font-size-14-with-semibold">{_.get(singleJobDetails, 'number', '')}</Box>
                    </Grid>
                    <Grid lg={2} md={2}>
                        <Box className="font-size-13">Product Name</Box>
                      <Box className="font-size-14-with-semibold">{_.get(singleJobDetails, 'productName', '')}</Box>
                    </Grid>
                    <Grid lg={2} md={2}>
                        <Box className="font-size-13">Quantity</Box>
                      <Box className="font-size-14-with-semibold">{_.get(singleJobDetails, 'quantity', '')}</Box>
                    </Grid>
                    <Grid lg={2} md={2}>
                        <Box className="font-size-13">Priority</Box>
                      <Box className="font-size-14-with-semibold">{_.get(singleJobDetails, 'priority', '')}</Box>
                    </Grid>
                    <Grid lg={2} md={2}>
                        <Box className="font-size-13">Customer name</Box>
                      <Box className="font-size-14-with-semibold">{_.get(singleJobDetails, 'customerName', '')}</Box>
                    </Grid>
                </Grid>
                <Grid lg={1.80} md={1.80} mr={'10px'} className="align-center-with-gap font-size-13">Status <span className="align-center"><FiberManualRecordIcon sx={{ fontSize: '12px' }} color="warning" /></span> <span className="font-size-14-with-semibold">Pending</span></Grid>
            </Box>
          {singleJobDetails?.bompart.map((data, ind) => (
              <Grid container xs={12} md={12} spacing={2} className='mainborder' mt={'10px'} ml={'1px'} mr={'0px'} key={ind}>

                <Grid container xs={12} md={12} className='quality-drowdown-title' display={'flex'}>
                    <Grid lg={11} md={11} container className='jobdetails-padding'>
                        <Grid lg={2} md={2}>
                            <Box className="font-size-13">Part Name</Box>
                              <Box className="font-size-14-with-semibold-primary"> {data.partName}</Box>
                        </Grid>
                        <Grid lg={2} md={2}>
                            <Box className="font-size-13">Part Code</Box>
                              <Box className="font-size-14-with-semibold">{data.itemCode}</Box>
                        </Grid>
                        <Grid lg={2} md={2}>
                            <Box className="font-size-13">Quantity</Box>
                              <Box className="font-size-14-with-semibold">{_.get(singleJobDetails, 'quantity', '')}</Box>
                        </Grid>
                        <Grid lg={2} md={2}>
                            <Box className="font-size-13">Drawing no</Box>
                              <Box className="font-size-14-with-semibold">{data.drawingNumber}</Box>
                        </Grid>
                    </Grid>
                    <Grid lg={1} md={1} display={'flex'} justifyContent={'end'}>
                        <Button
                            className=''
                            onClick={() => {
                              handleShow()
                              // setNodeData(values);
                            }}
                        >
                            {' '}
                            <span>{showAdditionInfo}</span>
                            {showAdditionInfo
                              ? (
                                    <IoIosArrowUp
                                        color='rgba(2, 2, 2, 1)'
                                        size={20}
                                        className='quality-drowdown-title-mr-1' />
                                )
                              : (
                                    <IoIosArrowDown
                                        color='rgba(2, 2, 2, 1)'
                                        size={20}
                                        className='quality-drowdown-title-mr-1' />
                                )}{' '}
                        </Button>
                    </Grid>
                </Grid>
                {showAdditionInfo && (
                    <React.Fragment>

                        <SingleJobcardComn
                            shiftheding='Day shift - tomorrow'
                            title='Task Nickname - 7246712'
                              ans1={data.bomprocess[0].task[0].description}
                            // ans2='Lorem ipsum ..2'
                              ans2={data.bomprocess[0].task[0].taskType.name}
                              ans3={data.bomprocess[0].task[0].description}
                            ans4='Lorem ipsum ..4'
                            ans5='Lorem ipsum ..5'>
                        </SingleJobcardComn>
                        <SingleJobcardComn
                            shiftheding='Night shift - tomorrow'
                            title='Task Nickname second - 7246712'
                            ans1='Lorem ipsum ..1'
                            ans2='Lorem ipsum ..2'
                            ans3='Lorem ipsum ..3'
                            ans4='Lorem ipsum ..4'
                            ans5='Lorem ipsum ..5'>
                        </SingleJobcardComn>
                        <SingleJobcardComn
                            shiftheding='Day shift - Day after'
                            title='Task Nickname therd - 7246712'
                            ans1='Lorem ipsum ..1'
                            ans2='Lorem ipsum ..2'
                            ans3='Lorem ipsum ..3'
                            ans4='Lorem ipsum ..4'
                            ans5='Lorem ipsum ..5'>
                        </SingleJobcardComn>
                        <SingleJobcardComn
                            shiftheding='Night shift - Day after'
                            title='Task Nickname forth - 7246712'
                            ans1='Lorem ipsum ..1'
                            ans2='Lorem ipsum ..2'
                            ans3='Lorem ipsum ..3'
                            ans4='Lorem ipsum ..4'
                            ans5='Lorem ipsum ..5'>
                        </SingleJobcardComn>
                    </React.Fragment>
                )}
            </Grid>
          ))}
        </Grid>
            <Grid>
                <Box className="button-child">
                    <CustomButton title="Publish" type="submit" onClick={() => {
                      setOpen(true)
                    }} />
                </Box>
                {open && (
                    <JobSlipUpdateModal
                        open={open}
                        handleClose={() => setOpen(false)}
                        DetailsHandleClose={handleDetailsClose} />
                )}

            </Grid></>
  )
}

export default SingleJobcard
