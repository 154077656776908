import * as React from 'react'
import Box from '@mui/material/Box'
import './jobslipsradiowithselect.css'
import CustomButton from '../../leastcomponent/custombutton/custombutton'
import CustomizedModal from '../../leastcomponent/custommodal/commonmodal'
import {
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup
} from '@mui/material'
import CustomSelect from '../../leastcomponent/customselect/customselect'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import { useGetJobSlipsFullList } from '../../hooks/jobslipshooks'

const JobSlipsRadioWithSelectModal = (props) => {
  const { open, handleClose, slipOpen, overdueOpen, setJobIdValues } = props

  const [error, setError] = React.useState(false)

  const validationSchema = Yup.object().shape({
    // TODO:: future use validation
    // jobSlip: Yup.string().required("Job slip is required"),
    // selection: Yup.string().required("Please select an option"),
  })

  // useGetJobSlipsFullList
  const { data: getjobSlipsFullList } = useGetJobSlipsFullList()

  const options = getjobSlipsFullList?.map((item) => {
    const originalDate = new Date(item?.date)
    const day = ('0' + originalDate.getUTCDate()).slice(-2)
    const month = ('0' + (originalDate.getUTCMonth() + 1)).slice(-2)
    const year = originalDate.getUTCFullYear()
    const formattedDate = `${day}-${month}-${year}`
    return {
      value: item?.id,
      label: `${item?.name} - ${formattedDate}`
    }
  })

  return (
    <React.Fragment>
      <CustomizedModal
        isHeadingName={true}
        heading="Where do you want to move the selected tasks?"
        open={open}
        onClick={handleClose}
        width="30%"
      >
        <Formik
          initialValues={{
            jobSlip: '',
            selection: ''
          }}
          enableReinitialize={true}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            if (values.selection === 'Overdue') {
              // overdueOpen()
              // handleClose()
            } else {
              if (values?.jobSlip) {
                // slipOpen()
                // handleClose()
                // setJobIdValues(values)
              }
              // setError(true)
            }
          }}
        >
          {({
            errors,
            touched,
            values,
            handleChange,
            setFieldValue
            //   handleSubmit
          }) => (
            <Form>
              <Box>
                <Box className="job-slips-main-box">
                  <FormControl>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="selection"
                      onChange={handleChange}
                      value={values?.selection}
                      defaultChecked={true}
                    >
                      <Grid container spacing={2} gap={1}>
                        <Grid item xs={12} sx={{ marginTop: '25px' }}>
                          <Box sx={{ display: 'flex', gap: '20px' }}>
                            <FormControlLabel
                              value="Overdue"
                              control={<Radio />}
                              label="Overdue"
                            />
                          </Box>
                        </Grid>
                        <Grid item xs={12}>
                          <Box sx={{ display: 'flex', gap: '20px' }}>
                            <FormControlLabel
                              value="Job Slip"
                              control={<Radio />}
                              label="Job Slip"
                            />
                          </Box>
                        </Grid>

                        {errors?.selection && touched?.selection && (
                          <div className="validation-text-select">
                            {errors?.selection}
                          </div>
                        )}
                        {values.selection === 'Job Slip' && (
                          <Grid
                            item
                            xs={9}
                            md={9}
                            xl={9}
                            sx={{ marginTop: '10px' }}
                          >
                            <CustomSelect
                              name="jobSlip"
                              label="Job Slip"
                              placeholder="Select Job slip"
                              className="default-select-new"
                              options={options}
                              value={values?.jobSlip}
                              handleSelectChange={(e) =>
                                setFieldValue('jobSlip', e?.target?.value)
                              }
                              errors={errors?.jobSlip}
                              touched={touched?.jobSlip}
                            />

                            {values?.jobSlip
                              ? (
                              <React.Fragment></React.Fragment>
                                )
                              : (
                                  error && (
                                <div className="validation-text-select">
                                  Please select jobSlip{' '}
                                </div>
                                  )
                                )}
                          </Grid>
                        )}
                      </Grid>
                    </RadioGroup>
                  </FormControl>
                </Box>
                <Grid className="approved-yes-btn1">
                  <CustomButton
                    className="button-box"
                    type="submit"
                    title="Next"
                    onClick={() => {
                      if (values?.selection === 'Overdue') {
                        overdueOpen()
                        handleClose()
                      } else {
                        if (values?.jobSlip) {
                          setJobIdValues({ jobSlip: values?.jobSlip })
                          slipOpen()
                          handleClose()
                        }
                        setError(true)
                      }
                    }}
                  />
                </Grid>
              </Box>
            </Form>
          )}
        </Formik>
      </CustomizedModal>
    </React.Fragment>
  )
}

export default JobSlipsRadioWithSelectModal
