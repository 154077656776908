import * as React from 'react'
import Box from '@mui/material/Box'
import Collapse from '@mui/material/Collapse'
import IconButton from '@mui/material/IconButton'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { Pagination, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { useNavigate } from 'react-router-dom'
// import Kanban from '../../assets/svg/Kanban'
import { user } from '../../querykeys/usermanagement'
import { useQueryClient } from '@tanstack/react-query'
import CustomButton from '../custombutton/custombutton'

const PaginationWrapper = styled(Box)({
  display: 'flex',
  justifyContent: 'flex-end',
  gap: '10px',
  alignItems: 'center',
  width: '100%',
  padding: '50px 0px'
})

function Row (props) {
  const { row, columns } = props
  const [open, setOpen] = React.useState(false)
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const userData = queryClient.getQueryData([user.LOGGED_IN_USER])
  const AdminUser = userData?.data?.data?.user?.userPermissions?.find((data) => data?.userModule === 'all' && data)
  const JobCardDepartWise = userData?.data?.data?.user?.userPermissions?.find((data) => data?.userModule === 'Schedule Job Cards' && data)

  const handleClick = (rowId) => {
    navigate(`/job-card-details-view/${rowId}?department`, { state: { sidebarTab: location.pathname.includes('job-card-department') ? 3 : 2 } })
  }
  return (
    <React.Fragment>
      <TableRow
        sx={{
          borderTop: '1px solid #DEDEDE',
          '&:hover': {
            backgroundColor: '#F6F6F6',
            m: 1
          }
        }}
      >
        <TableCell>{row?.no ? row?.no : '-'}</TableCell>
        <TableCell component="th"
          scope="row"
          sx={{ color: '#0160B9', fontWeight: 600, cursor: 'pointer' }}
          onClick={() => handleClick(row?.id)}>
          {row.number ? row.number : '-'}
          <IconButton
            aria-label="expand row"
            size="small"
            // onClick={() => setOpen(!open)}
            onClick={(e) => {
              e.stopPropagation()
              setOpen(!open)
            }}
            // sx={{
            //   color: '#1976d2',
            //   '&:hover': {
            //     color: '#1976d2'
            //   }
            // }}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>{' '}
        </TableCell>
        <TableCell
          // component="th"
          // scope="row"
          // sx={{ color: '#0160B9', fontWeight: 600, cursor: 'pointer' }}
          // onClick={() => handleClick(row?.id)}
        >
          {row.name}
        </TableCell>
        <TableCell align="left">{row.productName ? row.productName : '-'}</TableCell>
        <TableCell align="left">{row.quantity ? row.quantity : '-'}</TableCell>
        <TableCell align="left">{row.customerName ? row.customerName : '-'}</TableCell>
        <TableCell align="left">{row?.submitted || row.date }</TableCell>
        <TableCell align="left">{row.priority ? row.priority : '-'}</TableCell>
        <TableCell align="left">{row.approveDate ? row.approveDate : '-'}</TableCell>
        <TableCell align="left">{row.pushToPendingDate ? row.pushToPendingDate : '-'}</TableCell>
        {/* <TableCell align="left">{row.description}</TableCell> */}
        {/* TODO:: kanban view */}
        {/* <TableCell align="left">
          <Box className="table-btn-main">
          <span
            className="process-delete"
            onClick={() =>
              navigate(`/schedule/${row.id}?department`, { state: { sidebarTab: location.pathname.includes('job-card-department') ? 3 : 2 } })
            }
          >
            <Kanban />
          </span>
        </Box>
        </TableCell> */}
      </TableRow>
      {open && (
        <TableRow sx={{ padding: '10px' }}>
          <TableCell
            style={{
              paddingBottom: '0 !important',
              paddingTop: '0 !important'
            }}
            colSpan={9}
            sx={{
              paddingLeft: '1000px',
              marginLeft: '50px',
              '& > .MuiTableCell-root': {
                paddingLeft: '200px'
              }
            }}
          >
            <Collapse in={open} timeout="auto" unmountOnExit>
            <Box className='parent-nested'>
              <Table aria-label="collapsible table">
                <TableHead sx={{
                  color: '#020202',
                  fontSize: '14px',
                  fontWeight: 600,
                  marginLeft: '10rem',
                  '& > .MuiTableCell-root': {
                    paddingLeft: '200px'
                  }
                }}>
                  <TableRow sx={{
                    height: '55px !important',
                    backgroundColor: '#FFFFFF',
                    paddingLeft: '1000px',
                    marginLeft: '10px',
                    '& > .MuiTableCell-root': {
                      paddingLeft: '20px'
                    }
                  }}>
                    {columns?.map((innerColumn, index) => {
                      return (
                        <TableCell key={index} >
                          {' '}
                          {innerColumn?.headerName}{' '}
                        </TableCell>
                      )
                    })}
                  </TableRow>
                </TableHead>
                {row?.history
                  ? (
                      row?.history?.map((historyRow, ind) => (
                      <TableRow key={ind} >
                        <TableCell>{ind + 1}</TableCell>
                        <TableCell>{historyRow.partName ? historyRow.partName : '-'}</TableCell>
                        <TableCell align="left">
                          {historyRow.itemCode ? historyRow.itemCode : '-'}
                        </TableCell>
                        <TableCell align="left">
                          {historyRow.drawingNumber ? historyRow.drawingNumber : '-'}
                        </TableCell>
                        <TableCell align="left">{historyRow?.qty ? historyRow?.qty : '-'}</TableCell>
                        <TableCell align="left">
                          {historyRow.departmentSequence ? historyRow.departmentSequence : '-'}
                        </TableCell>
                        <TableCell align="left">
                          {historyRow.prevDeptName ? historyRow.prevDeptName : '-'}
                        </TableCell>
                        <TableCell align="left">
                          {historyRow.nextDeptName ? historyRow.nextDeptName : '-'}
                        </TableCell>
                        {/* //!------ */}
                        <TableCell align="left">
                          {(JobCardDepartWise?.edit || AdminUser?.userModule === 'all')
                            ? <div className="nested-actions">
                           <div
                             style={{ cursor: 'pointer' }}
                             onClick={() => navigate(`/job-dep-wise/${historyRow.jobCardId}/${historyRow.id}`, { state: { partId: historyRow.id } })}
                           >
                             Schedule Tasks
                           </div>
                        </div>
                            : <CustomButton
                             title="Schedule Tasks"
                             className="disabled-only-name-btn"
                           />
                          }

                        </TableCell>
                      </TableRow>
                      ))
                    )
                  : (
                    <div>
                    </div>
                    )}
              </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </React.Fragment>
  )
}

const NestedTableSecond = ({
  rows,
  columns,
  mainCols,
  setPage,
  tableName,
  isPagination = true,
  paginationData,
  page,
  totalPages,
  totalResults,
  user
}) => {
  const rowsPerPage = 10
  const start = (page - 1) * rowsPerPage + 1
  const end = Math.min(page * rowsPerPage, totalResults)
  return (
    <React.Fragment>
      <Box
        className="details-label-text"
      >
        {tableName}
      </Box>
      <TableContainer
        component={Paper}
      >
        <Table aria-label="collapsible table">
          <TableHead sx={{ height: '55px', background: '#F6F6F6', borderRadius: '8px 8px 0px 0px' }}>
            <TableRow>
              {mainCols?.map((Column, index) => {
                return <TableCell key={index}> {Column?.field} </TableCell>
              })}
            </TableRow>
          </TableHead>

          <TableBody>
            {rows.map((row, index) => (
              <Row key={index} row={row} columns={columns} user={user} />
            ))}

          </TableBody>
        </Table>
      </TableContainer>
      {rows?.length <= 0 &&
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '20px', color: '#000000DE', fontSize: '14px' }}>
              No Rows
              </Box>
          }
      {isPagination && rows?.length > 0 &&
        (
          <PaginationWrapper>
            <Typography sx={{ color: '#8A8A8A', fontSize: '14px' }}>
              {/* Showing 1 to 10 of {totalResults} */}
              Showing {start} to {end} of {totalResults}
            </Typography>
            <Pagination
              page={page}
              color="primary"
              count={totalPages || paginationData?.totalPages}
              variant="outlined"
              onChange={(e, page) => {
                setPage(page)
              }}
            />
          </PaginationWrapper>
        )}
    </React.Fragment>
  )
}

export default NestedTableSecond
