import React from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Modal from '@mui/material/Modal'
import styled from '@emotion/styled'
import HighMarkError from '../assets/svg/HighMarkError'
import '../assets/css/modal.css'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  borderRadius: '8px',
  boxShadow: 24,
  padding: '30px 30px 40px 30px'
}

const HeadBoxWrapper = styled(Box)(() => ({
  '.modal-title': {
    fontWeight: 600,
    fontSize: '21px',
    lineHeight: '32px',
    color: '#1D1B1B',
    paddingBottom: '10px'
  },
  '.left-box': {
    width: '10px',
    background: '#CC0000'
  },
  '.right-box': {
    width: '100%',
    background: '#F5CCCC'
  },
  '.btn-center': {
    marginTop: '40px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
}))
// interface errorI {
//   isOpen: boolean;
//   handleClose: () => void;
//   errorDetails?: string;
// }
const ErrorPopup = (props) => {
  const { isOpen, handleClose, errorDetails } = props
  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      aria-labelledby=""
      aria-describedby="modal-modal-description"
    >
      <HeadBoxWrapper sx={style}>
        <Box display={'flex'}>
          <Box className="left-box"></Box>
          <Box className="right-box" p={2}>
            <Box display={'flex'} justifyContent={'space-between'}>
              <Box>
                <Box display={'flex'}>
                  <HighMarkError />
                  <Typography className="field-tit" ml={2}>
                    {errorDetails}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box className="btn-center">
          <Button variant="outlined" color="error" onClick={handleClose}>
            Close
          </Button>
        </Box>
      </HeadBoxWrapper>
    </Modal>
  )
}
export default ErrorPopup
