import { useMutation, useQueryClient, useQuery } from '@tanstack/react-query'
import { authApi, verifyToken } from '../api/auth'
import { useNavigate } from 'react-router-dom'
import { cookie } from '../utils/cookie'
import { user } from '../querykeys/usermanagement'
import { toast } from 'react-toastify'
// import { toast } from "react-toastify";

export const useLogin = (handleOpen) => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  return useMutation(authApi.login, {
    onSuccess: (res) => {
      const data = res.data?.data
      if (data?.tokens) {
        queryClient.setQueryData([user.LOGGED_IN_USER], data?.user)
        toast.success(data.message)
        // cookie.set("token", data?.tokens.access.token);
        cookie.set('token', data?.tokens.access.token)
        navigate('/dashboard')
      }
    },

    onError: (e) => {
      // queryClient.invalidateQueries(['user']);
      handleOpen()
      // navigate('/login');
      // toast.error(errData.message);
    }
  })
}

export const useLogout = () => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  return useMutation(authApi.logout, {
    onSuccess: () => {
      cookie.remove('token')
      localStorage.removeItem('selectDepartmentid')
      queryClient.removeQueries()
      navigate('/login')
    }
  })
}

export const useChangePassword = (handleOpen) => {
  return useMutation(authApi.changePassword, {
    onSuccess: (res) => {
      const data = res.data
      if (data.tokens) {
        // toast.success(data.message);
      }
    },
    onError: () => {
      // queryClient.invalidateQueries([user.LOGGED_IN_USER]);
      handleOpen()
      // navigate('/login');
      // toast.error(errData.message);
    }
  })
}
export const useResetPassword = (handleOpen) => {
  const navigate = useNavigate()

  return useMutation(authApi.resetPassword, {
    onSuccess: () => {
      navigate('/login')
    },
    onError: () => {
      handleOpen()
    }
  })
}

export const useForgotPassword = (handleOpen, handleSuccessOpen) => {
  // const navigate = useNavigate();
  // const queryClient = useQueryClient();

  return useMutation(authApi.forgotPassword, {
    onSuccess: () => {
      // navigate('/login');
      handleSuccessOpen()
    },
    onError: () => {
      handleOpen()
      // queryClient.invalidateQueries([user.LOGGED_IN_USER]);
      // toast.error(errData.message);
    }
  })
}

export const useVerifyToken = (isEnabled = true) => {
  const navigate = useNavigate()
  // const queryClient = useQueryClient()
  return useQuery([user.LOGGED_IN_USER], verifyToken, {
    onSuccess: (data) => {
      // queryClient.setQueryData([user.LOGGED_IN_USER], 'hi')
    },
    keepPreviousData: true,
    enabled: isEnabled,
    refetchOnWindowFocus: false,
    onError: () => {
      cookie.clean()
      navigate('/login')
    }
  })
}

export const useUpdateProfile = () => {
  const queryClient = useQueryClient()

  return useMutation(authApi.updateProfile, {
    onSuccess: () => {
      queryClient.invalidateQueries([user.LOGGED_IN_USER])
      // queryClient.invalidateQueries([user.LOGGED_IN_USER, id]);
      // toast.success(data?.message);
    },
    onError: () => {
      // toast.error(errData.message);
    }
  })
}
