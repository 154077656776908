import { get } from '../utils/index'

export const JobSlips = async (data) => {
  const page = data?.queryKey[2] || 1
  const payload = { page, limit: 10, status: data?.queryKey[1] }
  return get('/automation/jobslip-cron', payload).then((res) => res.data)
}
export const GetJobSlipsFullList = async (data) => {
  const page = data?.queryKey[1] || 1
  const payload = { page, limit: 10 }
  return get('/automation/jobslip-cron/full-list', payload).then(
    (res) => res.data
  )
}

export const GetJobSlipsSingle = async (data) => {
  const id = data?.queryKey[1]
  const deptId = data?.queryKey[2]
  return get(`/automation/jobslip-wise-tasklist/${id}?departmentId=${deptId}`)
}

export const jobslipsApi = {
  JobSlips,
  GetJobSlipsFullList,
  GetJobSlipsSingle
}
