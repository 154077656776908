import * as React from 'react'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'
import { Typography } from '@mui/material'
import './../customdatetimepicker/customdatetimepicker.css'
import Required from '../../pages/commoncomponents/required'

const CustomDateTimePicker = (props) => {
  const {
    value,
    handleDateChange,
    className = 'default-date-time-picker',
    label = '',
    isRequired = true,
    titleClassName = 'date-time-title',
    touched = false,
    errors = ''
  } = props
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      {label && (
        <Typography className={titleClassName}>
          {label}&nbsp;
          {isRequired && <Required />}
        </Typography>
      )}
      <DemoContainer components={['DatePicker']}>
        <DateTimePicker
          // label="Date Picker"
          value={value}
          onChange={(e) => {
            handleDateChange(e?.toDate())
          }}
          className={className}
        />
      </DemoContainer>
      {touched && errors && (
        <Typography className="validation-date-time">{errors}</Typography>
      )}
    </LocalizationProvider>
  )
}
export default CustomDateTimePicker
