import * as React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Modal from '@mui/material/Modal'
import styled from '@emotion/styled'
// import "./../../src/style/modal.css";
import '../assets/css/modal.css'
import CustomButton from '../leastcomponent/custombutton/custombutton'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 350,
  bgcolor: 'background.paper',
  // border: '2px solid #000',
  borderRadius: '8px',
  boxShadow: 24,
  p: 2
}

const BoxWrapper = styled(Box)(() => ({
  '.button-box': {
    width: '150px'
  }
}))
// interface propI {
//   open: boolean;
//   handleClose: () => void;
//   handleOpen?: () => void;
//   handleDelete: (id?: string | undefined) => void;
//   id?: string;
//   isDeleteOrRestoreAll?: boolean;
// }

const DeleteWarningModal = (props) => {
  const {
    open,
    handleClose,
    handleDelete,
    id,
    rowId,
    isDeleteOrRestoreAll = false,
    isLocal = false,
    taskData
  } = props
  return (
    <div>
      {/* <Button onClick={handleOpen}>Open modal</Button> */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby=""
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <BoxWrapper>
            <Box textAlign={'center'}>
              <Box m={2}>
                <Typography>
                  Are yor sure you want to delete this record ?
                </Typography>
              </Box>

              <Box m={2} display={'flex'} justifyContent={'space-between'}>
                <CustomButton
                  variant="outlined"
                  className="button-box"
                  onClick={() => handleClose()}
                  title="Cancel"
                />
                <CustomButton
                  variant="outlined"
                  className="button-box blue-bg ml-20"
                  onClick={() => {
                    if (isLocal) {
                      const updatedTasks = taskData?.filter((data) => (data?.name !== rowId) && data)
                      localStorage.setItem('taskData', JSON.stringify(updatedTasks))
                      handleClose()
                    } else {
                      if (isDeleteOrRestoreAll) {
                        handleDelete()
                        handleClose()
                      } else {
                        handleDelete(id)
                        handleClose()
                      }
                    }
                  }}
                  title="Delete"
                />
              </Box>
            </Box>
          </BoxWrapper>
        </Box>
      </Modal>
    </div>
  )
}
export default DeleteWarningModal
