import * as React from 'react'
import { Box } from '@mui/system'
import {
  Modal,
  Typography
} from '@mui/material'
import { styled } from '@mui/material/styles'
import CloseIcon from '@mui/icons-material/Close'
import GanntChart from '../../leastcomponent/ganntChart/GanntChart'
import gantt from 'highcharts/highcharts-gantt'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 1175,
  height: 512,
  overflow: 'auto',
  bgcolor: 'background.paper',
  borderRadius: '8px',
  boxShadow: 24,
  overFlow: 'auto'
}

const HeadBox = styled(Box)(() => ({
  '.btn-box': {
    display: 'flex',
    justifyContent: 'end',
    margin: '3px 20px 30px 0px'
  }
}))
const ManualScenario = (props) => {
  const {
    open,
    DetailsHandleClose
  } = props
  const machineOption = [

    {
      name: 'Project 1',

      dragDrop: {
        liveRedraw: true,
        draggableStart: true,
        draggableEnd: true,
        draggableX: true,
        draggableY: true,
        dragMinY: 0,
        dragMaxY: 2,
        gridLineWidth: false,
        gridZIndex: false

      },
      dataLabels: [
        {
          enabled: true,
          format: '{point.lable}',
          useHTML: true,
          align: 'left',
          // radius: '80px',
          borderRadius: 100,
          gridLineWidth: false,
          gridZIndex: false
        }

      ],

      // data: workOrderList,
      data: [
        // {
        //   start: Date.UTC(2018, 3, 26, 1),
        //   end: Date.UTC(2018, 3, 26, 12),
        //   completed: {
        //     // amount: 0.95
        //   },
        //   name: 'Prototyping'
        // },
        {
          start: Date.UTC(2018, 3, 26, 2),
          end: Date.UTC(2018, 3, 26, 3),
          color: 'rgba(236, 195, 91, 1)',
          name: 'Machine 1',
          lable: 'P1'
          // y : 0
        },
        {
          start: Date.UTC(2018, 3, 26, 3),
          end: Date.UTC(2018, 3, 26, 4),
          color: 'rgba(247, 231, 171, 1)',
          name: 'Machine 1',
          lable: 'p2'
          // y : 0
        },
        {
          start: Date.UTC(2018, 3, 26, 4),
          end: Date.UTC(2018, 3, 26, 5),
          color: 'rgba(197, 214, 233, 1)',
          name: 'Machine 1',
          lable: 'p5'
          // y : 0
        },
        {
          start: Date.UTC(2018, 3, 26, 5),
          end: Date.UTC(2018, 3, 26, 6),
          color: 'rgba(134, 169, 95, 1)',
          name: 'Machine 1',
          lable: 'p4'
          // y : 0
        },
        {
          start: Date.UTC(2018, 3, 26, 2),
          end: Date.UTC(2018, 3, 26, 3),
          color: 'rgba(236, 195, 91, 1)',
          name: 'Machine 2',
          lable: 'p1'
          // y : 0
        },
        {
          start: Date.UTC(2018, 3, 26, 3),
          end: Date.UTC(2018, 3, 26, 4),
          color: 'rgba(197, 214, 233, 1)',
          name: 'Machine 2',
          lable: 'p5'
          // y : 0
        },
        {
          start: Date.UTC(2018, 3, 26, 4),
          end: Date.UTC(2018, 3, 26, 5),
          color: 'rgba(134, 169, 95, 1)',
          name: 'Machine 2',
          lable: 'p4'
          // y : 0
        },
        {
          start: Date.UTC(2018, 3, 26, 2, 0),
          end: Date.UTC(2018, 3, 26, 3, 0),
          color: 'rgba(236, 195, 91, 1)',
          name: 'Machine 3',
          lable: 'p1'
          // y : 0
        },
        {
          start: Date.UTC(2018, 3, 26, 2, 20),
          end: Date.UTC(2018, 3, 26, 3, 0),
          color: 'rgba(247, 231, 171, 1)',
          name: 'Machine 3',
          lable: 'p1'
          // y : 0
        },
        {
          start: Date.UTC(2018, 3, 26, 5),
          end: Date.UTC(2018, 3, 26, 6),
          color: 'rgba(247, 231, 171, 1)',
          name: 'Machine 3',
          lable: 'p2'
          // y : 0
        },
        {
          start: Date.UTC(2018, 3, 26, 4),
          end: Date.UTC(2018, 3, 26, 5),
          color: 'rgba(134, 169, 95, 1)',
          name: 'Machine 3',
          lable: 'p4'
          // y : 0
        },
        {
          start: Date.UTC(2018, 3, 26, 2),
          end: Date.UTC(2018, 3, 26, 3),
          color: 'rgba(236, 195, 91, 1)',
          name: 'Machine 4',
          lable: 'p1'
          // y : 0
        },
        {
          start: Date.UTC(2018, 3, 26, 4),
          end: Date.UTC(2018, 3, 26, 5),
          color: 'rgba(134, 169, 95, 1)',
          name: 'Machine 4',
          lable: 'p4'
          // y : 0
        }

      ]

    }

  ]
  return (
    <Box>
      <Modal
        open={open}
        onClose={DetailsHandleClose}
        aria-labelledby=''
        aria-describedby='modal-modal-description'
      >
        <HeadBox sx={style}>
          <Box className='title-head-box'>
            <Typography className='top-up-name'>Update Process Details</Typography>
            <CloseIcon className='close-icon' onClick={DetailsHandleClose} />
          </Box>
          <Box className='card-header chart-2' mt={'10px'}>
            <GanntChart title='Machine View' constructorType={'ganttChart'} highcharts={gantt} data={machineOption} allowChartUpdate={true} />
          </Box>
        </HeadBox>
      </Modal>
    </Box>
  )
}
export default ManualScenario
