const moment = require('moment')

export function formatDateTitle (dateString) {
  const currentDate = moment.utc().startOf('day') // Start of today

  const givenDate = moment.utc(dateString).startOf('day') // Start of given date

  // Check if the given date is today
  if (givenDate.isSame(currentDate, 'day')) {
    return 'Today'
  }

  // Check if the given date is tomorrow
  if (givenDate.isSame(currentDate.clone().add(1, 'day'), 'day')) {
    return 'Tomorrow'
  }

  // Check if the given date is the day after tomorrow
  if (givenDate.isSame(currentDate.clone().add(2, 'days'), 'day')) {
    return 'Day After Tomorrow'
  }

  // If the given date is not today, tomorrow, or the day after tomorrow, return the formatted date
  return givenDate.format('dddd, MMMM Do')
}
