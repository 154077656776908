import React, { useState, useEffect } from 'react'
import './userManagement.css'
import { styled } from '@mui/material/styles'
import {
  Box,
  Divider,
  Grid,
  Typography,
  FormControl,
  Select,
  MenuItem,
  CircularProgress
} from '@mui/material'
import { Form, Formik } from 'formik'
import CustomInput from '../../leastcomponent/custominput/input'
import { AiOutlineInfoCircle } from 'react-icons/ai'
import CustomButton from '../../leastcomponent/custombutton/custombutton'
import * as Yup from 'yup'
import BreadcrumbNavigation from '../commoncomponents/breadcrumbnavigation'
import {
  useAddUser,
  useGetDeletedUser,
  useGetUserSingle,
  useHardDeleteUser,
  useRestoreUser,
  useUpdateUser
} from '../../hooks/userHooks'
import { useLocation, useParams } from 'react-router-dom'
import Required from '../commoncomponents/required'
import { useGetFullRole } from '../../hooks/roleHooks'
import ErrorPopup from '../../modals/errorpopup'
const Selects = styled(Select)(() => ({
  borderRadius: '4px !important',
  '.MuiOutlinedInput-input': {
    padding: '8.5px 14px',
    fontSize: '14px !important'

  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: '#8A8A8A'
  }
}))
const CreateUsers = () => {
  //  delete items
  const location = useLocation()
  // const { state } = location
  const isDeleted = location.pathname.startsWith('/deleted-items')
  const isRestore = location.pathname.startsWith('/deleted-items')
  const { id } = useParams()
  const [isEdit, setIsEdit] = useState(false)
  const { mutate: hardDeleteUser } = useHardDeleteUser()

  const { mutate: restoreUser } = useRestoreUser()

  const handlePartDelete = () => {
    if (isDeleted) {
      hardDeleteUser(id)
    } else {
      // deleteSinglePart(id)
    }
  }
  const handlePartRestore = () => {
    if (isRestore) {
      restoreUser(id)
    } else {
      // nothing to restore
    }
  }
  const { data: getUserData, isFetching: isGetUserData, error: getUserError } = useGetUserSingle(id, !!id && !isDeleted)
  const { data: getDeletedUserData } = useGetDeletedUser(
    id,
    !!id && isDeleted
  )
  const getUser = isDeleted ? getDeletedUserData : getUserData
  const { data: roleData } = useGetFullRole()
  const singleUser = getUser?.data
  useEffect(() => {
    if (id) {
      setIsEdit(true)
    }
  }, [id])
  const breadCrumbData = [
    {
      title: 'User Management',
      link: '/user-management'
    },
    {
      title: 'Add New User',
      link: ''
    }
  ]
  const [isErrorModalOpen, setIsErrorModalOpen] = React.useState(false)
  const handleErrorModalClose = () => setIsErrorModalOpen(false)
  const [errorMessage, setErrorMessage] = React.useState('')
  const icon = <AiOutlineInfoCircle color="red" />

  const {
    mutate: addUser,
    isError: isAddUserError,
    error: addUserError,
    isLoading
  } = useAddUser()

  const {
    mutate: editUser,
    isError: isEditUserError,
    error: updateUserError
  } = useUpdateUser()

  React.useEffect(() => {
    if (isAddUserError || isEditUserError || getUserError) {
      setErrorMessage(
        isAddUserError
          ? addUserError?.data?.message
          : isEditUserError
            ? updateUserError?.data?.message
            : getUserError?.data?.message
      )
      setIsErrorModalOpen(true)
    }
  }, [isAddUserError, isEditUserError, getUserError, addUserError, updateUserError])

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required('User name is required'),
    mobile: Yup.string()
      .matches(/^\d{10}$/, 'Mobile number must be 10 digits')
      .required('Phone number is required')
      .min(10, 'Mobile number must be 10 digits')
      .max(10, 'Mobile number must be 10 digits'),
    password: Yup.string().required('Password  is required'),
    confirmPassword: Yup.string().required('Confirm password  is required'),
    userRole: Yup.string().required('Role is required'),
    email: Yup.string().required('Email is required')
  })

  return (
    <Box className="production-team-head-overflow-hidden">
      <BreadcrumbNavigation
        breadCrumbData={breadCrumbData}
        pageTitle={isDeleted ? 'Deleted User' : id ? 'Edit User' : 'Add New User'}
        backToLink={'/user-management'}
        isRestore={true}
        isDeleted={true}
        handleDelete={handlePartDelete}
        handleRestore={handlePartRestore}
      />

      {!isGetUserData
        ? <Box className="">
        <Typography className="form-heading-edit">
          Enter Basic Details{' '}
        </Typography>
        <Formik
          enableReinitialize={true}
          initialValues={{
            // userName: "",
            firstName: isEdit ? singleUser?.firstName : '',
            lastName: 'fastuser',
            // userId: "",
            mobile: isEdit ? singleUser?.mobile : '',
            password: isEdit ? singleUser?.password : '',
            confirmPassword: isEdit ? singleUser?.confirmPassword : '',
            // userRole: isEdit ? singleUser?.userRole : "",
            userRole:
              isEdit && singleUser?.userRole?.id
                ? singleUser?.userRole?.id
                : '',
            email: isEdit ? singleUser?.email : ''
          }}
          onSubmit={(values) =>
            id ? editUser({ ...values, id }) : addUser(values)
          }
          validationSchema={validationSchema}
        >
          {({ errors, handleChange, touched, values }) => (
            <React.Fragment>
              <Form className="user-form-perent">
                <Box className="user-managegement-form-child">
                  <Box className="padding-y-50">
                    <Grid spacing={2} container paddingTop={2}>
                      <Grid item xs={12} md={4} xl={4}>
                        <CustomInput
                          label="User's Name"
                          placeholder="Enter Name"
                          handleChange={handleChange}
                          value={values?.firstName}
                          name="firstName"
                          InputProps={{
                            endAdornment:
                              touched?.firstName && errors?.firstName && icon
                          }}
                          errors={errors?.firstName}
                          touched={touched?.firstName}
                        />
                      </Grid>
                      <Grid item xs={12} md={4} xl={4}>
                        <CustomInput
                          label="Phone Number"
                          placeholder="Enter Phone Number"
                          handleChange={handleChange}
                          name="mobile"
                          value={values?.mobile}
                          InputProps={{
                            endAdornment:
                              touched.mobile && errors.mobile && icon
                          }}
                          errors={errors.mobile}
                          touched={touched.mobile}
                        />
                      </Grid>
                      <Grid item xs={12} md={4} xl={4}>
                        <CustomInput
                          label="Password"
                          placeholder="Enter Password"
                          handleChange={handleChange}
                          name="password"
                          type='password'
                          value={values?.password}
                          InputProps={{
                            endAdornment:
                              touched.password && errors.password && icon
                          }}
                          errors={errors.password}
                          touched={touched.password}
                        />
                      </Grid>
                      { }
                      {/* <Grid item xs={4} md={4} lg={4}>
                        <Typography className="field-title" mt={'2px'}>
                          Department
                          <Required />
                        </Typography>
                        <FormControl fullWidth>
                          <Selects
                            name="department"
                            variant="outlined"
                            value={
                              departmentData?.data?.length
                                ? values?.department
                                : ''
                            }
                            onChange={handleChange}
                            displayEmpty
                          >
                            <MenuItem value="">
                              <span className="menu-item-span">
                                Select Department
                              </span>
                            </MenuItem>

                            {departmentData?.data?.map((data, ind) => {
                              return (
                                <MenuItem value={data?.id} key={ind}>
                                  {data?.name}
                                </MenuItem>
                              )
                            })}
                          </Selects>
                        </FormControl>

                        {touched.department && errors.department && (
                          <Typography className="validation-text">
                            {typeof errors.department === 'string'
                              ? errors.department
                              : ''}
                          </Typography>
                        )}
                      </Grid> */}
                    </Grid>
                    <Grid spacing={2} paddingTop={3} container>

                      <Grid item xs={12} md={4} xl={4}>
                        <CustomInput
                          label="Confirm Password"
                          placeholder="Enter Confirm Password"
                          handleChange={handleChange}
                          type='password'
                          name="confirmPassword"
                          value={values?.confirmPassword}
                          InputProps={{
                            endAdornment:
                              touched.password &&
                              errors.confirmPassword &&
                              icon
                          }}
                          errors={errors.confirmPassword}
                          touched={touched.confirmPassword}
                        />
                      </Grid>
                    </Grid>
                    <Divider />
                  </Box>
                  <Box className="user-module-access">
                    <Typography className="form-heading-1  pb-20">
                      Module Access
                    </Typography>
                    <Grid spacing={2} container>
                      <Grid item xs={4} md={4} lg={4}>
                        <Typography className="field-title" mt={'2px'}>
                          User Role
                          <Required />
                        </Typography>
                        <FormControl fullWidth>
                          <Selects
                            name="userRole"
                            variant="outlined"
                            value={values?.userRole}
                            onChange={handleChange}
                            displayEmpty
                          >
                            <MenuItem value="">
                              <span className="menu-item-span">
                                Select User Role
                              </span>
                            </MenuItem>

                            {roleData?.data?.map((data, ind) => {
                              return (
                                <MenuItem value={data?.id} key={ind}>
                                  {data?.name}
                                </MenuItem>
                              )
                            })}
                          </Selects>
                        </FormControl>

                        {touched.userRole && errors.userRole && (
                          <Typography className="validation-text">
                            {typeof errors.userRole === 'string'
                              ? errors.userRole
                              : ''}
                          </Typography>
                        )}
                      </Grid>
                    </Grid>
                    <Divider />
                  </Box>
                  <Box className="user-email-id">
                    <Typography className="form-heading-1  pb-20">
                      User Email Id
                    </Typography>
                    <Grid spacing={2} container>
                      <Grid item xs={12} md={4} xl={4}>
                        <CustomInput
                          label="Email ID for login"
                          placeholder="Enter Email Id"
                          handleChange={handleChange}
                          name="email"
                          type="email"
                          value={values?.email}
                          InputProps={{
                            endAdornment: touched.email && errors.email && icon
                          }}
                          errors={errors.email}
                          touched={touched.email}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
               { !isDeleted && <Box className="button-child">
                  <CustomButton
                    disabled={isLoading}
                  title="Save User" type="submit" />
                </Box>}
              </Form>
            </React.Fragment>
          )}
        </Formik>
      </Box>
        : <Box className='machine-kanban-progress'>
       <CircularProgress />
     </Box>}
      {isErrorModalOpen && (
        <ErrorPopup
          isOpen={isErrorModalOpen}
          handleClose={handleErrorModalClose}
          errorDetails={errorMessage}
        />
      )}
    </Box>
  )
}

export default CreateUsers
