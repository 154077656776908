import styled from '@emotion/styled'
import * as Yup from 'yup'
// TODO:: future use fullscreen-loading
// import Loading from 'react-fullscreen-loading'
import {
  Box,
  Divider,
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  Checkbox,
  TableCell,
  useTheme,
  useMediaQuery,
  Typography,
  MenuItem,
  Select,
  CircularProgress
} from '@mui/material'
import FormControl from '@mui/material/FormControl'
import { Formik, Form } from 'formik'
import React from 'react'
import { AiOutlineInfoCircle } from 'react-icons/ai'
import { useLocation, useParams } from 'react-router-dom'
import CustomButton from '../../leastcomponent/custombutton/custombutton'
import CheckboxFilledIcon from '../../assets/svg/CheckboxFilledIcon'
import CheckboxIcon from '../../assets/svg/CheckboxIcon'
import { useGetDeletedRole, useGetRole, useHardDeleteRole, useRestoreRole } from '../../hooks/userRoleHook'
import _ from 'lodash'
import AddRoles from '../../modals/AddRoles'
import BreadcrumbNavigation from '../commoncomponents/breadcrumbnavigation'
import './userManagement.css'
import CustomInput from '../../leastcomponent/custominput/input'
import Required from '../commoncomponents/required'
import { useGetFullDepartmentsGroup } from '../../hooks/departmentHooks'

const TabContainer = styled(TableContainer)(() => ({
  borderRadius: '8px 8px 0px 0px !important',
  border: '1px solid #DEDEDE !important',
  width: '60%'
}))

const Selects = styled(Select)(() => ({
  borderRadius: '4px !important',
  '.MuiOutlinedInput-input': {
    padding: '8.5px 14px',
    fontSize: '14px !important'

  },
  '& .MuiOutlinedInput-notchedOutline': {
    // borderColor: '#8A8A8A'
  }
}))

const BoxWrapper = styled(Box)(() => ({
  paddingBottom: '20px',
  '.validation-text': {
    color: 'red',
    fontSize: '12px'
  },
  '.button-box': {
    display: 'flex',
    justifyContent: 'end',
    marginTop: '20px',
    boxShadow: 'rgba(40, 40, 40, 0.08) 0px -6px 4px -6px',
    position: 'absolute',
    bottom: '1px',
    left: '0px',
    width: '100%'
  },
  '.field-title': {
    fontSize: 14,
    fontWeight: 500,
    padding: '0px 0px 8px 0px',
    color: '#000'
  },
  '.disable-btn': {
    background: '#C9C9C9'
  },
  '.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root': {
    padding: '0px !important'
  },
  '.MuiButtonBase-root-MuiCheckbox-root': {
    padding: '0px !important'
  },
  '.row-bg-color': {
    backgroundColor: ''
  },
  '.customize-table': {
    border: '1px solid #DEDEDE !important'
  },
  '.module-text': {
    width: '35%',
    color: '#0160B9 !important',
    fontWeight: 600
  },
  'full-width': {
    width: '100% !important'
  },
  '.background-head': {
    background: '#F6F6F6'
  },
  '.bs-info-circle': {
    marginLeft: '7px'
  },
  '.table-data-cell-box': {
    display: 'flex',
    alignItems: 'center'
  }
}))
const AddRole = () => {
  //  delete items
  const location = useLocation()
  const isDeleted = location.pathname.startsWith('/deleted-items')
  const isRestore = location.pathname.startsWith('/deleted-items')
  const [isEdit, setIsEdit] = React.useState(false)
  const icon = <AiOutlineInfoCircle color="red" />
  const [modalValues, setModalValues] = React.useState({})
  const [isModalOpen, setIsModalOpen] = React.useState(false)
  const { id } = useParams()
  const { data: roleDetailsData, isFetching: isRoleDetailsData } = useGetRole(id, !!id && !isDeleted)
  const { data: getDeletedRoleData } = useGetDeletedRole(
    id,
    !!id && isDeleted
  )
  const roleDetails = isDeleted ? getDeletedRoleData?.data : roleDetailsData
  const theme = useTheme()
  const isMdScreen = useMediaQuery(theme.breakpoints.only('md'))
  const handleOpen = () => setIsModalOpen(true)
  const handleClose = () => setIsModalOpen(false)
  const { data: departmentGroupData } = useGetFullDepartmentsGroup()
  const addRoleSchema = Yup.object().shape({
    name: Yup.string()
      .required('Role is required')
      .matches(/^[ a-zA-Z0-9]+$/, 'Only alphabets and numbers are allowed'),
    departmentGroup: Yup.string()
      .required(' Department Group is required')
      .matches(/^[ a-zA-Z0-9]+$/, 'Only alphabets and numbers are allowed'),
    rights: Yup.array()
  })
  React.useEffect(() => {
    if (id) {
      setIsEdit(true)
    }
  }, [id])
  const breadCrumbData = [
    {
      title: 'User Management',
      link: '/user-management',
      activeTab: '2'
    },
    {
      title: `${isEdit ? 'Edit' : 'Add new'} Role`,
      link: '',
      activeTab: '2'
    }
  ]

  const TableDataCell = styled(TableCell)(() => ({
    padding: isMdScreen ? '8px 8px 8px 16px' : '8px 16px 8px 16px'
  }))

  const StyledTableRow = styled(TableRow)(() => ({
    '.MuiTableCell-root': {

    },
    '&:nth-of-type(odd)': {

    },
    '&:last-child td, &:last-child th': {
      border: 0
    }
  }))

  const modules = [
    'Create Job Cards',
    'Approve Job Cards',
    'Add tasks to Job Cards',
    'Schedule Job Cards',
    'Resources',
    'Dashboard',
    'edit job card after approval'
  ]
  const rowsData = modules.map((module, ind) => {
    return {
      id: ind,
      module
    }
  })

  const rightsArr = modules.map((module) => {
    return {
      userModule: module,
      view: false,
      edit: false
    }
  })

  const notificationModules = [
    'User Added',
    'User Removed',
    'User Updated',
    'Resource Added',
    'Resource Removed',
    'Resource Updated',
    'Job Cards submitted',
    'Job Cards approved',
    'Job Cards completed',
    'Tasks added'
  ]

  const notificationsArr = notificationModules?.map((notification) => {
    return {
      event: notification,
      email: false,
      dashboard: false

    }
  })

  const fetchedRightsArr = modules?.map((module) => {
    const currentModule = roleDetails?.userPermission?.find(
      (permission) => permission?.userModule === module
    )
    return {
      edit: currentModule?.edit || false,
      view: currentModule?.view || false,
      userModule: currentModule?.userModule || 'edit job card after approval'
    }
  })

  const notificationRowData = notificationModules?.map((notification, ind) => {
    return {
      id: ind,
      notification
    }
  })
  const fetchedNotificationsArr = notificationModules?.map((module) => {
    const currentModule = roleDetails?.notifications?.find(
      (permission) => permission?.event === module
    )
    return {
      event: currentModule?.event,
      email: currentModule?.email,
      dashboard: currentModule?.dashboard
    }
  })

  const moduleMapping = {
    'Create Job Cards': 'Create Job Cards',
    'Approve Job Cards': 'Approve Job Cards',
    'Add tasks to Job Cards': 'Add task to job Card',
    'Schedule Job Cards': 'Schedule job Card',
    Schedule: 'Schedule',
    Resources: 'Resources',
    Dashboard: 'Dashboard',
    'edit job card after approval': 'Edit JC after approval'
  }
  const { mutate: hardDeleteRole } = useHardDeleteRole()
  const { mutate: restoreRole } = useRestoreRole()

  const handlePartDelete = () => {
    if (isDeleted) {
      hardDeleteRole(id)
    } else {
      // deleteSinglePart(id)
    }
  }
  const handlePartRestore = () => {
    if (isRestore) {
      restoreRole(id)
    } else {
      // nothing to restore
    }
  }
  return (
    <React.Fragment>
      <Box className="production-team-head-overflow-hidden">
        <BreadcrumbNavigation
          breadCrumbData={breadCrumbData}
          pageTitle={isDeleted ? 'Deleted Role' : `${isEdit ? 'Edit' : 'Add new'} Role`}
          backToLink="/user-management"
          activeTab="2"
          isRestore={true}
        isDeleted={true}
        handleDelete={handlePartDelete}
        handleRestore={handlePartRestore}
        />
        {!isRoleDetailsData
          ? <BoxWrapper>
          <Formik
            initialValues={{
              name: isDeleted ? roleDetails?.name : isEdit ? roleDetails?.userRole?.name : '',
              departmentGroup: isDeleted ? roleDetails?.departmentGroup : isEdit ? roleDetails?.userRole?.departmentgroup?.id : '',
              rights: isEdit ? fetchedRightsArr : rightsArr,
              notifications: isEdit
                ? fetchedNotificationsArr
                : notificationsArr
            }}
            enableReinitialize={true}
            validationSchema={addRoleSchema}
            onSubmit={(values) => {
              setModalValues(values)
              handleOpen()
            }}
            >
            {({ values, touched, errors, handleChange, setFieldValue }) => (
              <Form>
                <Box className="role-team-head-overflow-auto">
                  <Typography className="title-typography">
                    Enter Role Details
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      <CustomInput
                        label="Role"
                        placeholder="Enter Name"
                        handleChange={handleChange}
                        value={values?.name}
                        name="name"
                        InputProps={{
                          endAdornment:
                            touched?.firstName && errors?.firstName && icon
                        }}
                        errors={errors?.firstName}
                        touched={touched?.firstName}
                      />
                      {touched.name && errors.name && (
                        <Typography className="validation-text">
                          {errors.name}
                        </Typography>
                      )}
                    </Grid>
                    <Grid item xs={4} md={4} lg={4}>
                      <Typography className='field-title' mt={'2px'}>
                        Department Group
                        <Required />
                      </Typography>
                      <FormControl fullWidth>
                        <Selects
                        style={{ borderColor: '#8A8A8A' }}
                          name='departmentGroup'
                          variant='outlined'
                          value={
                            values?.departmentGroup
                              ? values?.departmentGroup
                              : ''
                          }
                          onChange={handleChange}
                          displayEmpty
                        >
                          <MenuItem value=''>
                            <span className='menu-item-span'>Select Department Group</span>
                          </MenuItem>

                          {departmentGroupData?.data?.map((data, ind) => {
                            return (
                              <MenuItem value={data?.id} key={ind}>
                                {data?.title}
                              </MenuItem>
                            )
                          })}
                        </Selects>
                      </FormControl>

                      {touched.departmentGroup && errors.departmentGroup && (
                        <Typography className='validation-text'>
                          {typeof errors.departmentGroup === 'string' ? errors.departmentGroup : ''}
                        </Typography>
                      )}
                    </Grid>
                  </Grid>

                  <Divider />

                  <Typography className="title-typography">
                    {' '}
                    Module and Action Access
                  </Typography>
                  <Box height={'auto'} width={isMdScreen ? '100%' : '62%'}>
                    <TabContainer>
                      <Table aria-label="customized table">
                        <TableHead className="background-head">
                          <TableRow>
                            <TableDataCell>Module</TableDataCell>
                            <TableDataCell align="left">View</TableDataCell>
                            <TableDataCell align="left">Edit</TableDataCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {rowsData.map((row) => {
                            return (
                              <StyledTableRow
                                key={row.module}
                                style={{ padding: '0px' }}
                              >
                                <TableDataCell
                                  component="th"
                                  scope="row"
                                  className={
                                    values?.rights[row.id]?.view === false
                                      ? 'row-bg-color module-text'
                                      : 'module-text'
                                  }
                                >
                                  {moduleMapping[row.module]}
                                </TableDataCell>
                                <TableDataCell
                                  align="left"
                                  className={
                                    values?.rights[row.id]?.view === false
                                      ? 'row-bg-color'
                                      : ''
                                  }
                                >
                                  {moduleMapping[row.module] !== 'Edit JC after approval' &&
                                  <Box className="table-data-cell-box">
                                    <Checkbox
                                      name={`rights[${row.id}].view`}
                                      checked={
                                        !!values?.rights[row.id]?.view
                                      }
                                      onChange={(e) => {
                                        // handleChange(e)
                                        if (e.target.checked === false) {
                                          setFieldValue(
                                            `rights[${row.id}].edit`,
                                            false
                                          )
                                        }
                                      }}
                                      icon={<CheckboxIcon />}
                                      checkedIcon={<CheckboxFilledIcon />}
                                    />
                                  </Box>}
                                </TableDataCell>
                                <TableDataCell
                                  align="left"
                                  className={
                                    values?.rights[row.id]?.view === false
                                      ? 'row-bg-color'
                                      : ''
                                  }
                                >
                                  <Box className="table-data-cell-box">
                                    <Checkbox
                                      name={`rights[${row.id}].edit`}
                                      checked={
                                        !!values?.rights[row.id]?.edit
                                      }
                                      onChange={(e) => {
                                        handleChange(e)
                                        // ! new vahivat left
                                        if (e.target.checked) {
                                          setFieldValue(`rights[${row.id}].view`, true)
                                          if (row.id === 0 || row.id === values.rights.length - 1 || row.id === 1
                                          ) {
                                            setFieldValue('rights[0].view', true)
                                          }
                                        } else {
                                          const filteredObject = _.pickBy(
                                            values?.rights[row.id],
                                            function (value, key) {
                                              return ['add', 'delete'].includes(
                                                key
                                              )
                                            }
                                          )
                                          const isAnyRightTrue = Object.values(
                                            filteredObject || {}
                                          ).includes(true)
                                          if (!isAnyRightTrue && ([0, 6]?.includes(row.id) ? (row.id === 6 && !values?.rights?.[0]?.edit) || (row.id === 0 && !values?.rights?.[6]?.edit) : true)) {
                                            setFieldValue(
                                              `rights[${row.id}].view`,
                                              false
                                            )
                                            if (row?.id === values?.rights?.length - 1) {
                                              setFieldValue(
                                                'rights[0].view',
                                                false
                                              )
                                            }
                                          }
                                        }
                                      }}
                                      icon={<CheckboxIcon />}
                                      checkedIcon={<CheckboxFilledIcon />}
                                    />
                                  </Box>
                                </TableDataCell>
                              </StyledTableRow>
                            )
                          })}
                        </TableBody>
                      </Table>
                    </TabContainer>
                  </Box>
                  <Divider />
                  <Typography className="title-typography">
                    {' '}
                    Notification Setup
                  </Typography>
                  <Box
                    height={'auto'}
                    mb={isMdScreen ? '8%' : '6%'}
                    width={isMdScreen ? '100%' : '62%'}
                  >
                    <TabContainer>
                      <Table
                        sx={{ minWidth: 400 }}
                        aria-label="customize table"
                      >
                        <TableHead className="background-head">
                          <TableRow>
                            <TableDataCell>Event</TableDataCell>
                            <TableDataCell align="left">
                              Dashboard
                            </TableDataCell>
                            <TableDataCell align="left">Email</TableDataCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {notificationRowData.map((row) => {
                            return (
                              <StyledTableRow key={row.notification}>
                                <TableDataCell className="module-text">
                                  {' '}
                                  {row.notification}
                                </TableDataCell>
                                <TableDataCell className="module-text">
                                  <Checkbox
                                    name={`notifications[${row.id}].dashboard`}
                                    checked={
                                      !!(values?.notifications[row.id].dashboard ||
                                        '')
                                    }
                                    onChange={(e) => {
                                      handleChange(e)
                                      if (e.target.checked) {
                                        setFieldValue(
                                          `notifications[${row.id}].dashboard`,
                                          true
                                        )
                                      } else {
                                        const filteredObject = _.pickBy(
                                          values?.notifications[row.id],
                                          function (value, key) {
                                            return ['edit', 'delete'].includes(
                                              key
                                            )
                                          }
                                        )
                                        const isAnyRightTrue = Object.values(
                                          filteredObject || {}
                                        ).includes(true)
                                        if (!isAnyRightTrue) {
                                          setFieldValue(
                                            `notifications[${row.id}].dashboard`,
                                            false
                                          )
                                        }
                                      }
                                    }}
                                    icon={<CheckboxIcon />}
                                    checkedIcon={<CheckboxFilledIcon />}
                                  />
                                </TableDataCell>
                                <TableDataCell className="module-text">
                                  <Checkbox
                                    name={`notifications[${row.id}].email`}
                                    checked={
                                      !!(values?.notifications[row.id].email || '')
                                    }
                                    onChange={(e) => {
                                      handleChange(e)
                                      if (e.target.checked) {
                                        setFieldValue(
                                          `notifications[${row.id}].email`,
                                          true
                                        )
                                      } else {
                                        const filteredObject = _.pickBy(
                                          values?.notifications[row.id],
                                          function (value, key) {
                                            return ['edit', 'delete'].includes(
                                              key
                                            )
                                          }
                                        )
                                        const isAnyRightTrue = Object.values(
                                          filteredObject || {}
                                        ).includes(true)
                                        if (!isAnyRightTrue) {
                                          setFieldValue(
                                            `notifications[${row.id}].email`,
                                            false
                                          )
                                        }
                                      }
                                    }}
                                    icon={<CheckboxIcon />}
                                    checkedIcon={<CheckboxFilledIcon />}
                                  />
                                </TableDataCell>

                              </StyledTableRow>
                            )
                          })}
                        </TableBody>
                      </Table>
                    </TabContainer>
                  </Box>
                </Box>
              {!isDeleted && <Box className="button-child button-box">
                  <CustomButton title="Save Role" type="submit" />
                </Box>}
              </Form>
            )}
          </Formik>
          {isModalOpen && (
            <AddRoles
              isOpen={isModalOpen}
              values={modalValues}
              handleClose={handleClose}
            />
          )}
          {/* TODO:: future use fullscreen-loading */}
          {/* <Loading
            loading={!!(id && !isSuccess)}
            background="white"
            loaderColor="#265AA8"
          /> */}
        </BoxWrapper>
          : <Box className='machine-kanban-progress'>
        <CircularProgress />
      </Box>}
      </Box>
    </React.Fragment>
  )
}

export default AddRole
