import React from 'react'

const ShareIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 6.875C5 6.875 6.29442 6.875 7.20971 7.79029C7.20971 7.79029 8.125 8.70558 8.125 10C8.125 10 8.125 11.2944 7.20971 12.2097C7.20971 12.2097 6.29442 13.125 5 13.125C5 13.125 3.70558 13.125 2.79029 12.2097C2.79029 12.2097 1.875 11.2944 1.875 10C1.875 10 1.875 8.70558 2.79029 7.79029C2.79029 7.79029 3.70558 6.875 5 6.875ZM5 8.125C5 8.125 4.22335 8.125 3.67418 8.67417C3.67418 8.67417 3.125 9.22335 3.125 10C3.125 10 3.125 10.7767 3.67418 11.3258C3.67418 11.3258 4.22335 11.875 5 11.875C5 11.875 5.77665 11.875 6.32583 11.3258C6.32583 11.3258 6.875 10.7767 6.875 10C6.875 10 6.875 9.22335 6.32583 8.67417C6.32583 8.67417 5.77665 8.125 5 8.125Z"
        fill="#265AA8"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.75 12.5C13.75 12.5 15.0444 12.5 15.9597 13.4153C15.9597 13.4153 16.875 14.3306 16.875 15.625C16.875 15.625 16.875 16.9194 15.9597 17.8347C15.9597 17.8347 15.0444 18.75 13.75 18.75C13.75 18.75 12.4556 18.75 11.5403 17.8347C11.5403 17.8347 10.625 16.9194 10.625 15.625C10.625 15.625 10.625 14.3306 11.5403 13.4153C11.5403 13.4153 12.4556 12.5 13.75 12.5ZM13.75 13.75C13.75 13.75 12.9733 13.75 12.4242 14.2992C12.4242 14.2992 11.875 14.8483 11.875 15.625C11.875 15.625 11.875 16.4017 12.4242 16.9508C12.4242 16.9508 12.9733 17.5 13.75 17.5C13.75 17.5 14.5267 17.5 15.0758 16.9508C15.0758 16.9508 15.625 16.4017 15.625 15.625C15.625 15.625 15.625 14.8483 15.0758 14.2992C15.0758 14.2992 14.5267 13.75 13.75 13.75Z"
        fill="#265AA8"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.75 1.25C13.75 1.25 15.0444 1.25 15.9597 2.16529C15.9597 2.16529 16.875 3.08058 16.875 4.375C16.875 4.375 16.875 5.66942 15.9597 6.58471C15.9597 6.58471 15.0444 7.5 13.75 7.5C13.75 7.5 12.4556 7.5 11.5403 6.58471C11.5403 6.58471 10.625 5.66942 10.625 4.375C10.625 4.375 10.625 3.08058 11.5403 2.16529C11.5403 2.16529 12.4556 1.25 13.75 1.25ZM13.75 2.5C13.75 2.5 12.9733 2.5 12.4242 3.04917C12.4242 3.04917 11.875 3.59835 11.875 4.375C11.875 4.375 11.875 5.15165 12.4242 5.70083C12.4242 5.70083 12.9733 6.25 13.75 6.25C13.75 6.25 14.5267 6.25 15.0758 5.70083C15.0758 5.70083 15.625 5.15165 15.625 4.375C15.625 4.375 15.625 3.59835 15.0758 3.04917C15.0758 3.04917 14.5267 2.5 13.75 2.5Z"
        fill="#265AA8"
      />
      <path
        d="M11.9863 6.25242L11.9904 6.24975C12.167 6.13431 12.2734 5.93758 12.2734 5.72662C12.2734 5.60682 12.239 5.48953 12.1742 5.38874L12.1716 5.38462C12.0818 5.2473 11.9416 5.15079 11.7813 5.11591L11.7767 5.11492C11.7345 5.10608 11.6915 5.10162 11.6484 5.10162C11.5286 5.10162 11.4113 5.13606 11.3105 5.20083L6.76388 8.12257C6.62443 8.21218 6.52608 8.35365 6.49084 8.51562C6.48134 8.55928 6.47655 8.60382 6.47655 8.6485L6.47683 8.66697C6.48018 8.78045 6.51438 8.89087 6.57576 8.98638C6.69075 9.16532 6.88885 9.2735 7.10155 9.2735C7.11056 9.2735 7.11956 9.2733 7.12856 9.27291C7.23909 9.26814 7.34637 9.2341 7.43944 9.17429L11.9863 6.25242Z"
        fill="#265AA8"
      />
      <path
        d="M11.3103 14.7991C11.4111 14.8639 11.5286 14.8985 11.6484 14.8985L11.6533 14.8985C11.8643 14.8968 12.0602 14.7889 12.1742 14.6114C12.239 14.5106 12.2734 14.3933 12.2734 14.2735L12.2734 14.2686C12.2718 14.0576 12.1638 13.8617 11.9863 13.7477L7.43944 10.8258C7.33865 10.7611 7.22136 10.7266 7.10155 10.7266L7.09664 10.7266C6.88569 10.7283 6.68981 10.8363 6.57576 11.0137C6.51099 11.1145 6.47655 11.2318 6.47655 11.3516L6.47657 11.3565C6.47823 11.5675 6.5862 11.7634 6.76367 11.8774L11.3103 14.7991Z"
        fill="#265AA8"
      />
    </svg>
  )
}

export default ShareIcon
