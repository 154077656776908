import React from 'react'
import './factorySchedule.css'
import { Box, CircularProgress, Grid } from '@mui/material'
import EditIconNew from '../../assets/svg/EditIconNew'
import AddIcon from '@mui/icons-material/Add'
import { useNavigate } from 'react-router-dom'
import BreadcrumbNavigation from '../commoncomponents/breadcrumbnavigation'
import { useGetFactorySchedule } from '../../hooks/factoryschedulehooks'
import moment from 'moment'

const FactorySchedule = () => {
  const navigate = useNavigate()

  const { data: fectoryScheduleRes, isFetching: isFectoryScheduleRes } = useGetFactorySchedule(true)
  const workingDays = fectoryScheduleRes?.workingDays || []
  const shifts = fectoryScheduleRes?.shifts || []
  const holidays = fectoryScheduleRes?.holidays || []

  return (
    <Box className="factory-schedule-overflow-hidden">
      <BreadcrumbNavigation
        breadCrumbData={null}
        pageTitle={'Factory Schedule'}
        backToLink={null}
      />

      {!isFectoryScheduleRes
        ? <Box className="factory-schedule-overflow-auto" paddingTop={'10px'}>
        <Box className="padding-x-50">
          <Box className="border-border-radius">
            <Box className="padding-2-border-bg">
              <Box display={'flex'} justifyContent={'space-between'}>
                <Box className="font-size-16-with-semibold">Factory Timing</Box>
                <Box className="factory-table-btn-main">
                  <span
                    className="process-Edit"
                    onClick={() => navigate('/edit-factory-schedule')}
                  >
                    <EditIconNew />{' '}
                    <span className="process-Edit-name">Edit</span>
                  </span>
                </Box>
              </Box>
            </Box>
            <Box className="padding-10-2">
              <Grid container className="padding-x-40">
                <Grid item lg={5}>
                  <Box className="font-size-13 ">Working Days</Box>
                  {workingDays?.length > 0 && (
                    <Box className="font-size-14-with-semibold">
                      {workingDays?.join(' , ')}
                    </Box>
                  )}
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
        <Box className="padding-x-50">
          <Box className="border-border-radius">
            <Box className="padding-2-border-bg">
              <Box display={'flex'} justifyContent={'space-between'}>
                <Box className="font-size-16-with-semibold">Shift</Box>
                <Box className="factory-table-btn-main">
                  <span
                    className="process-Edit"
                    onClick={() => navigate('/edit-factory-schedule')}
                  >
                    <EditIconNew />{' '}
                    <span className="process-Edit-name">Edit</span>
                  </span>
                  <span
                    className="process-add"
                    onClick={() => navigate('/edit-factory-schedule')}
                  >
                    <AddIcon />{' '}
                    <span className="process-Edit-name">Add New Shift</span>
                  </span>
                </Box>
              </Box>
            </Box>
            <Box className="padding-10-2">
              {shifts?.length > 0 &&
                shifts?.map((shift, ind) => {
                  return (
                    <Grid container className="padding-x-40" key={ind}>
                      <Grid item lg={3} md={4}>
                        <Box className="font-size-13">Title</Box>
                        <Box className="font-size-14-with-semibold">
                          {shift?.name ? shift?.name : '-'}
                        </Box>
                      </Grid>
                      <Grid item lg={3} md={4}>
                        <Box className="font-size-13">Start Time</Box>
                        <Box className="font-size-14-with-semibold">
                          {shift?.startTime ? shift?.startTime : ''}
                        </Box>
                      </Grid>
                      <Grid item lg={2} md={4}>
                        <Box className="font-size-13">End Time</Box>
                        <Box className="font-size-14-with-semibold">
                          {shift?.endTime ? shift?.endTime : ''}
                        </Box>
                      </Grid>
                    </Grid>
                  )
                })}
            </Box>
          </Box>
        </Box>
        <Box className="padding-x-50">
          <Box className="border-border-radius">
            <Box className="padding-2-border-bg">
              <Box display={'flex'} justifyContent={'space-between'}>
                <Box className="font-size-16-with-semibold">Factory Timing</Box>
                <Box className="factory-table-btn-main">
                  <span
                    className="process-Edit"
                    onClick={() => navigate('/edit-factory-schedule')}
                  >
                    <EditIconNew />{' '}
                    <span className="process-Edit-name">Edit</span>
                  </span>
                  <span
                    className="process-add"
                    onClick={() => navigate('/edit-factory-schedule')}
                  >
                    <AddIcon />{' '}
                    <span className="process-Edit-name">Add New Holiday</span>
                  </span>
                </Box>
              </Box>
            </Box>
            <Box className="padding-10-2" sx={{ display: 'flex' }}>
              {holidays.length > 0 &&
                holidays?.map((holiday, index) => {
                  return (
                    <Grid container className="padding-x-40" key={index}>
                      <Grid item lg={12}>
                        <Box className="font-size-13">{holiday?.name}</Box>
                        <Box className="font-size-14-with-semibold">
                          {holiday?.date
                            ? moment(holiday?.date).format('Do MMM')
                            : '-'}
                        </Box>
                      </Grid>
                    </Grid>
                  )
                })}
            </Box>
          </Box>
        </Box>
      </Box>
        : <Box className='machine-kanban-progress'>
                <CircularProgress />
              </Box>}
    </Box>
  )
}
export default FactorySchedule
