import { Box } from '@mui/system'
import React, { useState } from 'react'
import { Grid, Typography } from '@mui/material'
import styled from '@emotion/styled'
import TableComponent from '../../leastcomponent/customtable/customtable'
import NorthEastOutlinedIcon from '@mui/icons-material/NorthEastOutlined'

import PlanModal from './planModal'
import BreadcrumbNavigation from '../commoncomponents/breadcrumbnavigation'
const TextFiled = styled(Typography)(() => ({
  color: 'gray',
  paddingTop: '10px',
  fontSize: '14px !important',
  padding: '24px 0px 0px 24px'
}))
const JobPlan = () => {
  const [open, setOpen] = useState(false)
  const handleDetailsClose = () => {
    setOpen(false)
  }
  const breadCrumbData = [
    {
      title: 'Plan',
      link: '/resource'
    },
    {
      title: 'Plan',
      link: ''
    }
  ]
  const rows = [
    {
      id: 1,
      rawMaterial: 'Raw Material',
      dateNeeded: '10/10/10, 10:10',
      cardName: 'Job Card',
      cardNumber: '123-456-789',
      forPart: 'Part 1 ',
      partCode: '12345'
    },
    {
      id: 2,
      rawMaterial: 'Raw Material',
      dateNeeded: '10/10/10, 10:10',
      cardName: 'Job Card',
      cardNumber: '123-456-789',
      forPart: 'Part 1 ',
      partCode: '12345'
    },
    {
      id: 3,
      rawMaterial: 'Raw Material',
      dateNeeded: '10/10/10, 10:10',
      cardName: 'Job Card',
      cardNumber: '123-456-789',
      forPart: 'Part 1 ',
      partCode: '12345'
    },
    {
      id: 4,
      rawMaterial: 'Raw Material',
      dateNeeded: '10/10/10, 10:10',
      cardName: 'Job Card',
      cardNumber: '123-456-789',
      forPart: 'Part 1 ',
      partCode: '12345'
    },
    {
      id: 5,
      rawMaterial: 'Raw Material',
      dateNeeded: '10/10/10, 10:10',
      cardName: 'Job Card',
      cardNumber: '123-456-789',
      forPart: 'Part 1 ',
      partCode: '12345'
    }
  ]
  const columns = [
    { field: 'id', headerName: '#', flex: 0.2 },
    { field: 'rawMaterial', headerName: 'Job Card Number', flex: 1 },
    { field: 'dateNeeded', headerName: 'Part Name', flex: 1 },
    { field: 'cardName', headerName: 'Process Name', flex: 1 },
    { field: 'cardNumber', headerName: 'Start date and time', flex: 1 },
    { field: 'forPart', headerName: 'Allocated time', flex: 1 },
    { field: 'partCode', headerName: 'Drawing', flex: 1 }
  ]
  return (
    <React.Fragment>
      <BreadcrumbNavigation
        breadCrumbData={breadCrumbData}
        pageTitle={'Plan'}
        backToLink={null}
      />

      <Box className="common-parent-class">
        <Box
          padding={'0px 0px 10px'}
          className="common-parent-class"
          display={'flex'}
          justifyContent={'space-between'}
        >
          {/* <BreadCrumb
            breadCrumbData={breadCrumbData}
            className="common-parent-class"
            title="Plan"
          /> */}
          {/* <Grid lg={5} className="align-center-with-gap">Status <span className="align-center"><FiberManualRecordIcon sx={{ fontSize: '12px' }} color="warning" /></span> <span className="font-size-14-with-semibold" >Pending</span></Grid> */}
        </Box>
        <Grid item xs={6}>
          <Box className="details-box-border padding-bottom" mr={'10px'}>
            <Typography
              id="modal-modal-title"
              className="details-sub-title"
              mt={'5px'}
            >
              Basic Details
            </Typography>
            <Grid container display={'flex'}>
              <Grid item xs={3}>
                <TextFiled>Plan Name </TextFiled>
                <Typography className="span-color pl-sub-text">
                  job card
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <TextFiled>Department </TextFiled>
                <Typography className="span-color pl-sub-text">
                  Department
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <TextFiled>Timeline </TextFiled>
                <Typography className="span-color pl-sub-text">
                  Timeline
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Grid>

        <Box className="">
          <Box className="">
            <Grid container className="" mt={'20px'}>
              <Grid lg={12} wrap="nowrap" spacing={2}>
                <Box display={'flex'} justifyContent={'space-between'}>
                  <Box className="font-size-18">Machine 1</Box>
                  <NorthEastOutlinedIcon
                    onClick={() => setOpen(true)}
                  ></NorthEastOutlinedIcon>
                </Box>
                <TableComponent rows={rows} columns={columns} />
              </Grid>
            </Grid>
          </Box>
        </Box>
        {open && (
          <PlanModal
            open={open}
            DetailsHandleClose={handleDetailsClose}
            setOpen={() => setOpen(false)}
          />
        )}
      </Box>
    </React.Fragment>
  )
}

export default JobPlan
