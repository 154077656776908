import React from 'react'

const BlackUpArrow = () => {
  return (
    <svg
      width="15"
      height="9"
      viewBox="0 0 15 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.66634 7.5L7.49967 1.66667L13.333 7.5"
        stroke="#1C1C1C"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default BlackUpArrow
