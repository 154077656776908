import React from 'react'
import { FieldArray, Form, Formik } from 'formik'
import CustomizedModal from '../leastcomponent/custommodal/commonmodal'
import {
  Box,
  Grid,
  useMediaQuery,
  useTheme,
  Typography,
  FormControl,
  Select,
  MenuItem
} from '@mui/material'
import { styled } from '@mui/material/styles'
import CustomButton from '../leastcomponent/custombutton/custombutton'
// import CustomInput from "../leastcomponent/custominput/input";
import * as Yup from 'yup'
import './../modals/processdetails.css'
// import CustomTimePicker from "../leastcomponent/customtimepicker/customtimepicker";
// import dayjs from "dayjs";
import DeleteIconNew from '../assets/svg/DeleteIconNew'
import Plus from '../assets/svg/Plus'
// import CustomSelect from "../leastcomponent/customselect/customselect";

// import Required from '../pages/commoncomponents/required'
import { useGetFullDepartments } from '../hooks/departmentHooks'
const Selects = styled(Select)(() => ({
  borderRadius: '4px !important',
  '.MuiOutlinedInput-input': {
    padding: '8.5px 14px',
    fontSize: '14px !important'
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: '#8A8A8A'
  }
}))

const validationSchema = Yup.object().shape({
  processes: Yup.array().of(
    Yup.object().shape({
      department: Yup.string().required('Department is required')
    })
  )
})

const ProcessDetails = (props) => {
  const {
    open,
    setOpen,
    activeProcess,
    DetailsHandleClose
    // setFieldValue,
    // activeProcess,

    // initialValues = [],
  } = props
  const theme = useTheme()

  const isMdScreen = useMediaQuery(theme.breakpoints.only('md'))

  // const handleFormSubmit = (values) => {
  //   console.log("values==>", values)
  //   props.onSubmit(values);
  //   DetailsHandleClose();
  // };

  const handleFormSubmit = (values) => {
    const selectedDepartment = values?.processes?.map((process) => {
      return {
        department: process?.department,
        proccessId: process?.proccessId,
        departmentGroup: process.departmentGroup,
        code: process.code,
        name: departmentData?.data?.find(
          (data) => data?.id === process?.department
        )?.name
      }
    })
    props.onSubmit({
      processes: selectedDepartment
    })
    localStorage.setItem(
      `processData-${activeProcess}`,
      JSON.stringify(selectedDepartment)
    )
    // Close the modal
    DetailsHandleClose()
  }
  const { data: departmentData } = useGetFullDepartments()

  const initialValues = localStorage.getItem(`processData-${activeProcess}`)

  return (
    <Box>
      <CustomizedModal
        isHeadingName={true}
        heading="Process/Department Details"
        open={open}
        onClick={setOpen}
        // width="30%"
        width={isMdScreen ? '40%' : '30%'}
      >
        <Formik
          // initialValues={{
          //   processes:
          //     initialValues?.length > 0
          //       ? initialValues
          //       : [
          //           {
          //             department: "",
          //             departmentGroup: "",
          //           },
          //         ],
          // }}
          initialValues={{
            processes: initialValues ? JSON.parse(initialValues) : [{ department: '', name: '', code: '' }]
          }}

          enableReinitialize={true}
          validationSchema={validationSchema}
          // onSubmit={(values) => {
          //   setFieldValue(
          //     `parts.${activeProcess}.processes`,
          //     values?.processes
          //   );
          //   setOpen();
          //   console.log("object", values);
          // }}
          onSubmit={handleFormSubmit}
          // onSubmit={(values) =>
          //   console.log("fast==>", values)
          // }
        >
          {({
            errors,
            touched,
            handleChange,
            values,
            setFieldValue,
            handleSubmit
          }) => (
            <Form>
              <FieldArray
                name="processes"
                render={({ push, remove }) => (
                  <Box>
                    {values?.processes?.length > 0 &&
                      values?.processes?.map((processes, index) => (
                        <Box key={index} className="process-main-box">
                          <Grid container spacing={2} sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                            <Grid item xs={1}>{index + 1}</Grid>
                            <Grid item xs={6}>
                              <FormControl fullWidth>
                                <Selects
                                  name={`processes.${index}.department`}
                                  variant="outlined"
                                  value={values?.processes?.[index]?.department}
                                  onChange={(e) => {
                                    handleChange(e)
                                    const selectedDept = departmentData?.data?.find(data => data?.id === e.target.value)
                                    setFieldValue(`processes.${index}.code`, selectedDept?.code)
                                    // setFieldValue(`processes.${index}.name`, selectedDept?.name)
                                  }}
                                  displayEmpty
                                >
                                  <MenuItem value="">
                                    <span className="menu-item-span">
                                      Select Department
                                    </span>
                                  </MenuItem>
                                  {departmentData?.data?.map((data, ind) => {
                                    return (
                                      <MenuItem value={data?.id} key={ind}>

                                        {data?.name}
                                      </MenuItem>
                                    )
                                  })}
                                </Selects>
                              </FormControl>

                              {touched.processes?.[index]?.department &&
                                errors.processes?.[index]?.department && (
                                  <Typography className="validation-text">
                                    {typeof errors.processes?.[index]
                                      ?.department === 'string'
                                      ? errors.processes?.[index]?.department
                                      : ''}
                                  </Typography>
                              )}
                            </Grid>

                            <Grid className="delete-icon-grid" item xs={1}>
                              <span
                              style={{ cursor: 'pointer' }}
                                onClick={(e) => {
                                  if (values?.processes?.[index]?.proccessId) {
                                    props?.setDeletedProcess([...props?.deletedProcess, values?.processes?.[index]?.proccessId])
                                  }
                                  remove(index)
                                }}
                                // className="process-delete"
                              >
                                <DeleteIconNew />
                              </span>
                            </Grid>
                          </Grid>
                        </Box>
                      ))}
                    <Grid className="add-process">
                      <CustomButton
                        variant="outlined"
                        className="button-box"
                        type="button"
                        title="Add"
                        endIcon={<Plus fill={'#0160B9'} />}
                        onClick={() =>
                          push({
                            department: '',
                            code: ''
                            // name: ''
                          })
                        }
                      >
                        Add
                      </CustomButton>
                    </Grid>
                  </Box>
                )}
              />
              <Grid className="save-btn">
                <CustomButton
                  className="button-box"
                  type="submit"
                  title="Save"
                  onClick={() => {
                    handleSubmit(values)
                  }}
                  />
              </Grid>
            </Form>
          )}
        </Formik>
      </CustomizedModal>
    </Box>
  )
}

export default ProcessDetails

// TODO:: auto generate department code FE
// import React from 'react'
// import { FieldArray, Form, Formik } from 'formik'
// import CustomizedModal from '../leastcomponent/custommodal/commonmodal'
// import {
//   Box,
//   Grid,
//   useMediaQuery,
//   useTheme,
//   Typography,
//   FormControl,
//   Select,
//   MenuItem
// } from '@mui/material'
// import { styled } from '@mui/material/styles'
// import CustomButton from '../leastcomponent/custombutton/custombutton'
// import * as Yup from 'yup'
// import './../modals/processdetails.css'
// import DeleteIconNew from '../assets/svg/DeleteIconNew'
// import Plus from '../assets/svg/Plus'
// import { useGetFullDepartments } from '../hooks/departmentHooks'

// const Selects = styled(Select)(() => ({
//   borderRadius: '4px !important',
//   '.MuiOutlinedInput-input': {
//     padding: '8.5px 14px',
//     fontSize: '14px !important'
//   },
//   '& .MuiOutlinedInput-notchedOutline': {
//     borderColor: '#8A8A8A'
//   }
// }))

// const validationSchema = Yup.object().shape({
//   processes: Yup.array().of(
//     Yup.object().shape({
//       department: Yup.string().required('Department is required')
//     })
//   )
// })

// const generateDepartmentCode = (departmentName) => {
//   if (!departmentName) return ''
//   return `${departmentName}Dpt`
// }

// const ProcessDetails = (props) => {
//   const {
//     open,
//     setOpen,
//     activeProcess,
//     DetailsHandleClose
//   } = props
//   const theme = useTheme()
//   const isMdScreen = useMediaQuery(theme.breakpoints.only('md'))

//   const handleFormSubmit = (values) => {
//     const selectedDepartment = values?.processes?.map((process) => {
//       const departmentName = departmentData?.data?.find(
//         (data) => data?.id === process?.department
//       )?.name

//       return {
//         department: process?.department,
//         proccessId: process?.proccessId,
//         departmentGroup: process.departmentGroup,
//         code: generateDepartmentCode(departmentName),
//         name: departmentName
//       }
//     })

//     props.onSubmit({
//       processes: selectedDepartment
//     })

//     localStorage.setItem(
//       `processData-${activeProcess}`,
//       JSON.stringify(selectedDepartment)
//     )
//     // Close the modal
//     DetailsHandleClose()
//   }

//   const { data: departmentData } = useGetFullDepartments()

//   const initialValues = localStorage.getItem(`processData-${activeProcess}`)

//   return (
//     <Box>
//       <CustomizedModal
//         isHeadingName={true}
//         heading="Process/Department Details"
//         open={open}
//         onClick={setOpen}
//         width={isMdScreen ? '40%' : '30%'}
//       >
//         <Formik
//           initialValues={{
//             processes: initialValues ? JSON.parse(initialValues) : [{ department: '', name: '', code: '' }]
//           }}
//           enableReinitialize={true}
//           validationSchema={validationSchema}
//           onSubmit={handleFormSubmit}
//         >
//           {({
//             errors,
//             touched,
//             handleChange,
//             values,
//             setFieldValue,
//             handleSubmit
//           }) => (
//             <Form>
//               <FieldArray
//                 name="processes"
//                 render={({ push, remove }) => (
//                   <Box>
//                     {values?.processes?.length > 0 &&
//                       values?.processes?.map((processes, index) => (
//                         <Box key={index} className="process-main-box">
//                           <Grid
//                             container
//                             spacing={2}
//                             sx={{
//                               display: 'flex',
//                               justifyContent: 'flex-start',
//                               alignItems: 'center'
//                             }}
//                           >
//                             <Grid item>{index + 1}</Grid>
//                             <Grid item xs={5.5}>
//                               <FormControl fullWidth>
//                                 <Selects
//                                   name={`processes.${index}.department`}
//                                   variant="outlined"
//                                   value={values?.processes?.[index]?.department}
//                                   onChange={(e) => {
//                                     handleChange(e)
//                                     const selectedDept = departmentData?.data?.find(
//                                       (data) => data?.id === e.target.value
//                                     )
//                                     setFieldValue(
//                                       `processes.${index}.code`,
//                                       generateDepartmentCode(selectedDept?.name)
//                                     )
//                                     // setFieldValue(`processes.${index}.name`, selectedDept?.name);
//                                   }}
//                                   displayEmpty
//                                 >
//                                   <MenuItem value="">
//                                     <span className="menu-item-span">Select Department</span>
//                                   </MenuItem>
//                                   {departmentData?.data?.map((data, ind) => {
//                                     return (
//                                       <MenuItem value={data?.id} key={ind}>
//                                         {data?.name}
//                                       </MenuItem>
//                                     )
//                                   })}
//                                 </Selects>
//                               </FormControl>

//                               {touched.processes?.[index]?.department &&
//                                 errors.processes?.[index]?.department && (
//                                   <Typography className="validation-text">
//                                     {typeof errors.processes?.[index]?.department === 'string'
//                                       ? errors.processes?.[index]?.department
//                                       : ''}
//                                   </Typography>
//                               )}
//                             </Grid>

//                             <Grid className="delete-icon-grid" item xs={1}>
//                               <span
//                                 onClick={() => {
//                                   if (values?.processes?.[index]?.proccessId) {
//                                     props?.setDeletedProcess([
//                                       ...props?.deletedProcess,
//                                       values?.processes?.[index]?.proccessId
//                                     ])
//                                   }
//                                   remove(index)
//                                 }}
//                               >
//                                 <DeleteIconNew />
//                               </span>
//                             </Grid>
//                           </Grid>
//                         </Box>
//                       ))}
//                     <Grid className="add-process">
//                       <CustomButton
//                         variant="outlined"
//                         className="button-box"
//                         type="button"
//                         title="Add"
//                         endIcon={<Plus fill={'#0160B9'} />}
//                         onClick={() =>
//                           push({
//                             department: '',
//                             code: ''
//                           })
//                         }
//                       >
//                         Add
//                       </CustomButton>
//                     </Grid>
//                   </Box>
//                 )}
//               />
//               <Grid className="save-btn">
//                 <CustomButton
//                   className="button-box"
//                   type="submit"
//                   title="Save"
//                   onClick={() => {
//                     handleSubmit(values)
//                   }}
//                 />
//               </Grid>
//             </Form>
//           )}
//         </Formik>
//       </CustomizedModal>
//     </Box>
//   )
// }

// export default ProcessDetails
