import React from 'react'
import { Box, Typography } from '@mui/material'
import './../DelayedJobCard/delay.css'

const DelayJobCard = ({ delayData }) => {
  return (
        <React.Fragment>
            <Box className='main-parent-delay'>
                <Box className='parent-delay'>
                    <Typography className='delay-title'>
                        {delayData?.title}
                    </Typography>
                    <Typography className='delay-title'>
                        {delayData?.number}
                    </Typography>
                </Box>
                <Typography className='title-set'>
                    Delay : <span className='time-set'>{delayData?.time}</span>
                </Typography>
            </Box>
        </React.Fragment>
  )
}

export default DelayJobCard
