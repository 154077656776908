import * as React from 'react'
import PropTypes from 'prop-types'
import LinearProgress from '@mui/material/LinearProgress'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'

function LinearProgressWithLabel (props) {
  return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: '100%', mr: 1, borderRadius: '30px' }}>
                <LinearProgress value={props?.value} variant="determinate" sx={{
                  borderRadius: 5,
                  height: 8,
                  background: '#F9F9F9',
                  '& .MuiLinearProgress-bar1Determinate': {
                    backgroundColor: props?.color
                  }
                }} />
            </Box>
        </Box>
  )
}

LinearProgressWithLabel.propTypes = {
  value: PropTypes.number.isRequired,
  color: PropTypes.string.isRequired
}

const ProgressBar = ({ title = '', value = 0, color = 'red' }) => {
  // ! for future use
  // React.useEffect(() => {
  // const timer = setInterval(() => {
  // setProgress((prevProgress) => (prevProgress >= 100 ? 10 : prevProgress + 10));
  // setProgress(30)
  // }, 800);
  // return () => {
  //     clearInterval(timer);
  // };
  // }, []);

  return (
        <Box sx={{ borderRadius: '10px', background: '#fff', padding: '18px' }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography sx={{ fontSize: '14px', fontWeight: 600, paddingBottom: '10px' }}>
                    {title}
                </Typography>
                <Typography sx={{ color: '#77838F', fontSize: '14px', fontWeight: 400 }}>
                    {value}
                </Typography>
            </Box>
            <LinearProgressWithLabel value={value} color={color} />
        </Box>
  )
}
export default ProgressBar
