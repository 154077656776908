import { Box, CircularProgress, Tab, Typography, useMediaQuery, useTheme } from '@mui/material'
import React, { useState } from 'react'
import Tabs from '@mui/material/Tabs'
import { TabContext, TabPanel } from '@mui/lab'
import AddIcon from '@mui/icons-material/Add'
import CustomButton from '../../leastcomponent/custombutton/custombutton'
import TableComponent from '../../leastcomponent/customtable/customtable'
import { useNavigate } from 'react-router-dom'
import DeleteIconNew from '../../assets/svg/DeleteIconNew'
// import ApprovedModal from '../../modals/jobcardmodal/approvedmodal'
import DeleteWarningModal from '../../modals/deletewarningmodal'
import Kanban from '../../assets/svg/Kanban'
import BreadcrumbNavigation from '../commoncomponents/breadcrumbnavigation'
import { useGetSalesTemMemberApprovedJobCard, useGetSalesTemMemberCompletionJobCard, useGetSalesTemMemberDraftJobCard, useGetSalesTemMemberJobCard, useGetSalesTemMemberOngoingJobCard, useJobSalesteamMember, useJobcardDraft } from '../../hooks/jobcardHooks'
import moment from 'moment'
import { useQueryClient } from '@tanstack/react-query'
import { user } from '../../querykeys/usermanagement'
import EditIconNew from '../../assets/svg/EditIconNew'
import CopyJobcardConfirmModel from '../../modals/jobcardmodal/copyJobcardConfirmModel'
import Copy from '../../assets/svg/Copy'
import JobcardSearchcomponent from './jobcardSearchcomponent'

const SalesTeamHead = () => {
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up('lg'))
  const [page, setPage] = useState(1)
  const [approvedInfoPage, setApprovedInfoPage] = useState(1)
  const [draftInfoPage, setDraftInfoPage] = useState(1)
  // const [number, setNumber] = useState('')
  const [productCode, setProductCode] = useState('')
  const [ongoingInfoPage, setOngoingInfoPage] = useState(1)
  const [completionInfoPage, setCompletionInfoPage] = useState(1)
  const [value, setValue] = React.useState('1')
  // const [isApproved, setIsApproved] = React.useState(false)
  // const [currentRowData, setCurrentRowData] = useState({})
  const [currentId, setCurrentId] = useState('')
  const [draftDeleteId, setDraftDeleteId] = useState('')
  const [deleteRecord, setDeleteRecord] = useState(false)
  const handleDeleteOpen = () => setDeleteRecord(true)
  const handleDeleteClose = () => setDeleteRecord(false)
  const [draftDeleteRecord, setDraftDeleteRecord] = useState(false)
  const handleDraftDeleteOpen = () => setDraftDeleteRecord(true)
  const handleDraftDeleteClose = () => setDraftDeleteRecord(false)
  const [isCnfModalOpen, setIsCnfModalOpen] = React.useState(false)
  const [copyId, setCopyId] = React.useState('')
  // Permission
  const queryClient = useQueryClient()
  const userData = queryClient.getQueryData([user.LOGGED_IN_USER])
  // const departmentGroupTitle = userData?.data?.data?.user?.roleId?.departmentGroup?.title
  const userRoleAccess = userData?.data?.data?.user
  const isAllPermission = userRoleAccess?.userPermissions?.find((data) => data?.userModule === 'all' && data)
  const isAddPermission = userRoleAccess?.userPermissions?.find((data) => data?.userModule === 'Create Job Cards' && data)
  const isApprovePermission = userRoleAccess?.userPermissions?.find((data) => data?.userModule === 'Approve Job Cards' && data)
  const isEditApprovePermission = userRoleAccess?.userPermissions?.find((data) => data?.userModule === 'edit job card after approval' && data)
  const handleChange = (event, newValue) => {
    setValue(newValue)
  }
  const navigate = useNavigate()
  const { mutate: deleteJobSalesteamMember } = useJobSalesteamMember()
  const { mutate: deleteJobcardDraft } = useJobcardDraft()
  const { data: salesTemHeadInfo, isFetching: isSalesTeam } = useGetSalesTemMemberJobCard(
    value === '1',
    page,
    productCode
  )
  // const newPartData = salesTemHeadInfo?.results?.[0]
  // const newPartId = newPartData?.bomparts?.[0]?.bomprocesses?.[0]

  const salesTemHead = salesTemHeadInfo?.results
  const rows = salesTemHead?.map((data, ind) => {
    const startIndex = (page - 1) * 10
    return {
      no: startIndex + ind + 1,
      id: data?.id,
      name: data?.name ? data?.name : '-',
      jobCardNumber: data?.jobCardNumber ? data?.jobCardNumber : '-',
      customerName: data?.customerName ? data?.customerName : '-',
      productName: data?.productName ? data?.productName : '-',
      productCode: data?.productCode ? data?.productCode : '-',
      quantity: data?.quantity ? data?.quantity : '-',
      submitted: data?.submitted ? moment(data?.submitted).format('DD MMM, YYYY') : '-'
    }
  })
  const {
    data: salesTemHeadDraftInfo,
    isFetching: isDraftTeamHead
  } = useGetSalesTemMemberDraftJobCard(value === '4',
    draftInfoPage, productCode)
  // const salesTemHeadDraft = salesTemHeadDraftInfo?.results
  const salesTemHeadDraft = salesTemHeadDraftInfo?.results
  const DraftRows = salesTemHeadDraft?.map((data, ind) => {
    const startIndex = (draftInfoPage - 1) * 10
    return {
      id: data?.id,
      no: startIndex + ind + 1,
      name: data?.name ? data?.name : '-',
      jobCardNumber: data?.jobCardNumber ? data?.jobCardNumber : '-',
      customerName: data?.customerName ? data?.customerName : '-',
      productName: data?.productName ? data?.productName : '-',
      productCode: data?.productCode ? data?.productCode : '-',
      quantity: data?.quantity ? data?.quantity : '-',
      priority: data?.priority ? data?.priority : '-',
      createdAt: data?.createdAt ? moment(data?.createdAt).format('DD MMM, YYYY') : '-'
    }
  })
  const {
    data: salesTemHeadApprovedInfo, isFetching: isSalesTeamHead
  } = useGetSalesTemMemberApprovedJobCard(value === '1',
    approvedInfoPage, productCode)
  const salesTemHeadApproved = salesTemHeadApprovedInfo?.results
  const approvedRows = salesTemHeadApproved?.map((data, ind) => {
    const startIndex = (approvedInfoPage - 1) * 10
    return {
      id: data?.id,
      no: startIndex + ind + 1,
      name: data?.name ? data?.name : '-',
      jobCardNumber: data?.jobCardNumber ? data?.jobCardNumber : '-',
      customerName: data?.customerName ? data?.customerName : '-',
      productName: data?.productName ? data?.productName : '-',
      productCode: data?.productCode ? data?.productCode : '-',
      quantity: data?.quantity ? data?.quantity : '-',
      priority: data?.priority ? data?.priority : '-',
      approveDate: data?.approveDate ? moment(data?.approveDate).format('DD MMM, YYYY') : '-'
    }
  })

  const {
    data: salesTemMemberOngoingInfo, isFetching: isSalesTeamMember
  } = useGetSalesTemMemberOngoingJobCard(value === '2',
    ongoingInfoPage, productCode)

  const salesTemMemberOngoing = salesTemMemberOngoingInfo?.results
  const ongoingRows = salesTemMemberOngoing?.map((data, ind) => {
    const startIndex = (ongoingInfoPage - 1) * 10
    return {
      id: data?.id,
      no: startIndex + ind + 1,
      name: data?.name ? data?.name : '-',
      jobCardNumber: data?.jobCardNumber ? data?.jobCardNumber : '-',
      customerName: data?.customerName ? data?.customerName : '-',
      productName: data?.productName ? data?.productName : '-',
      productCode: data?.productCode ? data?.productCode : '-',
      quantity: data?.quantity ? data?.quantity : '-',
      submitted: data?.submitted ? moment(data?.submitted).format('DD MMM, YYYY') : '-'
      // status: data?.status ? data?.status : '-',
      // expectedCompletion: data?.expectedCompletion ? moment(data?.expectedCompletion).format('DD MMM, YYYY') : '-'
    }
  })
  const {
    data: salesTemMemberCompletionInfo, isFetching: isSalesTeamMemberCompletion
  } = useGetSalesTemMemberCompletionJobCard(value === '3',
    completionInfoPage, productCode)

  const salesTemMemberCmpletion = salesTemMemberCompletionInfo?.results
  const completionRows = salesTemMemberCmpletion?.map((data, ind) => {
    const startIndex = (completionInfoPage - 1) * 10
    return {
      id: data?.id,
      no: startIndex + ind + 1,
      name: data?.name ? data?.name : '-',
      jobCardNumber: data?.jobCardNumber ? data?.jobCardNumber : '-',
      customerName: data?.customerName ? data?.customerName : '-',
      productName: data?.productName ? data?.productName : '',
      productCode: data?.productCode ? data?.productCode : '-',
      quantity: data?.quantity ? data?.quantity : '-',
      submitted: data?.submitted ? moment(data?.submitted).format('DD MMM, YYYY') : '-',
      completionDate: data?.completionDate ? moment(data?.completionDate).format('DD MMM, YYYY') : '-'
    }
  })
  const draftColumns = [
    { field: 'no', headerName: '#', flex: 0.2 },
    {
      field: 'name',
      headerName: 'Job Card Name',
      flex: 1
    },
    {
      field: 'jobCardNumber',
      headerName: 'Job Card Number',
      flex: 1,
      renderCell: (params) => {
        return (
    <Box className="table-icon-new">
        <span
          className="process-delete"
          onClick={() => {
            return navigate(`/job-card-details-view/${params.row.id}?sales`)
          }}
        >
          {params?.row?.jobCardNumber ? params?.row?.jobCardNumber : '-' }
        </span>
    </Box>
        )
      }

    },
    { field: 'customerName', headerName: 'Customer Name', flex: matches ? 1 : 1 },
    {
      field: 'productName',
      headerName: 'Product Name',
      flex: matches ? 1 : 1
    },
    { field: 'productCode', headerName: 'Product Code', flex: 0.7 },
    { field: 'quantity', headerName: 'Quantity', flex: 0.7 },
    // {
    //   field: 'productCode',
    //   headerName: 'Product Code',
    //   flex: matches ? 1 : 1
    // },
    // { field: 'quantity', headerName: 'Quantity', flex: matches ? 1 : 0.7 },
    { field: 'createdAt', headerName: 'Created On', flex: matches ? 1 : 0.7 },
    {
      field: 'actions',
      headerName: 'Actions',
      sortable: false,
      disableClickEventBubbling: true,
      flex: 1.5,
      renderCell: (params) => {
        return (
          <Box className="table-icon-new">
           {
            (isAllPermission || isAddPermission?.edit) &&
               <Box className="table-btn-main">
              <span className="process-delete" onClick={() => navigate(`/edit-job-card/${params.row.id}`)}>
                <EditIconNew />
              </span>
            </Box>
            //   : <Box className="table-btn-main">
            //      <span className="process-delete" // onClick={() => navigate(`/edit-user/${params.row.id}`)}
            // >
            //   <EditIconNew color ="#757575"/>
            // </span>
            // </Box>
      }
        <Box className="table-btn-main">
              <span onClick={() => {
                setDraftDeleteId(params.row.id)
                handleDraftDeleteOpen()
              }}
              className="process-delete">
                <DeleteIconNew />
              </span>
            </Box>
            {/* <Box className="table-btn-main">
              <span
                className="process-delete"
                onClick={() => navigate(`/schedule/${params.row.id}`)}
              >
                <Kanban />
              </span>
            </Box> */}
            {
            (isAllPermission || isAddPermission?.edit)
              ? <Box className="table-btn-main">
              <span
                className="process-delete"
                onClick={() => {
                  setIsCnfModalOpen(true)
                  setCopyId(params.row.id)
                  // addjobcardClone(params.row.id)
                }}
              >
                <Copy />
              </span>
            </Box>
              : <Box className="table-btn-main">
              <span
                className="process-delete"
              >
                <Copy color ="#757575"/>
              </span>
            </Box>
      }
          </Box>
        )
      }
    }
  ]
  const columns = [
    { field: 'no', headerName: '#', flex: 0.2 },
    {
      field: 'name',
      headerName: 'Job Card Name',
      flex: 1
    },
    {
      field: 'jobCardNumber',
      headerName: 'Job Card Number',
      flex: 1,
      renderCell: (params) => {
        return (
    <Box className="table-icon-new">
        <span
          className="process-delete"
          onClick={() => {
            return navigate(`/job-card-details-view/${params.row.id}?sales`)
          }}
        >
          {params?.row?.jobCardNumber ? params?.row?.jobCardNumber : '-' }
        </span>
    </Box>
        )
      }

    },
    { field: 'customerName', headerName: 'Customer Name', flex: matches ? 1 : 1 },
    {
      field: 'productName',
      headerName: 'Product Name',
      flex: matches ? 1 : 1
    },
    { field: 'productCode', headerName: 'Product Code', flex: 0.7 },
    { field: 'quantity', headerName: 'Quantity', flex: 0.7 },
    // {
    //   field: 'productCode',
    //   headerName: 'Product Code',
    //   flex: matches ? 1 : 1
    // },
    // { field: 'quantity', headerName: 'Quantity', flex: matches ? 1 : 0.7 },
    { field: 'submitted', headerName: 'Submitted On', flex: matches ? 1 : 0.7 },
    {
      field: 'actions',
      headerName: 'Actions',
      sortable: false,
      disableClickEventBubbling: true,
      flex: 1.5,
      renderCell: (params) => {
        return (
          <Box className="table-icon-new">
           {(isAllPermission || isApprovePermission?.edit)
             ? <Box className="table-approved">
              <CustomButton
                title="Approve"
                className="only-name-btn"
                // onClick={() => {
                //   setCurrentId(params.row.id)
                //   setNumber(params.row.number)
                //   setCurrentRowData({
                //     id: params.row.id
                //   })
                //   setIsApproved(true)
                // }}approve-job-card
                onClick={() => navigate(`/approve-job-card/${params.row.id}`)}
              />
            </Box>
             : <Box className="table-approved">
             <CustomButton
               title="Approve"
               className="disabled-only-name-btn"
             />
           </Box>}
           {
            (isAllPermission || isAddPermission?.edit) &&
              <Box className="table-btn-main">
              <span className="process-delete" onClick={() => navigate(`/edit-job-card/${params.row.id}`)}>
                <EditIconNew />
              </span>
            </Box>
            //   : <Box className="table-btn-main">
            //      <span className="process-delete" >
            //   <EditIconNew color ="#757575"/>456
            // </span>
            // </Box>
      }
           {
            (isAllPermission || isAddPermission?.edit)
              ? <Box className="table-btn-main">
              <span onClick={() => {
                setCurrentId(params.row.id)
                handleDeleteOpen()
              }}
              className="process-delete">
                <DeleteIconNew />
              </span>
            </Box>
              : <Box className="table-btn-main">
              <span className="process-delete">
                <DeleteIconNew color ="#757575" />
              </span>
            </Box>
      }
            {(isAllPermission || isAddPermission?.edit) &&
            <Box className="table-btn-main">
              <span
                className="process-delete"
                onClick={() =>
                  navigate(`/schedule/${params.row.id}?sales`)
                }
              >
                <Kanban />
              </span>
            </Box>}
            {/* //! here copy item----------- */}
            {
            (isAllPermission || isAddPermission?.edit)
              ? <Box className="table-btn-main">
              <span
                className="process-delete"
                onClick={() => {
                  setIsCnfModalOpen(true)
                  setCopyId(params.row.id)
                }}
              >
                <Copy />
              </span>
            </Box>
              : <Box className="table-btn-main">
              <span
                className="process-delete"
              >
                <Copy color ="#757575"/>
              </span>
            </Box>
      }
          </Box>
        )
      }
    }
  ]
  const approvedColumns = [
    { field: 'no', headerName: '#', flex: 0.2 },
    {
      field: 'name',
      headerName: 'Job Card Name',
      flex: 1
    },
    {
      field: 'jobCardNumber',
      headerName: 'Job Card Number',
      flex: 1,
      renderCell: (params) => {
        return (
  <Box className="table-icon-new">
      <span
        className="process-delete"
        onClick={() => {
          return navigate(`/job-card-details-view/${params.row.id}?sales`)
        }}
      >
        {params?.row?.jobCardNumber ? params?.row?.jobCardNumber : '-' }
      </span>
  </Box>
        )
      }
    },
    { field: 'customerName', headerName: 'Customer Name', flex: 1 },
    { field: 'productName', headerName: 'Product Name', flex: 1 },
    { field: 'productCode', headerName: 'Product Code', flex: 0.7 },
    { field: 'quantity', headerName: 'Quantity', flex: 0.7 },
    { field: 'approveDate', headerName: 'Approved Date', flex: 1 },
    { field: 'priority', headerName: 'Priority', flex: 1 },
    {
      field: 'actions',
      headerName: 'Actions',
      sortable: false,
      disableClickEventBubbling: true,
      flex: 1.2,
      renderCell: (params) => {
        return (
          <Box className="table-icon-new">
              {
            (isAllPermission || isEditApprovePermission) &&
               <Box className="table-btn-main">
              <span className="process-delete" onClick={() => navigate(`/edit-job-card/${params.row.id}`)}>
                <EditIconNew />
              </span>
            </Box>
            //   : <Box className="table-btn-main">
            //      <span className="process-delete">
            //   <EditIconNew color ="#757575"/>
            // </span>
            // </Box>
      }
             {
            (isAllPermission || isApprovePermission?.edit)
              ? <Box className="table-btn-main">
              <span
             onClick={() => {
               setCurrentId(params.row.id)
               handleDeleteOpen()
             }}
              className="process-delete">
                <DeleteIconNew />
              </span>
            </Box>
              : <Box className="table-btn-main">
              <span className="process-delete">
                <DeleteIconNew color ="#757575" />
              </span>
            </Box>}
            {(isAllPermission || isAddPermission?.edit) &&
            <Box className="table-btn-main">
              <span
                className="process-delete"
                onClick={() =>
                  navigate(`/schedule/${params.row.id}?sales`)
                }
              >
                <Kanban />
              </span>
            </Box>}
            {
            (isAllPermission || isAddPermission?.edit)
              ? <Box className="table-btn-main">
              <span
                className="process-delete"
                onClick={() => {
                  setIsCnfModalOpen(true)
                  setCopyId(params.row.id)
                }}
              >
                <Copy />
              </span>
            </Box>
              : <Box className="table-btn-main">
              <span
                className="process-delete"
              >
                <Copy color ="#757575"/>
              </span>
            </Box>
      }
          </Box>
        )
      }
    }
  ]
  const ongoingColumns = [
    { field: 'no', headerName: '#', flex: 0.2 },
    {
      field: 'name',
      headerName: 'Job Card Name',
      flex: 1
    },
    {
      field: 'jobCardNumber',
      headerName: 'Job Card Number',
      flex: 1,
      renderCell: (params) => {
        return (
  <Box className="table-icon-new">
      <span
        className="process-delete"
        onClick={() => {
          return navigate(`/job-card-details-view/${params.row.id}?sales`)
        }}
      >
        {params?.row?.jobCardNumber ? params?.row?.jobCardNumber : '-' }
      </span>
  </Box>
        )
      }
    },
    { field: 'productName', headerName: 'Product Name', flex: 1 },
    { field: 'productCode', headerName: 'Product Code', flex: 1 },
    { field: 'quantity', headerName: 'Quantity', flex: matches ? 1 : 0.7 },
    { field: 'submitted', headerName: 'Submitted Date', flex: 1 },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 1,
      renderCell: (params) => {
        return (
    <Box className="table-icon-new-sale">
      {
            (isAllPermission || isAddPermission?.edit)
              ? <Box className="table-btn-main">
              <span
                className="process-delete"
                onClick={() => {
                  setIsCnfModalOpen(true)
                  setCopyId(params.row.id)

                  // addjobcardClone(params.row.id)
                }}
              >
                <Copy />
              </span>
            </Box>
              : <Box className="table-btn-main">
              <span
                className="process-delete"
              >
                <Copy color ="#757575"/>
              </span>
            </Box>
      }
       {/* {(isAllPermission || isAddPermission?.edit) &&
            <Box className="table-btn-main">
              <span
                className="process-delete"
                onClick={() =>
                  navigate(`/schedule/${params.row.id}?sales`)
                }
              >
                <Kanban />
              </span>
            </Box>} */}
    </Box>
        )
      }
    }
    // { field: 'status', headerName: 'Status', flex: 1 },
    // { field: 'expectedCompletion', headerName: 'Expected Completion', flex: 1 }
  ]
  const completionColumns = [
    { field: 'no', headerName: '#', flex: 0.2 },
    {
      field: 'name',
      headerName: 'Job Card Name',
      flex: 1
    },
    {
      field: 'jobCardNumber',
      headerName: 'Job Card Number',
      flex: 1,
      renderCell: (params) => {
        return (
  <Box className="table-icon-new">
      <span
        className="process-delete"
        onClick={() => {
          return navigate(`/job-card-details-view/${params.row.id}?sales`)
        }}
      >
        {params?.row?.jobCardNumber ? params?.row?.jobCardNumber : '-' }
      </span>
  </Box>
        )
      }
    },
    { field: 'productName', headerName: 'Product Name', flex: 1 },
    { field: 'productCode', headerName: 'Product Code', flex: 1 },
    { field: 'quantity', headerName: 'Quantity', flex: matches ? 1 : 0.7 },
    { field: 'submitted', headerName: 'Submitted Date', flex: 1 },
    { field: 'completionDate', headerName: 'Completion Date', flex: 1 },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 1,
      renderCell: (params) => {
        return (
    <Box className="table-icon-new-sale">
      {
            (isAllPermission || isAddPermission?.edit)
              ? <Box className="table-btn-main">
              <span
                className="process-delete"
                onClick={() => {
                  setIsCnfModalOpen(true)
                  setCopyId(params.row.id)
                  // addjobcardClone(params.row.id)
                }}
              >
                <Copy />
              </span>
            </Box>
              : <Box className="table-btn-main">
              <span
                className="process-delete"
              >
                <Copy color ="#757575"/>
              </span>
            </Box>
      }
        {/* {(isAllPermission || isAddPermission?.edit) &&
            <Box className="table-btn-main">
              <span
                className="process-delete"
                onClick={() =>
                  navigate(`/schedule/${params.row.id}?sales`)
                }
              >
                <Kanban />
              </span>
            </Box>} */}
    </Box>
        )
      }
    }

  ]
  const allJobcardColumns = [
    { field: 'no', headerName: '#', flex: 0.2 },
    {
      field: 'name',
      headerName: 'Job Card Name',
      flex: 1
    },
    {
      field: 'jobCardNumber',
      headerName: 'Job Card Number',
      flex: 1,
      renderCell: (params) => {
        return (
  <Box className="table-icon-new">
      <span
        className="process-delete"
        onClick={() => {
          return navigate(`/job-card-details-view/${params.row.id}?sales`)
        }}
      >
        {params?.row?.jobCardNumber ? params?.row?.jobCardNumber : '-' }
      </span>
  </Box>
        )
      }
    },
    { field: 'productName', headerName: 'Product Name', flex: 1 },
    { field: 'productCode', headerName: 'Product Code', flex: 1 },
    { field: 'quantity', headerName: 'Quantity', flex: matches ? 1 : 0.7 },
    { field: 'submitted', headerName: 'Submitted Date', flex: 1 },
    { field: 'completionDate', headerName: 'Completion Date', flex: 1 },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 1,
      renderCell: (params) => {
        return (
    <Box className="table-icon-new-sale">
      {
            (isAllPermission || isAddPermission?.edit)
              ? <Box className="table-btn-main">
              <span
                className="process-delete"
                onClick={() => {
                  setIsCnfModalOpen(true)
                  setCopyId(params.row.id)
                  // addjobcardClone(params.row.id)
                }}
              >
                <Copy />
              </span>
            </Box>
              : <Box className="table-btn-main">
              <span
                className="process-delete"
              >
                <Copy color ="#757575"/>
              </span>
            </Box>
      }
        {/* {(isAllPermission || isAddPermission?.edit) &&
            <Box className="table-btn-main">
              <span
                className="process-delete"
                onClick={() =>
                  navigate(`/schedule/${params.row.id}?sales`)
                }
              >
                <Kanban />
              </span>
            </Box>} */}
    </Box>
        )
      }
    }

  ]
  return (
    <React.Fragment>
      <Box className="production-team-head-overflow-hidden">
        <BreadcrumbNavigation
          breadCrumbData={null}
          pageTitle={'Job Cards'}
          backToLink={null}
        />
        <Box className="production-team-head-overflow-auto">
        <Box className="search-component-nav" sx={{ display: 'flex', justifyContent: 'flex-end', padding: '10px 50px 0px 0px' }}>
            <JobcardSearchcomponent
            setProductCode={setProductCode}
            productCode={productCode}
            />
            </Box>
          <TabContext value={value}>
            <Box
              sx={{
                typography: 'body1'
              }}
              className="tab-content-padding"
            >
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="lab API tabs example"
              >
                <Tab
                  className="tab-head"
                  label="Draft"
                  value="4"
                /> <Tab
                  className="tab-head"
                  label="Submitted & Approved"
                  value="1"
                />
                <Tab className="tab-head" label="Ongoing" value="2" />
                <Tab className="tab-head" label="Completed" value="3" />
                {/* <Tab className="tab-head" label="Find" value="5" /> */}
              </Tabs>
            </Box>
            <TabPanel value="4" className="tab-panel-padding">
              <Box>
                <Box className="common-parent-haeding-btn-div">
                  <Typography className="common-tabel-heading">
                  Draft Job cards
                  </Typography>
                  { (isAddPermission?.edit || isAllPermission) &&
                   <CustomButton
                    variant="contained"
                    title="Add Job Card"
                    type="button"
                    endIcon=""
                    onClick={() => {
                      navigate('/add-job-card')
                    }}
                    startIcon={<AddIcon />}
                  />
                  }
                </Box>
             {!isDraftTeamHead
               ? <TableComponent
               rows={DraftRows}
                columns={draftColumns}
                //  detailsRedirection={'/details-card'}
                page={draftInfoPage}
                setPage={setDraftInfoPage}
                  totalResults={salesTemHeadDraftInfo?.totalResults}
                  paginationData={salesTemHeadDraftInfo}/>
               : <Box className='progress-parent'>
                  <CircularProgress />
                </Box>}

              </Box>
            </TabPanel><TabPanel value="1" className="tab-panel-padding">
              <Box>
                <Box className="common-parent-haeding-btn-div">
                  <Typography className="common-tabel-heading">
                    Submitted Job cards
                  </Typography>
                  { (isAddPermission?.edit || isAllPermission) &&
                   <CustomButton
                    variant="contained"
                    title="Add Job Card"
                    type="button"
                    endIcon=""
                    onClick={() => {
                      navigate('/add-job-card')
                    }}
                    startIcon={<AddIcon />}
                  />
                  }
                </Box>
             {!isSalesTeam
               ? <TableComponent
               rows={rows}
                columns={columns}
                //  detailsRedirection={'/details-card'}
                  setPage={setPage}
                  page={page}
                  totalResults={salesTemHeadInfo?.totalResults}
                  paginationData={salesTemHeadInfo}/>
               : <Box className='progress-parent'>
                  <CircularProgress />
                </Box>}

              </Box>
         <Box>
                <Box className="common-parent-haeding-btn-div">
                  <Typography className="common-tabel-heading">
                    Approved Job cards
                  </Typography>
                </Box>
               {!isSalesTeamHead
                 ? <TableComponent rows={approvedRows} columns={approvedColumns}
                //  detailsRedirection={'/details-card'}
                  page={approvedInfoPage}
                  setPage={setApprovedInfoPage}
                 totalResults={salesTemHeadApprovedInfo?.totalResults}
                 paginationData={salesTemHeadApprovedInfo}/>
                 : <Box className='progress-parent'>
                  <CircularProgress />
                </Box>}
              </Box>
            </TabPanel>
            <TabPanel value="2" className="tab-panel-padding">
              <Box>
                <Box className="common-parent-haeding-btn-div">
                  <Typography className="common-tabel-heading">
                    Ongoing Job cards
                  </Typography>
                  {/* { (isAddPermission?.edit || isAllPermission) &&
                  <CustomButton
                    variant="contained"
                    title="Add Job Card"
                    onClick={() => {
                      navigate('/add-job-card')
                    }}
                    type="button"
                    endIcon=""
                    startIcon={<AddIcon />}
                  />
} */}
                </Box>
                {!isSalesTeamMember
                  ? <TableComponent rows={ongoingRows} columns={ongoingColumns} page={ongoingInfoPage}
                  setPage={setOngoingInfoPage}
                 totalResults={salesTemMemberOngoingInfo?.totalResults}
                 paginationData={salesTemMemberOngoingInfo}/>
                  : <Box className='progress-parent'>
                  <CircularProgress />
                </Box>}
              </Box>
            </TabPanel>
            <TabPanel value="3" className="tab-panel-padding">
              <Box>
                <Box className="common-parent-haeding-btn-div">
                  <Typography className="common-tabel-heading">
                    Completed Job cards
                  </Typography>
                  {/* { (isAddPermission?.edit || isAllPermission) &&
                  <CustomButton
                    variant="contained"
                    title="Add Job Card"
                    onClick={() => {
                      navigate('/add-job-card')
                    }}
                    type="button"
                    endIcon=""
                    startIcon={<AddIcon />}
                  />
} */}
                </Box>
               {!isSalesTeamMemberCompletion
                 ? <TableComponent
                  rows={completionRows}
                  columns={completionColumns}
                  page={completionInfoPage}
                  setPage={setCompletionInfoPage}
                 totalResults={salesTemMemberCompletionInfo?.totalResults}
                 paginationData={salesTemMemberCompletionInfo}/>
                 : <Box className='progress-parent'>
                <CircularProgress />
              </Box>}
              </Box>
            </TabPanel>
             <TabPanel value="5" className="tab-panel-padding">
              <Box>
                <Box className="common-parent-haeding-btn-div">
                  <Typography className="common-tabel-heading">
                   All Job cards
                  </Typography>
                  {/* { (isAddPermission?.edit || isAllPermission) &&
                  <CustomButton
                    variant="contained"
                    title="Add Job Card"
                    onClick={() => {
                      navigate('/add-job-card')
                    }}
                    type="button"
                    endIcon=""
                    startIcon={<AddIcon />}
                  />
} */}
                </Box>
               {!isSalesTeamMemberCompletion
                 ? <TableComponent
                  rows={completionRows}
                  columns={allJobcardColumns}
                  page={completionInfoPage}
                  setPage={setCompletionInfoPage}
                 totalResults={salesTemMemberCompletionInfo?.totalResults}
                 paginationData={salesTemMemberCompletionInfo}/>
                 : <Box className='progress-parent'>
                <CircularProgress />
              </Box>}
              </Box>
            </TabPanel>
          </TabContext>
        </Box>
        {/* {isApproved && (
          <ApprovedModal
            open={isApproved}
            title="Approved"
            handleClose={() => setIsApproved(false)}
            data={currentRowData}
            number={number}
          />
        )} */}
          {isCnfModalOpen &&
            <CopyJobcardConfirmModel
              //  jcNum={jcNum}
              open={isCnfModalOpen}
              id={copyId}
              handleClose={() => setIsCnfModalOpen(false)}/>}

        {/* {value === '1' && deleteRecord && currentId && ( */}
        {value === '1' && deleteRecord && currentId && (
          <DeleteWarningModal
            open={deleteRecord}
            handleOpen={handleDeleteOpen}
            handleClose={handleDeleteClose}
            handleDelete={deleteJobSalesteamMember}
            id={currentId}
          />
        )}
        {value === '4' && draftDeleteRecord && draftDeleteId && (
          <DeleteWarningModal
            open={draftDeleteRecord}
            handleOpen={handleDraftDeleteOpen}
            handleClose={handleDraftDeleteClose}
            handleDelete={deleteJobcardDraft}
            id={draftDeleteId}
          />
        )}
      </Box>
    </React.Fragment>
  )
}

export default SalesTeamHead
