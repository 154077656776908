import React from 'react'
import moment from 'moment'
import DateRangePicker from 'react-bootstrap-daterangepicker'
import 'bootstrap-daterangepicker/daterangepicker.css'
import { Box, styled } from '@mui/material'
import InsertInvitationIcon from '@mui/icons-material/InsertInvitation'
const BoxWrapper = styled(Box)(() => ({
  '.date-input': {
    width: '228px',
    height: '32px',
    border: '1px solid #D2D1D1',
    borderRadius: '4px',
    padding: '18px'
  }
}))

const DateRange = (props) => {
  const { dates, setDates } = props

  const ranges = {
    Today: [moment(), moment()],
    Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    Tomorrow: [moment().add(1, 'days'), moment().add(1, 'days')],
    'Last Week': [
      moment().subtract(1, 'week').startOf('week'),
      moment().subtract(1, 'week').endOf('week')
    ],
    'This Week': [moment().startOf('week'), moment().endOf('week')],
    'Next Week': [
      moment().add(1, 'week').startOf('week'),
      moment().add(1, 'week').endOf('week')
    ],
    'Current Month': [moment().startOf('month'), moment().endOf('month')]
  }
  return (
    <BoxWrapper>
      <DateRangePicker
        onApply={setDates}
        initialSettings={{
          startDate: dates?.startDate ? new Date(dates?.startDate) : new Date(),
          endDate: dates?.endDate ? new Date(dates?.endDate) : new Date(),
          ranges,
          formate: 'YYYY/MM/DD'
        }}
      >
        <div style={{ position: 'relative' }}>
          <input
            type="text"
            value={dates?.startDate + ' to ' + dates?.endDate}
            defaultValue={dates?.startDate + ' to ' + dates?.endDate}
            className="date-input form-control"
            placeholder="enter your date"
            style={{ height: '40px', width: '228px' }}
          />
          <InsertInvitationIcon
            className="calendar-icon"
            style={{
              position: 'absolute',
              right: '10px',
              top: '10px',
              color: 'grey',
              cursor: 'pointer'
            }}
          />
        </div>
      </DateRangePicker>
    </BoxWrapper>
  )
}
export default DateRange
