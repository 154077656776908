import { deleteRequest, get, patch } from '../utils'

export const getDeletedItems = async () => {
  return get('/deleted-items')
}

export const deleteAllItems = async () => {
  return deleteRequest('/deleted-items')
}

export const restoreAllItems = async () => {
  return patch('/deleted-items')
}
export const restoreSelectedItems = async (data) => {
  return patch('/deleted-items/selected-restore', data)
}
export const deletedItemsApi = {
  getDeletedItems,
  deleteAllItems,
  restoreAllItems,
  restoreSelectedItems
}
