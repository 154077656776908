import { Box } from '@mui/material'
import React from 'react'
import DepartmentFactoryLayout from './departmentFactoryLayout'
const JobCards = () => {
  return (
    <React.Fragment>
      <Box className="">
        <DepartmentFactoryLayout />
      </Box>
    </React.Fragment>
  )
}

export default JobCards
