import { Box } from '@mui/material'
import React from 'react'
// TODO : for future use in job card module job card option
// import SalesTeamMember from './salesTeamMember'
// import SalesTeamHead from './salesteamhead'
import ProductionTeamMember from './productionteammember'
import ProductionTeamHead from './productionteamhead'
import UpdateStatus from './updatestatusoftask'
const JobCardsDepartment = () => {
  // TODO : for future use in job card module job card option
  // const userName = 'salesTeamMember'
  // const userName = "salesTeamHead";
  // const userName = 'productionTeamMember'
  // const userName = 'productionTeamHead'
  const userName = 'productionTeamMember'

  return (
    <React.Fragment>
      <Box className="">
        {
          // TODO : for future use in job card module job card option
        // userName === 'salesTeamMember'
        //   ? (
        //   <SalesTeamMember />
        //     )
        //   : userName === 'salesTeamHead'
        //     ? (
        //   <SalesTeamHead />
        //       )
        //     :
             userName === 'productionTeamMember'
               ? (
          <ProductionTeamMember />
                 )
               : userName === 'productionTeamHead'
                 ? (
          <ProductionTeamHead user="productionTeamHead" />
                   )
                 : userName === 'updateStatus'
                   ? (
          <UpdateStatus />
                     )
                   : (
          <React.Fragment></React.Fragment>
                     )}
      </Box>
    </React.Fragment>
  )
}

export default JobCardsDepartment
