import React from 'react'
import './factoryLayout.css'
import { Box, CircularProgress, Divider, Grid, MenuItem, Select, Typography } from '@mui/material'
import { FieldArray, Form, Formik } from 'formik'
import CustomInput from '../../leastcomponent/custominput/input'
import CustomButton from '../../leastcomponent/custombutton/custombutton'
import Plus from '../../assets/svg/Plus'
import * as Yup from 'yup'
import { AiOutlineInfoCircle } from 'react-icons/ai'
import FormControl from '@mui/material/FormControl'
import BreadcrumbNavigation from '../commoncomponents/breadcrumbnavigation'
import {
  useAddDepartmentGroup,
  useGetDeletedDepartmerntGroup,
  useGetFullDepartments,
  useGetSingleDepartmentsGroup,
  useHardDeleteDepartmentGroup,
  useRestoreDepartmentGroup,
  useUpdateDepartmentGroup
} from '../../hooks/departmentHooks'
import ErrorPopup from '../../modals/errorpopup'
import { useLocation, useParams } from 'react-router-dom'
import DeleteIconNew from '../../assets/svg/DeleteIconNew'
import { styled } from '@mui/material/styles'
import Required from '../commoncomponents/required'
const Selects = styled(Select)(() => ({
  borderRadius: '4px !important',
  '.MuiOutlinedInput-input': {
    padding: '8.5px 14px',
    fontSize: '14px !important'
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: '#8A8A8'
  }
}))
const AddDepartmentGroupForm = () => {
  const { id } = useParams()
  const { data: departmentData } = useGetFullDepartments()
  const icon = <AiOutlineInfoCircle color="red" />
  //  delete items
  const location = useLocation()
  const isDeleted = location.pathname.startsWith('/deleted-items')
  const isRestore = location.pathname.startsWith('/deleted-items')
  const validationSchema = Yup.object().shape({
    title: Yup.string().required('Department group name is required'),
    departments: Yup.array().of(Yup.string().required('Department name is required'))
  })

  const [isErrorModalOpen, setIsErrorModalOpen] = React.useState(false)
  const handleErrorModalClose = () => setIsErrorModalOpen(false)
  const [errorMessage, setErrorMessage] = React.useState('')

  const { mutate: hardDeleteDepartmentGroup } = useHardDeleteDepartmentGroup()
  const { mutate: restoreDepartmentGroup } = useRestoreDepartmentGroup()

  const handlePartDelete = () => {
    if (isDeleted) {
      hardDeleteDepartmentGroup(id)
    } else {
      // deleteSinglePart(id)
    }
  }
  const handlePartRestore = () => {
    if (isRestore) {
      restoreDepartmentGroup(id)
    } else {
      // nothing to restore
    }
  }
  const {
    mutate: addDepartmentGroup,
    isError: isAddDepartmentError,
    error: addDepartmentError,
    isLoading
    // isSuccess: isAddDepartmentSuccess,
  } = useAddDepartmentGroup()

  const {
    mutate: updateDepartmentGroup,
    isError: isUpdateDepartmentError,
    error: updateDepartmentError
  } = useUpdateDepartmentGroup()

  const { data: singleDepartmentGroup, isFetching: isSingleDepartmentGroup } = useGetSingleDepartmentsGroup(
    id, !!id && !isDeleted
  )
  const { data: getDepartmentGroupDeleted } = useGetDeletedDepartmerntGroup(
    id,
    !!id && isDeleted
  )
  const singleDepartmentGroupData = isDeleted ? getDepartmentGroupDeleted?.data : singleDepartmentGroup

  React.useEffect(() => {
    if (isAddDepartmentError || isUpdateDepartmentError) {
      setErrorMessage(
        isAddDepartmentError
          ? addDepartmentError?.data?.message
          : updateDepartmentError?.data?.message
      )
      setIsErrorModalOpen(true)
    }
  }, [
    isAddDepartmentError,
    isUpdateDepartmentError,
    addDepartmentError,
    updateDepartmentError
  ])

  const departmentsData = singleDepartmentGroupData?.departments?.map(
    (data) => {
      return {
        departmentName: data
      }
    }
  )
  const breadCrumbData = []
  const deptId = departmentsData?.map((data) => data?.departmentName)
  return (
    <Box className="production-team-head-overflow-hidden">
      <BreadcrumbNavigation
        {...(isDeleted && { breadCrumbData })}
        pageTitle={isDeleted ? 'Deleted Department Group' : id ? 'Edit Department Group' : 'Create Department Group'}
        backToLink={'/factory-layout'}
        activeTab="1"
        isRestore={true}
        isDeleted={true}
        handleDelete={handlePartDelete}
        handleRestore={handlePartRestore}
      />

      {!isSingleDepartmentGroup
        ? <Box className="production-team-head-overflow-auto ">
        <Formik
          enableReinitialize={true}
          initialValues={{
            title: id ? singleDepartmentGroupData?.title : '',
            departments: id
              ? deptId
              : [
                ]
          }}
          onSubmit={(values) => {
            const addDepartmentGroupValues = {
              title: values.title,
              departments: values.departments
            }

            if (id) {
              updateDepartmentGroup({ ...addDepartmentGroupValues, id })
            } else {
              addDepartmentGroup(addDepartmentGroupValues)
            }
          }}
          validationSchema={validationSchema}
        >
          {({ errors, setFieldValue, handleChange, touched, values }) => (
            <React.Fragment>
              <Form className="form-perent">
                <Box className="create-dep-form-child">
                  <Box>
                    <Box>
                      <Typography
                        className="add-form-heading-1"
                      >
                        {isDeleted ? 'Deleted Department Group' : id ? 'Edit Department Group' : 'Create Department Group'}
                      </Typography>
                    </Box>
                    <Box className="tab-content-padding">
                      <Grid
                        spacing={2}
                        container
                      >
                        <Grid item xs={12} md={6} xl={4}>
                          <Box>
                            <CustomInput
                              label="Department Group"
                              placeholder="Enter Department Group name"
                              handleChange={handleChange}
                              value={values?.title}
                              name="title"
                              InputProps={{
                                endAdornment:
                                  touched?.title && errors?.title && icon
                              }}
                              errors={errors?.title}
                              touched={touched?.title}
                            />
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                  <Box className="divider-content-padding">
                    <Divider />
                  </Box>
                 {!isDeleted && <> <Box>
                    <Typography
                      className="add-form-heading-2"
                    >
                      Add Department
                    </Typography>
                  </Box>
                  <FieldArray
                    name="departments"
                    render={({ push, remove }) => (
                      <Box>
                        {values?.departments?.length > 0 &&
                          values?.departments?.map((tasks, index) => (
                            <Box className="tab-content-padding" key={index}>
                              <Grid spacing={2} container >
                                <Grid
                                  item
                                  xs={12}
                                  md={6}
                                  xl={4}
                                  // lg={4}

                                >
                                  <Box>
                                    <Typography className="field-title">
                                      Add Department
                                      <Required />
                                    </Typography>
                                    <FormControl fullWidth>
                                      <Selects
                                        name={`departments.${index}`}
                                        variant="outlined"
                                        value={
                                          departmentData?.data?.length > 0
                                            ? values?.departments?.[index]
                                            : ''}
                                        onChange={handleChange}
                                        displayEmpty
                                      >
                                        <MenuItem values="">
                                          <span className="menu-item-span">
                                            Select Department
                                          </span>
                                        </MenuItem>

                                        {departmentData?.data?.map((data, ind) => {
                                          return (
                                            <MenuItem value={data?.id} key={ind}>
                                              {data?.name}
                                            </MenuItem>
                                          )
                                        })}
                                      </Selects>
                                    </FormControl>

                                    {touched.departments?.[index] && errors.departments?.[index] && (
                                      <Typography className="validation-text">
                                          {errors.departments}
                                      </Typography>
                                    )}
                                  </Box>
                                </Grid>
                                <Grid className="delete-icon-grid" item xs={1} marginBottom={'11px'}>
                                  <span
                                    onClick={() => remove(index)}
                                    className="process-delete"
                                  >
                                    <DeleteIconNew />
                                  </span>
                                </Grid>
                              </Grid>
                            </Box>

                          ))}
                        <Grid>
                       {/* { !values?.departments?.length > 0 && */}
                        <Box className="tab-content-padding">
                            <CustomButton
                              variant="outlined"
                              className="button-box"
                              type="button"
                              title="Add"
                              endIcon={<Plus fill={'#0160B9'} />}
                              onClick={() => push()}
                            />
                          </Box>
                        </Grid>
                      </Box>
                    )}
                  /> </>}
                </Box>
                <Box className="button-child">
                  <CustomButton
                    disabled={isLoading}
                  title={id ? 'Update' : 'Save'} type="submit" />
                </Box>
              </Form>
            </React.Fragment>
          )}
        </Formik>
        {isErrorModalOpen && (
          <ErrorPopup
            isOpen={isErrorModalOpen}
            handleClose={handleErrorModalClose}
            errorDetails={errorMessage}
          />
        )}
      </Box>
        : <Box className='machine-kanban-progress'>
                <CircularProgress />
              </Box>}
    </Box >
  )
}

export default AddDepartmentGroupForm
