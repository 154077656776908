/* eslint-disable react/jsx-key */
import { Box, Grid } from '@mui/material'
import React, { useState } from 'react'
import BreadcrumbNavigation from '../commoncomponents/breadcrumbnavigation'
import './jobslipsnumber.css'
import { useJobSlipsSingle } from '../../hooks/jobslipshooks'
import { useParams } from 'react-router-dom'
import moment from 'moment'
import NoData from '../../leastcomponent/noAddedYet'
import './print.css' // Import print-specific CSS
import { useGetDepartment } from '../../hooks/departmentHooks'

const PrintJobSlipsNumber = () => {
  const [first, setfirst] = useState('')
  const selectDepartmentid = localStorage.getItem('selectDepartmentid')

  const { id } = useParams()
  const { data: getjobSlipsSingle } = useJobSlipsSingle(id, !!id, selectDepartmentid)
  const newBasicJobSlipsData = getjobSlipsSingle?.data?.basicDetail
  const { data: deptData } = useGetDepartment(selectDepartmentid, !!selectDepartmentid)

  return (
    <React.Fragment>
      <Box>
        <BreadcrumbNavigation
          pageTitle={`Job Slip Number: ${newBasicJobSlipsData?.jobSlipNumber ? newBasicJobSlipsData?.jobSlipNumber : ''}`}
          backToLink={'/job-slips'}
          setfirst={setfirst}
          first={first}
          isDepartment={true}
          selectedDepartment={selectDepartmentid}
        />
        {!selectDepartmentid
          ? (
          <Box paddingTop={'350px'}>
            <NoData label='Please select department'/>
          </Box>
            )
          : (
          <Box className="print-job-slips-overflow-auto">
            <Box className="print-jobslips-number">
              {`Job Slip Number: ${newBasicJobSlipsData?.jobSlipNumber ? newBasicJobSlipsData?.jobSlipNumber : ''}`}
            </Box>
            <Box className="job-slips-main-border" mb={5}>
              <Grid container className="job-slips-grid-section">
                <Grid item lg={3} md={3} >
                  <Box className="job-slips-add-task-header">
                    Process/Department
                  </Box>
                  <Box className="job-slips-with-semibold">
                    {deptData?.name ? deptData?.name : '-'}
                  </Box>
                </Grid>
                <Grid item lg={3} md={3.5}>
                  <Box className="job-slips-add-task-header">Date</Box>
                  <Box className="job-slips-with-semibold">
                    {newBasicJobSlipsData?.Timeline
                      ? moment.utc(newBasicJobSlipsData?.Timeline)?.format('DD/MM/YY')
                      : '-'}
                  </Box>
                </Grid>
                <Grid item lg={3} md={2}>
                  <Box className="job-slips-add-task-header">Shift</Box>
                  <Box className="job-slips-with-semibold">
                    {newBasicJobSlipsData?.shift ? newBasicJobSlipsData?.shift : '-'}
                  </Box>
                </Grid>
              </Grid>
            </Box>
            {getjobSlipsSingle?.data?.taskList?.map((data, ind) => {
              const rows = data?.task?.map((data1, ind) => {
                return {
                  no: ind + 1,
                  // id: data1?.id,
                  name: data1?.taskNickName ? data1.taskNickName : '-',
                  taskNumber: data1?.taskNumber ? data1.taskNumber : '-',
                  taskType: data1?.taskType?.name ? data1?.taskType?.name : '-',
                  partName: data1?.bompart?.partName ? data1?.bompart?.partName : '-',
                  qty: data1?.jobcard?.quantity ? data1?.jobcard.quantity : '-',
                  description: data1?.bompart?.description ? data1?.bompart?.description : '-',
                  jobCardNo: data1?.jobcard?.jobCardNumber ? data1?.jobcard?.jobCardNumber : '-',
                  allocatedTime: data1?.allocatedTime,
                  drawing: data1?.bompart?.drawingNumber ? data1?.bompart?.drawingNumber : '-'
                }
              })
              const columns = [
                'No',
                'Name',
                'Task Number',
                'Task Type',
                'Part Name',
                'Qty',
                'Description',
                'Job Card No',
                'Allocated Time',
                'Drawing'
              ]
              return (
                <React.Fragment key={ind}>
                  <Box className="job-slips-card-box">
                    {data?.name}
                  </Box>
                  <Grid container className="job-slips-main-margin" mb={5}>
                    <Grid item lg={3} md={3.5} className="job-slips-task-assigned">
                      <Box className="job-slips-add-task-header">
                        No. of Tasks assigned:
                      </Box>
                      <Box className="job-slips-with-semibold">
                        {data?.numberOfTasks}
                      </Box>
                    </Grid>
                    <Grid item lg={3} md={3.5} className="job-slips-task-assigned">
                      <Box className="job-slips-add-task-header">
                        Total Qty assigned:
                      </Box>
                      <Box className="job-slips-with-semibold">
                        {data?.totalQty}
                      </Box>
                    </Grid>
                    <Grid item lg={3} md={5} className="job-slips-task-assigned">
                      <Box className="job-slips-add-task-header">
                        Total time assigned:
                      </Box>
                      <Box className="job-slips-with-semibold">{data?.totalTime} minutes</Box>
                    </Grid>
                  </Grid>
                  <Box className="print-table" width={'100%'}>
                    <table className='table-border-print' width={'100%'}>
      <thead>
        <tr >
          {columns.map((column) => (
            <th style={{ textAlign: 'center', padding: '5px' }} className='table-border-print-th' key={column}>{column}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {rows.map((row) => (
          <tr key={row.id || row.no} >
            {Object.keys(row).map((key) => (
              <td style={{ textAlign: 'center', padding: '5px' }} className='table-border-print-td' key={key}>{row[key]}</td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
                  </Box>
                </React.Fragment>
              )
            })}
          </Box>
            )}
      </Box>
    </React.Fragment>
  )
}

export default PrintJobSlipsNumber
