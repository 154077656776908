// this is use for mobile
import { deleteRequest, get, patch, post } from '../utils/index'

export const getSingleRole = async (data) => {
  const id = data?.queryKey[1]
  return get(`/user-role/${id}`).then((res) => res.data)
}
export const addRole = async (data) => {
  return post('/user-role', data).then((res) => res.data)
}
export const updateRole = async (data) => {
  const id = data?.id
  delete data.id
  return patch(`/user-role/${id}`, data)
}
export const deleteRole = async (id) => {
  return deleteRequest(`/user-role/${id}`)
}
export const restoreRole = async (id) => {
  return patch(`/user-role/restore/${id}`)
}
export const getSingleDeletedRole = async (data) => {
  const id = data?.queryKey[1]
  return get(`/user-role/deleted/${id}`)
}

export const hardDeleteRole = async (id) => {
  return deleteRequest(`/user-role/hard-delete/${id}`)
}

export const getRoles = async (data) => {
  const page = data?.queryKey[1] || 1
  const payload = { page, limit: 10 }
  return get('/user-role', payload)
}
export const getFullRoles = async () => {
  return get('/user-role/full-list')
}
export const userRoleAPI = {
  deleteRole,
  getSingleRole,
  updateRole,
  getRoles,
  addRole,
  restoreRole,
  hardDeleteRole,
  getSingleDeletedRole,
  getFullRoles
}
