import React from 'react'

const PendingsActive = () => {
  return (
    <React.Fragment>
      <div>
        <svg width="12" height="18" viewBox="0 0 12 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" clipRule="evenodd" d="M6.3771 9.49842C6.1548 9.66661 5.848 9.66725 5.625 9.5L0.875 5.9375C0.875 5.9375 0.638741 5.75803 0.507779 5.49611C0.507779 5.49611 0.376817 5.23419 0.375012 4.94135L0.375 2.125C0.375 2.125 0.375 1.60723 0.741117 1.24112C0.741117 1.24112 1.10723 0.875 1.625 0.875H10.375C10.375 0.875 10.8928 0.875 11.2589 1.24112C11.2589 1.24112 11.625 1.60723 11.625 2.125V4.90625C11.625 4.90625 11.6232 5.20294 11.4922 5.46486C11.4922 5.46486 11.3613 5.72679 11.1281 5.90393L6.3771 9.49842ZM10.372 4.9085C10.372 4.9085 10.3734 4.90741 10.3742 4.90584L10.3748 4.90512L10.375 4.90624V2.125H1.625L1.62499 4.93365C1.62499 4.93365 1.625 4.93547 1.62581 4.93709L1.62604 4.93799C1.62604 4.93799 1.62586 4.93815 1.62501 4.9375L5.99836 8.21752L10.372 4.9085Z" fill="#2196F3" />
          <path fillRule="evenodd" clipRule="evenodd" d="M11.1281 12.0961C11.1281 12.0961 11.3613 12.2732 11.4922 12.5351C11.4922 12.5351 11.6232 12.7971 11.625 13.0938V15.875C11.625 15.875 11.625 16.3928 11.2589 16.7589C11.2589 16.7589 10.8928 17.125 10.375 17.125H1.625C1.625 17.125 1.10723 17.125 0.741117 16.7589C0.741117 16.7589 0.375 16.3928 0.375 15.875L0.375012 13.0586C0.375012 13.0586 0.376817 12.7658 0.507779 12.5039C0.507779 12.5039 0.63874 12.242 0.875 12.0625L5.625 8.5C5.848 8.33275 6.1548 8.33339 6.3771 8.50158L11.1281 12.0961ZM10.3742 13.0942C10.3742 13.0942 10.3734 13.0926 10.372 13.0915L5.99836 9.78248L1.62501 13.0625L1.62604 13.062L1.62581 13.0629C1.62581 13.0629 1.625 13.0645 1.62499 13.0664L1.625 15.875H10.375V13.0938L10.3748 13.0949C10.3747 13.0948 10.3743 13.0945 10.3742 13.0942Z" fill="#2196F3" />
        </svg>
      </div>
    </React.Fragment>
  )
}

export default PendingsActive
