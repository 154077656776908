import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom'
import { departmentApi } from '../api/department'
import { department } from '../querykeys/department'

export const useAddDepartment = () => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  return useMutation(departmentApi.addDepartment, {
    onSuccess: () => {
      queryClient.invalidateQueries([department.DEPARTMENT])
      navigate('/factory-layout', { state: { activeTab: '2' } })
    },
    onError: (err) => {
      return err
      // ! TODO: for future implementation toast
      // toast.error(errData.message);
    }
  })
}

export const useGetDepartment = (id, isEnable = true) => {
  return useQuery(
    [department.DEPARTMENT, id],
    departmentApi.getSingleDepartment,
    {
      select: (data) => data?.data,
      enabled: isEnable,
      // ! TODO: for future implementation toast

      // onSuccess: () => {
      //   // const data = res?.data;
      // },
      // onError: () => {
      //   // const errData = err.data;
      //   // toast.error(errData.message);
      // },
      refetchOnWindowFocus: false
    }
  )
}

export const useGetDepartments = (isEnable = false, page) => {
  return useQuery(
    [department.DEPARTMENTS, page],
    departmentApi.getDepartments,
    {
      enabled: isEnable,
      select: (data) => data?.data,
      // ! TODO: for future implementation toast
      // onSuccess: () => {
      //   // const data = res?.data;
      // },
      onError: (err) => {
        return err
        // const errData = err?.data;
        // toast.error(errData.message);
      }
    }
  )
}
export const useGetFullDepartments = () => {
  return useQuery(
    [department.DEPARTMENT + 'FULL'],
    departmentApi.getFullDepartment,
    {
      select: (data) => data?.data,
      onError: (err) => {
        return err
      }
    }
  )
}
export const useGetFullDepartmentsGroup = () => {
  return useQuery(
    [department.DEPARTMENT + 'FULL'],
    departmentApi.getFullDepartmentGroup,
    {
      select: (data) => data?.data,
      onError: (err) => {
        return err
      }
    }
  )
}

export const useDeleteDepartment = () => {
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  return useMutation(departmentApi.deleteDepartment, {
    onSuccess: (res, id) => {
      queryClient.invalidateQueries([department.DEPARTMENT])
      queryClient.invalidateQueries([department.DEPARTMENT, id])
      // ! TODO: for future implementation toast
      // toast.success(data?.message);
      navigate('/factory-layout', { state: { activeTab: '2' } })
    },
    onError: () => {
      // toast.error(errData?.message);
    }
  })
}

export const useDeleteDepartmentGroup = () => {
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  return useMutation(departmentApi.deleteDepartmentGroup, {
    onSuccess: (res, id) => {
      queryClient.invalidateQueries([department.DEPARTMENT])
      queryClient.invalidateQueries([department.DEPARTMENT, id])
      // ! TODO: for future implementation toast
      // toast.success(data?.message);
      navigate('/factory-layout', { state: { activeTab: '1' } })
    },
    onError: () => {
      // toast.error(errData?.message);
    }
  })
}

export const useRestoreDepartment = () => {
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  return useMutation(departmentApi.restoreDepartment, {
    onSuccess: () => {
      queryClient.invalidateQueries([department.DEPARTMENTS])
      // ! TODO: for future implementation toast
      // queryClient.invalidateQueries([part.PART, id]);
      // toast.success(data.message);
      navigate('/deleted-items')
    }
    // ! TODO: for future implementation toast
    // onError: (err: any) => {
    //   const errData = err?.data;
    //   //toast.error(errData.message);
    // },
  })
}

export const useHardDeleteDepartment = () => {
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  return useMutation(departmentApi.hardDeleteDepartment, {
    onSuccess: () => {
      queryClient.invalidateQueries([department.DEPARTMENTS])
      navigate('/deleted-items')
    }
    // ! TODO: for future implementation toast
    // onError: (err: any) => {
    //   const errData = err?.data;
    //   //toast.error(errData.message);
    // },
  })
}

export const useGetDeletedDepartmernt = (id, isEnable = true) => {
  return useQuery(
    [department.DEPARTMENT + 'DELETED', id],
    departmentApi.getSingleDeletedDepartment,
    {
      enabled: isEnable,
      select: (data) => data.data,
      // ! TODO: for future implementation toast
      // onSuccess: (res: any) => {
      //   const data = res?.data;
      // },
      // onError: (err: any) => {
      //   const errData = err?.data;
      //   //toast.error(errData.message);
      // },
      refetchOnWindowFocus: false
    }
  )
}

export const useUpdateDepartment = () => {
  // const queryClient = useQueryClient();
  const navigate = useNavigate()
  return useMutation(departmentApi.updateDepartment, {
    onSuccess: (res, id) => {
      // queryClient.invalidateQueries([department.DEPARTMENT]);
      // queryClient.invalidateQueries([department.DEPARTMENT, id]);
      // ! TODO: for future implementation toast
      // toast.success(data?.message);
      navigate('/factory-layout', { state: { activeTab: '2' } })
    },
    onError: (err) => {
      return err
      // ! TODO: for future implementation toast
      // const errData = err.data;
      // toast.error(errData?.message);
    }
  })
}
export const useAddDepartmentGroup = () => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  return useMutation(departmentApi.addDepartmentGroup, {
    onSuccess: () => {
      queryClient.invalidateQueries([department.DEPARTMENT])
      navigate('/factory-layout', { state: { activeTab: '1' } })
    },
    onError: (err) => {
      return err
      // ! TODO: for future implementation toast
      // toast.error(errData.message);
    }
  })
}
export const useGetDepartmentsGroup = (isEnable = false, page) => {
  return useQuery(
    [department.DEPARTMENTSGROUPS, page],
    departmentApi.getDepartmentsGroup,
    {
      enabled: isEnable,
      select: (data) => data?.data,
      // ! TODO: for future implementation toast
      // onSuccess: () => {
      //   // const data = res?.data;
      // },
      onError: (err) => {
        return err
        // const errData = err?.data;
        // toast.error(errData.message);
      }
    }
  )
}
export const useGetSingleDepartmentsGroup = (id, isEnable = true) => {
  return useQuery(
    [department.SINGLE_GROUP_GET, id],
    departmentApi.getSingleDepartmentGroup,
    {
      enabled: isEnable,
      select: (data) => data?.data?.data,
      // ! TODO: for future implementation toast
      // onSuccess: () => {
      //   // const data = res?.data;
      // },
      onError: (err) => {
        return err
        // const errData = err?.data;
        // toast.error(errData.message);
      }
    }
  )
}
export const useUpdateDepartmentGroup = () => {
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  return useMutation(departmentApi.updateDepartmentGroup, {
    onSuccess: (res, id) => {
      queryClient.invalidateQueries([department.DEPARTMENT])
      queryClient.invalidateQueries([department.DEPARTMENT, id])
      // ! TODO: for future implementation toast
      // toast.success(data?.message);
      navigate('/factory-layout', { state: { activeTab: '1' } })
    },
    onError: (err) => {
      return err
      // ! TODO: for future implementation toast
      // const errData = err.data;
      // toast.error(errData?.message);
    }
  })
}
export const useRestoreDepartmentGroup = () => {
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  return useMutation(departmentApi.restoreDepartmentGroup, {
    onSuccess: () => {
      queryClient.invalidateQueries([department.DEPARTMENTSGROUPS])
      // ! TODO: for future implementation toast
      // queryClient.invalidateQueries([part.PART, id]);
      // toast.success(data.message);
      navigate('/deleted-items')
    }
    // ! TODO: for future implementation toast
    // onError: (err: any) => {
    //   const errData = err?.data;
    //   //toast.error(errData.message);
    // },
  })
}

export const useHardDeleteDepartmentGroup = () => {
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  return useMutation(departmentApi.hardDeleteDepartmentGroup, {
    onSuccess: () => {
      queryClient.invalidateQueries([department.DEPARTMENTSGROUPS])
      navigate('/deleted-items')
    }
    // ! TODO: for future implementation toast
    // onError: (err: any) => {
    //   const errData = err?.data;
    //   //toast.error(errData.message);
    // },
  })
}

export const useGetDeletedDepartmerntGroup = (id, isEnable = true) => {
  return useQuery(
    [department.DEPARTMENT + 'DELETED', id],
    departmentApi.getDeleteDepartmentGroup,
    {
      enabled: isEnable,
      select: (data) => data.data,
      // ! TODO: for future implementation toast
      // onSuccess: (res: any) => {
      //   const data = res?.data;
      // },
      // onError: (err: any) => {
      //   const errData = err?.data;
      //   //toast.error(errData.message);
      // },
      refetchOnWindowFocus: false
    }
  )
}
export const useValidateDepartment = () => {
  // const queryClient = useQueryClient();
  // const navigate = useNavigate();
  return useMutation(departmentApi.validateDepartment, {
    // onSuccess: (res) => {
    // queryClient.invalidateQueries([machine.MACHINES]);
    // const data = res.data;
    // toast.success(data?.message);
    // navigate(`/machine-details/${data?.machine.id}`);
    // navigate(`/resources`, { state: { activeTab: '1' } });
    // },
    // onError: (err: any) => {
    // const errData = err?.data;
    // },
  })
}
