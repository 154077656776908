import React from 'react'

const EditIconNew = () => {
  return (
    <React.Fragment>
      <svg
        width='20'
        height='20'
        viewBox='0 0 20 20'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M17.4953 7.76254L7.94194 17.3159C7.82473 17.4331 7.66576 17.499 7.5 17.499H3.75C3.75 17.499 3.23223 17.499 2.86612 17.1328C2.86612 17.1328 2.5 16.7667 2.5 16.249L2.5 12.7568C2.5 12.7568 2.49914 12.5115 2.59314 12.2827C2.59314 12.2827 2.68714 12.0538 2.86165 11.8784L12.2377 2.50233C12.2377 2.50233 12.4106 2.32679 12.6422 2.2298C12.6422 2.2298 12.8739 2.13281 13.125 2.13281C13.125 2.13281 13.3761 2.13281 13.6078 2.2298C13.6078 2.2298 13.8394 2.32679 14.0156 2.50572L17.4966 5.9867C17.4966 5.9867 17.6722 6.15954 17.7692 6.39118C17.7692 6.39118 17.8661 6.62283 17.8661 6.87395C17.8661 6.87395 17.8661 7.12508 17.7692 7.35672C17.7692 7.35672 17.6731 7.58616 17.4966 7.76121L17.4953 7.76254ZM16.6161 6.87396L16.6127 6.87058L13.125 3.38281L13.1216 3.38621L3.75 12.7578V16.249H7.24112L16.6127 6.87733L16.6161 6.87396Z'
          fill='#0160B9'
        />
        <path
          d='M16.875 16.249H7.49999C7.15481 16.249 6.87499 16.5288 6.87499 16.874C6.87499 17.2192 7.15482 17.499 7.5 17.499L16.875 17.499C17.2202 17.499 17.5 17.2192 17.5 16.874C17.5 16.5288 17.2202 16.249 16.875 16.249Z'
          fill='#0160B9'
        />
        <path
          d='M14.5577 9.81564C14.6749 9.93285 14.8342 9.99902 15 9.99902C15.1658 9.99902 15.3247 9.93318 15.4419 9.81597C15.5591 9.69875 15.625 9.53978 15.625 9.37402C15.625 9.20826 15.5591 9.04929 15.4419 8.93208L11.067 4.55716C10.9497 4.43987 10.7908 4.37402 10.625 4.37402C10.4592 4.37402 10.3003 4.43987 10.1831 4.55708C10.0658 4.67429 9.99999 4.83326 9.99999 4.99902C9.99999 5.16478 10.0658 5.32375 10.1831 5.44097L14.5577 9.81564Z'
          fill='#0160B9'
        />
      </svg>
    </React.Fragment>
  )
}

export default EditIconNew
