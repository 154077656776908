import { Box, Tab, Typography, useMediaQuery, useTheme } from '@mui/material'
import React, { useState } from 'react'
import Tabs from '@mui/material/Tabs'
import { TabContext, TabPanel } from '@mui/lab'
import AddIcon from '@mui/icons-material/Add'
import TableComponent from '../../leastcomponent/customtable/customtable'
import { useLocation, useNavigate } from 'react-router-dom'
import DeleteIconNew from '../../assets/svg/DeleteIconNew'
import DeleteWarningModal from '../../modals/deletewarningmodal'
import CustomButton from '../../leastcomponent/custombutton/custombutton'
import BreadcrumbNavigation from '../commoncomponents/breadcrumbnavigation'
import CircularProgress from '@mui/material/CircularProgress'
import {
  useDeleteDepartment,
  useDeleteDepartmentGroup,
  useGetDepartments,
  useGetDepartmentsGroup
} from '../../hooks/departmentHooks'
import EditIconNew from '../../assets/svg/EditIconNew'
import ErrorPopup from '../../modals/errorpopup'

const DepartmentFactoryLayout = () => {
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up('lg'))
  const [isErrorModalOpen, setIsErrorModalOpen] = React.useState(false)
  const [errorMessage, setErrorMessage] = React.useState('')
  const handleErrorModalOpen = () => setIsErrorModalOpen(true)
  const handleErrorModalClose = () => setIsErrorModalOpen(false)
  const location = useLocation()
  const [value, setValue] = useState(location?.state?.activeTab || '1')
  const [deleteRecord, setDeleteRecord] = useState(false)
  const handleDeleteOpen = () => setDeleteRecord(true)
  const handleDeleteClose = () => setDeleteRecord(false)

  const {
    data: departmentRes,
    isFetching: isDepartmentRes
  } = useGetDepartments(value === '2')
  const {
    data: departmentGroupRes,
    isFetching: isDepartmentFetching
  } = useGetDepartmentsGroup(value === '1')
  const [currentId, setCurrentId] = useState('')
  const handleChange = (event, newValue) => {
    setValue(newValue)
  }
  const navigate = useNavigate()
  const { mutate: deleteDepartmentsGroup } = useDeleteDepartmentGroup()
  const rows = departmentGroupRes?.data?.results?.map((data, index) => {
    return {
      no: index + 1,
      id: data?.id,
      title: data?.title,
      userRoleCount: data?.userRoleCount,
      departments: data?.departments?.map((data) => {
        return data?.name
      })
      // machines: "Customer Name",
      // workstations: "Product Name",
    }
  })

  const columns = [
    { field: 'no', headerName: '#', flex: 0.2 },
    { field: 'title', headerName: 'Department Group Name', flex: 1 },
    {
      field: 'departments',
      headerName: 'Departments mapped',
      flex: 1,
      renderCell: (params) => {
        return (
          <Box
            width={'100%'}
            style={{
              display: 'flex'
            }}
          >
            <div>
              {params?.row?.departments?.map((item, index) => {
                return <div key={index}> {item}</div>
              })}
            </div>
          </Box>
        )
      }
    },
    {
      field: 'actions',
      headerName: 'Actions',
      sortable: false,
      disableClickEventBubbling: true,
      flex: matches ? 0.7 : 1,
      renderCell: (params) => {
        return (
          <Box className="resource-table-btn-main">
            <span
              className="process-delete"
              onClick={() => navigate(`/edit-department-group/${params.row.id}`)}
            >
              <EditIconNew />
            </span>
            <span
              className="process-delete"
              onClick={() => {
                if (params.row.userRoleCount <= 0) {
                  setCurrentId(params.row.id)
                  handleDeleteOpen()
                } else {
                  setErrorMessage(
                    `You can not able to delete this department group, this department group is assigned to ${params.row.userRoleCount} user role`
                  )
                  handleErrorModalOpen()
                }
              }}
            >
              <DeleteIconNew />
            </span>
          </Box>
        )
      }
    }
  ]
  const { mutate: deleteDepartments } = useDeleteDepartment()
  const rows1 = departmentRes?.results?.map((data, index) => {
    return {
      no: index + 1,
      id: data?.id,
      name: data?.name,
      departmentGroupCount: data?.departmentGroupCount,
      departmentgroups: data?.departmentgroups,
      machines: data?.machine,
      workstations: data?.workstation
    }
  })
  const columns1 = [
    { field: 'no', headerName: '#', flex: 0.2 },
    { field: 'name', headerName: 'Department Name', flex: 2 },
    // { field: "group", headerName: "Department group", flex: 1 },
    {
      field: 'departmentgroups',
      headerName: 'Department Group',
      flex: 2,
      renderCell: (params) => {
        return (
          <Box
            width={'100%'}
            style={{
              display: 'flex'
            }}
          >
            <div>
              {params?.row?.departmentgroups?.map((item, index) => {
                return <div key={index}> {item?.title}</div>
              })}
            </div>
          </Box>
        )
      }
    },
    { field: 'machines', headerName: 'No. Of Machines', flex: 2 },
    { field: 'workstations', headerName: 'No. Of Workstations', flex: 2 },
    {
      field: 'actions',
      headerName: 'Actions',
      sortable: false,
      disableClickEventBubbling: true,
      flex: 2,
      renderCell: (params) => {
        return (
          <Box className="resource-table-btn-main">
            <span
              className="process-delete"
              onClick={() => {
                if (params.row.departmentGroupCount === 0 && params.row.machines === 0 && params.row.workstations === 0) {
                  setCurrentId(params.row.id)
                  handleDeleteOpen()
                } else {
                  setErrorMessage(
                    `You can not able to delete this department, this department is assigned to  ${params.row.departmentGroupCount} department group , ${params.row.machines} machines & ${params.row.workstations} workstations`
                  )
                  handleErrorModalOpen()
                }
              }}
            >
              <DeleteIconNew />
            </span>
          </Box>
        )
      }
    }
  ]

  return (
    <React.Fragment>
      <Box className="production-team-head-overflow-hidden ">
        <BreadcrumbNavigation
          breadCrumbData={null}
          pageTitle={'Factory Layout'}
          backToLink={null}
        />
        <Box className="production-team-head-overflow-auto ">
          <TabContext value={value}>
            <Box
              sx={{
                typography: 'body1'
              }}
              className="tab-content-padding"
            >
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="lab API tabs example"
              >
                <Tab className="tab-head" label="Department Group" value="1" />
                <Tab className="tab-head" label="Department" value="2" />
              </Tabs>
            </Box>
            <TabPanel value="1" className="tab-panel" sx={{ padding: '0px' }}>
              <Box className="tab-content-padding">
                <Box className="common-parent-haeding-btn-div">
                  <Typography className="common-tabel-heading">
                    Department Group
                  </Typography>
                  <CustomButton
                    variant="contained"
                    title="Add Department Group"
                    onClick={() => navigate('/create-department-group')}
                    type="button"
                    endIcon=""
                    startIcon={<AddIcon />}
                  />
                </Box>
                <Box className="department-group-table-width">
                  {
                    !isDepartmentFetching
                      ? <TableComponent
                    rows={rows}
                    columns={columns}
                    detailsRedirection={'/edit-department-group'}
                    />
                      : <Box className='progress-parent'>
                          <CircularProgress />
                        </Box>
                  }
                </Box>
              </Box>
            </TabPanel>
            <TabPanel value="2" className="tab-panel" sx={{ padding: '0px' }}>
              <Box className="tab-content-padding">
                <Box className="common-parent-haeding-btn-div">
                  <Typography className="common-tabel-heading">
                    Department
                  </Typography>
                  <CustomButton
                    variant="contained"
                    title="Add Department"
                    onClick={() => navigate('/create-department')}
                    type="button"
                    endIcon=""
                    startIcon={<AddIcon />}
                  />
                </Box>
                <Box className="department-table-width">
                  {' '}
                  {
                    !isDepartmentRes
                      ? <TableComponent
                      rows={rows1}
                      columns={columns1}
                      detailsRedirection={'/edit-department'}
                    />
                      : <Box className='progress-parent'>
                          <CircularProgress />
                        </Box>
                  }
                </Box>
              </Box>
            </TabPanel>
          </TabContext>
        </Box>
        {value === '1' && deleteRecord && currentId && (
          <DeleteWarningModal
            open={deleteRecord}
            handleOpen={handleDeleteOpen}
            handleClose={handleDeleteClose}
            handleDelete={deleteDepartmentsGroup}
            id={currentId}
          />
        )}
        {value === '2' && deleteRecord && currentId && (
          <DeleteWarningModal
            open={deleteRecord}
            handleOpen={handleDeleteOpen}
            handleClose={handleDeleteClose}
            handleDelete={deleteDepartments}
            id={currentId}
          />
        )}
            {isErrorModalOpen && (
        <ErrorPopup
          isOpen={isErrorModalOpen}
          handleClose={handleErrorModalClose}
          errorDetails={errorMessage}
        />
            )}
      </Box>
    </React.Fragment>
  )
}

export default DepartmentFactoryLayout
