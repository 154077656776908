import * as React from 'react'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { Typography } from '@mui/material'

import './../datepicker/customdatepicker.css'
import Required from '../../pages/commoncomponents/required'
// import moment from 'moment'

const BasicDatePicker = (props) => {
  const {
    value,
    handleDateChange,
    className = 'default-date-picker',
    label = '',
    isRequired = false,
    titleClassName = 'date-title',
    touched = false,
    errors = ''
  } = props
  // const newVlaue = moment(value).format("YYYY-MM-DD");
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Typography className={titleClassName}>
        {label}&nbsp;
        {isRequired && <Required />}
      </Typography>
      <DemoContainer components={['DatePicker']}>
        <DatePicker
          // label="Date Picker"
          value={value}
          onChange={(e) => {
            handleDateChange(e?.toDate())
          }}
          className={className}
        />
      </DemoContainer>
      {touched && errors && (
        <Typography className="validation-text-select">{errors}</Typography>
      )}
    </LocalizationProvider>
  )
}
export default BasicDatePicker
