import React from 'react'
import { TextField, Typography } from '@mui/material'
import './../custominput/input.css'
import Required from '../../pages/commoncomponents/required'
const CustomInput = (props) => {
  const {
    label = '',
    isRequired = true || false,
    placeholder = '',
    handleChange,
    name = '',
    InputProps,
    titleClassName = 'field-title',
    className = 'default-input',
    touched = false,
    errors = '',
    type = 'text',
    value = '',
    disabled = false
  } = props
  return (
    <React.Fragment>
      <Typography className={titleClassName}>
        {label}
        {isRequired && <Required />}
      </Typography>

      <TextField
        placeholder={placeholder}
        fullWidth
        type={type}
        onChange={handleChange}
        name={name}
        isRequired={isRequired}
        value={value}
        InputProps={InputProps}
        className={className}
        disabled={disabled}
      />
      {touched && errors && (
        <Typography className="validation-text">{errors}</Typography>
      )}
    </React.Fragment>
  )
}
export default CustomInput
