import React from 'react'
import DelayJobCard from './DelayJobCard'
import { Box, Grid, Typography } from '@mui/material'
import './../DelayedJobCard/delay.css'

const DelayWrapper = ({ data }) => {
  return (
    <React.Fragment>
      <Box className="parent-wrapper-delay">
        <Box className="parent-one-wrapper">
          <Box>
            <Typography className="header-wrapper">
              Top 5 Delayed Job Cards
            </Typography>
            <Typography className="view-btn">View all </Typography>
          </Box>
          <Box className="parent-bar-chart">
            <Typography className="Overall">On Time Delivery</Typography>
            <Typography className="percentile">-</Typography>
          </Box>
        </Box>
        {/* <Grid container spacing={2} className="delay-job-card-padding"> */}
        <Grid container spacing={2} className="">
          {data?.map((delayData, index) => {
            return (
              <Grid item xs={6} md={6} key={index}>
                <DelayJobCard delayData={delayData} />
              </Grid>
            )
          })}
        </Grid>
      </Box>
    </React.Fragment>
  )
}

export default DelayWrapper
