import { Box, Grid, Switch } from '@mui/material'
import React from 'react'
import './otherSetting.css'
import { styled } from '@mui/material/styles'
import BreadcrumbNavigation from '../commoncomponents/breadcrumbnavigation'

const OtherSetting = () => {
  const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 28,
    height: 16,
    padding: 0,
    display: 'flex',
    '&:active': {
      '& .MuiSwitch-thumb': {
        width: 15
      },
      '& .MuiSwitch-switchBase.Mui-checked': {
        transform: 'translateX(9px)'
      }
    },
    '& .MuiSwitch-switchBase': {
      padding: 2,
      '&.Mui-checked': {
        transform: 'translateX(12px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          opacity: 1,
          backgroundColor:
            theme.palette.mode === 'dark' ? '#177ddc' : '#1890ff'
        }
      }
    },
    '& .MuiSwitch-thumb': {
      boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
      width: 12,
      height: 12,
      borderRadius: 6,
      transition: theme.transitions.create(['width'], {
        duration: 200
      })
    },
    '& .MuiSwitch-track': {
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor:
        theme.palette.mode === 'dark'
          ? 'rgba(255,255,255,.35)'
          : 'rgba(0,0,0,.25)',
      boxSizing: 'border-box'
    }
  }))

  return (
    <Box className="production-team-head-overflow-hidden">
      <BreadcrumbNavigation
        breadCrumbData={null}
        pageTitle={'Other Settings'}
        backToLink={null}
      />

      <Box className="common-parent-class-overflow-auto-padding-10-50">
        <Box padding={'20px 0px 10px'}>
          <Grid lg={5}>
            <Box className="parent-title">
              <Box className="font-size-14">Mask Customer Name</Box>
              <Box>
                <AntSwitch
                  // defaultChecked
                  disabled={true}
                  inputProps={{ 'aria-label': 'ant design' }}
                />
              </Box>
            </Box>
            <Box className="parent-title">
              <Box className="font-size-14">Maintenance schedule</Box>
              <Box>
                <AntSwitch
                  // defaultChecked
                  disabled={true}
                  inputProps={{ 'aria-label': 'ant design' }}
                />
              </Box>
            </Box>
          </Grid>
        </Box>
      </Box>
    </Box>
  )
}

export default OtherSetting
