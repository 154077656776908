import { get } from '../utils/index'

export const getDashboardProgressReport = async (data) => {
  // const page = data?.queryKey[1] || 1
  const startDate = new Date(data?.queryKey?.[1]?.startDate)
  const endDate = new Date(data?.queryKey?.[1]?.endDate)
  const payload = { status: data?.queryKey[1], startDate, endDate }
  return get('/dashboard/progress-report', payload).then((res) => res.data)
}
export const getDashboardRecentActivity = async (data) => {
  // const page = data?.queryKey[1] || 1
  const startDate = new Date(data?.queryKey?.[1]?.startDate)
  const endDate = new Date(data?.queryKey?.[1]?.endDate)
  const payload = { status: data?.queryKey[1], startDate, endDate }
  return get('/dashboard/recent-activity', payload).then((res) => res.data)
}
export const getDashboardJobcardStatus = async (data) => {
  const page = data?.queryKey[2] || 1
  const status = data?.queryKey[1]
  const startDate = new Date(data?.queryKey?.[3]?.startDate)
  const endDate = new Date(data?.queryKey?.[3]?.endDate)
  const departmentId = data?.queryKey?.[4]
  const payload = { page, limit: 10, status: data?.queryKey[1], startDate, endDate, departmentId }
  return get(`/dashboard/jobcard-status?jobCardStatus=${status}`, payload).then((res) => res.data)
}
export const getDashboardTaskStatus = async (data) => {
  const page = data?.queryKey[2] || 1
  const status = data?.queryKey[1]
  const startDate = new Date(data?.queryKey?.[3]?.startDate)
  const endDate = new Date(data?.queryKey?.[3]?.endDate)
  const departmentId = data?.queryKey?.[4]
  const payload = { page, limit: 10, status: data?.queryKey[1], startDate, endDate, departmentId }
  return get(`/dashboard/task-status?jobCardStatus=${status}`, payload).then((res) => res.data)
}
export const getDashboardJobcardTaskStatus = async (data) => {
  const page = data?.queryKey[2] || 1
  const status = data?.queryKey[1]
  const startDate = new Date(data?.queryKey?.[3]?.startDate)
  const endDate = new Date(data?.queryKey?.[3]?.endDate)
  const payload = { page, limit: 10, status: data?.queryKey[1], startDate, endDate }
  return get(`/dashboard/jobcard-and-task-status?jobCardStatus=${status}`, payload).then((res) => res.data)
}

export const dashboardApi = {
  getDashboardProgressReport,
  getDashboardTaskStatus,
  getDashboardRecentActivity,
  getDashboardJobcardTaskStatus,
  getDashboardJobcardStatus
}
