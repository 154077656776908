/* eslint-disable react/jsx-key */
import React, { useState } from 'react'
import CustomizedModal from '../../leastcomponent/custommodal/commonmodal'
import { Box, Grid, useMediaQuery, useTheme } from '@mui/material'
import './jobslipsradiowithselect.css'
import TableComponent from '../../leastcomponent/customtable/customtable'
import DeleteIconNew from '../../assets/svg/DeleteIconNew'
import DeleteWarningModal from '../deletewarningmodal'
// import SaveFileIcon from '../../assets/svg/SaveFileIcon'
// import ShareIcon from '../../assets/svg/ShareIcon'
import JobSlipsRadioWithSelectModal from './jobslipsradiowithselect'
import EditIconNew from '../../assets/svg/EditIconNew'
import ConfirmJobSlip from './confirmjobslip'
import ConfirmOverdue from './confirmoverdue'
import moment from 'moment'

const JobCardTableModal = (props) => {
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up('lg'))
  const { open, handleClose, mainData, basicDetail, jobId } = props
  const [deleteRecord, setDeleteRecord] = useState(false)
  const [jobIdValues, setJobIdValues] = useState({})
  const [jobSlipsRadioWithSelectModal, setJobSlipsRadioWithSelectModal] =
    useState(false)
  const [confirmJobSlip, setConfirmJobSlip] = React.useState(false)
  const [confirmOverdue, setConfirmOverdue] = React.useState(false)
  const handleDeleteOpen = () => setDeleteRecord(true)
  const handleDeleteClose = () => setDeleteRecord(false)

  return (
    <Box>
      <CustomizedModal
        isHeadingName={false}
        open={open}
        onClick={handleClose}
        width="70%"
      >
        {/* //!--- */}
        {mainData?.map((data, ind) => {
          const rows = data?.task?.map((data1, ind) => {
            return {
              no: ind + 1,
              id: data1?.id,
              name: data1?.taskNickName ? data1.taskNickName : '-',
              number: data1?.taskNumber ? data1.taskNumber : '-',
              taskType: data1?.taskType?.name ? data1?.taskType?.name : '-',
              partName: data1?.bompart?.partName
                ? data1?.bompart?.partName
                : '-',
              qty: data1?.jobcard?.quantity ? data1?.jobcard.quantity : '-',
              partDes: data1?.bompart?.description ? data1?.bompart?.description : '-',
              jobCardNo: data1?.jobcard?.jobCardNumber ? data1?.jobcard?.jobCardNumber : '-',
              allocatedTime: data1?.allocatedTime ? data1?.allocatedTime : '-',
              drawing: data1?.bompart?.drawingNumber
                ? data1?.bompart?.drawingNumber
                : '-'
            }
          })
          const col = [
            { field: 'no', headerName: '#', flex: 0.1 },
            { field: 'name', headerName: 'Task Name', flex: 1, cellClassName: 'regular' },
            // { field: 'name', headerName: 'Task name', flex: 1 },
            { field: 'number', headerName: 'Task Number', flex: 1 },
            { field: 'taskType', headerName: 'Task type', flex: matches ? 1 : 0.7 },
            {
              field: 'partName',
              headerName: 'Part Name ',
              flex: matches ? 1 : 0.7
            },
            { field: 'qty', headerName: 'Qty ', flex: matches ? 1 : 0.7 },
            { field: 'partDes', headerName: 'Part Description ', flex: 1 },
            {
              field: 'jobCardNo',
              headerName: 'Job Card Number ',
              flex: matches ? 1 : 0.7
            },
            {
              field: 'allocatedTime',
              headerName: 'Allocated time (mins)',
              flex: matches ? 1 : 0.7
            },
            { field: 'drawing', headerName: 'Drawing', flex: 1 },
            {
              field: 'actions',
              headerName: 'Actions',
              sortable: false,
              disableClickEventBubbling: true,
              flex: 1,
              renderCell: (params) => {
                return (
                  <Box className="resource-table-btn-main">
                    <span
                      className="process-delete"
                      onClick={() => {
                        setJobSlipsRadioWithSelectModal(true)
                      }}
                    >
                      <EditIconNew />
                    </span>
                    <span onClick={handleDeleteOpen} className="process-delete">
                      <DeleteIconNew />
                    </span>
                  </Box>
                )
              }
            }
          ]
          return (
            <React.Fragment>
              <Box className="job-slips-card-box-1">{data?.name}</Box>
              <Grid container sx={{ marginTop: '20px', display: 'flex' }}>
                <Grid
                item
                  xl={4}
                  lg={4}
                  md={3.5}
                  sx={{ display: 'flex', gap: '10px' }}
                >
                  {' '}
                  <Box className="job-slips-add-task-header-1">
                    Department :
                  </Box>
                  <Box className="job-slips-with-semibold">
                    {mainData?.[0]?.task?.[0]?.department?.name ? mainData?.[0]?.task?.[0]?.department?.name : '-'}
                  </Box>
                </Grid>
                <Grid
                item
                  xl={4}
                  lg={4}
                  md={3.5}
                  sx={{ display: 'flex', gap: '10px' }}
                >
                  {' '}
                  <Box className="job-slips-add-task-header">Timeline :</Box>
                  <Box className="job-slips-with-semibold">
                    {basicDetail?.Timeline
                      ? moment(basicDetail?.Timeline)?.format('DD/MM/YY')
                      : '-'}
                  </Box>
                  <Box className="job-slips-with-semibold">
                      {basicDetail?.shift}
                  </Box>
                </Grid>
                <Grid
                item
                  xl={4}
                  lg={4}
                  md={5}
                  sx={{ display: 'flex', gap: '10px', justifyContent: 'end' }}
                  className="job-slips-add-task-header-share-save"
                >
                  {/* <Box className="job-slips-add-task-header-share">Share</Box>
                  <Box className="job-slips-add-task-header-share">
                    <ShareIcon />
                  </Box>
                  <Box className="job-slips-add-task-header-share">Save</Box>
                  <Box className="job-slips-add-task-header-share">
                    <SaveFileIcon />
                  </Box> */}
                </Grid>
              </Grid>
              <Grid container className="job-slips-main-margin">
                <Grid item lg={4} md={3.5} className="job-slips-task-assigned">
                  <Box className="job-slips-add-task-header">
                    No. of Tasks assigned:
                  </Box>
                  <Box className="job-slips-with-semibold">
                    {' '}
                    {data?.numberOfTasks}
                  </Box>
                </Grid>
                <Grid item lg={4} md={3.5} className="job-slips-task-assigned">
                  <Box className="job-slips-add-task-header">
                    Total Qty assigned:
                  </Box>
                  <Box className="job-slips-with-semibold">
                    {data?.totalQty}
                  </Box>
                </Grid>
                <Grid item lg={4} md={5} className="job-slips-task-assigned">
                  <Box className="job-slips-add-task-header">
                    Total time assigned per shift:
                  </Box>
                  <Box className="job-slips-with-semibold">{data?.totalTime}</Box>
                </Grid>
              </Grid>
              <Box>
                <TableComponent rows={rows} columns={col} />
              </Box>
            </React.Fragment>
          )
        })}
      </CustomizedModal>
      {deleteRecord && (
        <DeleteWarningModal
          open={deleteRecord}
          handleOpen={handleDeleteOpen}
          handleClose={handleDeleteClose}
        />
      )}
      {jobSlipsRadioWithSelectModal && (
        <JobSlipsRadioWithSelectModal
          open={jobSlipsRadioWithSelectModal}
          handleClose={() => setJobSlipsRadioWithSelectModal(false)}
          handleOpen={() => setJobSlipsRadioWithSelectModal(true)}
          slipOpen={() => setConfirmJobSlip(true)}
          overdueOpen={() => setConfirmOverdue(true)}
          setJobIdValues={setJobIdValues}
        />
      )}
      {/* //! new */}
      {confirmJobSlip && (
        <ConfirmJobSlip
          open={confirmJobSlip}
          handleClose={() => setConfirmJobSlip(false)}
          openJobSlip={() => setJobSlipsRadioWithSelectModal(true)}
          jobIdValues={jobIdValues}
          jobId={jobId}
        />
      )}

      {ConfirmOverdue && (
        <ConfirmOverdue
          open={confirmOverdue}
          handleClose={() => setConfirmOverdue(false)}
          openJobSlip={() => setJobSlipsRadioWithSelectModal(true)}
          jobId={jobId}
        />
      )}
      {/* //! end */}
    </Box>
  )
}

export default JobCardTableModal
