import React, { useState } from 'react'
import { Box, CircularProgress } from '@mui/material'

import BreadcrumbNavigation from '../commoncomponents/breadcrumbnavigation'
import CommonKanbanView from './CommonKanbanView'
import { useDepartmentWiseKanban } from '../../hooks/task'
import NoData from '../../leastcomponent/noAddedYet'

const ScheduleMachine = () => {
  const [first, setfirst] = useState('')
  const selectDepartmentid = localStorage.getItem('selectDepartmentid')
  const { data: departmentWiseData, isFetching: isDepartmentWiseData } =
    useDepartmentWiseKanban(selectDepartmentid)
  const departmentList = departmentWiseData?.data?.results

  return (
    <Box className="production-team-head-overflow-hidden">
      <BreadcrumbNavigation
        breadCrumbData={null}
        pageTitle={'Department Kanban View'}
        backToLink={null}
        setfirst={setfirst}
        first={first}
        isDepartment={true}
        selectedDepartment={selectDepartmentid}
      />
      <>
      {
        !selectDepartmentid
          ? <Box paddingTop={'350px'}><NoData label='Please select department'/></Box>
          : <>
       {/* <Box className="common-parent-class-overflow-auto-padding-10-50" style={{ border: '2px solid red' }}> */}
       {!isDepartmentWiseData
         ? (
        <Box padding={'10px 0px 10px 50px'}>
          <CommonKanbanView
          // TODO:: machine name is temporary comment
            // machineName={departmentWiseData?.data?.departmentGroupName}
            taskData={departmentList}
          />
        </Box>
           )
         : (
        <Box className="machine-kanban-progress">
          <CircularProgress />
        </Box>
           )}

                </>
      }
      </>

    </Box>
  )
}

export default ScheduleMachine
