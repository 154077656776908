import * as React from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
// eslint-disable-next-line camelcase
import HC_exporting from 'highcharts/modules/exporting'
import bellCurve from 'highcharts/modules/histogram-bellcurve'

bellCurve(Highcharts)
HC_exporting(Highcharts)

function LineChart (props) {
  const chartOptions = {
    chart: {
      type: 'line',
      height: props.height
    },
    type: 'bellcurve',
    credits: false,
    exporting: { enabled: false },
    title: {
      text: ''
    },
    legend: {
      align: 'center',
      verticalAlign: 'bottom',
      x: 0,
      y: 0
    },
    tooltip: {
      shared: true,
      useHTML: true,
      formatter: function () {
        const self = this
        let formattedString = '<small></small><table>'
        self.points.forEach((elem) => {
          formattedString +=
            '<tr><td style="color: {series.color}">' +
            elem.series.name +
            ': </td>'
          formattedString +=
            '<td style="text-align: right"><b>' + elem.y + '</b></td>'
          if (elem.point.cts) {
            formattedString +=
              '<td style="text-align: right">cts: <b>' +
              elem.point.cts +
              '</b></td></tr>'
          } else {
            formattedString +=
              '<td style="text-align: right">cts: <b>' +
              elem.point.ots +
              '</b></td></tr>'
          }
        })
        return formattedString
      }
    },
    colors: props.legendColor,
    xAxis: {
      visible: true,
      // tickPositions: [0, 20, 40, 60, 80],
      //  min: 0,
      // max: 100,
      categories: ['Week 1', 'Week 2', 'Week 3', 'Week 4', 'Week 5', 'Week 6', 'Week 7', 'Week 8', 'Week 9', 'Week 10'],
      type: 'datetime',
      // gridLineWidth: 1,
      gridLineColor: 'transparent'
      //   labels: {
      //     format: "{value:%b-%e-%Y}", //for history chart
      //   },
    },
    yAxis: {
      tickPixelInterval: 20,
      min: 0,
      max: 100,
      labels: {
        formatter: function () {
          return this.value + '%'
        }
      },
      title: {
        text: ''
      },
      visible: true,
      //   gridLineColor: 'transparent',
      gridLineWidth: 1
      //   step: 1,
    },
    plotOptions: {
      series: {
        type: 'bellcurve',
        fillColor: {
          // ! here to change shadow of this lauout
          linearGradient: [0, 0, 0, 130],
          stops: [
            [0, Highcharts.getOptions().colors[4]],
            [
              1,
              Highcharts.Color(Highcharts.getOptions().colors[0])
                .setOpacity(0)
                .get('rgba')
            ]
          ]
        },
        marker: {
          enabled: false
        }
      },
      column: {
        dataLabels: {
          enabled: true,
          crop: false,
          overflow: 'none'
        }
      },
      line: {
        marker: {
          enabled: false
        }
      }
    },
    series: props.chartData
    //         series: [
    //   {
    //     type: "bellcurve",
    //     data: [1, 2, 30, 48, 95],
    //     shadow: {
    //       color: "#002242" // Change this to your desired color
    //     }
    //   },
    //   {
    //     type: "bellcurve",
    //     data: [6, 87, 38, 9, 100],
    //     shadow: {
    //       color: "#fff" // Change this to your desired color
    //     }
    //   }
    // ]
    //     series: [{
    //     data: [29.9, 71.5, 106.4, 129.2, 144.0, 176.0, 135.6, 148.5, 216.4, 194.1, 95.6, 54.4],
    //     shadow: {
    //       width: 8,
    //       opacity: 0.2,
    //       color: '#3498db'
    //     }
    // }, {
    //     data: [216.4, 194.1, 95.6, 54.4, 29.9, 71.5, 106.4, 129.2, 144.0, 176.0, 135.6, 148.5],
    //     shadow: {
    //       width: 8,
    //       opacity: 0.2,
    //       color: '#e74c3c'
    //     }
    // }]
  }
  return (
    <React.Fragment>
      <HighchartsReact highcharts={Highcharts} options={chartOptions} />
    </React.Fragment>
  )
}

export default LineChart
