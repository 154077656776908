import React from 'react'
import './simulate.css'
import {
  Box,
  Grid
} from '@mui/material'
import { FieldArray, Form, Formik } from 'formik'
import CustomInput from '../../leastcomponent/custominput/input'
import { AiOutlineInfoCircle } from 'react-icons/ai'
import CustomButton from '../../leastcomponent/custombutton/custombutton'
import * as Yup from 'yup'
import CustomSelect from '../../leastcomponent/customselect/customselect'
// import DeleteIconNew from '../../assets/svg/DeleteIconNew';
import Plus from '../../assets/svg/Plus'

const UpdatedScenario = () => {
  const icon = <AiOutlineInfoCircle color="red" />
  const options = [
    { value: '1', label: 'Department1' },
    { value: '2', label: 'Department2' },
    { value: '3', label: 'Department3' },
    { value: '4', label: 'Department4' },
    { value: '5', label: 'Department5' },
    { value: '6', label: 'Department6' }
  ]
  const validationSchema = Yup.object().shape({
    parts: Yup.array().of(
      Yup.object().shape({
        jobCard: Yup.string().required('Job Card Type is required'),
        qunatityDiffrence: Yup.string().required('Qunatity Diffrence Type is required')

      })
    )
  })
  return (
        <Box className="form-wrapper">
            <Formik
                enableReinitialize={true}
                initialValues={{
                  tasks: [
                    {
                      jobCard: '',
                      qunatityDiffrence: ''
                    }
                  ]
                }}
                onSubmit={(values) => console.log('values======', values)}
                validationSchema={validationSchema}
            >
                {({
                  errors,
                  // handleBlur,
                  handleChange,
                  touched,
                  values,
                  setFieldValue
                }) => (
                    <React.Fragment>
                        <Form className="form-perent">
                            <Box className="form-child">

                                <FieldArray name="tasks">
                                    {({ push }) => (
                                        <React.Fragment>
                                            <Box className="gap-20">
                                                {values?.tasks?.length > 0 &&
                                                    values?.tasks?.map((tasks, index) => (
                                                        <Box key={index} >
                                                            <Box >
                                                                {/* To do fiture :
                                                                <Typography className="form-heading-1 pb-20">
                                                                    Task {index + 1}
                                                                    <span
                                                                        onClick={() => remove(index)}
                                                                        className="process-delete"
                                                                    >
                                                                        <DeleteIconNew />
                                                                    </span>
                                                                </Typography> */}
                                                                <Grid
                                                                    spacing={2}
                                                                    key={index}
                                                                    paddingBottom={'20px'}
                                                                    container
                                                                >
                                                                    <Grid item xs={12} md={6} xl={4}>
                                                                    <CustomSelect
                                                                            name={`tasks.${index}.jobCard`}
                                                                            label="Job card"
                                                                            placeholder="Select job card"
                                                                            className="default-select-new"
                                                                            options={options}
                                                                            value={values?.tasks?.[index]?.jobCard}
                                                                            handleSelectChange={(e) =>
                                                                              setFieldValue(
                                                                                    `tasks.${index}.jobCard`,
                                                                                    e?.target?.value
                                                                              )
                                                                            }
                                                                            errors={errors?.tasks?.[index]?.jobCard}
                                                                            touched={
                                                                                touched?.tasks?.[index]?.jobCard
                                                                            }
                                                                        />
                                                                    </Grid>

                                                                    <Grid item xs={12} md={6} xl={4}>
                                                                        <CustomInput
                                                                            label="Quantity Difference "
                                                                            placeholder="Enter quantity ifference "
                                                                            handleChange={handleChange}
                                                                            value={values?.tasks?.[index]?.qunatityDiffrence}
                                                                            name={`tasks.${index}.qunatityDiffrence`}
                                                                            InputProps={{
                                                                              endAdornment:
                                                                                    touched?.tasks?.[index]?.qunatityDiffrence &&
                                                                                    errors?.tasks?.[index]?.qunatityDiffrence &&
                                                                                    icon
                                                                            }}
                                                                            errors={errors?.tasks?.[index]?.qunatityDiffrence}
                                                                            touched={touched?.tasks?.[index]?.qunatityDiffrence}
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                            </Box>
                                                        </Box>
                                                    ))}
                                            </Box>
                                            <Box paddingTop={'10px'}>
                                                {' '}
                                                <CustomButton
                                                    variant="outlined"
                                                    className="button-box"
                                                    type="button"
                                                    title="Add "
                                                    endIcon={<Plus fill={'#0160B9'} />}
                                                    onClick={() => push({
                                                      objectType: '',
                                                      specificObjectType: '',
                                                      delay: '',
                                                      unit: ''
                                                    })}

                                                />
                                            </Box>
                                        </React.Fragment>
                                    )}
                                </FieldArray>
                            </Box>
                        </Form>
                    </React.Fragment>
                )}
            </Formik>
        </Box>
  )
}

export default UpdatedScenario
