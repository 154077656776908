import * as React from 'react'
import Box from '@mui/material/Box'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import styled from '@emotion/styled'
import { useMediaQuery, useTheme } from '@mui/material'
import { useNavigate } from 'react-router-dom'

const Activity = (props) => {
  const theme = useTheme()
  const isMdScreen = useMediaQuery(theme.breakpoints.only('md'))
  const {
    Component,
    data,
    title,
    height,
    isViewAll = false,
    isChart = false,
    isGrant = false,
    isGrantSF = false,
    isMutliCol = false,
    viewAllLink = ''
  } = props
  const CardWrapper = styled(Box)(() => ({
    borderRadius: '15px',
    padding: '10px',
    '.card-header': {
      position: 'sticky',
      zIndex: '2',
      height: isGrant || isGrantSF ? (isMdScreen ? '100px' : '40px') : '40px',
      alignItems: 'center'
    },
    '.header-box': {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
      alignItems: 'center',
      flexWrap: 'wrap'
    },
    '.card-block': {
      height,
      overflow: !isChart ? 'auto' : isGrant ? 'auto' : 'hidden',
      '@media(min-width: 425px) and (max-width: 1024px)': {
        height: '690px'
      },
      '@media(min-width: 1025px) and (max-width: 1440px)': {
        height: 'auto'
      }
    },
    '.MuiCardContent-root': {
      padding: '0px'
    },
    '.MuiCardContent-root:last-child': {
      paddingBottom: '0px'
    },
    '.heading-wrapper': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: '10px'
    },
    '.head-title': {
      color: '#020202',
      fontWeight: 600,
      fontSize: '18px'
    },
    '.view-link': {
      color: '#265AA8',
      fontWeight: 600,
      fontSize: '14px',
      cursor: 'pointer'
    },
    '.grant-button': {
      marginRight: '10px',
      border: '1px solid #D2D1D1',
      color: '#242424',
      textTransform: 'none',
      borderRadius: '8px',
      marginTop: isGrant || isGrantSF ? (isMdScreen ? '10px' : '0px') : '0px'
    }
  }))

  const navigate = useNavigate()
  return (
    <CardWrapper>
      <Box className="card-header heading-wrapper">
        <Box className="header-box">
          <Box display={'flex'}>
            <Box>
              <Typography className="head-title">{title}</Typography>
            </Box>
          </Box>

          {isViewAll && (
            <Box
              onClick={() => {
                viewAllLink && navigate(viewAllLink)
              }}
              className="view-link"
            >
              View All
            </Box>
          )}
        </Box>
      </Box>
      <CardContent>
        <Box className="card-block">
          {!isChart &&
            !isMutliCol &&
            data?.map((currentCard, ind) => {
              return <Component key={ind} cardData={currentCard} />
            })}
          {isChart && (
            <Component
              isEditBtn={
                ![
                  'Top Downtimes',
                  'TAT of Workers',
                  'Scrap Generations'
                ].includes(title)
              }
            />
          )}
        </Box>
      </CardContent>
    </CardWrapper>
  )
}

export default Activity
