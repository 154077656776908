import React from 'react'

const PrintIcon = () => {
  return (
    <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.375 1.75V4.25C13.375 4.59518 13.6548 4.875 14 4.875C14.3452 4.875 14.625 4.59518 14.625 4.25V1.125C14.625 0.779822 14.3452 0.5 14 0.5H4C3.65482 0.5 3.375 0.779822 3.375 1.125V4.25C3.375 4.59518 3.65482 4.875 4 4.875C4.34518 4.875 4.625 4.59518 4.625 4.25V1.75H13.375Z" fill="#265AA8"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M3.375 15.1875V9.875C3.375 9.52982 3.65482 9.25 4 9.25H14C14.3452 9.25 14.625 9.52982 14.625 9.875V15.1875C14.625 15.5327 14.3452 15.8125 14 15.8125H4C3.65482 15.8125 3.375 15.5327 3.375 15.1875ZM13.375 14.5625H4.625V10.5H13.375V14.5625Z" fill="#265AA8"/>
    <path d="M16.1875 11.125H14C13.6548 11.125 13.375 11.4048 13.375 11.75C13.375 12.0952 13.6548 12.375 14 12.375H16.8125C17.1577 12.375 17.4375 12.0952 17.4375 11.75V5.5C17.4375 4.71116 16.8393 4.15822 16.8393 4.15822C16.2624 3.625 15.4609 3.625 15.4609 3.625H2.53906C1.73762 3.625 1.16073 4.15822 1.16073 4.15822C0.5625 4.71116 0.5625 5.5 0.5625 5.5V11.75C0.5625 12.0952 0.842322 12.375 1.1875 12.375H4C4.34518 12.375 4.625 12.0952 4.625 11.75C4.625 11.4048 4.34518 11.125 4 11.125H1.8125V5.5C1.8125 5.25796 2.00919 5.07616 2.00919 5.07616C2.22682 4.875 2.53906 4.875 2.53906 4.875H15.4609C15.7732 4.875 15.9908 5.07616 15.9908 5.07616C16.1875 5.25796 16.1875 5.5 16.1875 5.5V11.125Z" fill="#265AA8"/>
    <path d="M14.625 7.0625C14.625 7.58023 14.2052 8 13.6875 8C13.1698 8 12.75 7.58023 12.75 7.0625C12.75 6.54477 13.1698 6.125 13.6875 6.125C14.2052 6.125 14.625 6.54477 14.625 7.0625Z" fill="#265AA8"/>
    </svg>
  )
}

export default PrintIcon
