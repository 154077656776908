import React from 'react'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import { Typography } from '@mui/material'
import './../customselect/customselect.css'
import Required from '../../pages/commoncomponents/required'

const CustomSelect = (props) => {
  const {
    value,
    label = '',
    handleSelectChange,
    options = [],
    isRequired = true,
    name = '',
    InputProps,
    placeholder = '',
    titleClassName = 'select-title',
    className = 'default-select-new ',
    touched = false,
    isFullWidth = true,
    errors = '',
    type = 'select',
    islabel = 'true'
  } = props
  return (
    <React.Fragment>
      <FormControl fullWidth={!!isFullWidth}>
        {islabel && (
          <Typography className={titleClassName}>
            {label}&nbsp;
            {isRequired && <Required />}
          </Typography>
        )}
        <Select
          value={value || ''} // Set the default selected value
          onChange={handleSelectChange}
          placeholder={placeholder}
          name={name}
          variant="outlined"
          InputProps={InputProps}
          className={
            !value ? 'disabled-text' : className
          }
          type={type}
          displayEmpty
          default-select-new
        >
          <MenuItem disabled value={''}>
            {placeholder}
          </MenuItem>
          {options?.map((data, ind) => {
            return (
              <MenuItem color="black" value={data?.value} key={ind}>
                {data?.label}
              </MenuItem>
            )
          })}
        </Select>
        {touched && errors && (
          <Typography className="validation-text-select">{errors}</Typography>
        )}
      </FormControl>
    </React.Fragment>
  )
}
export default CustomSelect
