const resource = {
  RESOURCE: 'RESOURCE',
  RESOURCES: 'RESOURCES',
  UPDATERESOURCE: 'UPDATERESOURCE',
  RESTORERESOURCE: 'RESTORERESOURCE',
  SETTER: 'SETTER',
  UPDATESETTER: 'UPDATESETTER',
  UPDATEOPERATOR: 'UPDATEOPERATOR',
  OPERATOR: 'OPERATOR'
}
export { resource }
