import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { role } from '../querykeys/role'
import { useNavigate } from 'react-router-dom'
import { roleApi } from '../api/role'
// import { DepartmentErrI, DepartmentListErrI } from '../Types/department';

export const useGetRole = (isEnable, page) => {
  return useQuery([role.ROLE, page], roleApi.getRole, {
    enabled: isEnable,
    select: (res) => res?.data,
    // ! TODO: for future implementation toast
    // onSuccess: () => {
    //   // const data = res?.data;
    // },
    // onError: (err) => {
    //   return err;
    //   // const errData = err?.data;
    //   // toast.error(errData.message);
    // },
    refetchOnWindowFocus: false
  })
}

export const useAddRole = () => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  return useMutation(roleApi.addUser, {
    onSuccess: () => {
      queryClient.invalidateQueries([role.ROLE])
      navigate('/user-management', { state: { activeTab: '2' } })
    },
    onError: () => {

      // ! TODO: for future implementation toast
      // toast.error(errData.message);
    }
  })
}

export const useDeleteRole = () => {
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  return useMutation(roleApi.deleteRole, {
    onSuccess: (res, id) => {
      // const data = res.data;
      queryClient.invalidateQueries([role.ROLES])
      queryClient.invalidateQueries([role.ROLES, id])
      // toast.success(data?.message);
      navigate('/user-management', { state: { activeTab: '2' } })
    }
    // onError: () => {
    //   // const errData = err.data;
    //   // toast.error(errData?.message);
    // },
  })
}

export const useGetFullRole = (isEnable = true) => {
  return useQuery(
    [role.ROLE],
    roleApi.getFullRoles,
    {
      select: (data) => data?.data,
      retry: 0,
      enabled: isEnable,
      // onSuccess: (res: any) => {
      //   const data = res?.data;
      //   //toast.success(data?.message);
      // },
      // onError: (err: any) => {
      //   const errData = err?.data;
      //   //toast.error(errData.message);
      // },
      refetchOnWindowFocus: false
    }
  )
}
