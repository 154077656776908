import { Box, CircularProgress, Tab, Typography, useMediaQuery, useTheme } from '@mui/material'
import React, { useState } from 'react'
import Tabs from '@mui/material/Tabs'
import { TabContext, TabPanel } from '@mui/lab'
import AddIcon from '@mui/icons-material/Add'
import CustomButton from '../../leastcomponent/custombutton/custombutton'
import TableComponent from '../../leastcomponent/customtable/customtable'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'
import DeleteIconNew from '../../assets/svg/DeleteIconNew'
import DeleteWarningModal from '../../modals/deletewarningmodal'
import Kanban from '../../assets/svg/Kanban'
import Copy from '../../assets/svg/Copy'
import BreadcrumbNavigation from '../commoncomponents/breadcrumbnavigation'
import {
  useGetSalesTemMemberJobCard,
  // useUpdateSalesMember,
  useGetSalesTemMemberApprovedJobCard,
  useJobSalesteamMember,
  useGetSalesTemMemberCompletionJobCard,
  useGetSalesTemMemberOngoingJobCard
} from '../../hooks/jobcardHooks'
import { useQueryClient } from '@tanstack/react-query'
import { user } from '../../querykeys/usermanagement'

const SalesTeamMember = () => {
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up('lg'))
  const [value, setValue] = useState('1')
  const [currentId, setCurrentId] = useState('')
  const [deleteRecord, setDeleteRecord] = useState(false)
  const handleDeleteOpen = () => setDeleteRecord(true)
  const handleDeleteClose = () => setDeleteRecord(false)
  const [page, setPage] = useState(1)
  const [approvedInfoPage, setApprovedInfoPage] = useState(1)
  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const navigate = useNavigate()
  const { data: salesTemMemberInfo, isFetching: isSalesTeamMemberInfo } = useGetSalesTemMemberJobCard(
    value === '1',
    page
  )

  // const { mutate: updateSalesMember } = useUpdateSalesMember()
  const { mutate: deleteJobSalesteamMember } = useJobSalesteamMember()
  const salesTemMember = salesTemMemberInfo?.results

  const rows = salesTemMember?.map((data, ind) => {
    const startIndex = (page - 1) * 10
    return {
      id: data.id,
      no: startIndex + ind + 1,
      name: data.name,
      number: data.number,
      customerName: data.customerName,
      productName: data.productName,
      productCode: data.productCode,
      quantity: data.quantity,
      submitted: moment(data?.submitted).format('DD MMM, YYYY')
    }
  })

  const {
    data: salesTemMemberApprovedInfo, isFetching: isSalesTemMemberApprovedInfo
  } = useGetSalesTemMemberApprovedJobCard(value === '1',
    approvedInfoPage)

  const salesTemMemberApproved = salesTemMemberApprovedInfo?.results
  const approvedRows = salesTemMemberApproved?.map((data, ind) => {
    const startIndex = (approvedInfoPage - 1) * 10
    return {
      id: data.id,
      no: startIndex + ind + 1,
      name: data.name,
      number: data.number,
      customerName: data.customerName,
      productName: data.productName,
      productCode: data.productCode,
      quantity: data.quantity,
      approveDate: moment(data?.approveDate).format('DD MMM, YYYY')
    }
  })

  const {
    data: salesTemMemberOngoingInfo, isFetching: isSalesTemMemberOngoingInfo
  } = useGetSalesTemMemberOngoingJobCard(value === '2',
    page)

  const salesTemMemberOngoing = salesTemMemberOngoingInfo?.results
  const ongoingRows = salesTemMemberOngoing?.map((data, ind) => {
    // const startIndex = (page - 1) * 10;
    return {
      id: data.id,
      no: ind + 1,
      name: data.name,
      number: data.number,
      customerName: data.customerName,
      productName: data.productName,
      productCode: data.productCode,
      quantity: data.quantity,
      submitted: moment(data?.submitted).format('DD MMM, YYYY')
    }
  })
  const {
    data: salesTemMemberCompletionInfo, isFetching: isSalesTemMemberCompletionInfo
  } = useGetSalesTemMemberCompletionJobCard(value === '3',
    page)

  const salesTemMemberCmpletion = salesTemMemberCompletionInfo?.results
  const completionRows = salesTemMemberCmpletion?.map((data, ind) => {
    // const startIndex = (page - 1) * 10;
    return {
      id: data.id,
      no: ind + 1,
      name: data.name,
      number: data.number,
      customerName: data.customerName,
      productName: data.productName,
      productCode: data.productCode,
      quantity: data.quantity,
      submitted: moment(data?.submitted).format('DD MMM, YYYY')
    }
  })
  const columns = [
    { field: 'no', headerName: '#', flex: 0.2 },
    { field: 'name', headerName: 'Job Card Name', flex: matches ? 1 : 0.7 },
    { field: 'number', headerName: 'Job Card Number', flex: matches ? 1 : 0.5 },
    {
      field: 'customerName',
      headerName: 'Customer Name',
      flex: matches ? 1 : 0.7
    },
    {
      field: 'productName',
      headerName: 'Product Name',
      flex: matches ? 1 : 0.5
    },
    {
      field: 'productCode',
      headerName: 'Product Code',
      flex: matches ? 1 : 0.5
    },
    { field: 'quantity', headerName: 'Quantity', flex: matches ? 1 : 0.5 },
    {
      field: 'submitted',
      headerName: 'Submitted On',
      flex: matches ? 1 : 0.7
    },
    {
      field: 'actions',
      headerName: 'Actions',
      sortable: false,
      disableClickEventBubbling: true,
      flex: 1.5,
      renderCell: (params) => {
        return (
          <Box className="table-icon-new">
            <Box
              // onClick={() => {
              //   if (params?.row) {
              //     updateSalesMember({
              //       id: params.row.id,
              //       priority: 'standard'
              //     })
              //   }
              // }}
            >
              <span className="table-approved">Approved</span>
            </Box>

            <Box className="table-btn-main">
              <span onClick={() => {
                setCurrentId(params.row.id)
                handleDeleteOpen()
              }} className="process-delete">
                <DeleteIconNew />
              </span>
            </Box>
            <Box className="table-btn-main">
              <span
                className="process-delete"
                onClick={() => navigate(`/schedule/${params.row.id}`)}
              >
                <Kanban />
              </span>
            </Box>
            <Box className="table-btn-main">
              <span
                className="process-delete"
              >
                <Copy />
              </span>
            </Box>
          </Box>
        )
      }
    }
  ]
  const approvedColumns = [
    { field: 'no', headerName: '#', flex: 0.2 },
    { field: 'name', headerName: 'Job Card Name', flex: matches ? 1 : 0.7 },
    { field: 'number', headerName: 'Job Card Number', flex: matches ? 1 : 0.7 },
    { field: 'customerName', headerName: 'Customer Name', flex: matches ? 1 : 0.7 },
    {
      field: 'productName',
      headerName: 'Product Name',
      flex: matches ? 1 : 0.7
    },
    {
      field: 'productCode',
      headerName: 'Product Code',
      flex: matches ? 1 : 0.7
    },
    { field: 'quantity', headerName: 'Quantity', flex: matches ? 1 : 0.7 },
    { field: 'approveDate', headerName: 'Approved Date', flex: matches ? 1 : 0.7 },
    {
      field: 'actions',
      headerName: 'Actions',
      sortable: false,
      disableClickEventBubbling: true,
      flex: 1.5,
      renderCell: (params) => {
        return (
          <Box className="table-icon-new">
            <Box className="table-btn-main">
              <span onClick={() => {
                setCurrentId(params.row.id)
                handleDeleteOpen()
              }} className="process-delete">
                <DeleteIconNew />
              </span>
            </Box>
            <Box className="table-btn-main">
              <span
                className="process-delete"
                onClick={() => navigate(`/schedule/${params.row.id}`)}
              >
                <Kanban />
              </span>
            </Box>
            <Box className="table-btn-main">
              <span
                className="process-delete"
              >
                <Copy />
              </span>
            </Box>
          </Box>
        )
      }
    }
  ]
  const ongoingColumns = [
    { field: 'no', headerName: '#', flex: 0.2 },
    { field: 'name', headerName: 'Job Card Name', flex: 1 },
    { field: 'number', headerName: 'Job Card Number', flex: 1 },
    { field: 'productName', headerName: 'Product Name', flex: 1 },
    {
      field: 'productCode',
      headerName: 'Product Code',
      flex: matches ? 1 : 0.7
    },
    { field: 'quantity', headerName: 'Quantity', flex: matches ? 1 : 0.7 },
    { field: 'date', headerName: 'Submitted Date', flex: matches ? 1 : 0.7 },
    { field: 'status', headerName: 'Status', flex: matches ? 1 : 0.7 },
    {
      field: 'expectedCompletion',
      headerName: 'Expected Completion',
      flex: matches ? 1 : 0.7
    },
    {
      field: 'actions',
      headerName: 'Actions',
      sortable: false,
      disableClickEventBubbling: true,
      flex: 1,
      renderCell: (params) => {
        return (
          <Box sx={{ display: 'flex', gap: '10px' }}>
            <Box className="table-btn-main">
              <span
                className="process-delete"
                onClick={() => navigate(`/schedule/${params.row.id}`)}
              >
                <Kanban />
              </span>
            </Box>
            <Box className="table-btn-main">
              <span
                className="process-delete"
              >
                <Copy />
              </span>
            </Box>
          </Box>
        )
      }
    }
  ]
  const completionColumns = [
    { field: 'no', headerName: '#', flex: 0.2 },
    { field: 'name', headerName: 'Job Card Name', flex: 1 },
    { field: 'number', headerName: 'Job Card Number', flex: 1 },
    { field: 'productName', headerName: 'Product Name', flex: 1 },
    { field: 'productCode', headerName: 'Product Code', flex: 1 },
    { field: 'quantity', headerName: 'Quantity', flex: matches ? 1 : 0.7 },
    { field: 'date', headerName: 'Submitted Date', flex: matches ? 1 : 0.7 },
    { field: 'date', headerName: 'Completion Date', flex: matches ? 1 : 0.7 },
    {
      field: 'actions',
      headerName: 'Actions',
      sortable: false,
      disableClickEventBubbling: true,
      flex: 1,
      renderCell: (params) => {
        return (
          <Box sx={{ display: 'flex', gap: '10px' }}>
            <Box className="table-btn-main">
              <span
                className="process-delete"
                onClick={() => navigate(`/schedule/${params.row.id}`)}
              >
                <Kanban />
              </span>
            </Box>
            <Box className="table-btn-main">
              <span
                className="process-delete"
              >
                <Copy />
              </span>
            </Box>
          </Box>
        )
      }
    }
  ]
  const queryClient = useQueryClient()
  const userData = queryClient.getQueryData([user.LOGGED_IN_USER])
  const departmentGroupTitle = userData?.data?.data?.user?.roleId?.departmentGroup?.title
  return (
    <React.Fragment>
      <Box className="production-team-head-overflow-hidden">
        <BreadcrumbNavigation
          breadCrumbData={null}
          pageTitle={`Job Cards - ${userData?.data?.data?.user?.userRoleType === 'administrator' ? 'All' : departmentGroupTitle}`}
          backToLink={null}
        />
        <Box className="production-team-head-overflow-auto">
          <TabContext value={value}>
            <Box
              sx={{
                typography: 'body1'
              }}
              className="tab-content-padding"
            >
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="lab API tabs example"
              >
                <Tab
                  className="tab-head"
                  label="Submitted & Approved"
                  value="1"
                />
                <Tab className="tab-head" label="Ongoing" value="2" />
                <Tab className="tab-head" label="Completed" value="3" />
              </Tabs>
            </Box>
            <TabPanel value="1" className="tab-panel-padding">
              <Box>
                <Box className="common-parent-haeding-btn-div">
                  <Typography className="common-tabel-heading">
                    Submitted Job cards
                  </Typography>
                  <CustomButton
                    variant="contained"
                    title="Add Job Card"
                    type="button"
                    onClick={() => {
                      navigate('/add-job-card')
                    }}
                    endIcon=""
                    startIcon={<AddIcon />}
                  />
                </Box>
                {!isSalesTeamMemberInfo
                  ? <TableComponent
                  rows={rows}
                  columns={columns}
                  setPage={setPage}
                  page={page}
                  totalResults={salesTemMemberInfo?.totalResults}
                  paginationData={salesTemMemberInfo}
                  // detailsRedirection={'/details-card'}
                />
                  : <Box className='progress-parent'>
                <CircularProgress />
              </Box>}
              </Box>
              <Box>
                <Box className="common-parent-haeding-btn-div">
                  <Typography className="common-tabel-heading">
                    Approved Job cards
                  </Typography>
                </Box>
                {!isSalesTemMemberApprovedInfo
                  ? <TableComponent
                  rows={approvedRows}
                  columns={approvedColumns}
                  page={approvedInfoPage}
                  setPage={setApprovedInfoPage}
                 totalResults={salesTemMemberApprovedInfo?.totalResults}
                 paginationData={salesTemMemberApprovedInfo}
                  detailsRedirection={'/job-card-details'}
                />
                  : <Box className='progress-parent'>
                <CircularProgress />
              </Box>}
              </Box>
            </TabPanel>

            <TabPanel value="2" className="tab-panel-padding">
              <Box>
                <Box className="common-parent-haeding-btn-div">
                  <Typography className="common-tabel-heading">
                    Ongoing Job cards
                  </Typography>
                  <CustomButton
                    variant="contained"
                    title="Add Job Card"
                    onClick={() => {
                      navigate('/add-job-card')
                    }}
                    type="button"
                    endIcon=""
                    startIcon={<AddIcon />}
                  />
                </Box>
                {!isSalesTemMemberOngoingInfo
                  ? <TableComponent rows={ongoingRows} columns={ongoingColumns} />
                  : <Box className='progress-parent'>
                <CircularProgress />
              </Box>}
              </Box>
            </TabPanel>
            <TabPanel value="3" className="tab-panel-padding">
              <Box>
                <Box className="common-parent-haeding-btn-div">
                  <Typography className="common-tabel-heading">
                    Completed Job cards
                  </Typography>
                  <CustomButton
                    variant="contained"
                    title="Add Job Card"
                    onClick={() => {
                      navigate('/add-job-card')
                    }}
                    type="button"
                    endIcon=""
                    startIcon={<AddIcon />}
                  />
                </Box>
                {!isSalesTemMemberCompletionInfo
                  ? <TableComponent
                  rows={completionRows}
                  columns={completionColumns}
                />
                  : <Box className='progress-parent'>
                <CircularProgress />
              </Box>}
              </Box>
            </TabPanel>
          </TabContext>
        </Box>

        {value === '1' && deleteRecord && currentId && (
          <DeleteWarningModal
            open={deleteRecord}
            handleOpen={handleDeleteOpen}
            handleClose={handleDeleteClose}
            handleDelete={deleteJobSalesteamMember}
            id={currentId}
          />
        )}
      </Box>
    </React.Fragment>
  )
}

export default SalesTeamMember
