import { Box, CircularProgress, Grid, Tab, Typography } from '@mui/material'
import React, { useState } from 'react'
import Tabs from '@mui/material/Tabs'
import { TabContext, TabPanel } from '@mui/lab'
import TableComponent from '../../leastcomponent/customtable/customtable'
import DeleteWarningModal from '../../modals/deletewarningmodal'
import BreadcrumbNavigation from '../commoncomponents/breadcrumbnavigation'
// import BlackKanban from '../../assets/svg/blackKanban'
import './jobslipsnumber.css'
import { useNavigate } from 'react-router-dom'
import { useGetJobSlips } from '../../hooks/jobslipshooks'
import moment from 'moment'
import NoAddedYet from '../../assets/svg/NoAddedYet'
import { formatDateTitle } from '../../utils/dateTitle'

const JobSlips = () => {
  const navigate = useNavigate()
  const [value, setValue] = useState(location?.state?.activeTab || '1')
  const [page, setPage] = useState(1)

  const [deleteRecord, setDeleteRecord] = useState(false)
  const handleDeleteOpen = () => setDeleteRecord(true)
  const handleDeleteClose = () => setDeleteRecord(false)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const statusMapping = {
    1: 'ongoing',
    2: 'planned',
    3: 'past'
  }

  const { data: getJobSlipsData, isFetching: isGetJobSlipsData } = useGetJobSlips(
    !!value,
    page,
    statusMapping[value]
  )
  const jobSlipsList = getJobSlipsData?.results
  const rows = getJobSlipsData?.results?.map((data, ind) => {
    const startIndex = (page - 1) * 10
    return {
      no: startIndex + ind + 1,
      id: data?.id,
      number: data?.orderNumber ? data?.orderNumber : '-',
      name: data?.name ? data?.name : '-',
      status: data?.status ? data?.status : '-',
      date: data?.date ? moment.utc(data?.date)?.format('DD/MM/YY') : '-'
      // createdAt: data?.createdAt
      //   ? moment.utc(data?.createdAt)?.format('DD/MM/YY , hh:MM')
      //   : '-',
      // createBy: '-',
      // completedOn: data?.completedOn
      //   ? moment.utc(data?.completedOn)?.format('DD/MM/YY , hh:MM')
      //   : '-'
    }
  })

  const columns = [
    { field: 'no', headerName: '#', flex: 0.3 },
    { field: 'number', headerName: 'Job slip Number', flex: 1 },
    { field: 'date', headerName: 'Job slip date', flex: 1 },
    { field: 'name', headerName: 'Shift', flex: 1 },
    { field: 'status', headerName: 'Status', flex: 1 }
    // { field: 'createdAt', headerName: 'Created on', flex: 1 },
    // { field: 'createBy', headerName: 'Created by', flex: 1 },
    // { field: 'completedOn', headerName: 'Completed on', flex: 1 }
  ]

  return (
    <Box className="production-team-head-overflow-hidden">
      <BreadcrumbNavigation
        breadCrumbData={null}
        pageTitle={`Job slips - ${getJobSlipsData?.departmentGroupName}`}
        backToLink={null}
        activeTab={value}
      />

      <Box className="production-team-head-overflow-auto">
        <TabContext value={value}>
          <Box
            sx={{
              typography: 'body1'
            }}
            className="tab-content-padding"
          >
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="lab API tabs example"
            >
              <Tab className="tab-head" label="Ongoing" value="1" />
              <Tab className="tab-head" label="Planned" value="2" />
              <Tab className="tab-head" label="Past" value="3" />
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  marginLeft: '80%'
                }}
              >
                {/* <BlackKanban /> */}
              </Box>
            </Tabs>
          </Box>

          <TabPanel value="1" className="tab-panel-padding">
            <Box className='parent-ongoing-box'>
          {!isGetJobSlipsData
            ? <Grid container spacing={0} className="job-slips-main-box-ongoing">
              {jobSlipsList?.length > 0
                ? jobSlipsList?.map((data, indx) => (
                <Grid
                  item
                  xl={4}
                  lg={4}
                  md={4}
                  key={indx}
                  className="job-slips-shift-report"
                  sx={{ cursor: 'pointer' }}
                  onClick={() => navigate(`/job-slips-number/${data?.id}`)}
                >
                  <Typography className="job-slips-shift-report-date">
                    {data?.date
                      ? moment.utc(data?.date)?.format('DD MMM, YYYY')
                      : '-'}
                  </Typography>
                  <Typography>
                    {' '}
                    <span className="job-slips-shift-report-days-name">
                      {formatDateTitle(data?.date)},
                    </span>{' '}
                    <span className="job-slips-shift-day">{data?.name}</span>
                  </Typography>
                </Grid>
                ))
                : <Box display={'flex'} justifyContent={'center'} alignItems={'center'} maxHeight={'5000px'}>
                <Box textAlign={'center'}>
                  <NoAddedYet />
                  <Typography>{'There are no job slips'}</Typography>
                </Box>
              </Box>}
            </Grid>
            : <Box className='progress-parent'>
                  <CircularProgress />
                </Box>
          }

            </Box>
          </TabPanel>
          <TabPanel value="2" className="tab-panel-padding">
          <Box className='parent-ongoing-box'>
            {!isGetJobSlipsData
              ? <Grid container spacing={0} className="job-slips-main-box-ongoing">
              {jobSlipsList?.length > 0
                ? jobSlipsList?.map((data, indx) => (
                <Grid
                  item
                  xl={4}
                  lg={4}
                  md={4}
                  key={indx}
                  className="job-slips-shift-report"
                  sx={{ cursor: 'pointer' }}
                  onClick={() => navigate(`/job-slips-number/${data?.id}`)}
                >
                  <Typography className="job-slips-shift-report-date">
                    {data?.date
                      ? moment.utc(data?.date)?.format('DD MMM, YYYY')
                      : '-'}
                  </Typography>
                  <Typography>
                    {' '}
                    <span className="job-slips-shift-report-days-name">
                    {formatDateTitle(data?.date)},
                    </span>{' '}
                    <span className="job-slips-shift-day">{data?.name}</span>
                  </Typography>
                </Grid>
                ))
                : <Box display={'flex'} justifyContent={'center'} alignItems={'center'} maxHeight={'5000px'}>
                <Box textAlign={'center'}>
                  <NoAddedYet />
                  <Typography>{'There are no job slips'}</Typography>
                </Box>
              </Box>}
            </Grid>
              : <Box className='progress-parent'>
            <CircularProgress />
          </Box>}
          </Box>
          </TabPanel>
          <TabPanel value="3" className="tab-panel-padding">
            <Box>
              <Box className="common-parent-haeding-btn-div">
                <Typography className="common-tabel-heading">
                  Past Job slips
                </Typography>
              </Box>
            {!isGetJobSlipsData
              ? <TableComponent
                rows={rows}
                columns={columns}
                detailsRedirection={'/job-slips-number'}
                page={page}
                setPage={setPage}
                totalResults={getJobSlipsData?.totalResults}
                paginationData={getJobSlipsData}
              />
              : <Box className='progress-parent'>
              <CircularProgress />
            </Box>}
            </Box>
          </TabPanel>
        </TabContext>
        {deleteRecord && (
          <DeleteWarningModal
            open={deleteRecord}
            handleOpen={handleDeleteOpen}
            handleClose={handleDeleteClose}
          />
        )}
      </Box>
    </Box>
  )
}

export default JobSlips
