import React from 'react'
import './simulate.css'
import {
  Box,
  Grid
} from '@mui/material'
import { FieldArray, Form, Formik } from 'formik'
import CustomButton from '../../leastcomponent/custombutton/custombutton'
import * as Yup from 'yup'
import CustomSelect from '../../leastcomponent/customselect/customselect'
// import DeleteIconNew from '../../assets/svg/DeleteIconNew';
import Plus from '../../assets/svg/Plus'

const UnavialableScenario = () => {
  const options = [
    { value: '1', label: 'Department1' },
    { value: '2', label: 'Department2' },
    { value: '3', label: 'Department3' },
    { value: '4', label: 'Department4' },
    { value: '5', label: 'Department5' },
    { value: '6', label: 'Department6' }
  ]
  const validationSchema = Yup.object().shape({
    parts: Yup.array().of(
      Yup.object().shape({
        machine: Yup.string().required('machine Type is required')
      })
    )
  })
  return (
        <Box className="form-wrapper">

            <Formik
                enableReinitialize={true}
                initialValues={{
                  tasks: [
                    {
                      machine: ''
                    }
                  ]
                }}
                onSubmit={(values) => console.log('values======', values)}
                validationSchema={validationSchema}
            >
                {({
                  errors,
                  touched,
                  values,
                  setFieldValue
                }) => (
                    <React.Fragment>
                        <Form className="form-perent">
                            <Box className="form-child">

                                <FieldArray name="tasks">
                                    {({ push }) => (
                                        <React.Fragment>
                                            <Box className="gap-20">
                                                {values?.tasks?.length > 0 &&
                                                    values?.tasks?.map((tasks, index) => (
                                                        <Box key={index} >
                                                            <Box >
                                                                {/* To do fiture :
                                                                 <Typography className="form-heading-1 pb-20">
                                                                    Task {index + 1}
                                                                    <span
                                                                        onClick={() => remove(index)}
                                                                        className="process-delete"
                                                                    >
                                                                        <DeleteIconNew />
                                                                    </span>
                                                                </Typography> */}
                                                                <Grid
                                                                    spacing={2}
                                                                    key={index}
                                                                    paddingBottom={'20px'}
                                                                    container
                                                                >
                                                                    <Grid item xs={12} md={6} xl={4}>
                                                                    <CustomSelect
                                                                            name={`tasks.${index}.machine`}
                                                                            label="Unavailable Machine"
                                                                            placeholder="Select Unavailable Machine"
                                                                            className="default-select-new"
                                                                            options={options}
                                                                            value={values?.tasks?.[index]?.machine}
                                                                            handleSelectChange={(e) =>
                                                                              setFieldValue(
                                                                                    `tasks.${index}.machine`,
                                                                                    e?.target?.value
                                                                              )
                                                                            }
                                                                            errors={errors?.tasks?.[index]?.machine}
                                                                            touched={
                                                                                touched?.tasks?.[index]?.machine
                                                                            }
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                            </Box>
                                                        </Box>
                                                    ))}
                                            </Box>
                                            <Box paddingTop={'10px'}>
                                                {' '}
                                                <CustomButton
                                                    variant="outlined"
                                                    className="button-box"
                                                    type="button"
                                                    title="Add "
                                                    endIcon={<Plus fill={'#0160B9'} />}
                                                    onClick={() => push({
                                                      machine: ''
                                                    })}

                                                />
                                            </Box>
                                        </React.Fragment>
                                    )}
                                </FieldArray>
                            </Box>
                        </Form>
                    </React.Fragment>
                )}
            </Formik>
        </Box>
  )
}

export default UnavialableScenario
