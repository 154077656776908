import React from 'react'
import './userManagement.css'
import { Box, Divider, Grid, Typography } from '@mui/material'
// import BreadCrumb from "../../leastcomponent/breadcrumb/breadcrumb";
import { Form, Formik } from 'formik'
import CustomInput from '../../leastcomponent/custominput/input'
import { AiOutlineInfoCircle } from 'react-icons/ai'
import CustomButton from '../../leastcomponent/custombutton/custombutton'
import * as Yup from 'yup'
import CustomSelect from '../../leastcomponent/customselect/customselect'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import TableComponent from '../../leastcomponent/customtable/customtable'
import BreadcrumbNavigation from '../commoncomponents/breadcrumbnavigation'

const CreateRole = () => {
  const breadCrumbData = [
    {
      title: 'User Management',
      link: '/user-management'
    },
    {
      title: 'Add New Role',
      link: ''
    }
  ]

  const icon = <AiOutlineInfoCircle color="red" />

  const options = [
    { value: 'Department1', label: 'Department1' },
    { value: 'Department2', label: 'Department2' },
    { value: 'Department3', label: 'Department3' },
    { value: 'Department4', label: 'Department4' },
    { value: 'Department5', label: 'Department5' },
    { value: 'Department6', label: 'Department6' }
  ]

  const rows = [
    {
      id: 1,
      module: 'Create Job Cards'
    },
    {
      id: 2,
      module: 'Approve Job Cards'
    },
    {
      id: 3,
      module: 'Add tasks to Job Cards'
    },
    {
      id: 4,
      module: 'Schedule Job Cards'
    },
    {
      id: 5,
      module: 'Plans & job slips'
    }
  ]

  const columns = [
    { field: 'module', headerName: 'Module', flex: 1 },
    {
      field: 'view',
      headerName: 'View',
      sortable: false,
      disableClickEventBubbling: true,
      flex: 0.5,
      renderCell: (params) => {
        return (
          <Box className="resource-table-btn-main">
            <input type="checkbox" checked={params.row.selected} />
            <ErrorOutlineIcon fontSize="small" />
          </Box>
        )
      }
    },
    {
      field: 'edit',
      headerName: 'Edit',
      sortable: false,
      disableClickEventBubbling: true,
      flex: 0.5,
      renderCell: (params) => {
        return (
          <Box className="resource-table-btn-main">
            <input type="checkbox" checked={params.row.selected} />
            <ErrorOutlineIcon fontSize="small" />
          </Box>
        )
      }
    }
  ]

  const rows1 = [
    {
      id: 1,
      event: 'User Added'
    },
    {
      id: 2,
      event: 'User Removed'
    },
    {
      id: 3,
      event: 'User Updated'
    },
    {
      id: 4,
      event: 'Resource Added'
    },
    {
      id: 5,
      event: 'Job Cards approved'
    },
    {
      id: 6,
      event: 'Job Cards completed'
    },
    {
      id: 7,
      event: 'Tasks added'
    },
    {
      id: 8,
      event: 'Tasks started'
    },
    {
      id: 9,
      event: 'Tasks scheduled'
    },
    {
      id: 10,
      event: 'Tasks completed'
    }
  ]

  const columns1 = [
    { field: 'event', headerName: 'Event', flex: 1 },
    {
      field: 'dashboard',
      headerName: 'Dashboard',
      sortable: false,
      disableClickEventBubbling: true,
      flex: 0.5,
      renderCell: (params) => {
        return (
          <Box className="resource-table-btn-main">
            <input type="checkbox" checked={params.row.selected} />
          </Box>
        )
      }
    },
    {
      field: 'email',
      headerName: 'Email',
      sortable: false,
      disableClickEventBubbling: true,
      flex: 0.5,
      renderCell: (params) => {
        return (
          <Box className="resource-table-btn-main">
            <input type="checkbox" checked={params.row.selected} />
          </Box>
        )
      }
    }
  ]

  const validationSchema = Yup.object().shape({
    department: Yup.string().required('Department is required'),
    role: Yup.string().required('Role is required')
  })
  return (
    <Box className="production-team-head-overflow-hidden">
      <BreadcrumbNavigation
        breadCrumbData={breadCrumbData}
        pageTitle={'Add New Role'}
        backToLink={'/user-management'}
      />
      <Box className="form-wrapper">
        {/* <Box className="padding-y-40">
          <BreadCrumb
            breadCrumbData={breadCrumbData}
            className="padding-y-20"
            title="Add New Role"
          />
        </Box> */}
        <Typography className="form-heading"> Enter Role Details </Typography>
        <Formik
          enableReinitialize={true}
          initialValues={{
            department: '',
            role: ''
          }}
          onSubmit={(values) => console.log('values======', values)}
          validationSchema={validationSchema}
        >
          {({ errors, handleChange, touched, values, setFieldValue }) => (
            <React.Fragment>
              <Form className="role-form-perent">
                <Box className="role-form-child">
                  <Box className="">
                    <Box className="padding-y-60">
                      <Grid spacing={7} container paddingTop={2}>
                        <Grid item xs={12} md={6} xl={3}>
                          <CustomInput
                            label="Role"
                            placeholder="Enter Role"
                            handleChange={handleChange}
                            value={values?.role}
                            name="role"
                            InputProps={{
                              endAdornment:
                                touched?.role && errors?.role && icon
                            }}
                            errors={errors?.role}
                            touched={touched?.role}
                          />
                        </Grid>
                        <Grid item xs={12} md={6} xl={3}>
                          <CustomSelect
                            name="department"
                            label="Department / Department Group"
                            placeholder="Select Department / Department Group"
                            className="default-select-new"
                            options={options}
                            value={values?.department}
                            handleSelectChange={(e) =>
                              setFieldValue('department', e?.target?.value)
                            }
                            errors={errors?.department}
                            touched={touched?.department}
                          />
                        </Grid>
                      </Grid>
                      <Divider />
                    </Box>
                    <Box padding={'0px 0px 0px 60px'}>
                      <Typography className="form-heading-1 ">
                        Module and Action Access
                      </Typography>
                      <Box width={'30%'}>
                        <TableComponent
                          isShowFiveRecords={true}
                          rows={rows}
                          columns={columns}
                        />
                        <Grid container>
                          <Grid item xs={12} md={6} xl={4}>
                            <CustomInput
                              disabled={true}
                              value="Dashboard"
                              isRequired={false}
                            />
                          </Grid>
                          <Grid item xs={12} md={6} xl={6}>
                            <CustomSelect
                              name="department"
                              isRequired={false}
                              placeholder="Select "
                              className="default-select-new"
                              options={options}
                              value={values?.department}
                              handleSelectChange={(e) =>
                                setFieldValue('department', e?.target?.value)
                              }
                              errors={errors?.department}
                              touched={touched?.department}
                            />
                          </Grid>
                        </Grid>
                      </Box>
                      <Divider />
                    </Box>
                    <Box padding={'5px 60px 0px'}>
                      <Typography className="form-heading-1 ">
                        Notification Setup
                      </Typography>
                      <Box width={'30%'} paddingBottom={'0px'}>
                        <TableComponent rows={rows1} columns={columns1} />
                      </Box>
                    </Box>
                  </Box>
                </Box>
                <Box className="button-child">
                  <CustomButton title="Save Role" type="submit" />
                </Box>
              </Form>
            </React.Fragment>
          )}
        </Formik>
      </Box>
    </Box>
  )
}

export default CreateRole
