import React from 'react'
import '../cardInfo/cardInfo.css'
import { Box, Grid, Typography } from '@mui/material'

const CardInfo = ({ title, count, data }) => {
  return (
        <React.Fragment>
            <Box className='parent-Card-info'>
                <Typography className='info-title'>{title}</Typography>
                <Grid container spacing={0}>
                    <Grid item xs={6} className='grid-info-1'>
                        <Typography className='card-value'>
                            {count}
                        </Typography>
                        <Typography className='info-description'>
                            Count
                        </Typography>
                    </Grid>
                    <Grid item xs={6} className='grid-info-2'>
                        <Typography className='card-value'>
                            {data}
                        </Typography>
                        <Typography className='info-description'>
                            On - Time
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
        </React.Fragment>
  )
}

export default CardInfo
