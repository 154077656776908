import React from 'react'
import './mrp.css'
import { Box, Grid } from '@mui/material'
// import BreadCrumb from "../../leastcomponent/breadcrumb/breadcrumb";
import TableComponent from '../../leastcomponent/customtable/customtable'
import CustomButton from '../../leastcomponent/custombutton/custombutton'
import BreadcrumbNavigation from '../commoncomponents/breadcrumbnavigation'

const NewMrp = () => {
  const rows = [
    {
      id: 1,
      rawMaterial: 'Raw Material',
      dateNeeded: '10/10/10, 10:10',
      cardName: 'Job Card',
      cardNumber: '123-456-789',
      forPart: 'Part 1 ',
      partCode: '12345'
    },
    {
      id: 2,
      rawMaterial: 'Raw Material',
      dateNeeded: '10/10/10, 10:10',
      cardName: 'Job Card',
      cardNumber: '123-456-789',
      forPart: 'Part 1 ',
      partCode: '12345'
    },
    {
      id: 3,
      rawMaterial: 'Raw Material',
      dateNeeded: '10/10/10, 10:10',
      cardName: 'Job Card',
      cardNumber: '123-456-789',
      forPart: 'Part 1 ',
      partCode: '12345'
    },
    {
      id: 4,
      rawMaterial: 'Raw Material',
      dateNeeded: '10/10/10, 10:10',
      cardName: 'Job Card',
      cardNumber: '123-456-789',
      forPart: 'Part 1 ',
      partCode: '12345'
    },
    {
      id: 5,
      rawMaterial: 'Raw Material',
      dateNeeded: '10/10/10, 10:10',
      cardName: 'Job Card',
      cardNumber: '123-456-789',
      forPart: 'Part 1 ',
      partCode: '12345'
    }
  ]
  const columns = [
    { field: 'id', headerName: '#', flex: 0.2 },
    { field: 'rawMaterial', headerName: 'Raw Material needed', flex: 1 },
    { field: 'dateNeeded', headerName: 'Date needed', flex: 1 },
    { field: 'cardName', headerName: 'Job Card name', flex: 1 },
    { field: 'cardNumber', headerName: 'Job Card number', flex: 1 },
    { field: 'forPart', headerName: 'For part', flex: 1 },
    { field: 'partCode', headerName: 'Part code', flex: 1 }
  ]

  return (
    <React.Fragment>
      <BreadcrumbNavigation
        breadCrumbData={null}
        pageTitle={'MRP'}
        backToLink={'/checklists'}
      />
      <Box paddingTop={'30px'}>
        {/* <Box padding={"0px 40px"}>
          <BreadCrumb title="MRP " />
        </Box> */}
        <Grid container className="padding-20-75">
          <Grid lg={1.75}>
            <Box className="font-size-13">MRP No.</Box>
            <Box className="font-size-14-with-semibold-primary">123432534</Box>
          </Grid>
          <Grid lg={1.75}>
            <Box className="font-size-13">Created ON</Box>
            <Box className="font-size-14-with-semibold">11/11/11</Box>
          </Grid>
          <Grid lg={1.75}>
            <Box className="font-size-13">Created by</Box>
            <Box className="font-size-14-with-semibold">Lorem Ispum</Box>
          </Grid>
          <Grid lg={1.75}>
            {' '}
            <Box className="font-size-13">For Date</Box>
            <Box className="font-size-14-with-semibold">11/11/11</Box>
          </Grid>
          <Grid lg={1.75}>
            {' '}
            <Box className="font-size-13">Schedule used</Box>
            <Box className="font-size-14-with-semibold">Schedule v1.1</Box>
          </Grid>
        </Grid>
        <Box className="form-perent">
          <Box className="mrp-form-child">
            <Grid container className="padding-10">
              <Grid lg={12} wrap="nowrap" spacing={2}>
                <Box className="font-size-18">MRP Output</Box>
                <TableComponent rows={rows} columns={columns} />
              </Grid>
            </Grid>
          </Box>
          <Box className="button-child">
            <CustomButton title="Save MRP Run" type="submit" />
          </Box>
        </Box>
      </Box>
    </React.Fragment>
  )
}

export default NewMrp
