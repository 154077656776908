import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'
import React, { useState } from 'react'
import './jobcard.css'
import { Box, Grid, MenuItem, Select, Typography } from '@mui/material'
import { Form, Formik } from 'formik'
import CustomInput from '../../leastcomponent/custominput/input'
import { AiOutlineInfoCircle } from 'react-icons/ai'
import CustomButton from '../../leastcomponent/custombutton/custombutton'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import BreadcrumbNavigation from '../commoncomponents/breadcrumbnavigation'
import { useGetJobDetailsSingleTask, useScheduleTask } from '../../hooks/jobcardHooks'
import { useParams } from 'react-router-dom'
import Required from '../commoncomponents/required'
import FormControl from '@mui/material/FormControl'
import { styled } from '@mui/material/styles'
import { useGetFullresource } from '../../hooks/resourceHooks'
import { useGetJobSlipsFullList } from '../../hooks/jobslipshooks'
import * as Yup from 'yup'
import { useQueryClient } from '@tanstack/react-query'
import { user } from '../../querykeys/usermanagement'

const Selects = styled(Select)(() => ({
  borderRadius: '4px !important',
  '.MuiOutlinedInput-input': {
    padding: '8.5px 14px',
    fontSize: '14px !important'
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: '#8A8A8'
  }
}))
const JobCardsDepartmentWise = () => {
  const icon = <AiOutlineInfoCircle color="red" />
  const { mutate: addScheduleTask } = useScheduleTask()
  const { id, partId } = useParams()
  const { data: getJobDetails } = useGetJobDetailsSingleTask(id, !!id)
  const singleJobDetails = getJobDetails?.data
  const selectDepartmentid = localStorage.getItem('selectDepartmentid')
  const queryClient = useQueryClient()
  const userData = queryClient.getQueryData([user.LOGGED_IN_USER])
  const departmentGroupTitle = userData?.data?.data?.user?.roleId?.departmentGroup?.title
  console.log('departmentGroupTitle ==>', departmentGroupTitle)
  const finalBompartData = singleJobDetails?.bompart.map(data => ({
    ...data,
    bomprocess: data.bomprocess.filter(bomproces => bomproces.department.id === selectDepartmentid)
  })).filter(data => data.id === partId && data.bomprocess.length > 0)
  const taskArr = finalBompartData?.[0]?.bomprocess?.map((data) => data?.task)?.flat()?.map((val) => {
    return {
      resource: '',
      jobSlip: '',
      setupTime: '',
      productionTime: '',
      changeoverTime: '',
      id: val?.id
    }
  })
  // TODO : use for add validation
  // const validationSchema = Yup.object().shape({
  //   setupTime: Yup.number()
  //     .required('Setup time per piece (min) is required')
  //     .max(60, 'Setup time per piece must be at most 60 minutes'),
  //   productionTime: Yup.number()
  //     .required('Production time per piece (min) is required')
  //     .max(60, 'Production time per piece must be at most 60 minutes'),
  //   changeoverTime: Yup.number()
  //     .required('Changeover time per piece (min) is required')
  //     .max(60, 'Changeover time per piece must be at most 60 minutes'),
  //   jobSlip: Yup.string().required('Schedule for Job Slips is required')
  // })

  const [openIndex, setOpenIndex] = useState(null)
  const { data: resourcesData } = useGetFullresource()
  const { data: jobSlipsData } = useGetJobSlipsFullList()
  const taskSchema = Yup.object().shape({
    resource: Yup.string().required('Resource is required'),
    jobSlip: Yup.string().required('Job Slip is required'),
    setupTime: Yup.number().typeError('Setup Time must be a number').required('Setup Time is required').positive('Setup Time must be a positive number'),
    productionTime: Yup.number().typeError('Production Time must be a number').required('Production Time is required').positive('Production Time must be a positive number'),
    changeoverTime: Yup.number().typeError('Changeover Time must be a number').required('Changeover Time is required').positive('Changeover Time must be a positive number')
  })
  return (
    <Box className="add-job-card-overflow-hidden"
    >
      <BreadcrumbNavigation
        breadCrumbData={null}
        pageTitle={` Job Card ${singleJobDetails?.jobCardNumber ? '#' + singleJobDetails?.jobCardNumber : '-'}`}
        backToLink={null}
      />
      <Box>
        <Box className="padding-24-50">
          <Grid container className="padding-20-75-border1">
            <Grid item xs={1.7} lg={1.7} md={3}>
              <Box className="add-task-header">Job Card Name</Box>
              <Box className="font-size-14-with-semibold-primary">{singleJobDetails?.name ? singleJobDetails?.name : '-'}</Box>
            </Grid>
            <Grid item lg={1.7} md={3} xs={1.7}>
              <Box className="add-task-header">Job Card Number</Box>
              <Box className="font-size-14-with-semibold">{singleJobDetails?.jobCardNumber ? singleJobDetails?.jobCardNumber : '-'}</Box>
            </Grid>
            <Grid item lg={1.7} md={3} xs={1.8}>
              <Box className="add-task-header">Product Name</Box>
              <Box className="font-size-14-with-semibold">{singleJobDetails?.productName ? singleJobDetails?.productName : '-'}</Box>
            </Grid>
            <Grid item lg={1.7} md={3}>
              {' '}
              <Box className="add-task-header">Quantity</Box>
              <Box className="font-size-14-with-semibold">{singleJobDetails?.quantity ? singleJobDetails?.quantity : '-'}</Box>
            </Grid>
            <Grid item lg={1.7} md={3}>
              {' '}
              <Box className="add-task-header">Priority</Box>
              <Box className="font-size-14-with-semibold">{singleJobDetails?.priority ? singleJobDetails?.priority : '-'}</Box>
            </Grid>
            <Grid item lg={1.7} md={3}>
              {' '}
              <Box className="add-task-header">Customer name</Box>
              <Box className="font-size-14-with-semibold">{singleJobDetails?.customerName ? singleJobDetails?.customerName : '-'}</Box>
            </Grid>
            <Grid item lg={1.7} md={6} className="align-center-with-gap">
              Status:{' '}
              {singleJobDetails?.jobCardStatus === 'approved' && <>
                <span className="align-center">
                  <FiberManualRecordIcon
                    sx={{ fontSize: '14px', color: '#C89AFC' }}
                  />
                </span>{' '}
                <span className="font-size-14-with-semibold">Approved</span>
                </>}
                {singleJobDetails?.jobCardStatus === 'completed' && <>
                <span className="align-center">
                  <FiberManualRecordIcon
                    sx={{ fontSize: '14px', color: '#1AD04C' }}
                  />
                </span>{' '}
                <span className="font-size-14-with-semibold">Completed</span>
                </>}
            </Grid>
          </Grid>
        </Box>
        {finalBompartData?.map((part, partIndex) => (
          <Formik
            key={partIndex}
            enableReinitialize={true}
            initialValues={{
              task: taskArr
            }}
            validationSchema={Yup.object().shape({
              task: Yup.array().of(taskSchema)
            })}
            onSubmit={(values) => {
              console.log('values ==>', values)
              if (values?.task?.length > 0) {
                addScheduleTask({ task: values?.task })
              } else {
                console.log('No data to submit')
              }
            }}
            // Todo use for validation
            // validationSchema={validationSchema}
          >

          {({ errors, setFieldValue, handleChange, touched, values }) => (
            <React.Fragment>
              <Form>
                <Box className="form-perent-add-task">
                  {finalBompartData?.map((data, ind) => {
                    let allTasks = []
                    data.bomprocess.forEach(process => {
                      // Concatenate the tasks from each process to the allTasks array
                      allTasks = allTasks.concat(process.task)
                    })
                    return (
                    <Box
                      className="form-child-border"
                      style={{ padding: '0px 20px' }}
                      key={ind}
                    >
                      <Grid container className="padding-20-75-border-bottom">
                        <Grid item lg={1.75} md={2}>
                          <Box className="add-task-header">Part Name</Box>
                          <Box className="task-type-form-heading">{data.partName ? data.partName : '-'}</Box>
                        </Grid>
                        <Grid item lg={1.75} md={2}>
                          {' '}
                          <Box className="add-task-header">Part Code</Box>
                          <Box className="task-type-form-heading">{data.itemCode ? data.itemCode : '-'}</Box>
                        </Grid>
                        <Grid item lg={1.75} md={2}>
                          {' '}
                          <Box className="add-task-header">Quantity.</Box>
                          <Box className="task-type-form-heading">{data?.quantity ? data?.quantity : '-'}</Box>
                        </Grid>
                        <Grid item lg={1.75} md={2}>
                          {' '}
                          <Box className="add-task-header">Drawing no.</Box>
                          <Box className="task-type-form-heading"> {data.drawingNumber ? data.drawingNumber : '-'}</Box>
                        </Grid>
                        <Grid item lg={5} md={4} className="align-center-with-gap">
                          <span onClick={() => setOpenIndex(openIndex === ind ? null : ind)} style={{ cursor: 'pointer' }}>
                            {openIndex === ind ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                          </span>
                        </Grid>
                      </Grid>
                      {openIndex === ind && (
                        <Box
                          className="form-child-border11"
                          style={{
                            borderTop: '1px solid #dedede'
                          }}
                        >
                          <React.Fragment>
                            <Box className="gap-20">
                              <Box padding={'20px'}>
                                {allTasks?.map((task, index) => {
                                  return (
                                    <Box borderBottom={index === allTasks?.length - 1 ? '' : '1px solid #dedede'} sx={{ paddingTop: '14px' }} key={index}>
                                      <Grid container className="padding-top-bottom">
                                      <Grid item lg={4} md={3} >
                                        <Typography className="form-heading-1 pb-20">
                                         {index + 1}{'.'} {task?.taskNickName}
                                        </Typography>
                                      </Grid>
                                      <Grid item
                                        lg={3}
                                        md={4}
                                        sx={{ display: 'flex', gap: '20px' }}
                                      >
                                        <Typography className="add-task-type-header">
                                        Task Type
                                        </Typography>{' '}
                                        <Typography className="task-type-form-heading">
                                            {task?.taskType?.name ? task?.taskType?.name : '-'}
                                        </Typography>{' '}
                                      </Grid>
                                      <Grid item
                                        lg={4}
                                        md={5}
                                        sx={{ display: 'flex', gap: '20px' }}
                                      >
                                        <Typography className="add-task-type-header">
                                          Task Description
                                        </Typography>
                                        <Typography className="task-type-form-heading">
                                         {task?.description ? task?.description : '-'}
                                        </Typography>
                                      </Grid>

                                    </Grid>
                                    {/* ))} */}
                                    {/* //! here grid start-------------- */}
                                    <Grid
                                      spacing={2}
                                      paddingBottom={'20px'}
                                      container
                                    >
                                      <Grid item xs={12} lg={4} md={6} xl={4}>
                                        <Typography className="field-title">
                                          Machine/Workstation
                                          <Required />
                                        </Typography>

                                        <FormControl fullWidth>
                                          <Selects
                                            name={`task.${index}.resource`}
                                            variant="outlined"
                                            value={
                                              resourcesData?.data?.length > 0
                                                ? values?.task?.[index]?.resource
                                                : ''
                                            }
                                            onChange={handleChange}
                                            displayEmpty
                                            errors={errors?.task?.[index]?.resource}
                                            touched={touched?.task?.[index]?.resource}
                                          >
                                            <MenuItem value="">
                                              <span className="menu-item-span">
                                                Enter Name/code
                                              </span>
                                            </MenuItem>

                                            {resourcesData?.data?.map((data, index) => {
                                              return (
                                                <MenuItem value={data?.id} key={index}>
                                                  {data?.name}
                                                </MenuItem>
                                              )
                                            })}
                                          </Selects>
                                        </FormControl>

                                        {touched?.task?.[index]?.resource && errors?.task?.[index]?.resource && (
                                          <Typography className="validation-text">
                                             {errors?.task?.[index]?.resource}
                                          </Typography>
                                        )}
                                      </Grid>
                                      <Grid item xs={12} lg={4} md={6} xl={4}>
                                        <Typography className="field-title">
                                        Add to Job Slip
                                          <Required />
                                        </Typography>

                                        <FormControl fullWidth>
                                          <Selects
                                            name={`task.${index}.jobSlip`}
                                            variant="outlined"
                                            value={
                                              jobSlipsData?.length > 0
                                                ? values?.task?.[index]?.jobSlip
                                                : ''
                                            }
                                            onChange={handleChange}
                                            displayEmpty
                                          >
                                            <MenuItem value="">
                                              <span className="menu-item-span">
                                              Select Job Slip
                                              </span>
                                            </MenuItem>

                                            {jobSlipsData?.map((data, jobindex) => {
                                              const originalDate = new Date(data?.date)
                                              const day = ('0' + originalDate.getUTCDate()).slice(-2)
                                              const month = ('0' + (originalDate.getUTCMonth() + 1)).slice(-2)
                                              const year = originalDate.getUTCFullYear()
                                              const formattedDate = `${day}-${month}-${year}`
                                              return (
                                                <MenuItem value={data?.id} key={jobindex}>
                                                  {`${data?.name} - ${formattedDate}`}
                                                </MenuItem>
                                              )
                                            })}
                                          </Selects>
                                        </FormControl>

                                        {touched?.task?.[index]?.jobSlip && errors?.task?.[index]?.jobSlip && (
                                          <Typography className="validation-text">
                                            {errors?.task?.[index]?.jobSlip}
                                          </Typography>
                                        )}
                                      </Grid>
                                    </Grid>
                                    <Grid
                                      spacing={2}
                                      paddingBottom={'30px'}
                                      container
                                    >
                                      <Grid item xs={12} lg={4} md={6} xl={4}>
                                        <CustomInput
                                          label="Setup time per piece (min)"
                                          placeholder="Enter Time (min)"
                                          handleChange={handleChange}
                                          value={values?.task?.[index]?.setupTime}
                                          name={`task.${index}.setupTime`}
                                          InputProps={{
                                            endAdornment:
                                              touched?.task?.[index]?.setupTime &&
                                              errors?.task?.[index]?.setupTime &&
                                              icon
                                          }}
                                          errors={errors?.task?.[index]?.setupTime}
                                          touched={touched?.task?.[index]?.setupTime}
                                        />
                                      </Grid>
                                      <Grid item xs={12} lg={4} md={6} xl={4}>
                                        <CustomInput
                                          label="Production time per piece (min)"
                                          placeholder="Enter Time (min)"
                                          handleChange={handleChange}
                                          value={values?.task?.[index]?.productionTime}
                                          name={`task.${index}.productionTime`}
                                          InputProps={{
                                            endAdornment:
                                              touched?.task?.[index]?.productionTime &&
                                              errors?.task?.[index]?.productionTime &&
                                              icon
                                          }}
                                          errors={errors?.task?.[index]?.productionTime}
                                          touched={touched?.task?.[index]?.productionTime}
                                        />
                                      </Grid>{' '}
                                      <Grid item xs={12} lg={4} md={6} xl={4}>
                                        <CustomInput
                                          label="Changeover time per piece (min)"
                                          placeholder="Enter Time (min)"
                                          handleChange={handleChange}
                                          value={values?.task?.[index]?.changeoverTime}
                                          name={`task.${index}.changeoverTime`}
                                          InputProps={{
                                            endAdornment:
                                              touched?.task?.[index]?.changeoverTime &&
                                              errors?.task?.[index]?.changeoverTime &&
                                              icon
                                          }}
                                          errors={errors?.task?.[index]?.changeoverTime}
                                          touched={touched?.task?.[index]?.changeoverTime}
                                        />
                                      </Grid>
                                    </Grid>
                                  </Box>
                                  )
                                })}

                              </Box>
                            </Box>
                          </React.Fragment>
                        </Box>
                      )}
                    </Box>
                    )
                  }
                  )}
                </Box>
                <Box className="button-child">
                  <CustomButton title="Submit" type="submit" />
                </Box>
              </Form>
            </React.Fragment>
          )}
        </Formik>
        ))}
      </Box>
    </Box>
  )
}

export default JobCardsDepartmentWise
