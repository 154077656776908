import { Box, Grid, Typography } from '@mui/material'
import React, { useRef } from 'react'
import styled from '@emotion/styled'
import BreadcrumbNavigation from '../commoncomponents/breadcrumbnavigation'
import { useLocation, useParams } from 'react-router-dom'
import { useGetDeletedJobCard, useGetJobDetailsSingle, useHardDeleteJobCard, useRestoreJobCard } from '../../hooks/jobcardHooks'
import _ from 'lodash'
import moment from 'moment'
import NestedDetailsPageTable from '../../leastcomponent/customtable/nestedDetailsPageTable'
import { useReactToPrint } from 'react-to-print'
import './../jobslips/print.css' // Import print-specific CSS'

const TextFiled = styled(Typography)(() => ({
  color: 'gray',
  fontSize: '14px !important'
}))
const BorderFiled = styled(Typography)(() => ({
  color: 'gray',
  paddingTop: '0px',
  fontSize: '14px !important',
  padding: '4px 0px 4px 4px'
}))
const JobCardDetails = () => {
  const { id, partId } = useParams()
  const location = useLocation()
  const isShowOptions = location.pathname.startsWith('/job-card-details-view')
  const isShowOptionsSidebar = location.state?.sidebarTab !== 3

  //  delete items jobcard
  // const { state } = location
  const isDeleted = location.pathname.startsWith('/deleted-items')
  const isRestore = location.pathname.startsWith('/deleted-items')
  // TODO:: delete task and restore task
  // const isDeletedTask = location.pathname.startsWith('/deleted-items')
  // const isRestoreTask = location.pathname.startsWith('/deleted-items')
  // const [isEdit, setIsEdit] = useState(false)
  const { mutate: hardDeleteJobCard } = useHardDeleteJobCard()
  const { mutate: restoreJobCard } = useRestoreJobCard()
  // TODO:: delete task and restore task
  // const { mutate: hardDeleteTask } = useHardDeleteTask()
  // const { mutate: restoreTask } = useRestoreTask()

  const handlePartDelete = () => {
    if (isDeleted) {
      hardDeleteJobCard(id)
    } else {
      // deleteSinglePart(id)
    }
  }
  const handlePartRestore = () => {
    if (isRestore) {
      restoreJobCard(id)
    } else {
      // nothing to restore
    }
  }

  const { data: getJobDetails } = useGetJobDetailsSingle(id, !!id && !isDeleted)
  const { data: getDeletedJobCard } = useGetDeletedJobCard(id, !!id && isDeleted)
  const getJobCardData = isDeleted ? getDeletedJobCard : getJobDetails
  const singleJobDetails = getJobCardData?.data

  const mainCols = [
    { field: '#', headerName: '#', flex: 0.2 },
    { field: 'Process/depart Name', headerName: 'name', flex: 1 },
    { field: 'Process/department Code', headerName: 'Job Card Name', flex: 1 },
    { field: 'Department Group', headerName: 'Department Group', flex: 1 }
  ]

  const finalDataColumns = [
    { field: 'id', headerName: '#', flex: 0.2 },
    { field: 'Task Name', headerName: 'Task Name', flex: 1 },
    { field: ' Task Type', headerName: 'Task Type', flex: 1 },
    { field: 'Description', headerName: 'Description', flex: 1 },
    { field: 'Status', headerName: 'Status', flex: 1 }
  ]
  const breadCrumbData = [
    {
      title: isDeleted ? 'Delete Job Cards' : isShowOptionsSidebar ? 'Create Job Cards' : 'Job Cards',
      link: isShowOptionsSidebar ? '/job-card' : '/job-card-department'
    },
    {
      title: singleJobDetails?.name || 'Job Card Name',
      link: `/job-card-details-view/${id}?sales`
    }
  ]
  const componentRef = useRef()
  const handlePrint = useReactToPrint({
    content: () => componentRef?.current
  })
  return (
    <React.Fragment>
      <Box
      className="production-team-head-overflow-hidden"
      >
        <BreadcrumbNavigation
          breadCrumbData={breadCrumbData}
          pageTitle={ isDeleted ? ` Delete Job Card ${singleJobDetails?.jobCardNumber ? '#' + singleJobDetails?.jobCardNumber : '-'}` : ` Job Card ${singleJobDetails?.jobCardNumber ? '#' + singleJobDetails?.jobCardNumber : '-'}`}
          backToLink={isShowOptionsSidebar ? '/job-card' : '/job-card-department'}
          isPendingStatus
          status={singleJobDetails?.jobCardStatus}
          isPrinter={singleJobDetails?.jobCardStatus === 'submitted' && !singleJobDetails?.isDraft}
          onClick={() => {
            handlePrint()
          }}
          isRestore={true}
        isDeleted={true}
        handleDelete={handlePartDelete}
        handleRestore={handlePartRestore}
        />

        <Box className="common-parent-class-overflow-auto-padding-10-50"
           ref={componentRef}
        >
          <Box className="jobcard-print-title">Job Card ${singleJobDetails?.jobCardNumber ? '#' + singleJobDetails?.jobCardNumber : '-'}</Box>
          <Box
            style={{
              borderRadius: '3px',
              marginBottom: '25px'
            }}
            className="jobcard-details-page-border"
          >
            <Box
              style={{
                fontSize: '16px',
                fontWeight: 600,
                background: '#F6F6F6',
                padding: '10px 20px'
              }}
              className="jobcard-detais-page-basic"
            >
              Basic Details
            </Box>
            <Box style={{ borderTop: '1px solid #CDCDCD' }} className="jobcard-print-border-top"></Box>
            <Box
              style={{
                padding: '20px'
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={3} md={3}>
                  <TextFiled>OC Number</TextFiled>
                  <Typography className="span-color">
                    {singleJobDetails?.ocNumber ? singleJobDetails?.ocNumber : '-'}
                  </Typography>
                </Grid>
                <Grid item xs={3} md={3}>
                  <TextFiled>Date Created</TextFiled>
                  <Typography className="span-color">
                    {' '}
                    {singleJobDetails?.createdAt ? moment(singleJobDetails?.createdAt).format('MM/DD/YYYY') : '-'}
                  </Typography>
                </Grid>
                <Grid item xs={3} md={3}>
                  <TextFiled>Sale Order Number</TextFiled>
                  <Typography className="span-color">
                    {singleJobDetails?.saleOrderNumber ? singleJobDetails?.saleOrderNumber : '-'}

                  </Typography>
                </Grid>
                <Grid item xs={3} md={3}>
                  <TextFiled>Customer Name</TextFiled>
                  <Typography className="span-color">
                    {' '}
                    {singleJobDetails?.customerName ? singleJobDetails?.customerName : '-'}

                  </Typography>
                </Grid>
                <Grid item xs={3} md={3}>
                  <TextFiled>Customer Reference</TextFiled>
                  <Typography className="span-color">
                    {' '}
                    {singleJobDetails?.customerReference ? singleJobDetails?.customerReference : '-'}
                  </Typography>
                </Grid>
                <Grid item xs={3} md={3}>
                  <TextFiled>Product Code</TextFiled>
                  <Typography className="span-color">
                    {singleJobDetails?.productCode ? singleJobDetails?.productCode : '-'}
                  </Typography>
                </Grid>
                <Grid item xs={3} md={3}>
                  <TextFiled>Product Name</TextFiled>
                  <Typography className="span-color">
                    {singleJobDetails?.productName ? singleJobDetails?.productName : '-'}
                  </Typography>
                </Grid>
                <Grid item xs={3} md={3}>
                  <TextFiled>Priority</TextFiled>
                  <Typography className="span-color">
                    {singleJobDetails?.priority ? singleJobDetails?.priority : '-'}
                  </Typography>
                </Grid>
                <Grid item xs={9} md={9}>
                  <TextFiled>Description</TextFiled>
                  <Typography className="span-color">
                    {singleJobDetails?.description ? singleJobDetails?.description : '-'}
                  </Typography>
                </Grid>
                <Grid item xs={2.3} md={2.3}>
                  <Box border={'1px solid #8A8A8A'} display={'flex'} mb={'6px'}>
                    <Grid item xs={9} md={9}>
                      <BorderFiled>Quantity </BorderFiled>
                    </Grid>
                    <Typography
                      className="span-color pl-sub-text"
                      alignItems={'center'}
                      marginRight={'5px'}
                      mt={'3px'}
                    >
                      {singleJobDetails?.quantity ? singleJobDetails?.quantity : '-'}
                    </Typography>
                  </Box>
                  {/* <Box border={'1px solid #8A8A8A'} display={'flex'} mb={'6px'}>
                    <Grid item xs={9} md={9}>
                      <BorderFiled>Produced Quantity </BorderFiled>
                    </Grid>
                    <Typography
                      className="span-color pl-sub-text"
                      alignItems={'center'}
                      marginRight={'5px'}
                      mt={'3px'}
                    >
                      {_.get(singleJobDetails, 'producedQuantity', '')}
                    </Typography>
                  </Box>
                  <Box border={'1px solid #8A8A8A'} display={'flex'} mb={'6px'}>
                    <Grid item xs={9} md={9}>
                      <BorderFiled>Balance Quantity </BorderFiled>
                    </Grid>
                    <Typography
                      className="span-color pl-sub-text"
                      alignItems={'center'}
                      marginRight={'5px'}
                      mt={'3px'}
                    >
                      {_.get(singleJobDetails, 'balanceQuantity', '')}
                    </Typography>
                  </Box> */}
                </Grid>
              </Grid>
            </Box>
          </Box>
           {singleJobDetails?.bompart?.map((data, ind) => {
             const finalDataOne = data?.bomprocess?.map((process) => {
               const history = process?.task?.map(task => ({
                 taskId: task.id,
                 taskNickName: task.taskNickName ? task.taskNickName : '-',
                 taskType: task.taskType?.name ? task.taskType?.name : '-',
                 description: task.description ? task.description : '-',
                 taskStatus: task.taskStatus ? task.taskStatus : '-',
                 department: process.department.id ? process.department.id : '-'
               })) || []
               return {
                 no: ind + 1,
                 name: process?.department?.name ? process?.department?.name : '-',
                 code: process?.department?.code ? process?.department?.code : '-',
                 title: process?.department?.departmentgroups?.title ? process?.department?.departmentgroups?.title : '-',
                 partId: data?.id,
                 history
               }
             })

             return (
               (data?.id === partId || isShowOptions) && (
                <Box
                  style={{
                    border: '1px solid #CDCDCD',
                    borderRadius: '3px'
                  }}
                  className='print-margin-bottom'
                  key={ind}
                >
                  <Box
                    style={{
                      fontSize: '16px',
                      fontWeight: 600,
                      background: '#F6F6F6',
                      padding: '10px 20px'
                      // cursor: 'pointer'
                    }}
                    // onClick={() => {
                    //   setTasks(finalDataOne?.find(item => item.partId === partId))
                    //   if (partId) {
                    //     setOpen(true)
                    //   }
                    // }}
                  >
                    {ind + 1}. {_.get(data, 'partName', '')}
                  </Box>
                  <Box style={{ borderTop: '1px solid #CDCDCD' }}></Box>
                  <Box
                    style={{
                      padding: '20px'
                    }}
                  >
                    <Grid container spacing={2}>
                      <Grid item xs={3} md={3}>
                        <TextFiled>Item Code</TextFiled>
                        <Typography className="span-color">
                          {data?.itemCode ? data?.itemCode : '-'}
                        </Typography>
                      </Grid>
                      <Grid item xs={3} md={3}>
                        <TextFiled>Number of</TextFiled>
                        <Typography className="span-color">{data?.numberOf ? data?.numberOf : '-'}</Typography>
                      </Grid>
                      <Grid item xs={3} md={3}>
                        <TextFiled>Drawing number</TextFiled>
                        <Typography className="span-color">
                          {data?.drawingNumber ? data?.drawingNumber : '-'}
                        </Typography>
                      </Grid>
                      <Grid item xs={3} md={3}>
                        <TextFiled>Weight (gms)</TextFiled>
                        <Typography className="span-color">
                          {data?.weight ? data?.weight : '-'}
                        </Typography>
                      </Grid>
                      <Grid item xs={3} md={3}>
                        <TextFiled> Final Size - OD (mm)</TextFiled>
                        <Typography className="span-color">
                          {data?.sizeOd ? data?.sizeOd : '-'}
                        </Typography>
                      </Grid>
                      <Grid item xs={3} md={3}>
                        <TextFiled>Final Size - ID (mm)</TextFiled>
                        <Typography className="span-color">
                          {data?.sizeId ? data?.sizeId : '-'}
                        </Typography>
                      </Grid>
                      <Grid item xs={3} md={3}>
                        <TextFiled>Final Size - L (mm)</TextFiled>
                        <Typography className="span-color">
                          {data?.sizeL ? data?.sizeL : '-'}
                        </Typography>
                      </Grid>
                      <Grid item xs={3} md={3}>
                        {/* <TextFiled>Process Code</TextFiled>
                        <Typography className="span-color">
                          {data?.code}
                        </Typography> */}
                      </Grid>
                      <Grid item xs={9} md={9}>
                        <TextFiled>Description</TextFiled>
                        <Typography className="span-color">
                          {data?.description ? data?.description : '-'}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <Box>
                          {data?.bomprocess?.length > 0 &&
                          <NestedDetailsPageTable
                            rows={finalDataOne}
                            columns={finalDataColumns}
                            mainCols={mainCols}
                            tableName="Process details"
                            isPagination={false}
                          />
                          }
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
               )
             )
           }

           )}
        </Box>
      </Box>
    </React.Fragment>
  )
}
export default JobCardDetails
