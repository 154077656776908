import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { user } from '../querykeys/user'
import { useNavigate } from 'react-router-dom'
import { userApi } from '../api/user'
// import { DepartmentErrI, DepartmentListErrI } from '../Types/department';

export const useGetUser = (isEnable, page) => {
  return useQuery([user.USER, page], userApi.getUser, {
    enabled: isEnable,
    select: (res) => res?.data,
    // ! TODO: for future implementation toast
    // onSuccess: () => {
    //   // const data = res?.data;
    // },
    // onError: (err) => {
    //   return err;
    //   // const errData = err?.data;
    //   // toast.error(errData.message);
    // },
    refetchOnWindowFocus: false
  })
}

export const useAddUser = () => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  return useMutation(userApi.addUser, {
    onSuccess: () => {
      queryClient.invalidateQueries([user.USER])
      navigate('/user-management', { state: { activeTab: '1' } })
    },
    onError: (err) => {
      return err
      // ! TODO: for future implementation toast
      // toast.error(errData.message);
    }
  })
}
export const useUpdateUser = () => {
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  return useMutation(userApi.updateUser, {
    onSuccess: (res, id) => {
      // const data = res;
      queryClient.invalidateQueries([user.USER])
      queryClient.invalidateQueries([user.USER, id])
      navigate('/user-management', { state: { activeTab: '1' } })
    },
    onError: (err) => {
      return err
      // ! TODO: for future implementation toast
      // const errData = err?.data;
      // toast.error(errData.message);
    }
  })
}

export const useGetUserSingle = (id, isEnable = true) => {
  return useQuery([user.USER, id], userApi.getSingleUser, {
    enabled: isEnable,
    // onSuccess: (res) => {
    //   // const data = res?.data;
    // },
    onError: (err) => {
      return err
    },
    refetchOnWindowFocus: false
  })
}

export const useDeleteUser = () => {
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  return useMutation(userApi.deleteUser, {
    onSuccess: (res, id) => {
      // const data = res.data;
      queryClient.invalidateQueries([user.USERS])
      queryClient.invalidateQueries([user.USERS, id])
      // toast.success(data?.message);
      navigate('/user-management', { state: { activeTab: '1' } })
    }
    // onError: () => {
    //   // const errData = err.data;
    //   // toast.error(errData?.message);
    // },
  })
}
export const useRestoreUser = () => {
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  return useMutation(userApi.restoreUser, {
    onSuccess: () => {
      queryClient.invalidateQueries([user.USERS])
      // queryClient.invalidateQueries([part.PART, id]);
      // toast.success(data.message);
      navigate('/deleted-items')
    }
    // onError: (err: any) => {
    //   const errData = err?.data;
    //   //toast.error(errData.message);
    // },
  })
}

export const useHardDeleteUser = () => {
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  return useMutation(userApi.hardDeleteUser, {
    onSuccess: () => {
      queryClient.invalidateQueries([user.USERS])
      // queryClient.invalidateQueries([part.PART, id]);
      navigate('/deleted-items')
    }
    // onError: (err: any) => {
    //   const errData = err?.data;
    //   //toast.error(errData.message);
    // },
  })
}

export const useGetDeletedUser = (id, isEnable = true) => {
  return useQuery([user.USERS + 'DELETED', id], userApi.getSingleUserDelete, {
    enabled: isEnable,
    select: (data) => data.data,
    // onSuccess: (res: any) => {
    //   const data = res?.data;
    // },
    // onError: (err: any) => {
    //   const errData = err?.data;
    //   //toast.error(errData.message);
    // },
    refetchOnWindowFocus: false
  })
}
