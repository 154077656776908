import React from 'react'
import './createreport.css'
import { Box, Grid, Typography } from '@mui/material'
// import BreadCrumb from "../../leastcomponent/breadcrumb/breadcrumb";
import CustomButton from '../../leastcomponent/custombutton/custombutton'
import BreadcrumbNavigation from '../commoncomponents/breadcrumbnavigation'
const SelectReport = () => {
  return (
    <Box className="production-team-head-overflow-hidden">
      <BreadcrumbNavigation
        breadCrumbData={null}
        pageTitle={'Reports'}
        backToLink={'/resource'}
      />

      <Box className="report-form-wrapper-overflow-auto">
        {/* <Box className="padding-y-40">
          <BreadCrumb title="Reports" />
        </Box> */}
        <Typography className="report-form-heading">Report </Typography>
        <Box className="report-form-child">
          <Box className="padding-y-60">
            <Typography className="common-tabel-heading1">
              Select KPI{' '}
            </Typography>
            <Grid container spacing={1}>
              {[...Array(4).keys()].map((index) => (
                <Grid item xs={3} md={3} key={index}>
                  <Box className="select-report-kpi"></Box>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Box>
        <Box className="report-button-child">
          <CustomButton variant="outlined" title="Save as PDF" type="submit" />
          <CustomButton title="Share" type="submit" />
        </Box>
      </Box>
    </Box>
  )
}

export default SelectReport
