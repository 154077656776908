import { Box, CircularProgress, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import TableComponent from '../../leastcomponent/customtable/customtable'
import DeleteIconNew from '../../assets/svg/DeleteIconNew'
import CustomButton from '../../leastcomponent/custombutton/custombutton'
import DeleteWarningModal from '../../modals/deletewarningmodal'
import RestoreAll from '../../assets/svg/RestoreAll'
import DeleteIcon from '@mui/icons-material/Delete'
import RestoreIcon from '../../assets/svg/RestoreIcon'
import './deleteItems.css'
import BreadcrumbNavigation from '../commoncomponents/breadcrumbnavigation'
import { useDeleteAllDeletedItems, useGetDeletedItems, useRestoreAllDeletedItems } from '../../hooks/deleteitems'
import moment from 'moment'
import { useHardDeleteDepartment, useHardDeleteDepartmentGroup, useRestoreDepartment, useRestoreDepartmentGroup } from '../../hooks/departmentHooks'
import { useHardDeleteRole, useRestoreRole } from '../../hooks/userRoleHook'
import { useHardDeleteResource, useRestoreResource } from '../../hooks/resourceHooks'
import RestoreWarningModal from '../../modals/restorewarningmodal'
import { useHardDeleteJobCard, useRestoreJobCard } from '../../hooks/jobcardHooks'
import { useHardDeleteTaskType, useRestoreTaskType } from '../../hooks/tasktype'
import { useHardDeleteUser, useRestoreUser } from '../../hooks/userHooks'
import { useHardDeleteTask, useRestoreTask } from '../../hooks/task'
import { useNavigate } from 'react-router-dom'

const DeleteItems = () => {
  const navigate = useNavigate()
  const [deleteRecord, setDeleteRecord] = useState(false)
  const [restoreRecord, setRestoreRecord] = useState(false)
  const [currentIdForDeletion, setCurrentIdForDeletion] = useState('')
  const [currentIdForRestore, setCurrentIdForRestore] = useState('')
  const [currentType, setCurrentType] = useState('')

  const handleDeleteOpen = () => {
    setRestoreRecord(false)
    setDeleteRecord(true)
  }
  const handleDeleteClose = () => {
    setRestoreRecord(false)
    setDeleteRecord(false)
  }
  const handleRestoreOpen = () => {
    setDeleteRecord(false)
    setRestoreRecord(true)
  }
  const handleRestoreClose = () => {
    setDeleteRecord(false)
    setRestoreRecord(false)
  }

  const typeObject = {
    Resource: '/deleted-items/resource-details',
    Department: '/deleted-items/department',
    DepartmentGroup: '/deleted-items/edit-department-group',
    UserRole: '/deleted-items/edit-role',
    DesktopUser: '/deleted-items/edit-user',
    TaskType: '/deleted-items/edit-task-type',
    // JobCard: '/deleted-items/job-card-details',
    JobCard: '/deleted-items/job-card-details-view',
    Task: '/deleted-items/task'
  }

  const { mutate: deleteAllDeletedItems } = useDeleteAllDeletedItems()
  const { mutate: restoreAllDeletedItems } = useRestoreAllDeletedItems()

  const { mutate: restoreDepartment } = useRestoreDepartment()
  const { mutate: restoreRole } = useRestoreRole()
  const { mutate: restoreResource } = useRestoreResource()
  const { mutate: restoreDepartmentGroup } = useRestoreDepartmentGroup()
  const { mutate: restoreJobCard } = useRestoreJobCard()
  const { mutate: restoreTaskType } = useRestoreTaskType()
  const { mutate: restoreUser } = useRestoreUser()
  const { mutate: restoreTask } = useRestoreTask()

  const handleRestore = () => {
    if (currentIdForRestore === 'restore') {
      restoreAllDeletedItems()
    } else {
      if (currentType === 'Resource') {
        restoreResource(currentIdForRestore)
      } else if (currentType === 'Department') {
        restoreDepartment(currentIdForRestore)
      } else if (currentType === 'DepartmentGroup') {
        restoreDepartmentGroup(currentIdForRestore)
      } else if (currentType === 'UserRole') {
        restoreRole(currentIdForRestore)
      } else if (currentType === 'JobCard') {
        restoreJobCard(currentIdForRestore)
      } else if (currentType === 'TaskType') {
        restoreTaskType(currentIdForRestore)
      } else if (currentType === 'DesktopUser') {
        restoreUser(currentIdForRestore)
      } else if (currentType === 'Task') {
        restoreTask(currentIdForRestore)
      }
    }
  }

  const { mutate: deleteResource } = useHardDeleteResource()
  const { mutate: hardDeleteDepartment } = useHardDeleteDepartment()
  const { mutate: hardDeleteDepartmentGroup } = useHardDeleteDepartmentGroup()
  const { mutate: hardDeleteRole } = useHardDeleteRole()
  const { mutate: hardDeleteJobCard } = useHardDeleteJobCard()
  const { mutate: hardDeleteTaskType } = useHardDeleteTaskType()
  const { mutate: hardDeleteUser } = useHardDeleteUser()
  const { mutate: hardDeleteTask } = useHardDeleteTask()

  const handleDeleteForDeletion = () => {
    if (currentIdForDeletion === 'delete') {
      deleteAllDeletedItems()
    } else {
      if (currentType === 'Resource') {
        deleteResource(currentIdForDeletion)
      } else if (currentType === 'Department') {
        hardDeleteDepartment(currentIdForDeletion)
      } else if (currentType === 'DepartmentGroup') {
        hardDeleteDepartmentGroup(currentIdForDeletion)
      } else if (currentType === 'UserRole') {
        hardDeleteRole(currentIdForDeletion)
      } else if (currentType === 'JobCard') {
        hardDeleteJobCard(currentIdForDeletion)
      } else if (currentType === 'TaskType') {
        hardDeleteTaskType(currentIdForDeletion)
      } else if (currentType === 'DesktopUser') {
        hardDeleteUser(currentIdForDeletion)
      } else if (currentType === 'Task') {
        hardDeleteTask(currentIdForDeletion)
      }
    }
  }
  const { data: deletedItemsList, isFetching: isDeletedItemsList, isSuccess: isItemsSuccess, isRefetching } = useGetDeletedItems(true)
  const [page, setPage] = useState(1)
  const [deletedData, setDeletedData] = useState([])
  useEffect(() => {
    if (isItemsSuccess) {
      const listData = deletedItemsList?.data?.slice((page - 1) * 10, page * 10)
      setDeletedData(listData)
    }
  }, [isItemsSuccess, page, isRefetching])

  // const deleteList = deletedData?.data
  const rows = deletedData?.map((data, ind) => {
    const startIndex = (page - 1) * 10
    return {
      id: data?.id,
      no: startIndex + ind + 1,
      name: data?.name || data?.title || data?.firstName
        ? data?.name || data?.title || data?.firstName
        : '-',
      type: data?.type ? data?.type : '-',
      deletedOn: data?.updatedAt ? moment(data?.updatedAt).format('DD-MM-YYYY') : '-',
      deletedBy: data?.updateBy?.firstName ? data?.updateBy?.firstName : '-'
    }
  })
  const columns = [
    // TODO::- Restore selected item checkbox
    // {
    //   field: 'checkboxes',
    //   headerName: 'Select',
    //   sortable: false,
    //   width: 110,
    //   disableClickEventBubbling: true,
    //   renderCell: (params) => {
    //     // Check if the item is already selected for deletion
    //     const isSelectedForDeletion = selectedItems.some(
    //       (item) => item.type === params.row.type && item.IdList.includes(params.row.id)
    //     )

    //     return (
    //       <Box className='table-icons'>
    //         <Box>
    //           {/* Handle checkbox click */}
    //           <Checkbox
    //             icon={<CheckboxIcon />}
    //             checkedIcon={<CheckboxFilledIcon />}
    //             onClick={(e) => {
    //               e.preventDefault()
    //               e.stopPropagation()
    //               const itemIndex = selectedItems.findIndex(
    //                 (item) => item.type === params.row.type
    //               )

    //               if (isSelectedForDeletion) {
    //                 // If the item is already selected for deletion, remove the ID from IdList
    //                 if (itemIndex !== -1) {
    //                   setSelectedItems((prev) => {
    //                     const newState = [...prev]
    //                     newState[itemIndex].IdList = newState[itemIndex].IdList.filter(
    //                       (id) => id !== params.row.id
    //                     )
    //                     return newState.filter((item) => item.IdList.length > 0)
    //                   })
    //                 }
    //               } else {
    //                 // If the item is not selected for deletion, add the ID to IdList
    //                 if (itemIndex !== -1) {
    //                   setSelectedItems((prev) => {
    //                     const newState = [...prev]
    //                     newState[itemIndex].IdList.push(params.row.id)
    //                     return newState
    //                   })
    //                 } else {
    //                   setSelectedItems((prev) => [
    //                     ...prev,
    //                     {
    //                       type: params.row.type,
    //                       IdList: [params.row.id]
    //                     }
    //                   ])
    //                 }
    //               }
    //             }}
    //             checked={isSelectedForDeletion}
    //           />
    //         </Box>
    //       </Box>
    //     )
    //   }
    // },
    { field: 'no', headerName: '#', flex: 0.2 },
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
      renderCell: (params) => {
        const redirectionPath = typeObject[params.row.type]
        return (
          <Typography
          className='table-row-font'
          onClick={() => {
            // if (!['JobCard', 'Task'].includes(params.row.type)) {
            if (!['Task'].includes(params.row.type)) {
              navigate(`${redirectionPath}/${params.id}`)
            }
          }}
        >
          {params.row.name}
        </Typography>
        )
      }
    },
    { field: 'type', headerName: 'Type', flex: 1 },
    { field: 'deletedOn', headerName: 'Deleted On', flex: 1 },
    { field: 'deletedBy', headerName: 'Deleted By', flex: 1 },
    {
      field: 'actions',
      headerName: 'Actions',
      sortable: false,
      // width: 110,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        return (
          <Box className='table-icons'>
            <Box
              pr={'22px'}
              onClick={() => {
                setCurrentType(params.row.type)
                setCurrentIdForRestore(params.row.id)
                handleRestoreOpen()
              }}
            >
              <RestoreIcon />
            </Box>

            <Box
              onClick={() => {
                setCurrentType(params.row.type)
                setCurrentIdForDeletion(params.row.id)
                handleDeleteOpen()
              }}
            >
              <DeleteIconNew />
            </Box>
          </Box>
        )
      }
    }
  ]
  return (
    <Box className="delete-item-overflow-hidden">
      <BreadcrumbNavigation
        breadCrumbData={null}
        pageTitle={'System Settings'}
        backToLink={null}
      />

      <Box className="common-parent-class-overflow-auto-padding-10-50">
        <Box>
          <Box display={'flex'} justifyContent={'space-between'}>
            <Box className="common-tabel-heading">Deleted Items</Box>
            <Box className="factory-table-btn-main">
              <span
                style={{ display: 'flex', justifyContent: 'space-between' }}
                onClick={() => {
                  handleRestoreOpen()
                  setCurrentIdForRestore('restore')
                  // restoreAllDeletedItems();
                }}
              >
                <span className="process-retsore">
                  <RestoreAll />
                </span>
                <span className="process-Restore-name"> Restore All </span>
              </span>
              <span>
                <CustomButton
                  variant="contained"
                  title="Delete All"
                  onClick={() => {
                    handleDeleteOpen()
                    setCurrentIdForDeletion('delete')
                  }}
                  type="button"
                  endIcon=""
                  startIcon={<DeleteIcon />}
                />
              </span>
            </Box>
          </Box>
          {!isDeletedItemsList
            ? <TableComponent
            rows={rows}
            columns={columns}
            setPage={setPage}
            page={page}
            totalResults={deletedItemsList?.data?.length}
            totalPages={Math.ceil(deletedItemsList?.data?.length / 10)}
            />
            : <Box className='progress-parent'>
          <CircularProgress />
        </Box>
      }
        </Box>
        {deleteRecord && currentIdForDeletion && (
          <DeleteWarningModal
            open={deleteRecord}
            handleOpen={handleDeleteOpen}
            handleClose={handleDeleteClose}
            handleDelete={handleDeleteForDeletion}
            id={currentIdForDeletion}
            isDeleteOrRestoreAll={['delete'].includes(currentIdForDeletion)}
            // params={params.row.id}
          />
        )}

        {restoreRecord && currentIdForRestore && (
          <RestoreWarningModal
            open={restoreRecord}
            // handleOpen={handleRestoreOpen}
            handleClose={handleRestoreClose}
            handleDelete={handleRestore}
            id={currentIdForRestore}
            isDeleteOrRestoreAll={['restore'].includes(currentIdForRestore)}
            // params={params.row.id}
          />
        )}
      </Box>
    </Box>
  )
}

export default DeleteItems
