import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { jobcard } from '../querykeys/jobcard'
import { useNavigate } from 'react-router-dom'
import { jobcardApi } from '../api/jobcard'
// import { DepartmentErrI, DepartmentListErrI } from '../Types/department';

export const useGetSalesTemMemberJobCard = (isEnable, page, productCode) => {
  return useQuery([jobcard.JOBCARDSUBMITTED, page, productCode], jobcardApi.GetSalesTemMemberJobCard, {
    enabled: isEnable,
    select: (res) => res?.data,
    // ! TODO: for future implementation toast
    // onSuccess: () => {
    //   // const data = res?.data;
    // },
    // onError: (err) => {
    //   return err;
    //   // const errData = err?.data;
    //   // toast.error(errData.message);
    // },
    refetchOnWindowFocus: false
  })
}

export const useGetSalesTemMemberApprovedJobCard = (isEnable, page, productCode) => {
  const queryClient = useQueryClient()
  return useQuery([jobcard.JOBCARDAPPROVED, page, productCode], jobcardApi.GetSalesTemMemberApprovedJobCard, {
    enabled: isEnable,
    select: (res) => res?.data,
    onSuccess: () => {
      // const data = res.data;
      queryClient.invalidateQueries([jobcard.JOBCARDUPDATE])
    },
    // onError: (err) => {
    //   return err;
    //   // const errData = err?.data;
    //   // toast.error(errData.message);
    // },
    refetchOnWindowFocus: false
  })
}
export const useGetSalesTemMemberDraftJobCard = (isEnable, page, productCode) => {
  const queryClient = useQueryClient()
  return useQuery([jobcard.JOBCARDDRAFT, page, productCode], jobcardApi.GetSalesTemMemberDraftJobCard, {
    enabled: isEnable,
    select: (res) => res?.data,
    onSuccess: () => {
      // const data = res.data;
      queryClient.invalidateQueries([jobcard.JOBCARDUPDATE])
    },
    // onError: (err) => {
    //   return err;
    //   // const errData = err?.data;
    //   // toast.error(errData.message);
    // },
    refetchOnWindowFocus: false
  })
}
export const useGetSalesTemMemberOngoingJobCard = (isEnable, page, productCode) => {
  return useQuery([jobcard.JOBCARDONGOING, page, productCode], jobcardApi.GetSalesTemMemberOngoingJobCard, {
    enabled: isEnable,
    select: (res) => res?.data,
    // ! TODO: for future implementation toast
    // onSuccess: () => {
    //   // const data = res?.data;
    // },
    // onError: (err) => {
    //   return err;
    //   // const errData = err?.data;
    //   // toast.error(errData.message);
    // },
    refetchOnWindowFocus: false
  })
}
export const useGetSalesTemMemberCompletionJobCard = (isEnable, page, productCode) => {
  return useQuery([jobcard.JOBCARDCOMPLETION, page, productCode], jobcardApi.GetSalesTemMemberCompletionJobCard, {
    enabled: isEnable,
    select: (res) => res?.data,
    // ! TODO: for future implementation toast
    // onSuccess: () => {
    //   // const data = res?.data;
    // },
    // onError: (err) => {
    //   return err;
    //   // const errData = err?.data;
    //   // toast.error(errData.message);
    // },
    refetchOnWindowFocus: false
  })
}
export const useAddJobcard = () => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  return useMutation(jobcardApi.addJobcard, {
    onSuccess: () => {
      queryClient.invalidateQueries([jobcard.JOBCARD])
      navigate('/job-card', { state: { activeTab: '1' } })
      localStorage.removeItem('processData')
    },
    onError: () => {

      // ! TODO: for future implementation toast
      // toast.error(errData.message);
    }
  })
}

export const useCompleteTask = () => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  return useMutation(jobcardApi.addCompleteTask, {
    onSuccess: () => {
      queryClient.invalidateQueries([jobcard.JOBCARD])
      navigate('/job-card-department', { state: { activeTab: '4' } })
    },
    onError: () => {

      // ! TODO: for future implementation toast
      // toast.error(errData.message);
    }
  })
}

export const useUpdateSalesMember = () => {
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  return useMutation(jobcardApi.UpdateSalesMember, {
    onSuccess: () => {
      // const data = res.data;
      // queryClient.invalidateQueries([jobcard.JOBCARDUPDATE])
      queryClient.invalidateQueries([jobcard.JOBCARDAPPROVED, 1])
      queryClient.invalidateQueries([jobcard.JOBCARDSUBMITTED, 1])
      navigate('/job-card', { state: { activeTab: '2' } })
    },
    onError: (err) => {
      return err
      // const errData = err?.data;
      // toast.error(errData.message);
    }
  })
}
export const useGetproductionTeamMember = (isEnable = false, page, departmentId) => {
  return useQuery([jobcard.JOBCARD, page, departmentId], jobcardApi.getproductionTeamMember, {
    enabled: isEnable,
    select: (res) => res?.data,
    // ! TODO: for future implementation toast
    // onSuccess: () => {
    //   // const data = res?.data;
    // },
    // onError: (err) => {
    //   return err;
    //   // const errData = err?.data;
    //   // toast.error(errData.message);
    // },
    refetchOnWindowFocus: false
  })
}

export const useGetproductionTeamMemberPending = (isEnable = false, page, departmentId) => {
  return useQuery([jobcard.JOBCARDPENDING, page, departmentId], jobcardApi.getproductionTeamMemberPending, {
    enabled: isEnable,
    select: (res) => res?.data,
    // ! TODO: for future implementation toast
    // onSuccess: () => {
    //   // const data = res?.data;
    // },
    // onError: (err) => {
    //   return err;
    //   // const errData = err?.data;
    //   // toast.error(errData.message);
    // },
    refetchOnWindowFocus: false
  })
}
export const useGetproductionTeamMemberCompleted = (isEnable = false, page, departmentId) => {
  return useQuery([jobcard.JOBCARD, page, departmentId], jobcardApi.getproductionTeamMemberCompleted, {
    enabled: isEnable,
    select: (res) => res?.data,
    // ! TODO: for future implementation toast
    // onSuccess: () => {
    //   // const data = res?.data;
    // },
    // onError: (err) => {
    //   return err;
    //   // const errData = err?.data;
    //   // toast.error(errData.message);
    // },
    refetchOnWindowFocus: false
  })
}
export const useGetproductionTeamMemberOngoing = (isEnable = false, page, departmentId) => {
  return useQuery([jobcard.JOBCARD, page, departmentId], jobcardApi.getproductionTeamMemberOngoing, {
    enabled: isEnable,
    select: (res) => res?.data,
    // ! TODO: for future implementation toast
    // onSuccess: () => {
    //   // const data = res?.data;
    // },
    // onError: (err) => {
    //   return err;
    //   // const errData = err?.data;
    //   // toast.error(errData.message);
    // },
    refetchOnWindowFocus: false
  })
}
export const useAddTask = () => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  return useMutation(jobcardApi.addTask, {
    onSuccess: () => {
      queryClient.invalidateQueries([jobcard.JOBCARD])
      navigate('/job-card-department', { state: { activeTab: '1' } })
    },
    onError: () => {

      // ! TODO: for future implementation toast
      // toast.error(errData.message);
    }
  })
}
export const useScheduleTask = () => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  return useMutation(jobcardApi.addScheduleTask, {
    onSuccess: () => {
      queryClient.invalidateQueries([jobcard.JOBCARD])
      navigate('/job-card-department', { state: { activeTab: '3' } })
    },
    onError: () => {

      // ! TODO: for future implementation toast
      // toast.error(errData.message);
    }
  })
}
export const useJobSalesteamMember = () => {
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  return useMutation(jobcardApi.deleteJobSalesteamMember, {
    onSuccess: (res, id) => {
      // const data = res.data;
      queryClient.invalidateQueries([jobcard.JOBCARDS])
      queryClient.removeQueries([jobcard.JOBCARD, id])
      // toast.success(data.message);
      navigate('/job-card', { state: { activeTab: '1' } })
    }
    // onError: (err) => {
    //   return err;
    //   // const errData = err?.data;
    //   // toast.error(errData.message);
    // },
  })
}
export const useJobcardDraft = () => {
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  return useMutation(jobcardApi.deleteJobSalesteamMember, {
    onSuccess: (res, id) => {
      // const data = res.data;
      queryClient.invalidateQueries([jobcard.JOBCARDS])
      queryClient.removeQueries([jobcard.JOBCARD, id])
      // toast.success(data.message);
      navigate('/job-card', { state: { activeTab: '4' } })
    }
    // onError: (err) => {
    //   return err;
    //   // const errData = err?.data;
    //   // toast.error(errData.message);
    // },
  })
}
export const useGetJobDetailsSingle = (id, isEnable = true) => {
  return useQuery([jobcard.JOBCARD, id], jobcardApi.getSingleJobDetails, {
    enabled: isEnable,
    // onSuccess: (res) => {
    //   // const data = res?.data;
    // },
    onError: () => {

    },
    refetchOnWindowFocus: false
  })
}
export const useGetJobDetailsSingleSchedule = (id, isEnable = true) => {
  return useQuery([jobcard.JOBCARD, id], jobcardApi.getSingleJobDetailsSchedule, {
    enabled: isEnable,
    // onSuccess: (res) => {
    //   // const data = res?.data;
    // },
    onError: () => {

    },
    refetchOnWindowFocus: false
  })
}
export const useGetJobDetailsSingleTask = (id, isEnable = true) => {
  return useQuery([jobcard.JOBCARD, id], jobcardApi.getSingleJobDetailsTask, {
    enabled: isEnable,
    // onSuccess: (res) => {
    //   // const data = res?.data;
    // },
    onError: () => {

    },
    refetchOnWindowFocus: false
  })
}
export const updatePushToPanding = () => {
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  return useMutation(jobcardApi.updatePushToPanding, {
    onSuccess: (res, id) => {
      // const data = res;
      queryClient.invalidateQueries([jobcard.JOBCARD])
      queryClient.invalidateQueries([jobcard.JOBCARD, id])
      queryClient.invalidateQueries([jobcard.JOBCARDPENDING, id])
      navigate('/job-card-department', { state: { activeTab: '2' } })
    },
    onError: (err) => {
      return err
      // ! TODO: for future implementation toast
      // const errData = err?.data;
      // toast.error(errData.message);
    }
  })
}
export const useRestoreJobCard = () => {
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  return useMutation(jobcardApi.restoreJobCard, {
    onSuccess: () => {
      queryClient.invalidateQueries([jobcard.JOBCARDS])
      // ! TODO: for future implementation toast
      // queryClient.invalidateQueries([part.PART, id]);
      // toast.success(data.message);
      navigate('/deleted-items')
    }
    // ! TODO: for future implementation toast
    // onError: (err: any) => {
    //   const errData = err?.data;
    //   //toast.error(errData.message);
    // },
  })
}

export const useHardDeleteJobCard = () => {
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  return useMutation(jobcardApi.hardDeleteJobCard, {
    onSuccess: () => {
      queryClient.invalidateQueries([jobcard.JOBCARDS])
      navigate('/deleted-items')
    }
    // ! TODO: for future implementation toast
    // onError: (err: any) => {
    //   const errData = err?.data;
    //   //toast.error(errData.message);
    // },
  })
}
export const useGetTaskData = (id, isEnable = true, departmentId) => {
  return useQuery([jobcard.GET_JOBCARD, id, departmentId], jobcardApi.getTask, {
    enabled: isEnable,
    // onSuccess: (res) => {
    //   // const data = res?.data;
    // },
    onError: () => {

    },
    refetchOnWindowFocus: false
  })
}
export const useGetDeletedJobCard = (id, isEnable = true) => {
  return useQuery([jobcard.GET_JOBCARD_DELETE + 'DELETED', id], jobcardApi.getDeleteJobCard, {
    enabled: isEnable,
    select: (data) => data.data,
    // onSuccess: (res: any) => {
    //   const data = res?.data;
    // },
    // onError: (err: any) => {
    //   const errData = err?.data;
    //   //toast.error(errData.message);
    // },
    refetchOnWindowFocus: false
  })
}
export const useGetDeleteTask = (id, isEnable = true) => {
  return useQuery([jobcard.GET_TASK_DELETE + 'DELETED', id], jobcardApi.getDeleteTask, {
    enabled: isEnable,
    select: (data) => data.data,
    // onSuccess: (res: any) => {
    //   const data = res?.data;
    // },
    // onError: (err: any) => {
    //   const errData = err?.data;
    //   //toast.error(errData.message);
    // },
    refetchOnWindowFocus: false
  })
}
export const useAddjobcardClone = (id) => {
  const queryClient = useQueryClient()
  // const navigate = useNavigate()
  return useMutation(jobcardApi.jobcardClone, {
    onSuccess: () => {
      queryClient.invalidateQueries([jobcard.JOBCARD_CLONE, id])
      queryClient.invalidateQueries([jobcard.JOBCARDSUBMITTED])
      // navigate('/job-card', { state: { activeTab: '1' } })
    },
    onError: () => {

      // ! TODO: for future implementation toast
      // toast.error(errData.message);
    }
  })
}

export const useUpdateJobcard = (isUpdateSalesMemberCalled = false) => {
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const { mutate: updateSalesMember } = useUpdateSalesMember()

  return useMutation(jobcardApi.editJobcard, {
    onSuccess: (res) => {
      queryClient.invalidateQueries([jobcard.JOBCARD])
      queryClient.invalidateQueries([jobcard.SINGLE_JOBCARDS, res?.data?.id])
      navigate('/job-card', { state: { activeTab: '1' } })

      // localStorage.removeItem('processData')
      if (isUpdateSalesMemberCalled) {
        updateSalesMember({ id: res?.data?.id })
      }
    },
    onError: (err) => {
      return err
      // const errData = err?.data;
      // toast.error(errData.message);
    }
  })
}
export const useGetSingleJobcardSales = (id, isEnable = true) => {
  return useQuery([jobcard.SINGLE_JOBCARDS, id], jobcardApi.getSingleJobcardSales, {
    enabled: isEnable,
    // onSuccess: (res) => {
    //   // const data = res?.data;
    // },
    onError: () => {

    },
    refetchOnWindowFocus: false
  })
}
export const useAddDraftJobcard = () => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  return useMutation(jobcardApi.addDraftJobcard, {
    onSuccess: () => {
      queryClient.invalidateQueries([jobcard.ADDJOBCARDDRAFT])
      navigate('/job-card', { state: { activeTab: '1' } })
      localStorage.removeItem('processData')
    },
    onError: () => {

      // ! TODO: for future implementation toast
      // toast.error(errData.message);
    }
  })
}
export const useValidateJobcardNumber = () => {
  // const queryClient = useQueryClient();
  // const navigate = useNavigate();
  return useMutation(jobcardApi.validateJobcardNumber, {
    // onSuccess: (res) => {
    // queryClient.invalidateQueries([machine.MACHINES]);
    // const data = res.data;
    // toast.success(data?.message);
    // navigate(`/machine-details/${data?.machine.id}`);
    // navigate(`/resources`, { state: { activeTab: '1' } });
    // },
    // onError: (err: any) => {
    // const errData = err?.data;
    // },
  })
}
