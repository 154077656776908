import { Box, CircularProgress, Tab, Typography } from '@mui/material'
import React, { useState } from 'react'
import Tabs from '@mui/material/Tabs'
import { TabContext, TabPanel } from '@mui/lab'
import DeleteIconNew from '../../assets/svg/DeleteIconNew'
import DeleteWarningModal from '../../modals/deletewarningmodal'
import NestedTable from '../../leastcomponent/customtable/nestedTable'
import EditIconNew from '../../assets/svg/EditIconNew'
import BreadcrumbNavigation from '../commoncomponents/breadcrumbnavigation'
// import BlackKanban from '../../assets/svg/blackKanban'
// import FilterIconNew from '../../assets/svg/FilterIconNew'
import { useGetproductionTeamMember, useGetproductionTeamMemberCompleted, useGetproductionTeamMemberOngoing, useGetproductionTeamMemberPending } from '../../hooks/jobcardHooks'
import moment from 'moment'
import NestedTableSecond from '../../leastcomponent/customtable/nestedTableSecond'
// import { useQueryClient } from '@tanstack/react-query'
// import { user } from '../../querykeys/usermanagement'
import { useLocation, useNavigate } from 'react-router-dom'
import NoData from '../../leastcomponent/noAddedYet'
const ProductionTeamMember = () => {
  const selectDepartmentid = localStorage.getItem('selectDepartmentid')
  const [deleteRecord, setDeleteRecord] = useState(false)
  const handleDeleteOpen = () => setDeleteRecord(true)
  const handleDeleteClose = () => setDeleteRecord(false)
  const [page, setPage] = useState(1)
  const [panddingPage, setPanddingPage] = useState(1)
  const location = useLocation()
  const [value, setValue] = useState(location?.state?.activeTab || '1')
  const [ongoingPage, setOngoingPage] = useState(1)
  const [completedpage, setCompletedPage] = useState(1)
  const navigate = useNavigate()
  const handleChange = (event, newValue) => {
    if (!isproductionTeamMember || !isproductionTeamMemberPending || !productionTeamMemberOngoing || !productionTeamMemberCompleted) {
      // history.replace({
      //   ...location,
      //   state: newState
      // });
      navigate('', { state: { activeTab: newValue } })
      setValue(newValue)
    }
  }
  // const [selectedDepartment, setSelectedDepartment] = useState('')
  // const [first, setfirst] = useState('')
  const [first, setfirst] = useState('')
  // const queryClient = useQueryClient()
  // const userData = queryClient.getQueryData([user.LOGGED_IN_USER])
  // const departmentGroupTitle = userData?.data?.data?.user?.roleId?.departmentGroup?.title

  const mainCols = [
    { field: '#', headerName: '#', flex: 0.2 },
    { field: 'Job Card Number', headerName: 'Job Card Number', flex: 1 },
    { field: 'Job Card Name', headerName: 'Job Card Name', flex: 1 },
    { field: 'Product', headerName: 'Product', flex: 1 },
    { field: 'Qty', headerName: 'Qty', flex: 1 },
    { field: 'Customer Name', headerName: 'Customer Name', flex: 1 },
    { field: 'Submitted On', headerName: 'Submitted On', flex: 1 },
    { field: 'Priority', headerName: 'Priority', flex: 1 },
    { field: 'Approved On', headerName: 'Approved On', flex: 1 },
    ...(value !== '1' && value !== '3' && value !== '4' ? [{ field: 'Pending since', headerName: 'Pending since', flex: 1 }] : []),
    // { field: 'Pending since', headerName: 'Pending since', flex: 1 },
    ...(value !== '1' && value !== '2' && value !== '3' ? [{ field: 'Overall completion', headerName: 'Overall completion', flex: 1 }] : [])

  ]
  // TODO:: kanban
  // if (value !== 3 || value !== 4) {
  //   mainCols = [...mainCols, { field: '', headerName: 'view', flex: 1 }]
  // }

  const finalDataColumns = [
    { field: 'id', headerName: '#', flex: 0 },
    { field: 'Part Name', headerName: 'Part Name', flex: 1 },
    { field: ' Part Code', headerName: ' Part Code', flex: 1 },
    { field: 'Drawing Number', headerName: 'Drawing Number', flex: 1 },
    { field: 'Qty', headerName: 'Qty', flex: 1 },
    { field: 'Dept. sequence', headerName: 'Dept. sequence', flex: 1 },
    { field: 'Preceding dept.', headerName: 'Preceding dept.', flex: 1 },
    { field: 'Next dept.', headerName: 'Next dept.', flex: 1 },
    ...(value !== '3' && value !== '4'
      ? [{
          field: 'actions',
          headerName: 'Actions',
          sortable: false,
          disableClickEventBubbling: true,
          flex: 1,
          renderCell: () => {
            return (
          <Box className="report-table-btn-main">
            <span className="report-delete">
              <EditIconNew />
            </span>
            <span className="report-delete">
              <DeleteIconNew />
            </span>
          </Box>
            )
          }

        }
        ]
      : [])
  ]

  const {
    data: productionTeamMemberListInfo,
    isFetching: isproductionTeamMember
  } = useGetproductionTeamMember(!!(selectDepartmentid && value === '1'), page, selectDepartmentid)
  const productionTeamMemberList = productionTeamMemberListInfo?.results
  const finalDataOne = productionTeamMemberList?.map((data, ind) => ({
    id: data.id ? data.id : '-',
    no: ind + 1,
    name: data.name ? data.name : '-',
    number: data.jobCardNumber ? data.jobCardNumber : '-',
    saleOrderNumber: data.saleOrderNumber ? data.saleOrderNumber : '-',
    productName: data.productName ? data.productName : '-',
    quantity: data.quantity ? data.quantity : '-',
    customerName: data.customerName ? data.customerName : '-',
    priority: data.priority ? data.priority : '-',
    approveDate: data.approveDate
      ? moment(data?.approveDate)?.format('DD/MM/YY')
      : '-',
    submitted: data?.submitted
      ? moment(data?.submitted)?.format('DD/MM/YY')
      : '-',
    date: data?.date
      ? moment(data?.date?.[0]?.date)?.format('DD/MM/YY')
      : '-',
    jobCardStatus: data.jobCardStatus,
    history: data.bomparts?.map((bompart) => {
      return ({
        bomprocesses: bompart?.bomprocesses ? bompart?.bomprocesses : '-',
        partName: bompart?.partName ? bompart?.partName : '-',
        itemCode: bompart?.itemCode ? bompart?.itemCode : '-',
        drawingNumber: bompart?.drawingNumber ? bompart?.drawingNumber : '-',
        jobCardId: bompart?.jobCardId ? bompart?.jobCardId : '-',
        departmentSequence: bompart?.bomprocesses?.[0]?.numericIndex || '-',
        prevDeptName: bompart?.bomprocesses?.[0]?.prevDeptName === 'None' ? '-' : bompart?.bomprocesses?.[0]?.prevDeptName,
        nextDeptName: bompart?.bomprocesses?.[0]?.nextDeptName === 'None' ? '-' : bompart?.bomprocesses?.[0]?.nextDeptName,
        qty: bompart?.quantity ? bompart?.quantity : '-',
        partStatus: bompart?.partStatus || '-',
        id: bompart?.id ? bompart?.id : '-',
        isShow: true,
        taskLength: bompart?.taskLength ? bompart?.taskLength : '-'

      })
    })
  })) || []
  const {
    data: productionTeamMemberPendingListInfo,
    isFetching: isproductionTeamMemberPending
  } = useGetproductionTeamMemberPending(!!(selectDepartmentid && value === '2'), panddingPage, selectDepartmentid)
  const productionTeamMemberPendingList = productionTeamMemberPendingListInfo?.results
  const finalDataPandding = productionTeamMemberPendingList?.map((data, ind) => ({
    id: data.id ? data.id : '-',
    no: ind + 1,
    name: data.name ? data.name : '-',
    number: data.jobCardNumber ? data.jobCardNumber : '-',
    saleOrderNumber: data.saleOrderNumber ? data.saleOrderNumber : '-',
    productName: data.productName ? data.productName : '-',
    quantity: data.quantity ? data.quantity : '-',
    customerName: data.customerName ? data.customerName : '-',
    priority: data.priority ? data.priority : '-',
    approveDate: data.approveDate
      ? moment(data?.approveDate)?.format('DD/MM/YY')
      : '-',
    pushToPendingDate: data?.bomparts[0]?.task[0]?.pushToPendingDate
      ? moment(data?.bomparts[0]?.task[0]?.pushToPendingDate)?.format('DD/MM/YY')
      : '-',
    submitted: data?.submitted
      ? moment(data?.submitted)?.format('DD/MM/YY')
      : '-',
    date: data?.date
      ? moment(data?.date?.[0]?.date)?.format('DD/MM/YY')
      : '-',
    jobCardStatus: data.jobCardStatus ? data.jobCardStatus : '-',
    history: data.bomparts?.map((bompart) => ({
      partName: bompart?.partName ? bompart?.partName : '-',
      itemCode: bompart?.itemCode ? bompart?.itemCode : '-',
      drawingNumber: bompart?.drawingNumber ? bompart?.drawingNumber : '-',
      qty: bompart?.quantity ? bompart?.quantity : '-',
      jobCardId: bompart?.jobCardId ? bompart?.jobCardId : '-',
      departmentSequence: bompart?.bomprocesses?.[0]?.numericIndex || '-',
      prevDeptName: bompart?.bomprocesses?.[0]?.prevDeptName === 'None' ? '-' : bompart?.bomprocesses?.[0]?.prevDeptName,
      nextDeptName: bompart?.bomprocesses?.[0]?.nextDeptName === 'None' ? '-' : bompart?.bomprocesses?.[0]?.nextDeptName,
      id: bompart?.id ? bompart?.id : '-',
      isShow: true
    }))
  })) || []

  const {
    data: productionTeamMemberOngoingListInfo,
    isFetching: productionTeamMemberOngoing
  } = useGetproductionTeamMemberOngoing(!!(selectDepartmentid && value === '3'), ongoingPage, selectDepartmentid)

  const productionTeamMemberOngoingList = productionTeamMemberOngoingListInfo?.results
  const finalDataOngoing = productionTeamMemberOngoingList?.map((data, ind) => ({
    no: ind + 1,
    id: data.id ? data.id : '-',
    name: data.name ? data.name : '-',
    number: data.jobCardNumber ? data.jobCardNumber : '-',
    saleOrderNumber: data.saleOrderNumber ? data.saleOrderNumber : '-',
    productName: data.productName ? data.productName : '-',
    quantity: data.quantity ? data.quantity : '-',
    customerName: data.customerName ? data.customerName : '-',
    priority: data.priority ? data.priority : '-',
    approveDate: data.approveDate
      ? moment(data?.approveDate)?.format('DD/MM/YY')
      : '-',
    submitted: data?.submitted
      ? moment(data?.submitted)?.format('DD/MM/YY')
      : '-',
    date: data?.date
      ? moment(data?.date?.[0]?.date)?.format('DD/MM/YY')
      : '-',
    jobCardStatus: data.jobCardStatus ? data.jobCardStatus : '-',
    history: data.bomparts?.map((bompart) => ({
      partName: bompart?.partName ? bompart?.partName : '-',
      itemCode: bompart?.itemCode ? bompart?.itemCode : '-',
      drawingNumber: bompart?.drawingNumber ? bompart?.drawingNumber : '-',
      jobCardId: bompart?.jobCardId ? bompart?.jobCardId : '-',
      qty: bompart?.quantity ? bompart?.quantity : '-',
      departmentSequence: bompart?.bomprocesses?.[0]?.numericIndex || '-',
      prevDeptName: bompart?.bomprocesses?.[0]?.prevDeptName === 'None' ? '-' : bompart?.bomprocesses?.[0]?.prevDeptName,
      nextDeptName: bompart?.bomprocesses?.[0]?.nextDeptName === 'None' ? '-' : bompart?.bomprocesses?.[0]?.nextDeptName,
      id: bompart?.id ? bompart?.id : '-',
      isShow: false
    }))
  })) || []
  const {
    data: productionTeamMemberCompletedListInfo,
    isFetching: productionTeamMemberCompleted
  } = useGetproductionTeamMemberCompleted(!!(selectDepartmentid && value === '4'), completedpage, selectDepartmentid)

  const productionTeamMemberCompletedList = productionTeamMemberCompletedListInfo?.results
  const finalDataCompleted = productionTeamMemberCompletedList?.map((data, ind) => ({
    no: ind + 1,
    id: data.id ? data.id : '-',
    name: data.name ? data.name : '-',
    number: data.jobCardNumber ? data.jobCardNumber : '-',
    saleOrderNumber: data.saleOrderNumber ? data.saleOrderNumber : '-',
    productName: data.productName ? data.productName : '-',
    quantity: data.quantity ? data.quantity : '-',
    customerName: data.customerName ? data.customerName : '-',
    priority: data.priority ? data.priority : '-',
    overallCompletion: data.overallCompletion ? `${data.overallCompletion?.toFixed(2)}%` : '-',
    approveDate: data.approveDate
      ? moment(data?.approveDate)?.format('DD/MM/YY')
      : '-',
    submitted: data?.submitted
      ? moment(data?.submitted)?.format('DD/MM/YY')
      : '-',
    date: data?.date
      ? moment(data?.date?.[0]?.date)?.format('DD/MM/YY')
      : '-',
    jobCardStatus: data.jobCardStatus ? data.jobCardStatus : '-',
    history: data.bomparts?.map((bompart) => ({
      partName: bompart?.partName ? bompart?.partName : '-',
      itemCode: bompart?.itemCode ? bompart?.itemCode : '-',
      drawingNumber: bompart?.drawingNumber ? bompart?.drawingNumber : '-',
      jobCardId: bompart?.jobCardId ? bompart?.jobCardId : '-',
      qty: bompart?.quantity ? bompart?.quantity : '-',
      departmentSequence: bompart?.bomprocesses?.[0]?.numericIndex || '-',
      prevDeptName: bompart?.bomprocesses?.[0]?.prevDeptName === 'None' ? '-' : bompart?.bomprocesses?.[0]?.prevDeptName,
      nextDeptName: bompart?.bomprocesses?.[0]?.nextDeptName === 'None' ? '-' : bompart?.bomprocesses?.[0]?.nextDeptName,
      id: bompart?.id ? bompart?.id : '-',
      isShow: true
    }))
  })) || []
  return (
    <React.Fragment>
      <Box className="production-team-head-overflow-hidden">
        <BreadcrumbNavigation
          breadCrumbData={null}
          setfirst={setfirst}
          first={first}
          // pageTitle={`Job Cards - ${userData?.data?.data?.user?.userRoleType === 'administrator' ? 'All' : departmentGroupTitle}`}
          pageTitle={'Job Cards -'}
          backToLink={null}
          isDepartment={true}
          selectedDepartment={selectDepartmentid}
          // handleSelectChange={handleSelectChange}
        />
        <Box className="production-team-head-overflow-auto">
          <TabContext value={value}>
            <Box
              sx={{
                typography: 'body1'
              }}
              className="tab-content-padding"
            >
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="lab API tabs example"
              >
                <Tab className="tab-head" label="Approved" value="1" />
                <Tab className="tab-head" label="Pending" value="2" />
                <Tab className="tab-head" label="Ongoing" value="3" />
                <Tab className="tab-head" label="Completed" value="4" />
                {/* <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    marginLeft: '73%'
                  }}
                >
                  <BlackKanban />
                </Box> */}
              </Tabs>
            </Box>
            <TabPanel value="1" className="tab-panel-padding">
              <Box>
                <Box className="common-parent-haeding-btn-div">
                  <Typography className="common-tabel-heading">
                    Approved Job Cards
                  </Typography>
                  {/* <CustomButton
                variant="contained"
                title="Add Job Card"
                onClick={() => navigate(`/add-task-production-team-member`)}
                type="button"
                endIcon=""
                startIcon={<AddIcon />}
              /> */}
                  {/* <Box className="job-card-filter">
                    {' '}
                    <FilterIconNew />
                    <Typography>Filters</Typography>
                  </Box> */}
                </Box>
                {
                  !isproductionTeamMember
                    ? <NestedTable
                  rows={finalDataOne}
                  columns={finalDataColumns}
                  mainCols={mainCols}
                  page={page}
                  setPage={setPage}
                  paginationData={productionTeamMemberListInfo}
                  totalResults={productionTeamMemberListInfo?.totalResults}
                  value={value}
                  // status={value}
                />
                    : <Box className='progress-parent'>
                      <CircularProgress />
                    </Box>}
              </Box>
             { !selectDepartmentid && <NoData label='Please select department'/>
              }
            </TabPanel>
            <TabPanel value="2" className="tab-panel-padding">
              <Box>
                <Box className="common-parent-haeding-btn-div">
                  <Typography className="common-tabel-heading">
                    Pending Job cards
                  </Typography>
                  {/* <Box className="job-card-filter">
                    <FilterIconNew />
                    <Typography>Filters</Typography>
                  </Box> */}
                </Box>
                {
                  !isproductionTeamMemberPending
                    ? <NestedTableSecond
                  rows={finalDataPandding}
                  columns={finalDataColumns}
                  mainCols={mainCols}
                      page={panddingPage}
                      setPage={setPanddingPage}
                      paginationData={productionTeamMemberPendingListInfo}
                      totalResults={productionTeamMemberPendingListInfo?.totalResults}
                      // status={value}
                />
                    : <Box className='progress-parent'>
                      <CircularProgress />
                    </Box>}
              </Box>
              { !selectDepartmentid && <NoData label='Please select department'/>}

            </TabPanel>
            <TabPanel value="3" className="tab-panel-padding">
              <Box>
                <Box className="common-parent-haeding-btn-div">
                  <Typography className="common-tabel-heading">
                    Ongoing Job cards
                  </Typography>
                  {/* <Box className="job-card-filter">
                    {' '}
                    <FilterIconNew />
                    <Typography>Filters</Typography>
                  </Box> */}
                </Box>
                {
                  !productionTeamMemberOngoing
                    ? <NestedTable
                  rows={finalDataOngoing}
                  isOngoing={true}
                  columns={finalDataColumns}
                  mainCols={mainCols}
                      page={ongoingPage}
                      setPage={setOngoingPage}
                      paginationData={productionTeamMemberOngoingListInfo}
                      totalResults={productionTeamMemberOngoingListInfo?.totalResults}
                      // status={value}
                />
                    : <Box className='progress-parent'>
                      <CircularProgress />
                    </Box>}
              </Box>
              { !selectDepartmentid && <NoData label='Please select department'/>}

            </TabPanel>
            <TabPanel value="4" className="tab-panel-padding">
              <Box>
                <Box className="common-parent-haeding-btn-div">
                  <Typography className="common-tabel-heading">
                    Completed Job cards
                  </Typography>
                  {/* <CustomButton
                variant="contained"
                title="Add Job Card"
                onClick={() => navigate(`/add-task-production-team-member`)}
                type="button"
                endIcon=""
                startIcon={<AddIcon />}
              /> */}
              {/* <Box className="job-card-filter">
                    {' '}
                    <FilterIconNew />
                    <Typography>Filters</Typography>
                  </Box> */}
                </Box>
                {
                  !productionTeamMemberCompleted
                    ? <NestedTable
                  rows={finalDataCompleted}
                  columns={finalDataColumns}
                  isCompleted={true}
                  mainCols={mainCols}
                  page={completedpage}
                  setPage={setCompletedPage}
                  paginationData={productionTeamMemberCompletedListInfo}
                  totalResults={productionTeamMemberCompletedListInfo?.totalResults}
                  // status={value}
                />
                    : <Box className='progress-parent'>
                      <CircularProgress />
                    </Box>}
              </Box>
              { !selectDepartmentid && <NoData label='Please select department'/>}

            </TabPanel>
          </TabContext>
        </Box>
        {deleteRecord && (
          <DeleteWarningModal
            open={deleteRecord}
            handleOpen={handleDeleteOpen}
            handleClose={handleDeleteClose}
          />
        )}
      </Box>
    </React.Fragment>
  )
}

export default ProductionTeamMember
