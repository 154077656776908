import React, { useState } from 'react'
import { Box, Grid, TextField, Button, Modal, Typography } from '@mui/material'
import BreadcrumbNavigation from '../commoncomponents/breadcrumbnavigation'
import './systemHealth.css'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'
import CustomButton from '../../leastcomponent/custombutton/custombutton'
import { useGetHealth } from '../../hooks/health'
import { useNavigate } from 'react-router-dom'
import styled from '@emotion/styled'
// import CloseIcon from '@mui/icons-material/Close'

const Input = styled(TextField)(() => ({
  borderRadius: '4px',
  '.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input': {
    height: '40px',
    padding: '1px 14px'
  },

  '.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input:-webkit-autofill': {
    borderRadius: '4px'
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#8A8A8A',
      borderRadius: '4px'
    }
  }
}))
const SystemHealthTable = () => {
  const navigate = useNavigate()
  const [enteredPassword, setEnteredPassword] = useState('')
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [open, setOpen] = useState(true)
  const correctPassword = '123789'
  const { data: getHealth, status: currentStatus } = useGetHealth(true)
  console.log('getHealth===>', getHealth, currentStatus)
  // Data
  const systemHealth = [
    'Database connection',
    'Back end connection',
    'No. of users using system',
    'No. of active users (active since the last 48 hours)',
    'Memory usage ',
    'Storage usage',
    'Database backup completed on'
  ]
  const indicator = [
    currentStatus === 'success' ? 'Green' : 'Red',
    currentStatus === 'success' ? 'Green' : 'Red',
    'Green',
    'Red',
    'Green',
    'Green',
    'Red'
  ]
  const status = [
    currentStatus === 'success'
      ? 'Database Connection Working Fine'
      : 'Database Connection Failed!!!',
    currentStatus === 'success'
      ? 'Back end Connection Working Fine'
      : 'Failed To Connect With Backend!!!',
    'Under Working',
    'Under Working',
    'Under Working',
    '0%',
    'Under Working'
  ]

  const handlePasswordSubmit = () => {
    if (enteredPassword === correctPassword) {
      setIsAuthenticated(true)
      setOpen(false)
    } else {
      alert('Incorrect password. Please try again.')
    }
  }
  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handlePasswordSubmit()
    }
  }
  // const handleClose = () => {
  //   setOpen(false)
  // }
  return (
    <>
      <Modal open={open}>
        <Box className="health-modal-main-box">
          <Box className="health-modal-box">
            {/* <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <CloseIcon
                onClick={handleClose}
                sx={{ cursor: 'pointer' }}
              />
            </Box> */}
            <Box>
              <Typography className="field-title">Password</Typography>
              <Input
                placeholder="Enter Password"
                fullWidth
                className="back-color"
                onChange={(e) => setEnteredPassword(e.target.value)}
                value={enteredPassword}
                type="password"
                onKeyDown={handleKeyPress}
              />
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <Button
                variant="contained"
                onClick={handlePasswordSubmit}
                sx={{ marginTop: '20px' }}
              >
                Submit
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
      {isAuthenticated && (
        <Box className="production-team-head-overflow-hidden">
          <BreadcrumbNavigation
            breadCrumbData={null}
            pageTitle={'System Health'}
            isSearch={false}
            isUserInfo={false}
          />
          <Box className="system-health-overflow-auto">
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'end',
                paddingBottom: '30px'
              }}
            >
              {/* <CustomButton
                variant="outlined"
                title="Referesh"
                type="submit"
                startIcon={<Referesh fill={'#0160B9'} />}
                // onClick={() => setNewOpen(true)}
              /> */}
            </Box>
            <Box>
              <Grid container sx={{ border: '1px solid #C0C0C0' }}>
                <Grid item sm={5} className="system-health-heading">
                  System Health
                </Grid>
                <Grid item sm={2} className="system-health-heading">
                  Indicator
                </Grid>
                <Grid
                  item
                  sm={5}
                  className="system-health-heading"
                  sx={{ borderRight: 'none' }}
                >
                  Status
                </Grid>
              </Grid>
            </Box>
            <Box sx={{ border: '1px solid #C0C0C0', borderTop: 'none' }}>
              {systemHealth.map((item, index) => (
                <Grid container key={index}>
                  <Grid item sm={5} className="system-health-item-data">
                    {item}
                  </Grid>
                  <Grid item sm={2} className="system-health-item-data">
                    <span style={{ paddingRight: '10px' }}>
                      <FiberManualRecordIcon
                        sx={{
                          fontSize: '18px',
                          color:
                            indicator[index] === 'Red' ? '#EB5757' : '#A5DA6B'
                        }}
                      />
                    </span>{' '}
                    {indicator[index]}
                  </Grid>
                  <Grid
                    item
                    sm={5}
                    className="system-health-item-data"
                    sx={{ borderRight: 'none' }}
                  >
                    {status[index]}
                  </Grid>
                </Grid>
              ))}
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'end',
                paddingTop: '320px'
              }}
            >
              <CustomButton
                title="Back to login"
                type="submit"
                onClick={() => navigate('/login')}
              />
            </Box>
          </Box>
        </Box>
      )}
    </>
  )
}

export default SystemHealthTable
