import * as React from 'react'
import Box from '@mui/material/Box'
import Collapse from '@mui/material/Collapse'
import IconButton from '@mui/material/IconButton'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { Pagination, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'

const PaginationWrapper = styled(Box)({
  display: 'flex',
  justifyContent: 'flex-end',
  gap: '10px',
  alignItems: 'center',
  width: '100%',
  padding: '50px 0px'
})

function Row (props) {
  const { row, columns, index } = props
  const [open, setOpen] = React.useState(false)

  return (
    <React.Fragment>
      <TableRow
         sx={{
           borderTop: '1px solid #DEDEDE',
           '&:hover': {
             backgroundColor: '#F6F6F6',
             m: 1
           }
         }}
      >
        <TableCell>{index + 1}</TableCell>
        <TableCell>
          {row.name ? row.name : '-'}
         {row?.history?.length > 0 && <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
            // sx={{
            //   color: '#1976d2',
            //   '&:hover': {
            //     color: '#1976d2'
            //   }
            // }}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>}
        </TableCell>
        <TableCell align="left">{row.code ? row.code : '-'}</TableCell>
        <TableCell align="left">{row.title ? row.title : '-'}</TableCell>
        {/* <TableCell align="left">{row.action}
        <Box className="table-btn-main">
          <span
            className="process-delete"
            onClick={() => {
              // setTasks(data)
              setTasks(row)
              if (partId) {
                openModal(true)
              }
            }}
            onClick={() => navigate(`/schedule/${row.id}`)}
          >
            {departmentIds?.includes(selectDepartmentid) && (isAddTasksPermission?.edit || AdminUser?.userModule === 'all') && <EditIconNew />}
          </span>
        </Box>
        </TableCell> */}
      </TableRow>
      {open && (
        <TableRow>
          <TableCell
            style={{
              paddingBottom: '0 !important',
              paddingTop: '0 !important',
              background: '#FFFFFF'
            }}
            colSpan={9}
            sx={{

              paddingLeft: '1000px',
              marginLeft: '50px',
              '& > .MuiTableCell-root': {
                paddingLeft: '200px'
              }
            }}
          >
            <Collapse in={open} timeout="auto" unmountOnExit >
              <Box className='parent-nested'>
              <Table aria-label="collapsible table" sx={{ background: '#FFFFFF' }}>
                <TableHead
                  sx={{
                    color: '#020202',
                    fontSize: '14px',
                    fontWeight: 600,
                    marginLeft: '10rem',
                    '& > .MuiTableCell-root': {
                      paddingLeft: '200px'
                    }
                  }}>
                  <TableRow sx={{
                    backgroundColor: '#FFFFFF',
                    paddingLeft: '1000px',
                    marginLeft: '10px',
                    borderBottom: '1px red',
                    '& > .MuiTableCell-root': {
                      paddingLeft: '20px'
                    }
                  }}
                    >
                    {columns?.map((innerColumn, index) => {
                      return (
                        <TableCell key={index} sx={{
                          color: '#020202',
                          fontSize: '14px',
                          fontWeight: 600,
                          paddingLeft: '1000px',
                          marginLeft: '50rem',
                          '& > .MuiTableCell-root': {
                            paddingLeft: '200px'
                          }
                        }}>
                          {' '}
                          {innerColumn?.headerName}{' '}
                        </TableCell>
                      )
                    })}
                  </TableRow>
                </TableHead>
                {row?.history
                  ? (
                      row?.history?.map((historyRow, ind) => (
                      <TableRow key={ind}>
                        <TableCell>{ind + 1}</TableCell>
                          <TableCell>{historyRow.taskNickName ? historyRow.taskNickName : '-'}</TableCell>
                          <TableCell align="left">
                            {historyRow.taskType ? historyRow.taskType : '-'}
                          </TableCell>
                        <TableCell align="left">
                            {historyRow.description ? historyRow.description : '-'}
                        </TableCell>
                          <TableCell align="left">
                            {historyRow.taskStatus ? historyRow.taskStatus : '-' }
                          </TableCell>
                      </TableRow>
                      ))
                    )
                  : (
                    <div>
                    </div>
                    )}
              </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </React.Fragment>
  )
}

const NestedDetailsPageTable = ({
  rows,
  columns,
  mainCols,
  setPage,
  tableName,
  isPagination = true,
  paginationData,
  page,
  totalPages,
  totalResults,
  user,
  data,
  partId,
  setTasks,
  openModal
}) => {
  return (
    <React.Fragment>
      <Box
        className="details-label-text"
      >
        {tableName}
      </Box>
     <TableContainer
        component={Paper}
      >
        <Table aria-label="collapsible table">
          <TableHead sx={{ height: '55px', background: '#F6F6F6', borderRadius: '8px 8px 0px 0px' }}>
            <TableRow>
              {mainCols?.map((Column, index) => {
                return <TableCell key={index}> {Column?.field} </TableCell>
              })}
            </TableRow>
          </TableHead>

          <TableBody>
            {rows.map((row, index) => (
              <Row data={data} openModal={openModal} key={index} partId={partId} setTasks={setTasks} row={row} columns={columns} index={index} user={user} onCellClick={(e) => {
              }} />
            ))}

          </TableBody>
        </Table>
      </TableContainer>
      {isPagination &&
        (
          <PaginationWrapper>
            <Typography sx={{ color: '#8A8A8A', fontSize: '14px' }}>
            Showing 1 to 10 of {totalResults}
            </Typography>
            <Pagination
            page={page}
            color="primary"
            count={totalPages || paginationData?.totalPages}
            variant="outlined"
            onChange={(e, page) => {
              setPage(page)
            }}
            />
          </PaginationWrapper>
        )}
    </React.Fragment>
  )
}

export default NestedDetailsPageTable
