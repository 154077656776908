import { get, post, patch, deleteRequest } from '../utils/index'

export const GetSalesTemMemberJobCard = async (data) => {
  const page = data?.queryKey[1] || 1
  const productCode = data?.queryKey?.[2] || ''
  const payload = { page, limit: 10, productCode }
  return get('/jobcard/sales?jobCardStatus=submitted', payload).then((res) => res.data)
}
export const GetSalesTemMemberApprovedJobCard = async (data) => {
  const page = data?.queryKey[1] || 1
  const productCode = data?.queryKey?.[2] || ''
  const payload = { page, limit: 10, productCode }
  return get('/jobcard/sales?jobCardStatus=approved', payload).then((res) => res.data)
}
export const GetSalesTemMemberDraftJobCard = async (data) => {
  const page = data?.queryKey[1] || 1
  const productCode = data?.queryKey?.[2] || ''
  const payload = { page, limit: 10, productCode }
  return get('/jobcard/sales?jobCardStatus=draft', payload).then((res) => res.data)
}
export const GetSalesTemMemberOngoingJobCard = async (data) => {
  const page = data?.queryKey[1] || 1
  const productCode = data?.queryKey?.[2] || ''
  const payload = { page, limit: 10, productCode }
  return get('/jobcard/sales?jobCardStatus=ongoing', payload).then((res) => res.data)
}
export const GetSalesTemMemberCompletionJobCard = async (data) => {
  const page = data?.queryKey[1] || 1
  const productCode = data?.queryKey?.[2] || ''
  const payload = { page, limit: 10, productCode }
  return get('/jobcard/sales?jobCardStatus=completed', payload).then((res) => res.data)
}
export const addJobcard = async (data) => {
  return post('/jobcard/sales', data).then((res) => res.data)
}
export const editJobcard = async (data) => {
  const id = data?.id
  delete data?.id
  return patch(`/jobcard/sales/${id}`, data).then((res) => res.data)
}

export const UpdateSalesMember = async (data) => {
  const id = data?.id
  delete data.id
  delete data.supplierId
  return patch(`/jobcard/sales/approve/${id}`, data).then((res) => res.data)
}
export const getproductionTeamMember = async (data) => {
  const page = data?.queryKey[1] || 1
  const departmentId = data?.queryKey[2]
  const payload = { page, limit: 10, departmentId }
  return get('/jobcard/manager/list-department-wise?jobCardStatus=approved', payload).then((res) => res.data)
}
export const getproductionTeamMemberPending = async (data) => {
  const page = data?.queryKey[1] || 1
  const departmentId = data?.queryKey[2]
  const payload = { page, limit: 10, departmentId }
  return get('/jobcard/manager/list-department-wise?jobCardStatus=pending', payload).then((res) => res.data)
}
export const getproductionTeamMemberCompleted = async (data) => {
  const page = data?.queryKey[1] || 1
  const departmentId = data?.queryKey[2]
  const payload = { page, limit: 10, departmentId }
  return get('/jobcard/manager/list-department-wise?jobCardStatus=completed', payload).then((res) => res.data)
}
export const getproductionTeamMemberOngoing = async (data) => {
  const page = data?.queryKey[1] || 1
  const departmentId = data?.queryKey[2]
  const payload = { page, limit: 10, departmentId }
  return get('/jobcard/manager/list-department-wise?jobCardStatus=ongoing', payload).then((res) => res.data)
}
export const addTask = async (data) => {
  return post('/task', data).then((res) => res.data)
}
export const addScheduleTask = async (data) => {
  return patch('/task/task-schedule', data).then((res) => res.data)
}
export const addCompleteTask = async (data) => {
  return patch('/task/completed', data).then((res) => res.data)
}
export const deleteJobSalesteamMember = async (id) => {
  return deleteRequest(`/jobcard/${id}`)
}
export const getSingleJobDetails = async (data) => {
  const id = data?.queryKey[1]
  return get(`/jobcard/manager/single-task-wise/${id}`).then((res) => res.data)
}
export const getSingleJobDetailsSchedule = async (data) => {
  const id = data?.queryKey[1]
  return get(`/jobcard/manager/department-wise-jobcard/${id}`).then((res) => res.data)
}
export const getSingleJobDetailsTask = async (data) => {
  const id = data?.queryKey[1]
  return get(`/jobcard/manager/single-task-wise/${id}`).then((res) => res.data)
}
export const updatePushToPanding = async (data) => {
  const id = data?.id
  delete data.id
  delete data.supplierId
  return patch(`/task/push-to-pending/${id}`, data).then((res) => res.data)
}
export const restoreJobCard = async (id) => {
  return patch(`/jobcard/restore/${id}`)
}

export const hardDeleteJobCard = async (id) => {
  return deleteRequest(`/jobcard/hard-delete/${id}`)
}
export const getDeleteJobCard = async (data) => {
  const id = data?.queryKey[1]
  return get(`/jobcard/deleted/${id}`)
}
export const getDeleteTask = async (data) => {
  const id = data?.queryKey[1]
  return get(`/task/deleted/${id}`)
}
export const getTask = async (data) => {
  const id = data?.queryKey[1]
  const departmentId = data?.queryKey[2]
  const payload = { departmentId }
  return get(`/task/list/${id}`, payload).then((res) => res.data)
}
export const jobcardClone = async (data) => {
  const id = data?.id
  return post(`/jobcard/sales/clone/${id}`).then((res) => res.data)
}
export const getSingleJobcardSales = async (data) => {
  const id = data?.queryKey?.[1]
  return get(`/jobcard/sales/${id}`).then((res) => res.data)
}
export const addDraftJobcard = async (data) => {
  return post('/jobcard/sales/draft-jobcard', data).then((res) => res.data)
}
export const validateJobcardNumber = async (data) => {
  return post('/jobcard/sales/validate', data)
}
export const jobcardApi = {
  GetSalesTemMemberJobCard,
  GetSalesTemMemberApprovedJobCard,
  addJobcard,
  addCompleteTask,
  UpdateSalesMember,
  getproductionTeamMember,
  addTask,
  deleteJobSalesteamMember,
  getSingleJobDetails,
  GetSalesTemMemberCompletionJobCard,
  GetSalesTemMemberOngoingJobCard,
  updatePushToPanding,
  addScheduleTask,
  getSingleJobDetailsTask,
  getproductionTeamMemberPending,
  getproductionTeamMemberOngoing,
  getproductionTeamMemberCompleted,
  getSingleJobDetailsSchedule,
  restoreJobCard,
  hardDeleteJobCard,
  getTask,
  getDeleteJobCard,
  jobcardClone,
  getDeleteTask,
  editJobcard,
  getSingleJobcardSales,
  GetSalesTemMemberDraftJobCard,
  addDraftJobcard,
  validateJobcardNumber
}
