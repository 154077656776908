import * as React from 'react'
import Box from '@mui/material/Box'
import './approvedmodal.css'
import CustomButton from '../../leastcomponent/custombutton/custombutton'
import CustomizedModal from '../../leastcomponent/custommodal/commonmodal'
import { Grid, useMediaQuery, useTheme } from '@mui/material'
import { useNavigate } from 'react-router-dom'

const CopyJobcardConfirmModel = (props) => {
  const navigate = useNavigate()
  const theme = useTheme()
  const isMdScreen = useMediaQuery(theme.breakpoints.only('md'))
  const {
    open,
    handleClose,
    id
    // number
  } = props

  return (
    <React.Fragment>
      <CustomizedModal
        isHeadingName={false}
        // heading={'Approve Job Card'}
        open={open}
        onClick={handleClose}
        // width="30%"
        width={isMdScreen ? '40%' : '30%'}
      >
                <Box className="approved-padding">
                  <Box className="approved-job-card-box">
                    <Box className="approved-job-card-main-box">
                    {/* Do you want to duplicate this Job Card? # {number}? */}
                    Do you want to duplicate this Job Card?
                    </Box>{' '}
                    <Box className="approved-job-card-main-box">
                      {/* Job Card no. {number} */}
                    </Box>
                  </Box>
                </Box>

              <Grid className="approved-yes-btn1">
                <CustomButton
                  className="button-box"
                  type="button"
                  title="Confirm"
                  onClick={() => {
                    // addjobcardClone({ id })
                    handleClose()
                    navigate(`/clone-job-card/${id}`)
                  }}
                />
              </Grid>

      </CustomizedModal>
    </React.Fragment>
  )
}
export default CopyJobcardConfirmModel
