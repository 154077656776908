import React from 'react'
import CustomizedModal from '../../leastcomponent/custommodal/commonmodal'
import { Box } from '@mui/material'
import CustomButton from '../../leastcomponent/custombutton/custombutton'
import './jobslipsradiowithselect.css'
import { useOverdueStatusTask } from '../../hooks/task'

const ConfirmOverdue = (props) => {
  const { open, handleClose, openJobSlip, jobId } = props
  const { mutate: taskOverdueStatus } = useOverdueStatusTask()
  const handleConfirmClick = () => {
    if (jobId) {
      taskOverdueStatus({ jobId })
      handleClose()
    }
  }
  return (
    <Box>
      <CustomizedModal
        isHeadingName={false}
        //   heading="Process Details"
        open={open}
        onClick={() => {
          handleClose()
          openJobSlip()
        }}

      >
        <Box className="job-slips-box">
          {' '}
          <Box className="job-slips-main-box">
            All successor tasks will be moved to overdue as well.
          </Box>
        </Box>

        <Box className="job-slips-main-button">
          <CustomButton className="button-box" type="button" title="Confirm" onClick={handleConfirmClick}/>
        </Box>
      </CustomizedModal>
    </Box>
  )
}

export default ConfirmOverdue
