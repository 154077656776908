import { get, post, deleteRequest, patch } from '../utils/index'

export const getResources = async (data) => {
  const page = data?.queryKey[1] || 1
  const payload = { page, limit: 10 }
  return get('/resource', payload).then((res) => res.data)
}
export const addResource = async (data) => {
  return post('/resource', data).then((res) => res.data)
}
export const updateResource = async (data) => {
  const id = data?.id
  delete data.id
  delete data?.maintenanceDetails?.map((res) => delete res?.maintenanceCode & delete res?._id)
  return patch(`/resource/${id}`, data).then((res) => res.data)
}
export const getSingleResources = async (data) => {
  const id = data?.queryKey[1]
  return get(`/resource/${id}`).then((res) => res.data)
}
export const deleteResources = async (id) => {
  return deleteRequest(`/resource/${id}`)
}
export const getFullResources = async () => {
  return get('/resource/full-list')
}
export const restoreResource = async (id) => {
  // const id = data?.queryKey[1];
  // const id = data?.id;
  // delete data['id'];
  return patch(`/resource/restore/${id}`)
}
export const hardDeleteResource = async (id) => {
  // const id = data?.queryKey[1];
  // const id = data?.id;
  // delete data['id'];
  return deleteRequest(`/resource/hard-delete/${id}`)
}
export const getDeleteResource = async (data) => {
  const id = data?.queryKey[1]
  return get(`/resource/deleted/${id}`)
}
export const addSetter = async (data) => {
  return post('/setter', data).then((res) => res.data)
}
export const updateSetter = async (data) => {
  const id = data?.id
  delete data.id
  return patch(`/setter/${id}`, data).then((res) => res.data)
}
export const getFullSetter = async (data) => {
  const page = data?.queryKey[1] || 1
  const payload = { page, limit: 10 }
  return get('/setter', payload).then((res) => res.data)
}
export const deleteSetter = async (id) => {
  return deleteRequest(`/setter/${id}`)
}
export const getSingleSetter = async (data) => {
  const id = data?.queryKey[1]
  return get(`/setter/${id}`).then((res) => res.data)
}
export const validateSetter = async (data) => {
  return post('/setter/validate', data)
}
export const addOperator = async (data) => {
  return post('/operator', data).then((res) => res.data)
}
export const updateOperator = async (data) => {
  const id = data?.id
  delete data.id
  return patch(`/operator/${id}`, data).then((res) => res.data)
}
export const deleteOperator = async (id) => {
  return deleteRequest(`/operator/${id}`)
}
export const getFullOperator = async (data) => {
  const page = data?.queryKey[1] || 1
  const payload = { page, limit: 10 }
  return get('/operator', payload).then((res) => res.data)
}
export const getSingleOperator = async (data) => {
  const id = data?.queryKey[1]
  return get(`/operator/${id}`).then((res) => res.data)
}
export const resourceApi = {
  getResources,
  addResource,
  getSingleResources,
  deleteResources,
  getFullResources,
  updateResource,
  restoreResource,
  hardDeleteResource,
  getDeleteResource,
  addSetter,
  updateSetter,
  getFullSetter,
  deleteSetter,
  getSingleSetter,
  validateSetter,
  addOperator,
  updateOperator,
  getFullOperator,
  deleteOperator,
  getSingleOperator
}
