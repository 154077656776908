import { Box, Typography } from '@mui/material'
import React from 'react'
// import BreadCrumb from "../../leastcomponent/breadcrumb/breadcrumb";
import CustomButton from '../../leastcomponent/custombutton/custombutton'
import TableComponent from '../../leastcomponent/customtable/customtable'
import AddIcon from '@mui/icons-material/Add'
import DeleteIconNew from '../../assets/svg/DeleteIconNew'
// import "./resource.css"
import EditIconNew from '../../assets/svg/EditIconNew'
import { useNavigate } from 'react-router-dom'
import BreadcrumbNavigation from '../commoncomponents/breadcrumbnavigation'

const Reports = () => {
  const navigate = useNavigate()

  const rows = [
    {
      id: 1,
      name: 'Name',
      location: 'Location',
      type: 'Machine',
      capacity: '10',
      '24hrutilization': '10%',
      '1weekutilization': '10%',
      '24hrplan': '10%',
      '1weekplan': '10%',
      action: 'action'
    },
    {
      id: 2,
      name: 'Name',
      location: 'Location',
      type: 'Machine',
      capacity: '10',
      '24hrutilization': '10%',
      '1weekutilization': '10%',
      '24hrplan': '10%',
      '1weekplan': '10%',
      action: 'action'
    },
    {
      id: 3,
      name: 'Name',
      location: 'Location',
      type: 'Machine',
      capacity: '10',
      '24hrutilization': '10%',
      '1weekutilization': '10%',
      '24hrplan': '10%',
      '1weekplan': '10%',
      action: 'action'
    },
    {
      id: 4,
      name: 'Name',
      location: 'Location',
      type: 'Machine',
      capacity: '10',
      '24hrutilization': '10%',
      '1weekutilization': '10%',
      '24hrplan': '10%',
      '1weekplan': '10%',
      action: 'action'
    },
    {
      id: 5,
      name: 'Name',
      location: 'Location',
      type: 'Machine',
      capacity: '10',
      '24hrutilization': '10%',
      '1weekutilization': '10%',
      '24hrplan': '10%',
      '1weekplan': '10%',
      action: 'action'
    },
    {
      id: 6,
      name: 'Name',
      location: 'Location',
      type: 'Machine',
      capacity: '10',
      '24hrutilization': '10%',
      '1weekutilization': '10%',
      '24hrplan': '10%',
      '1weekplan': '10%',
      action: 'action'
    }
  ]
  const columns = [
    { field: 'id', headerName: '#', flex: 0.2 },
    { field: 'name', headerName: 'Report Name', flex: 1 },
    { field: 'location', headerName: 'Created on', flex: 1 },
    { field: 'type', headerName: 'Created by', flex: 1 },
    { field: 'capacity', headerName: 'Recurring On', flex: 1 },
    { field: '24hrutilization', headerName: 'Timeline', flex: 1 },
    {
      field: 'actions',
      headerName: 'Actions',
      sortable: false,
      disableClickEventBubbling: true,
      flex: 1,
      renderCell: () => {
        return (
          <Box className="resource-table-btn-main">
            <span className="process-delete">
              <EditIconNew />
            </span>
            <span className="process-delete">
              <DeleteIconNew />
            </span>
          </Box>
        )
      }
    }
  ]
  return (
    <React.Fragment>
      <BreadcrumbNavigation
        breadCrumbData={null}
        pageTitle={'Reports'}
        backToLink={'/resource'}
      />

      <Box className="common-parent-class">
        {/* <BreadCrumb title="Reports" /> */}
        <Box>
          <Box className="common-parent-haeding-btn-div">
            <Typography className="common-tabel-heading">
              Reports List
            </Typography>
            <CustomButton
              variant="contained"
              //   disabled = false,
              title="Create Reports"
              onClick={() => navigate('/create-reports')}
              type="button"
              endIcon=""
              startIcon={<AddIcon />}
            />
          </Box>
          <TableComponent rows={rows} columns={columns} />
        </Box>
      </Box>
    </React.Fragment>
  )
}

export default Reports
