import React from 'react'

const FilterIconNew = () => {
  return (
    <svg
      width="18"
      height="10"
      viewBox="0 0 18 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14 4.375H4C3.83424 4.375 3.67527 4.44085 3.55806 4.55806C3.44085 4.67527 3.375 4.83424 3.375 5C3.375 5.16576 3.44085 5.32473 3.55806 5.44194C3.67527 5.55915 3.83424 5.625 4 5.625H14C14.1658 5.625 14.3247 5.55915 14.4419 5.44194C14.5592 5.32473 14.625 5.16576 14.625 5C14.625 4.83424 14.5592 4.67527 14.4419 4.55806C14.3247 4.44085 14.1658 4.375 14 4.375Z"
        fill="#0160B9"
      />
      <path
        d="M17.125 0.625H0.875C0.70924 0.625 0.550268 0.690848 0.433058 0.808058C0.315848 0.925269 0.25 1.08424 0.25 1.25C0.25 1.41576 0.315848 1.57473 0.433058 1.69194C0.550268 1.80915 0.70924 1.875 0.875 1.875H17.125C17.2908 1.875 17.4497 1.80915 17.5669 1.69194C17.6842 1.57473 17.75 1.41576 17.75 1.25C17.75 1.08424 17.6842 0.925269 17.5669 0.808058C17.4497 0.690848 17.2908 0.625 17.125 0.625Z"
        fill="#0160B9"
      />
      <path
        d="M10.875 8.125H7.125C6.95924 8.125 6.80027 8.19085 6.68306 8.30806C6.56585 8.42527 6.5 8.58424 6.5 8.75C6.5 8.91576 6.56585 9.07473 6.68306 9.19194C6.80027 9.30915 6.95924 9.375 7.125 9.375H10.875C11.0408 9.375 11.1997 9.30915 11.3169 9.19194C11.4342 9.07473 11.5 8.91576 11.5 8.75C11.5 8.58424 11.4342 8.42527 11.3169 8.30806C11.1997 8.19085 11.0408 8.125 10.875 8.125Z"
        fill="#0160B9"
      />
    </svg>
  )
}

export default FilterIconNew
