import { Box, Button, Divider, Pagination, Typography } from '@mui/material'
import React from 'react'
import { styled } from '@mui/material/styles'
import CustomButton from '../custombutton/custombutton'
import {
  DataGrid,
  gridClasses,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarFilterButton
} from '@mui/x-data-grid'
import { useLocation, useNavigate } from 'react-router-dom'
import CustomizeColumn from '../../assets/svg/CustomizeColumn'
import TableDataListIcon from '../../assets/svg/TableDataListIcon'
import Plus from '../../assets/svg/Plus'
import PropTypes from 'prop-types'

const PaginationWrapper = styled(Box)({
  //  !TODO:: some new change for pagination
  display: 'flex',
  justifyContent: 'flex-end',
  gap: '10px',
  alignItems: 'center',
  width: '100%',
  padding: '10px 0px'
})
const CustomTableButton = styled(Button)({
  borderRadius: '4px !important',
  fontSize: '14px !important',
  fontWeight: '600 !important',
  height: '40px',
  textTransform: 'uppercase',
  padding: '10px 16px'
})

const TableComponent = (props) => {
  const {
    data,
    width,
    paginationData,
    page,
    setPage,
    listName,
    rows = [],
    columns = [],
    isRowHeight = false,
    totalResults
  } = props
  let isOptionShow = true
  if (isOptionShow === true || isOptionShow === false) {
    isOptionShow = props.isOptionShow
  }

  const rowsPerPage = 10

  const navigate = useNavigate()
  const isNoEntityShow = props?.isNoEntityShow || false
  const { isHideDropDown = false } = props
  const moduleName = props?.moduleName
  const redirectPage = props?.redirectPage
  const location = useLocation()
  const CustomDataGrid = styled(DataGrid)(() => ({
    fontSize: '14px',
    '.MuiDataGrid-columnHeaders': {
      minHeight: `${location?.pathname?.startsWith('/dashboard') ? '40px !important' : '60px !important'}`,
      maxHeight: `${location?.pathname?.startsWith('/dashboard') ? '40px !important' : '60px !important'}`,
      // minHeight: '20px',
      // maxHeight: '60px !important',
      height: '60px !important',
      border: '1px solid #dedede',
      borderRadius: '8px 8px 0px 0px',
      backgroundColor: '#F6F6F6',
      // lineHeight: '10px',
      '@media(min-width: 425px) and (max-width: 1024px)': {
        minHeight: `${location?.pathname?.startsWith('/dashboard') ? '40px !important' : '65px !important'}`,
        maxHeight: `${location?.pathname?.startsWith('/dashboard') ? '40px !important' : '65px !important'}`,
        // minHeight: '65px !important',
        // maxHeight: '65px !important',
        height: '65px !important'
      },
      '@media(min-width: 1025px) and (max-width: 1440px)': {
        minHeight: `${location?.pathname?.startsWith('/dashboard') ? '40px !important' : '65px !important'}`,
        maxHeight: `${location?.pathname?.startsWith('/dashboard') ? '40px !important' : '65px !important'}`,
        // minHeight: '65px !important',
        // maxHeight: '65px !important',
        height: '65px !important'
      }
    },

    '.css-204u17-MuiDataGrid-main': {
      height: '100% !important',
      overFlow: 'auto'
    },
    '.css-aelge9-MuiDataGrid-root': {
      minHeight: 'max-content !important',
      maxHeight: 'max-content !important'
    },
    '.MuiDataGrid-cell': {
      borderBottom: 'none'
      // height: '50px !important', // Fixed height for rows
      // overflow: 'auto !important' // Enable scrolling if content overflows
      // TODO:: table row height css
      // border: '1px solid red',
      // minHeight: '49px !important',
      // maxHeight: '49px !important',
      // height: '49px !important'

    },
    '& .MuiDataGrid-cell:focus': {
      outline: 'none'
    },
    [`& .${gridClasses.row}.odd`]: {
      backgroundColor: '#F4F9FF'
    },
    [`& .${gridClasses.cell}.blue-font`]: {
      color: '#0160B9 !important',
      cursor: 'pointer',
      fontWeight: '600',
      fontSize: '14px'
    }
  }))
  let isBtnShow = true

  if (props?.data?.isBtnShow === true || props.data?.isBtnShow === false) {
    isBtnShow = props?.data?.isBtnShow
  }

  const CustomDivider = styled(Divider)(() => ({
    margin: '0px 16px 0px 18px !important'
  }))
  const customBtnLink = data?.linkData[0]?.to
  const isHideOptions =
    location.pathname.startsWith('/add-job-card') ||
    location.pathname.startsWith('/add-product') ||
    location.pathname.startsWith('/worker-detail') ||
    location.pathname.startsWith('/machine-details') ||
    location.pathname.startsWith('/dashboard') ||
    location.pathname.startsWith('/deleted-items1111') ||
    location.pathname.startsWith('/factory-layout') ||
    location.pathname.startsWith('/add-role') ||
    location.pathname.startsWith('/job-card-name') ||
    location.pathname.startsWith('/add-mrp') ||
    location.pathname.startsWith('/task-type') ||
    location.pathname.startsWith('/resource-details') ||
    location.pathname.startsWith('/job-slips-number') ||
    location.pathname.startsWith('/edit-resource') ||
    location.pathname.startsWith('/edit-job-card') ||
    location.pathname.startsWith('/create-resource') ||
    location.pathname.startsWith('/print-job-slips-number') ||
    location.pathname.startsWith('/approve-job-card') ||
    location.pathname.startsWith('/clone-job-card')

  // location.pathname.startsWith('/job-card')

  function CustomNoRowsOverlay () {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center', height: '50px' }}> No Rows</Box>
    )
  }
  function CustomToolbar () {
    return (
      <Box>
        <Box className="table-toolbar">
          <Box>
            <Typography className="list-title">{listName}</Typography>
          </Box>
          <Box>
            {props.isOptionShow && !isHideOptions && (
              <GridToolbarContainer>
                <GridToolbarColumnsButton
                  placeholder={undefined}
                  name="Customize Columns"
                />
                <CustomDivider
                  orientation="vertical"
                  className="table-verticle-divider"
                />
                <GridToolbarFilterButton placeholder={undefined} />
                {isBtnShow && (
                  <React.Fragment>
                    <CustomDivider
                      orientation="vertical"
                      className="table-verticle-divider"
                    />
                    {!isHideDropDown
                      ? (
                        <CustomButton
                          title={data?.title}
                          linkData={data?.linkData}
                          isBtnDisable={data?.isBtnDisable}
                        />
                        )
                      : (
                        <React.Fragment>
                          <CustomTableButton
                            className="blue-bg"
                            onClick={() => navigate(`${customBtnLink}`)}
                          >
                            <Plus fill="white" />{' '}
                            <span className="tb-margin-left">{data?.title}</span>
                          </CustomTableButton>
                        </React.Fragment>
                        )}
                  </React.Fragment>
                )}
              </GridToolbarContainer>
            )}
          </Box>
        </Box>
      </Box>
    )
  }

  const isTableHeight = location.pathname.startsWith('/my-tickets')
  const start = (page - 1) * rowsPerPage + 1
  const end = Math.min(page * rowsPerPage, totalResults)
  return (
    <React.Fragment>
      {rows?.length <= 0 && !props.isLoading && isNoEntityShow
        ? (
          <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
            <Box textAlign={'center'}>
              <TableDataListIcon />
              <Typography>{`No '${moduleName}' added yet`}</Typography>
              <Typography
                mb={3}
              >{`Add '${moduleName}' to ${moduleName} List`}</Typography>
              {isBtnShow && (
                <CustomButton
                  className="blue-color"
                  onClick={() =>
                    navigate(`${redirectPage}`, {
                      state: { requestCount: props?.requestCount }
                    })
                  }
                >
                  <Plus fill={'#0160B9'} />{' '}
                  <span className="ml">{`Add ${moduleName}`}</span>
                </CustomButton>
              )}
            </Box>
          </Box>
          )
        : (
          <React.Fragment>
            <Box
              className={isTableHeight ? 'table-height-ticket' : 'table-height'}
              width={`${width ? 'max-content' : '100%'}`}
            >
              <CustomDataGrid
              slots={{
                noRowsOverlay: CustomNoRowsOverlay
              }}
                autoHeight
                sx={{
                  '& > .MuiDataGrid-columnSeparator': {
                    visibility: 'hidden' // or display: "none"
                  },
                  '.MuiDataGrid-columnSeparator--sideRight': {
                    display: 'none !important'
                  }
                }}
                headerRowHeight={30}
                className="common-table"
                rows={rows}
                getRowHeight={() => 'auto'}
                // headerHeight={10}
                hideFooter
                pagination
                rowHeight={isRowHeight ? 'auto' : 30}
                loading={props.isLoading}
                // TODO:: table header height css
                headerHeight={33}
                columns={columns}
                onCellClick={(e) => {
                  if (e.field !== 'actions') {
                    const isRedeirction = props?.detailsRedirection
                    if (
                      isRedeirction &&
                      props?.detailsRedirection !== 'noRedirection'
                    ) {
                      navigate(`${props?.detailsRedirection}/${e?.id}`)
                    }
                  }
                }}
                getCellClassName={(params) => {
                  return [
                    'Process Name',
                    'Job Card Number',
                    'Actions',
                    'Line Name',
                    'Department Name',
                    'Supplier Name',
                    'Part Name',
                    'Assigned to',
                    'Title',
                    'Part',
                    'User’s Name',
                    'Roles',
                    'Department Name',
                    'Department Group Name',
                    'Department group',
                    'No. of machines',
                    'No. of workstations',
                    'Module',
                    'Event',
                    'Dashboard',
                    'Simulation Name',
                    'Name',
                    'Task Type',
                    'Job slip Number',
                    'Nickname',
                    'Maintenance Name',
                    'Job slip Number'
                  ].includes(params.colDef.headerName || '')
                    ? 'blue-font'
                    : 'regular'
                }}
                components={{
                  Toolbar: CustomToolbar,
                  ColumnSelectorIcon: CustomizeColumn
                }}
                componentsProps={{
                  panel: {
                    sx: {
                      top: '-30px !important',
                      left: '810px !important'
                    }
                  }
                }}
              />
            </Box>
            <Box>
              {!isHideOptions && rows?.length > 0 && (
                <PaginationWrapper
                // ! TODO:: for future use class in pagination
                // className='pagination-wrapper'
                >
                  <Typography sx={{ color: '#8A8A8A', fontSize: '14px' }}>
                    {/* Showing 1 to 10 of {totalResults} */}
                    Showing {start} to {end} of {totalResults}
                  </Typography>
                  <Pagination
                    page={page}
                    color="primary"
                    count={props?.totalPages || paginationData?.totalPages}
                    variant="outlined"
                    onChange={(e, page) => {
                      setPage(page)
                    }}
                  />
                </PaginationWrapper>
              )}
            </Box>
          </React.Fragment>
          )}
    </React.Fragment>
  )
}

export default TableComponent
TableComponent.propTypes = {
  isAuthenticated: PropTypes.any,
  tableData: PropTypes.any,
  totalPages: PropTypes.any,
  isOptionShow: PropTypes.any,
  isEditStock: PropTypes.any,
  isHideDropDown: PropTypes.any,
  width: PropTypes.any,
  isLoading: PropTypes.any,
  isStateChanged: PropTypes.any,
  isShowFiveRecords: PropTypes.any,
  paginationData: PropTypes.any,
  page: PropTypes.any,
  setPage: PropTypes.any,
  listName: PropTypes.any,
  requestCount: PropTypes.any,
  isNoEntityShow: PropTypes.any,
  moduleName: PropTypes.any,
  redirectPage: PropTypes.any,
  totalResults: PropTypes.any
}
