import { Box } from '@mui/material'
import React from 'react'
import SalesTeamMember from './salesTeamMember'
import SalesTeamHead from './salesteamhead'
// TODO : for future use in job card module job card option
// import ProductionTeamMember from './productionteammember'
// import ProductionTeamHead from './productionteamhead'
// import UpdateStatus from './updatestatusoftask'
const JobCards = () => {
  // const userName = 'salesTeamMember'
  const userName = 'salesTeamHead'
  // TODO : for future use in job card module job card option
  // const userName = "productionTeamMember";
  // const userName = "productionTeamHead";
  // const userName = "updateStatus";

  return (
    <React.Fragment>
      <Box className="">
        {userName === 'salesTeamMember'
          ? (
          <SalesTeamMember />
            )
          : userName === 'salesTeamHead'
            ? (
          <SalesTeamHead />
              )
              // TODO : for future use in job card module job card option
          //   : userName === 'productionTeamMember'
          //     ? (
          // <ProductionTeamMember />
          //       )
          //     : userName === 'productionTeamHead'
          //       ? (
          // <ProductionTeamHead user="productionTeamHead" />
          //         )
          //   : userName === 'updateStatus'
          //     ? (
          // <UpdateStatus />
          //       )
            : (
          <React.Fragment></React.Fragment>
              )}
      </Box>
    </React.Fragment>
  )
}

export default JobCards
