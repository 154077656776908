import React, { useState, useEffect } from 'react'
import { styled } from '@mui/material/styles'
import {
  Box,
  Divider,
  Grid,
  Typography,
  Select,
  CircularProgress,
  FormControl,
  MenuItem,
  TextField
} from '@mui/material'
import { ErrorMessage, Form, Formik } from 'formik'
import CustomInput from '../../leastcomponent/custominput/input'
import { AiOutlineInfoCircle } from 'react-icons/ai'
import CustomButton from '../../leastcomponent/custombutton/custombutton'
import * as Yup from 'yup'
import BreadcrumbNavigation from '../commoncomponents/breadcrumbnavigation'
import { useLocation, useParams } from 'react-router-dom'
import ErrorPopup from '../../modals/errorpopup'
import Required from '../commoncomponents/required'
import { useGetFullDepartments } from '../../hooks/departmentHooks'
import { useAddSetter, useGetSetterSingle, useUpdateSetter, useValidateSetter } from '../../hooks/resourceHooks'
const Selects = styled(Select)(() => ({
  borderRadius: '4px !important',
  '.MuiOutlinedInput-input': {
    padding: '8.5px 14px',
    fontSize: '14px !important'

  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: '#8A8A8A'
  }
}))
const Input = styled(TextField)(() => ({
  borderRadius: '4px',
  '.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input': {
    height: '38px',
    padding: '1px 10px',
    fontSize: '14px !important'
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#8A8A8A',
      borderRadius: '4px'
    }
  }
}))
const CreateSetter = () => {
  const location = useLocation()
  const isDeleted = location.pathname.startsWith('/deleted-items')
  const { id } = useParams()
  const [isEdit, setIsEdit] = useState(false)
  const { data: departmentData } = useGetFullDepartments()
  const { data: getSetterSingle, isGetsingleSetter, error: singleSetterError, isSuccess } = useGetSetterSingle(id, !!id)
  const singleSetter = getSetterSingle?.data

  useEffect(() => {
    if (id) {
      setIsEdit(true)
    }
  }, [id])
  const [isErrorModalOpen, setIsErrorModalOpen] = React.useState(false)
  const handleErrorModalClose = () => setIsErrorModalOpen(false)
  const [errorMessage, setErrorMessage] = React.useState('')
  const icon = <AiOutlineInfoCircle color="red" />

  const {
    mutate: addSetter,
    isError: isAddSetterError,
    error: addSetterError,
    isLoading
  } = useAddSetter()

  const {
    mutate: editSetter,
    isError: isEditUserError,
    error: updateUserError
  } = useUpdateSetter()
  const {
    mutate: validateSetter,
    data: validateData,
    isLoading: isValidating
  } = useValidateSetter()
  React.useEffect(() => {
    if (isAddSetterError || isEditUserError || singleSetterError) {
      setErrorMessage(
        isAddSetterError
          ? addSetterError?.data?.message
          : isEditUserError
            ? updateUserError?.data?.message
            : singleSetterError?.data?.message
      )
      setIsErrorModalOpen(true)
    }
  }, [isAddSetterError, isEditUserError, singleSetterError, addSetterError, updateUserError])
  React.useEffect(() => {
    if (id && isSuccess) {
      if (singleSetter?.name) {
        validateSetter({
          name: singleSetter?.name,
          id
        })
      }
    }
  }, [
    id,
    isSuccess,
    singleSetter?.name,
    id
  ])
  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Setter Name is required'),
    department: Yup.string().required('Department is required'),
    pin: Yup.string().required('Pin  is required'),
    confirmPin: Yup.string().required('Confirm pin  is required')
  })

  return (
    <Box className="production-team-head-overflow-hidden">
      <BreadcrumbNavigation
        breadCrumbData={null}
        pageTitle={id ? 'Edit Setter' : 'Create Setter'}
        backToLink={'/resource'}
        activeTab="2"
      />

      {!isGetsingleSetter
        ? <Box className="" paddingTop={'50px'}>
        <Typography className="form-heading-edit">
        Create Setter{' '}
        </Typography>
        <Formik
          enableReinitialize={true}
          initialValues={{
            name: isEdit ? singleSetter?.name : '',
            department: isEdit ? singleSetter?.department?.id : '',
            pin: isEdit ? singleSetter?.pin : '',
            confirmPin: isEdit ? singleSetter?.confirmPin : ''
          }}
          onSubmit={(values) =>
            id ? editSetter({ ...values, id }) : addSetter(values)
          }
          validationSchema={validationSchema}
        >
          {({ errors, handleChange, touched, values }) => (
            <React.Fragment>
              <Form>
                <Box className="user-managegement-form-child1">
                  <Box className="padding-y-50">
                    <Grid spacing={2} container paddingTop={2}>
                      <Grid item xs={12} md={4} xl={4}>
                        <Box>
                             <Typography className='field-title'>
                             Setter Name
                      <Required />
                    </Typography>
                           <Input
                      placeholder='Enter Setter Name'
                      fullWidth
                      onChange={(e) => {
                        validateSetter({
                          name: e.target.value,
                          ...(id && { id })
                        })
                        handleChange(e)
                      }}
                      value={values.name || ''}
                      name='name'
                      InputProps={{
                        style: { fontSize: 14, padding: '0px !important', height: '40px' },
                        endAdornment: touched.name && errors.name && icon
                      }}
                    />
                    {!validateData?.data.isValid && values?.name && !isValidating
                      ? (
                      <Typography className='validation-text'>
                        {'Setter Name already taken'}
                      </Typography>
                        )
                      : (
                      <ErrorMessage
                        name='name'
                        render={(msg) => <Typography className='validation-text'>{msg}</Typography>}
                      />
                        )}
                          </Box>
                      </Grid>
                      <Grid item xs={4} md={4} lg={4}>
                        <Typography className="field-title" mt={'2px'}>
                          Department
                          <Required />
                        </Typography>
                        <FormControl fullWidth>
                          <Selects
                            name="department"
                            variant="outlined"
                            value={
                              departmentData?.data?.length
                                ? values?.department
                                : ''
                            }
                            onChange={handleChange}
                            displayEmpty
                          >
                            <MenuItem value="">
                              <span className="menu-item-span">
                                Select Department
                              </span>
                            </MenuItem>

                            {departmentData?.data?.map((data, ind) => {
                              return (
                                <MenuItem value={data?.id} key={ind}>
                                  {data?.name}
                                </MenuItem>
                              )
                            })}
                          </Selects>
                        </FormControl>

                        {touched.department && errors.department && (
                          <Typography className="validation-text">
                            {typeof errors.department === 'string'
                              ? errors.department
                              : ''}
                          </Typography>
                        )}
                      </Grid>
                    </Grid>
                    <Divider />
                    <Typography className="form-heading-edit1">
                     Set Pin{' '}
                    </Typography>
                    <Grid spacing={2} paddingTop={3} container>
                    <Grid item xs={12} md={4} xl={4}>
                        <CustomInput
                          label="Enter Pin"
                          placeholder="Enter Pin"
                          handleChange={handleChange}
                          name="pin"
                          type='password'
                          value={values?.pin}
                          InputProps={{
                            endAdornment:
                              touched.pin && errors.pin && icon
                          }}
                          errors={errors.pin}
                          touched={touched.pin}
                        />
                      </Grid>
                      <Grid item xs={12} md={4} xl={4}>
                        <CustomInput
                          label="Confirm Pin"
                          placeholder="Enter Pin"
                          handleChange={handleChange}
                          type='password'
                          name="confirmPin"
                          value={values?.confirmPin}
                          InputProps={{
                            endAdornment:
                              touched.pin &&
                              errors.confirmPin &&
                              icon
                          }}
                          errors={errors.confirmPin}
                          touched={touched.confirmPin}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
               { !isDeleted && <Box className="button-child">
                  <CustomButton
                    disabled={isLoading}
                  title="Submit" type="submit" />
                </Box>}
              </Form>
            </React.Fragment>
          )}
        </Formik>
      </Box>
        : <Box className='machine-kanban-progress'>
       <CircularProgress />
     </Box>}
      {isErrorModalOpen && (
        <ErrorPopup
          isOpen={isErrorModalOpen}
          handleClose={handleErrorModalClose}
          errorDetails={errorMessage}
        />
      )}
    </Box>
  )
}

export default CreateSetter
