import { get, post, put, patch } from '../utils/index'

export const login = async (data) => {
  return post('/auth/login', data)
}

export const forgotPassword = (data) => {
  return post('/auth/forgot-password', data)
}

export const changePassword = (data) => {
  return put('/profile/change-password', data)
}
export const updateProfile = async (data) => {
  // const id = data?.queryKey[1];
  // const id = data?.id;
  delete data.id
  return patch('/profile/update-info', data)
}
export const verifyToken = () => {
  return get('/profile/me')
}

export const resetPassword = (data) => {
  return post('/auth/reset-password', data)
}

export const logout = () => {
  return post('/profile/logout')
}

export const authApi = {
  login,
  logout,
  verifyToken,
  forgotPassword,
  changePassword,
  resetPassword,
  updateProfile
}
