import { deleteRequest, get, patch, post, put } from '../utils'

export const addFactorySchedule = async (data) => {
  return put('/factory-schedule', data)
}
export const getFactorySchedule = async () => {
  // const id = data?.queryKey[1];
  return get('/factory-schedule')
}

// ! settings api here

export const getSetting = async () => {
  return get('/setting')
}
export const addSetting = async (data) => {
  return post('/setting', data)
}

export const updateFactorySchedule = async (data) => {
  const id = data?.id
  delete data.id
  return patch(`/factory-schedule/${id}`, data)
}
export const deleteFactorySchedule = async (id) => {
  return deleteRequest(`/factory-schedule/${id}`)
}
// export const getMachines = async () => {
//   return get(`/customer/machine`);
// };
export const factoryScheduleApi = {
  addFactorySchedule,
  getFactorySchedule,
  updateFactorySchedule,
  deleteFactorySchedule,
  addSetting,
  getSetting
}
