import React, { useState } from 'react'
import './factorySchedule.css'
import { Box, CircularProgress, Divider, Grid, Typography } from '@mui/material'
import { FieldArray, Form, Formik } from 'formik'
import CustomInput from '../../leastcomponent/custominput/input'
import { AiOutlineInfoCircle } from 'react-icons/ai'
import CustomButton from '../../leastcomponent/custombutton/custombutton'
import * as Yup from 'yup'
import Plus from '../../assets/svg/Plus'
import CustomTimePicker from '../../leastcomponent/customtimepicker/customtimepicker'
import * as _ from 'lodash'
import dayjs from 'dayjs'
import Required from '../commoncomponents/required'
import { WeekdaysInput } from '@quphoria/react-weekdays-input'
import BreadcrumbNavigation from '../commoncomponents/breadcrumbnavigation'
import {
  useAddFactorySchedule,
  useGetFactorySchedule
} from '../../hooks/factoryschedulehooks'
import moment from 'moment'
import BasicDatePicker from '../../leastcomponent/datepicker/customdatepicker'
import { useParams } from 'react-router-dom'
import DeleteIconNew from '../../assets/svg/DeleteIconNew'
const EditSchedule = () => {
  const id = useParams()
  const { data: fectoryScheduleRes, isFetching: isFectoryScheduleRes } = useGetFactorySchedule(id && true)

  // const [removeData, setRemoveData] = React.useState([])
  // const [removeHolyday, setRemoveHolyday] = React.useState([])
  const [isEdit, setIsEdit] = React.useState(false)

  const editHolidays = fectoryScheduleRes?.holidays?.map((data) => {
    return {
      name: data?.name,
      date: data?.date,
      // date: data?.date ? moment(data?.date).toDate() : null,
      // date: data?.date ? new Date(data.date) : null,
      // date: data?.date && moment(new Date(data?.date)),
      _id: data?._id
    }
  })

  const dayList = {
    0: 'Monday',
    1: 'Tuesday',
    2: 'Wednesday',
    3: 'Thursday',
    4: 'Friday',
    5: 'Saturday',
    6: 'Sunday'
  }

  React.useEffect(() => {
    if (id || !_.isEmpty(fectoryScheduleRes)) {
      setIsEdit(true)
      const workingDays = fectoryScheduleRes?.workingDays
      const workingDaysArray = Object.values(dayList).map((day) => {
        if (workingDays?.includes(day)) {
          return 1
        } else {
          return 0
        }
      })
      setDaysAsObject(workingDaysArray)
    }
  }, [id, _.isEmpty(fectoryScheduleRes)])

  const editShifts = fectoryScheduleRes?.shifts?.map((data) => {
    const [startHours, startMinutes] = data?.startTime.split(':').map(Number)

    const startTime = new Date()
    startTime.setHours(startHours, startMinutes, 0, 0)
    const [endHours, endMinutes] = data?.endTime.split(':').map(Number)

    if (endHours < startHours || (endHours === startHours && endMinutes < startMinutes)) {
      startTime.setDate(startTime.getDate() + 1)
    }

    const endTime = new Date()
    endTime.setHours(endHours, endMinutes, 0, 0)

    return {
      name: data?.name,
      startTime,
      endTime,
      _id: data?._id
    }
  })

  React.useEffect(() => {
    // setRemoveData(editShifts)
    // setRemoveHolyday(editHolidays)
  }, [id, fectoryScheduleRes?.shifts?.length])
  // console.log('objects removed', removeData)
  // console.log('objects removeHolyday', removeHolyday)

  const icon = <AiOutlineInfoCircle color="red" />
  const [daysAsObject, setDaysAsObject] = useState([0, 0, 0, 0, 0, 0, 0])
  const validationSchema = Yup.object().shape({
    workingDays: Yup.array().of(
      Yup.number()
        .oneOf([0, 1])
        .required('At least one working day is required')
    ),
    // shifts: Yup.array().of(
    //   Yup.object().shape({
    //     name: Yup.string().required('Shift title is required'),
    //     startTime: Yup.string().required('Start time is required'),
    //     endTime: Yup.string().required('End time is required')
    //   })
    // ),
    // holidays: Yup.array().of(
    //   Yup.object().shape({
    //     name: Yup.string().required('Holiday name is required'),
    //     date: Yup.date().required('Holiday date is required')
    //   })
    // )
    shifts: Yup.array().when([], (shifts, schema) => {
      return shifts.length > 0
        ? schema.of(
          Yup.object().shape({
            name: Yup.string().required('Shift title is required'),
            startTime: Yup.string().required('Start time is required'),
            endTime: Yup.string().required('End time is required')
          })
        )
        : schema
    }),
    holidays: Yup.array().when([], (holidays, schema) => {
      return holidays.length > 0
        ? schema.of(
          Yup.object().shape({
            name: Yup.string().required('Holiday name is required'),
            date: Yup.date().required('Holiday date is required')
          })
        )
        : schema
    })
  })

  const { mutate: addFactorySchedule } = useAddFactorySchedule()

  // Function to generate ObjectId-like strings
  // const generateObjectId = () => {
  //   const timestamp = ((new Date().getTime() / 1000) | 0).toString(16); // Convert current time to hexadecimal string
  //   const objectId =
  //     timestamp +
  //     "xxxxxxxxxxxxxxxx"
  //       .replace(/[x]/g, () => ((Math.random() * 16) | 0).toString(16))
  //       .toLowerCase(); // Append random hex digits
  //   return objectId;
  // };

  return (
    <React.Fragment>
      <Box className="production-team-head-overflow-hidden">
        <BreadcrumbNavigation
          breadCrumbData={null}
          pageTitle={'Factory Schedule'}
          backToLink={null}
        />

        {!isFectoryScheduleRes
          ? <Box>
          <Typography className="form-heading-edit-fac">
            {' '}
            Factory Timing{' '}
          </Typography>
          <Typography className="field-title-work">
            Working Days
            <Required />
          </Typography>
          <Formik
            enableReinitialize={true}
            initialValues={{
              workingDays: isEdit ? daysAsObject || [] : [],
              shifts: id
                ? editShifts
                : [
                    {
                      name: '',
                      startTime: '',
                      endTime: ''
                    }
                  ],
              holidays: id
                ? editHolidays
                : [
                    {
                      name: '',
                      date: ''
                    }
                  ] || []
            }}
            onSubmit={(values) => {
              const workingDays = values.workingDays.map((day, ind) => {
                if (day) {
                  return dayList[ind]
                } else {
                  return null
                }
              })
              // const dayName = [
              //   "Sunday",
              //   "Monday",
              //   "Tuesday",
              //   "Wednesday",
              //   "Thursday",
              //   "Friday",
              //   "Saturday",
              // ];

              // const workingDays = values.workingDays
              //   .map((dayIndex, index) =>
              //     dayIndex === 1 ? dayName[index] : null
              //   )
              //   .filter((day) => day);

              const shifts = values?.shifts?.map((shift) => ({
                ...shift,
                startTime: moment(shift?.startTime, 'HH:mm').format('HH:mm'),
                endTime: moment(shift?.endTime, 'HH:mm').format('HH:mm')
              }))

              const holidays = values?.holidays?.map((holiday) => ({
                ...holiday,
                date: moment(holiday?.date).format('YYYY-MM-DD')
              }))

              const payload = {
                startTime: '07:30',
                endTime: '19:30',
                workingDays: _.compact(workingDays),
                ...(shifts?.length > 0 && { shifts }),
                ...(holidays?.length > 0 && { holidays })
              }

              addFactorySchedule(payload)
            }}
            validationSchema={validationSchema}
          >
            {({ errors, handleChange, touched, values, setFieldValue }) => (
              <React.Fragment>
                <Form className="factory-form-perent">
                  <Box className="factory-form-child">
                    <Box className="padding-y-50">
                      <Grid spacing={2} container paddingTop={2}>
                        <Grid padding={'0px 20px'}>
                          <Box>
                            <WeekdaysInput
                              value={daysAsObject}
                              onChange={(value) => {
                                setDaysAsObject(value)
                                setFieldValue('workingDays', value)
                              }}
                              days={[
                                'monday',
                                'tuesday',
                                'wednesday',
                                'thursday',
                                'friday',
                                'saturday',
                                'sunday'
                              ]}
                              showChars={1}
                              activeDayStyle={{
                                color: '#0160B9',
                                fontSize: '16px',
                                backgroundColor: '#F6F6F6',
                                borderRadius: '4px 4px 4px 4px',
                                fontWeight: 700
                              }}
                              inactiveDayStyle={{
                                color: 'gray',
                                backgroundColor: '#FFFFFF',
                                borderRadius: '4px 4px 4px 4px'
                              }}
                              dayStyle={{
                                padding: '9px 0px 9px 0px',
                                width: '50px',
                                textAlign: 'center'
                              }}
                              inputStyle={{
                                border: '1px solid #8A8A8A',
                                borderRadius: '4px'
                              }}
                              forcedState={{
                                0: 'none',
                                1: 'none',
                                2: 'none',
                                3: 'none',
                                4: 'none',
                                5: 'none',
                                6: 'none'
                              }}
                              textCase={'firstToUpper'}
                            ></WeekdaysInput>
                            {touched.workingDays && errors.workingDays && (
                              <Typography className="validation-text">
                                {errors.workingDays}
                              </Typography>
                            )}
                          </Box>
                          {touched.workingDays && errors.workingDays && (
                            <Typography className="validation-text">
                              {errors.workingDays}
                            </Typography>
                          )}
                        </Grid>
                      </Grid>
                      <Divider />
                    </Box>
                    <Typography className="form-heading-edit">
                      Shifts{' '}
                    </Typography>
                    <Box className="padding-y-50">
                      <FieldArray name="shifts">
                        {({ push, remove }) => (
                          <React.Fragment>
                            <Box className="gap-20">
                              {values?.shifts?.length > 0 &&
                                values?.shifts?.map((tasks, index) => (
                                  <Box key={index}>
                                    <Box>
                                      {/* To do future :
                                      <Typography className="form-heading-edit-1 pb-20">
                                        Task {index + 1}
                                      <span
                                        onClick={() => remove(index)}
                                        className="process-delete">
                                        <DeleteIconNew />
                                      </span>
                                    </Typography> */}
                                      <Grid
                                        spacing={2}
                                        key={index}
                                        paddingBottom={'20px'}
                                        container
                                      >
                                        <Grid item xs={12} md={3.66} xl={3.66}>
                                          <CustomInput
                                            label="Title"
                                            placeholder="Enter Shift Title"
                                            handleChange={handleChange}
                                            value={
                                              values?.shifts?.[index]?.name
                                            }
                                            name={`shifts.${index}.name`}
                                            InputProps={{
                                              endAdornment:
                                                touched?.shifts?.[index]
                                                  ?.name &&
                                                errors?.shifts?.[index]?.name &&
                                                icon
                                            }}
                                            errors={
                                              errors?.shifts?.[index]?.name
                                            }
                                            touched={
                                              touched?.shifts?.[index]?.name
                                            }
                                          />
                                        </Grid>
                                        <Grid item xs={12} md={3.66} xl={3.66}>
                                          <CustomTimePicker
                                            name={`shifts.${index}.startTime`}
                                            label="Start Time"
                                            className="default-time-picker"
                                            handleDateChange={(e) => {
                                            // Format the Moment.js object to a string in the desired format
                                              // const formattedTime =
                                              //   e.format("HH:mm");

                                              setFieldValue(
                                                `shifts.${index}.startTime`,
                                                e
                                              )
                                            }}
                                            views={['hours', 'minutes']}
                                            value={
                                              values?.shifts?.[index]?.startTime
                                            }
                                            errors={
                                              errors?.shifts?.[index]?.startTime
                                            }
                                            touched={
                                              touched?.shifts?.[index]
                                                ?.startTime
                                            }
                                          />
                                        </Grid>
                                        <Grid item xs={12} md={3.66} xl={3.66}>
                                          <CustomTimePicker
                                            name={`shifts.${index}.endTime`}
                                            label="End Time"
                                            className="default-time-picker"
                                            handleDateChange={(e) => {
                                              setFieldValue(
                                                `shifts.${index}.endTime`,
                                                e
                                              )
                                            }}
                                            views={['hours', 'minutes']}
                                            value={
                                              values?.shifts?.[index]?.endTime
                                            }
                                            errors={
                                              errors?.shifts?.[index]?.endTime
                                            }
                                            touched={
                                              touched?.shifts?.[index]?.endTime
                                            }
                                          />
                                        </Grid>
                                  <Grid className="delete-icon-grid" item xs={1} md={1} xl={1} marginBottom={'11px'}>
                                  <span
                                    onClick={() => remove(index)}
                                    className="process-delete"
                                  >
                                    <DeleteIconNew />
                                  </span>
                                </Grid>
                                      </Grid>
                                    </Box>
                                  </Box>
                                ))}
                            </Box>
                            <Box paddingTop={'10px'}>
                              {/* {' '} */}
                              <CustomButton
                                variant="outlined"
                                className="button-box"
                                type="button"
                                title="Add New Shift"
                                endIcon={<Plus fill={'#0160B9'} />}
                                onClick={() =>
                                  push({
                                    name: '',
                                    startTime: new Date(),
                                    endTime: new Date()
                                  })
                                }
                              />
                            </Box>
                          </React.Fragment>
                        )}
                      </FieldArray>
                      <Divider />
                    </Box>
                    <Typography className="form-heading-edit">
                      {' '}
                      Holiday{' '}
                    </Typography>
                    <Box className="padding-y-50">
                      <FieldArray name="holidays">
                        {({ push, remove }) => (
                          <React.Fragment>
                            <Box className="gap-20">
                              {values?.holidays?.length > 0 &&
                                values?.holidays?.map((tasks, index) => (
                                  <Box key={index}>
                                    <Box>
                                      {/* To do future :
                                                                           <Typography className="form-heading-edit-1 pb-20">
                                                                              Task {index + 1}
                                                                              <span
                                                                                onClick={() => remove(index)}
                                                                                className="process-delete">
                                                                               <DeleteIconNew />
                                                                              </span>
                                                                            </Typography>
                                                                        */}

                                      <Grid
                                        spacing={2}
                                        key={index}
                                        paddingBottom={'20px'}
                                        container
                                      >
                                        <Grid item xs={12} md={6} xl={4}>
                                          <CustomInput
                                            label="Holiday Name"
                                            placeholder="Enter Holiday Name"
                                            handleChange={handleChange}
                                            value={
                                              values?.holidays?.[index]?.name
                                            }
                                            name={`holidays.${index}.name`}
                                            InputProps={{
                                              endAdornment:
                                                touched?.holidays?.[index]
                                                  ?.name &&
                                                errors?.holidays?.[index]
                                                  ?.name &&
                                                icon
                                            }}
                                            errors={
                                              errors?.holidays?.[index]?.name
                                            }
                                            touched={
                                              touched?.holidays?.[index]?.name
                                            }
                                          />
                                        </Grid>
                                        <Grid item xs={12} md={6} xl={4}>
                                          <Grid
                                            marginTop={'-1px'}
                                            fontSize={'14px'}
                                          >
                                            <BasicDatePicker
                                              label="Date"
                                              value={values?.holidays?.[index]?.date ? dayjs(new Date(values?.holidays?.[index]?.date)) : dayjs(new Date())}
                                              // value={new Date("2022-01-01")}
                                              handleDateChange={(e) => {
                                                setFieldValue(
                                                  `holidays.${index}.date`,
                                                  e
                                                )
                                              }}
                                              className="default-date-picker"
                                              errors={
                                                errors?.holidays?.[index]?.date
                                              }
                                              touched={
                                                touched?.holidays?.[index]?.date
                                              }
                                            />
                                          </Grid>
                                        </Grid>
                                        {/* <Box className='remove-button1'> */}
                                    {/* <CustomButton
                                     variant='outlined'
                      title="Remove"
                       onClick={() => remove(index)}
                    /> */}
                          <Grid className="delete-icon-grid" item xs={1} marginBottom={'11px'}>
                                  <span
                                    onClick={() => remove(index)}
                                    className="process-delete"
                                  >
                                    <DeleteIconNew />
                                  </span>
                                </Grid>
                                  {/* </Box> */}
                                      </Grid>
                                    </Box>
                                  </Box>
                                ))}
                            </Box>
                            <Box paddingTop={'10px'} paddingBottom={'10px'}>
                              {' '}
                              <CustomButton
                                variant="outlined"
                                className="button-box"
                                type="button"
                                title="Add New Holiday "
                                endIcon={<Plus fill={'#0160B9'} />}
                                onClick={() =>
                                  push({
                                    name: '',
                                    date: ''
                                  })
                                }
                              />
                            </Box>
                          </React.Fragment>
                        )}
                      </FieldArray>
                    </Box>
                  </Box>
                  <Box className="button-child">
                    <CustomButton
                      title={id ? 'Update' : 'Save '}
                      type="submit"
                    />
                  </Box>
                </Form>
              </React.Fragment>
            )}
          </Formik>
        </Box>
          : <Box className='machine-kanban-progress'>
                <CircularProgress />
              </Box>}
      </Box>
    </React.Fragment>
  )
}

export default EditSchedule
