import * as React from 'react'
import { Box } from '@mui/system'
import { Divider, Grid, Modal, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import CloseIcon from '@mui/icons-material/Close'
import { FieldArray, Form, Formik } from 'formik'
import CustomInput from '../../leastcomponent/custominput/input'
import CustomSelect from '../../leastcomponent/customselect/customselect'
import CustomButton from '../../leastcomponent/custombutton/custombutton'
import Plus from '../../assets/svg/Plus'
import dayjs from 'dayjs'

// TODO:: future use validation:
import * as Yup from 'yup'
import { AiOutlineInfoCircle } from 'react-icons/ai'
import BasicDatePicker from '../../leastcomponent/datepicker/customdatepicker'
import moment from 'moment'
import { useParams } from 'react-router-dom'
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 950,
  maxHeight: '80% !important',
  overflow: 'auto',
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: '8px',
  outline: 'none',
  padding: '1% 1% 1% 1%'
}

const HeadBox = styled(Box)(() => ({
  '.css-1t1j96h-MuiPaper-root-MuiDialog-paper': {
    borderRadius: '32px !important'
  },

  '.switch': {
    padding: '5px 0px'
  },
  '.jobcardform': {
    padding: '2px 24px 0px 24px'
  }
}))
const icon = <AiOutlineInfoCircle color="red" />
const options = [
  { value: 'monthly', label: 'Monthly' },
  { value: 'quarterly', label: 'Quarterly' }
]
const MaintenanceScheduleModal = (props) => {
  const { basicDetailForm, open, DetailsHandleClose, isEdit = false } = props
  const params = useParams()
  // TODO:: future use validation :
  // const validationSchema = Yup.object().shape({
  //   name: Yup.string().required("Name is required"),
  //   period: Yup.string().required("Period is required"),
  // });
  const validationSchema = Yup.object().shape({
    tasks: Yup.array().of(
      Yup.object().shape({
        name: Yup.string(),
        pmFrequency: Yup.string(),
        lastDate: Yup.string(),
        nextDate: Yup.string()
      })
    )
  })
  const editData = basicDetailForm?.map((data) => {
    return {
      name: data?.name,
      pmFrequency: data?.pmFrequency,
      // maintenanceCode: data?.maintenanceCode,
      lastDate: moment(data?.lastDate).format('MM/DD/YYYY'),
      nextDate: moment(data?.nextDate).format('MM/DD/YYYY'),
      selectTime: data?.selectTime
    }
  })

  const handleFormSubmit = (values) => {
    const taskData = values?.tasks
    const modifiedData = taskData.map(item => ({
      ...item,
      nextDate: new Date(item.nextDate)
    }))
    localStorage.setItem('taskData', JSON.stringify(modifiedData))
    props.onSubmit(values)
    DetailsHandleClose() // Close the modal
  }

  function calculateFutureDate (date, freq) {
    const futureDate = new Date(date)
    if (freq === 'monthly') {
      moment(futureDate.setMonth(futureDate.getMonth() + 1)).format('MM/DD/YYYY')
    } else if (freq === 'quarterly') {
      moment(futureDate.setMonth(futureDate.getMonth() + 3)).format('MM/DD/YYYY')
    }
    return moment(futureDate.toISOString().split('T')[0]).format('MM/DD/YYYY')
  }

  return (
    <React.Fragment>
      <Modal
        open={open}
        onClose={DetailsHandleClose}
        aria-labelledby=""
        aria-describedby="modal-modal-description"
      >
        <HeadBox sx={style}>
          <Box className="title-head-box-new">
            <Typography className="top-up-name">
              Set Maintenance Schedule
            </Typography>
            <CloseIcon className="close-icon" onClick={DetailsHandleClose} />
          </Box>
          <Formik
            enableReinitialize={true}
            validationSchema={validationSchema}
            initialValues={{
              tasks: params?.id ? editData : isEdit ? editData : [{ name: '', pmFrequency: '', lastDate: '', nextDate: '' }]
            }}

            onSubmit={handleFormSubmit}
          >
            {({
              errors,
              handleChange,
              touched,
              values,
              setFieldValue,
              handleSubmit
            }) => (
              <React.Fragment>
                <Box className="jobcardform">
                  <Form className="form-perent">
                    <FieldArray name="tasks">
                      {({ push }) => (
                        <React.Fragment>
                          <Box>
                            <Grid spacing={0} container>
                              {values?.tasks?.length > 0 &&
                                values?.tasks?.map((tasks, index) => (
                                  <Grid
                                    spacing={2}
                                    container
                                    paddingTop={2}
                                    key={index}
                                  >
                                    <Grid
                                      item
                                      xs={12}
                                      md={12}
                                      xl={12}
                                      className="title-head-box"
                                    >
                                      <Typography className="top-up-name">
                                        Schedule{index + 1}
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={12} md={3} xl={3}>
                                      <CustomInput
                                        label="Name"
                                        isRequired={false}
                                        placeholder="Enter Name"
                                        handleChange={handleChange}
                                        value={values?.tasks?.[index]?.name}
                                        name={`tasks.${index}.name`}
                                        InputProps={{
                                          endAdornment:
                                            touched.name && errors.name && icon
                                        }}
                                        errors={typeof errors.tasks?.[index]?.name === 'string' ? errors.tasks?.[index]?.name : ''}
                                        touched={touched.tasks?.[index]?.name ? touched.tasks?.[index]?.name : ''}
                                      />
                                    </Grid>
                                    <Grid item xs={12} md={3} xl={3}>
                                      <CustomSelect
                                        name={`tasks.${index}.pmFrequency`}
                                        isRequired={false}
                                        label="PM Frequency"
                                        placeholder="Select PM Frequency"
                                        className="default-select-new"
                                        options={options}
                                        value={values?.tasks?.[index]?.pmFrequency}
                                        handleSelectChange={(e) => {
                                          setFieldValue(
                                            `tasks.${index}.pmFrequency`,
                                            e?.target?.value
                                          )
                                        }
                                        }
                                        errors={typeof errors.tasks?.[index]?.pmFrequency === 'string' ? errors.tasks?.[index]?.pmFrequency : ''}
                                        touched={touched.tasks?.[index]?.pmFrequency ? touched.tasks?.[index]?.pmFrequency : ''}
                                      />
                                    </Grid>
                                    {/* ///!---------- start */}
                                    <Grid item xs={12} md={3} xl={3}>
                                        <BasicDatePicker
                                          // TODO:: future use datepicker
                                          // value={values?.tasks?.[index]?.lastDate} // Pass lastDate from form values
                                          value={values?.tasks?.[index]?.lastDate ? dayjs(new Date(values?.tasks?.[index]?.lastDate)) : dayjs()}
                                           handleDateChange={(date) => {
                                             setFieldValue(`tasks.${index}.lastDate`, date)
                                             setFieldValue(`tasks.${index}.nextDate`, calculateFutureDate(date, values?.tasks?.[index]?.pmFrequency))
                                           }}
                                            label="Last Maintenance Date"
                                                className="default-date-picker"
                                                />
                                    </Grid>
                                    <Grid item xs={12} md={3} xl={3}>
                                      <CustomInput
                                        label="Next Due Date"
                                        titleClassName="disabled-balance-Quantity"
                                        disabled={true}
                                        isRequired={false}
                                        placeholder="Enter Next Due Date"
                                        handleChange={handleChange}
                                        name={`tasks.${index}.nextDate`}
                                        value={moment(values?.tasks?.[index]?.nextDate).format('MM/DD/YYYY')}
                                      />
                                    </Grid>
                                    <Grid item xs={12} md={12} xl={12}>
                                   {index === values?.tasks?.length - 1 ? '' : <Divider />}
                                    </Grid>
                                  </Grid>
                                ))}

                              <Grid paddingLeft={'0px'}>
                                <Box padding={'30px 0px 0px'}>
                                  <CustomButton
                                    variant="outlined"
                                    className="button-box"
                                    type="button"
                                    startIcon={<Plus fill={'#0160B9'} />}
                                    title="Add New Schedule"
                                    onClick={() =>
                                      push({
                                        lastDate: '',
                                        name: '',
                                        pmFrequency: ''
                                      })
                                    }
                                  />
                                </Box>
                              </Grid>
                            </Grid>
                          </Box>
                        </React.Fragment>
                      )}
                    </FieldArray>

                    <Box
                      className=""
                      sx={{
                        display: 'flex',
                        justifyContent: 'end',
                        width: '100%'
                      }}
                    >
                      <CustomButton
                        title="Submit"
                        type="submit"
                        onClick={() => {
                          handleSubmit(values)
                        }}
                      />
                    </Box>
                  </Form>
                </Box>
              </React.Fragment>
            )}
          </Formik>
        </HeadBox>
      </Modal>
    </React.Fragment>
  )
}
export default MaintenanceScheduleModal
