import React from 'react'
import './factoryLayout.css'
import { Box, CircularProgress, Grid, TextField, Typography } from '@mui/material'
import { ErrorMessage, Form, Formik } from 'formik'
// import CustomInput from '../../leastcomponent/custominput/input'
import CustomButton from '../../leastcomponent/custombutton/custombutton'
import { AiOutlineInfoCircle } from 'react-icons/ai'
import * as Yup from 'yup'
import BreadcrumbNavigation from '../commoncomponents/breadcrumbnavigation'

import { useLocation, useParams } from 'react-router-dom'
import {
  useAddDepartment,
  useGetDeletedDepartmernt,
  useGetDepartment,
  useHardDeleteDepartment,
  useRestoreDepartment,
  useUpdateDepartment,
  useValidateDepartment
} from '../../hooks/departmentHooks'
import ErrorPopup from '../../modals/errorpopup'
import { styled } from '@mui/material/styles'
import Required from '../commoncomponents/required'
// import moment from 'moment'

const Input = styled(TextField)(() => ({
  borderRadius: '4px',
  '.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input': {
    height: '38px',
    padding: '1px 10px',
    fontSize: '14px !important'
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#8A8A8A',
      borderRadius: '4px'
    }
  }
}))
const AddDepartmentForm = () => {
  const { id } = useParams()
  //  delete items
  const location = useLocation()
  const isDeleted = location.pathname.startsWith('/deleted-items')
  const isRestore = location.pathname.startsWith('/deleted-items')

  const [isErrorModalOpen, setIsErrorModalOpen] = React.useState(false)
  const handleErrorModalClose = () => setIsErrorModalOpen(false)
  const [errorMessage, setErrorMessage] = React.useState('')
  const icon = <AiOutlineInfoCircle color="red" />
  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Department name is required')
  })

  const {
    mutate: addDepartment,
    isError: isAddDepartmentError,
    error: addDepartmentError,
    isLoading
    // isSuccess: isAddDepartmentSuccess,
  } = useAddDepartment()
  const { mutate: hardDeleteDepartment } = useHardDeleteDepartment()
  const { mutate: restoreDepartment } = useRestoreDepartment()
  const handlePartDelete = () => {
    if (isDeleted) {
      hardDeleteDepartment(id)
    } else {
      // deleteSinglePart(id)
    }
  }
  const handlePartRestore = () => {
    if (isRestore) {
      restoreDepartment(id)
    } else {
      // nothing to restore
    }
  }
  const { data: singleDepartmentData, isFetching: isSingleDepartment, isSuccess } = useGetDepartment(id, !!id && !isDeleted)
  const { data: getDepartmentDeleted } = useGetDeletedDepartmernt(
    id,
    !!id && isDeleted
  )
  const singleDepartment = isDeleted ? getDepartmentDeleted?.data : singleDepartmentData
  const {
    mutate: updateDepartment,
    isError: isUpdateDepartmentError,
    error: updateDepartmentError
    // isSuccess: isAddDepartmentSuccess,
  } = useUpdateDepartment()
  const {
    mutate: validateDepartment,
    data: validateData,
    isLoading: isValidating
  } = useValidateDepartment()

  React.useEffect(() => {
    if (isAddDepartmentError || isUpdateDepartmentError) {
      setErrorMessage(
        isAddDepartmentError
          ? addDepartmentError?.data?.message
          : updateDepartmentError?.data?.message
      )
      setIsErrorModalOpen(true)
    }
  }, [
    isAddDepartmentError,
    isUpdateDepartmentError,
    addDepartmentError,
    updateDepartmentError
  ])
  React.useEffect(() => {
    if (id && isSuccess) {
      if (singleDepartment?.name) {
        validateDepartment({
          name: singleDepartment.name,
          id
        })
      }
    }
  }, [
    id,
    isSuccess,
    singleDepartment?.name,
    id
  ])
  const breadCrumbData = []
  return (
    <Box className="production-team-head-overflow-hidden">
      <BreadcrumbNavigation
        {...(isDeleted && { breadCrumbData })}
        pageTitle={isDeleted ? 'Deleted Department' : id ? 'Edit Department' : 'Create Department'}
        backToLink={'/factory-layout '}
        activeTab="2"
        isRestore={true}
        isDeleted={true}
        handleDelete={handlePartDelete}
        handleRestore={handlePartRestore}
      />

     {!isSingleDepartment
       ? <Box className="production-team-head-overflow-auto ">
        <Formik
          enableReinitialize={true}
          initialValues={{
            name: id ? singleDepartment?.name : '',
            code: ''
          }}
          onSubmit={(values) => {
            const addDepartmentValues = {
              name: values.name
              // code: '123'
            }
            if (id) {
              updateDepartment({ ...addDepartmentValues, id })
            } else {
              addDepartment(addDepartmentValues)
            }
          }}
          validationSchema={validationSchema}
        >
          {({ handleChange, touched, errors, values }) => (
            <React.Fragment>
              <Form className="form-perent">
                <Box className="add-dep-form-child">
                  <Box>
                    <Box>
                      <Typography className="add-form-heading-1">
                        {isDeleted ? 'Deleted Department' : id ? 'Edit Department' : 'Create Department'}
                      </Typography>
                    </Box>
                    <Box className="tab-content-padding">
                      <Grid spacing={2} paddingBottom={'20px'} container>
                        <Grid item xs={12} md={6} xl={4}>
                          <Box>
                             <Typography className='field-title'>
                             Department Name
                      <Required />
                    </Typography>
                           <Input
                      // error={
                      //   (!validateData?.data.isValid && values?.jobCardNumber && !isValidating) ||
                      //   (!(!errors.jobCardNumber || !touched.jobCardNumber))
                      // }
                      placeholder='Enter Department Name'
                      fullWidth
                      onChange={(e) => {
                        validateDepartment({
                          name: e.target.value,
                          ...(id && { id })
                        })
                        handleChange(e)
                      }}
                      value={values.name || ''}
                      name='name'
                      InputProps={{
                        style: { fontSize: 14, padding: '0px !important', height: '40px' },
                        endAdornment: touched.name && errors.name && icon
                      }}
                    />
                    {!validateData?.data.isValid && values?.name && !isValidating
                      ? (
                      <Typography className='validation-text'>
                        {'Department Name already taken'}
                      </Typography>
                        )
                      : (
                      <ErrorMessage
                        name='name'
                        render={(msg) => <Typography className='validation-text'>{msg}</Typography>}
                      />
                        )}
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                </Box>
               {!isDeleted &&
             <Box className="button-child">
                  <CustomButton
                    disabled={isLoading}
                  title={id ? 'Update' : 'Save'} type="submit" />
                </Box>
                 }
              </Form>
            </React.Fragment>
          )}
        </Formik>
      </Box>
       : <Box className='machine-kanban-progress'>
      <CircularProgress />
    </Box>}
      {isErrorModalOpen && (
        <ErrorPopup
          isOpen={isErrorModalOpen}
          handleClose={handleErrorModalClose}
          errorDetails={errorMessage}
        />
      )}
    </Box>
  )
}

export default AddDepartmentForm
