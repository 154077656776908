import { useMutation, useQueryClient, useQuery } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom'
import { factoryScheduleApi } from '../api/factoryschedule'
import { factorySchedule } from '../querykeys/factoryschedule'

export const useGetFactorySchedule = (isEnable = false) => {
  return useQuery(
    [factorySchedule.FACTORYSCHEDULE],
    factoryScheduleApi.getFactorySchedule,
    {
      select: (data) => data?.data?.data,
      retry: 0,
      enabled: isEnable,
      // onSuccess: (res: any) => {
      //   const data = res?.data;
      //   //toast.success(data?.message);
      // },
      // onError: (err: any) => {
      //   const errData = err?.data;
      //   //toast.error(errData.message);
      // },
      refetchOnWindowFocus: false
    }
  )
}

export const useAddFactorySchedule = () => {
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  return useMutation(factoryScheduleApi.addFactorySchedule, {
    onSuccess: (res) => {
      queryClient.invalidateQueries([factorySchedule.FACTORYSCHEDULE])
      const data = res.data?.data
      console.log('object: ', data)
      // toast.success(data?.message);
      navigate('/factory-schedule')
    },
    onError: (err) => {
      return err
      // const errData = err?.data;
      // toast.error(errData.message);
    }
  })
}

export const useUpdateFactorySchedule = () => {
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  return useMutation(factoryScheduleApi.updateFactorySchedule, {
    onSuccess: (res, id) => {
      queryClient.invalidateQueries([factorySchedule.FACTORYSCHEDULE, id])
      queryClient.invalidateQueries([factorySchedule.FACTORYSCHEDULES])
      const data = res.data
      // toast.success(data?.message);
      navigate(`/factory-schedule-details/${data?.factorySchedule.id}`)
    },
    onError: (err) => {
      return err
      // const errData = err?.data;
      // toast.error(errData.message);
    }
  })
}

export const useDeleteFactorySchedule = () => {
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  return useMutation(factoryScheduleApi.deleteFactorySchedule, {
    onSuccess: (res) => {
      const data = res.data
      queryClient.invalidateQueries([
        factorySchedule.FACTORYSCHEDULE,
        data?.factorySchedule.id
      ])
      queryClient.invalidateQueries([factorySchedule.FACTORYSCHEDULES])
      // toast.success(data?.message);
      navigate('/factory-schedule')
    }
    // onError: (err: any) => {
    //   const errData = err?.data;
    //   //toast.error(errData.message);
    // },
  })
}

export const useAddSetting = () => {
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  return useMutation(factoryScheduleApi.addSetting, {
    onSuccess: () => {
      // queryClient.invalidateQueries([factorySchedule.FACTORYSCHEDULES_TOOL_CATEGORY]);
      queryClient.invalidateQueries([factorySchedule.LOGGED_IN_USER])
      //   const data = res.data;
      // toast.success(data?.message);
      navigate('/other-seting')
    },
    onError: (err) => {
      return err
      // const errData = err?.data;
      // toast.error(errData.message);
    }
  })
}

export const useGetSetting = (isEnable = true) => {
  return useQuery(
    [factorySchedule.GET_SETTING],
    factoryScheduleApi.getSetting,
    {
      select: (data) => data?.data,
      retry: 0,
      enabled: isEnable,
      // onSuccess: (res: any) => {
      //   const data = res?.data;
      //   //toast.success(data?.message);
      // },
      // onError: (err: any) => {
      //   const errData = err?.data;
      //   //toast.error(errData.message);
      // },
      refetchOnWindowFocus: false
    }
  )
}
