const jobcard = {
  JOBCARD: 'JOBCARD',
  GET_JOBCARD: 'GET_JOBCARD',
  JOBCARDS: 'JOBCARDS',
  JOBCARDPENDING: 'JOBCARDPENDING',
  JOBCARDUPDATE: 'JOBCARDUPDATE',
  JOBCARDSUBMITTED: 'JOBCARDSUBMITTED',
  JOBCARDAPPROVED: 'JOBCARDAPPROVED',
  JOBCARDONGOING: 'JOBCARDONGOING',
  JOBCARDCOMPLETION: 'JOBCARDCOMPLETION',
  GET_JOBCARD_DELETE: 'GET_JOBCARD_DELETE',
  JOBCARD_CLONE: 'JOBCARD_CLONE',
  GET_TASK_DELETE: 'GET_TASK_DELETE',
  SINGLE_JOBCARDS: 'SINGLE_JOBCARDS',
  JOBCARDDRAFT: 'JOBCARDDRAFT',
  ADDJOBCARDDRAFT: 'ADDJOBCARDDRAFT'

}
export { jobcard }
