import styled from '@emotion/styled'
import { Grid, Typography } from '@mui/material'
import Box from '@mui/material/Box'
import React, { useState } from 'react'
import './JobDetails.css'
import JobCardDetailsModal from '../../modals/jobcardmodal/jobCardDetailsModal'
import BreadcrumbNavigation from '../commoncomponents/breadcrumbnavigation'
import { useGetJobDetailsSingle } from '../../hooks/jobcardHooks'
import { useParams } from 'react-router-dom'
import _ from 'lodash'

const TextFiled = styled(Typography)(() => ({
  color: 'gray',
  paddingTop: '10px',
  fontSize: '14px !important',
  padding: '24px 0px 0px 24px'
}))
const BorderFiled = styled(Typography)(() => ({
  color: 'gray',
  paddingTop: '0px',
  fontSize: '14px !important',
  padding: '4px 0px 4px 4px'
}))
const JobDetails = () => {
  const [open, setOpen] = useState(false)
  const handleDetailsClose = () => {
    setOpen(false)
  }
  const { id } = useParams()
  const { data: getJobDetails } = useGetJobDetailsSingle(id, !!id)
  const singleJobDetails = getJobDetails?.data
  const breadCrumbData = [
    {
      title: 'Job Cards',
      link: '/resource'
    },
    {
      title: 'Job Cards Name',
      link: ''
    }
  ]

  return (
    <React.Fragment>
      <BreadcrumbNavigation
        breadCrumbData={breadCrumbData}
        pageTitle={'Job Card Name , 098-9273-1783-111-018'}
        backToLink={'/job-card'}
        isPendingStatus
        onClick={() => {
          setOpen(true)
        }}
      />

      <Box className="common-parent-class">
        <Grid item xs={6}>
          <Box className="details-box-border padding-bottom" mr={'10px'}>
            <Typography id="modal-modal-title" className="details-sub-title">
              Basic Details
            </Typography>
            <Grid container display={'flex'}>
              <Grid item xs={3}>
                <TextFiled>Oc Number </TextFiled>
                <Typography className="span-color pl-sub-text">
                  098-524-213-445
                  {_.get(singleJobDetails, 'name', '')}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <TextFiled>Date</TextFiled>
                <Typography className="span-color pl-sub-text">
                  11/11/11
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <TextFiled>Sale order number </TextFiled>
                <Typography className="span-color pl-sub-text">
                  879-987-654
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <TextFiled>Customer name </TextFiled>
                <Typography className="span-color pl-sub-text">
                  customer name
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <TextFiled>Customer reference </TextFiled>
                <Typography className="span-color pl-sub-text">
                  Customer reference
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <TextFiled>Product code </TextFiled>
                <Typography className="span-color pl-sub-text">
                  789-779-78-452
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <TextFiled>Product name</TextFiled>
                <Typography className="span-color pl-sub-text">
                  job card
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <TextFiled>Priority </TextFiled>
                <Typography className="span-color pl-sub-text">
                  job card
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <TextFiled>Priority </TextFiled>
                <Typography className="span-color pl-sub-text">
                  job card
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <TextFiled>Description </TextFiled>
                <Typography className="span-color pl-sub-text">
                  Lorem ipsum dolor sit amet consectetur. Nibh mauris montes
                  quis ut purus elit nulla. Viverra ultrices congue imperdiet
                  tortor a aliquet vitae enim mattis. Blandit integer volutpat
                  turpis turpis ultrices lacus non fermentum. Eu viverra amet
                  egestas purus mauris semper.
                </Typography>
              </Grid>
              <Grid item xs={2} mt={'30px'}>
                <Box border={'1px solid #8A8A8A'} display={'flex'} mb={'6px'}>
                  <Grid item xs={7}>
                    <BorderFiled>Quantity </BorderFiled>
                  </Grid>
                  <Typography
                    className="span-color pl-sub-text"
                    alignItems={'center'}
                    mt={'3px'}
                  >
                    10
                  </Typography>
                </Box>
                {/* <Box border={'1px solid #8A8A8A'} display={'flex'} mb={'6px'}>
                  <Grid item xs={7}>
                    <BorderFiled>Produced Quantity </BorderFiled>
                  </Grid>
                  <Typography
                    className="span-color pl-sub-text"
                    alignItems={'center'}
                    mt={'3px'}
                  >
                    10
                  </Typography>
                </Box>
                <Box border={'1px solid #8A8A8A'} display={'flex'} mb={'6px'}>
                  <Grid item xs={7}>
                    <BorderFiled>Balance Quantity </BorderFiled>
                  </Grid>
                  <Typography
                    className="span-color pl-sub-text"
                    alignItems={'center'}
                    mt={'3px'}
                  >
                    10
                  </Typography>
                </Box> */}
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={6} mt={'20px'}>
          <Box className="details-box-border padding-bottom">
            <Typography id="modal-modal-title" className="details-sub-title">
              Part Name
            </Typography>
            <Grid container display={'flex'}>
              <Grid item xs={3}>
                <TextFiled>Item Code </TextFiled>
                <Typography className="span-color pl-sub-text">
                  job card
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <TextFiled>Number of </TextFiled>
                <Typography className="span-color pl-sub-text">
                  job card
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <TextFiled>Drawing number </TextFiled>
                <Typography className="span-color pl-sub-text">
                  job card
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <TextFiled>Weight</TextFiled>
                <Typography className="span-color pl-sub-text">
                  job card
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <TextFiled>Final Size - OD (mm) </TextFiled>
                <Typography className="span-color pl-sub-text">
                  job card
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <TextFiled>Final Size - ID (mm) </TextFiled>
                <Typography className="span-color pl-sub-text">
                  job card
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <TextFiled>Final Size - L (mm) </TextFiled>
                <Typography className="span-color pl-sub-text">
                  job card
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <TextFiled>Process Code </TextFiled>
                <Typography className="span-color pl-sub-text">
                  job card
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <TextFiled>Description </TextFiled>
                <Typography className="span-color pl-sub-text">
                  Lorem ipsum dolor sit amet consectetur. Nibh mauris montes
                  quis ut purus elit nulla. Viverra ultrices
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        {open && (
          <JobCardDetailsModal
            open={open}
            DetailsHandleClose={handleDetailsClose}
            setOpen={() => setOpen(false)}
          />
        )}
      </Box>
    </React.Fragment>
  )
}

export default JobDetails
