import React from 'react'

const SaveFileIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.875 2.5V6.875H16.25L11.875 2.5Z"
        fill="black"
        fillOpacity="0.1"
      />
      <path
        d="M15.625 7.13388V10C15.625 10.3452 15.9048 10.625 16.25 10.625C16.5952 10.625 16.875 10.3452 16.875 10V6.875C16.875 6.70924 16.8092 6.55027 16.6919 6.43306L12.3169 2.05806C12.1997 1.94085 12.0408 1.875 11.875 1.875L4.375 1.875C3.85723 1.875 3.49112 2.24112 3.49112 2.24112C3.125 2.60723 3.125 3.125 3.125 3.125V10C3.125 10.3452 3.40482 10.625 3.75 10.625C4.09518 10.625 4.375 10.3452 4.375 10V3.125H11.6161L15.625 7.13388Z"
        fill="#265AA8"
      />
      <path
        d="M11.875 7.5H16.25C16.5952 7.5 16.875 7.22018 16.875 6.875C16.875 6.52982 16.5952 6.25 16.25 6.25H12.5V2.5C12.5 2.15482 12.2202 1.875 11.875 1.875C11.5298 1.875 11.25 2.15482 11.25 2.5V6.875C11.25 7.22018 11.5298 7.5 11.875 7.5Z"
        fill="#265AA8"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.125 16.875V13.125C3.125 12.7798 3.40482 12.5 3.75 12.5H5C5 12.5 5.77665 12.5 6.32582 13.0492C6.32582 13.0492 6.875 13.5984 6.875 14.375C6.875 14.375 6.875 15.1517 6.32582 15.7008C6.32582 15.7008 5.77665 16.25 5 16.25H4.375V16.875C4.375 17.2202 4.09518 17.5 3.75 17.5C3.40482 17.5 3.125 17.2202 3.125 16.875ZM5 15H4.375V13.75H5C5 13.75 5.25888 13.75 5.44194 13.9331C5.44194 13.9331 5.625 14.1161 5.625 14.375C5.625 14.375 5.625 14.6339 5.44194 14.8169C5.44194 14.8169 5.25888 15 5 15Z"
        fill="#265AA8"
      />
      <path
        d="M14.0625 13.125V16.875C14.0625 17.2202 14.3423 17.5 14.6875 17.5C15.0327 17.5 15.3125 17.2202 15.3125 16.875V13.75H16.875C17.2202 13.75 17.5 13.4702 17.5 13.125C17.5 12.7798 17.2202 12.5 16.875 12.5H14.6875C14.3423 12.5 14.0625 12.7798 14.0625 13.125Z"
        fill="#265AA8"
      />
      <path
        d="M16.5625 14.6875H14.6875C14.3423 14.6875 14.0625 14.9673 14.0625 15.3125C14.0625 15.6577 14.3423 15.9375 14.6875 15.9375H16.5625C16.9077 15.9375 17.1875 15.6577 17.1875 15.3125C17.1875 14.9673 16.9077 14.6875 16.5625 14.6875Z"
        fill="#265AA8"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.90625 17.5C8.56107 17.5 8.28125 17.2202 8.28125 16.875V13.125C8.28125 12.7798 8.56107 12.5 8.90625 12.5H10C10 12.5 11.0355 12.5 11.7678 13.2322C11.7678 13.2322 12.5 13.9645 12.5 15C12.5 15 12.5 16.0355 11.7678 16.7678C11.7678 16.7678 11.0355 17.5 10 17.5H8.90625ZM10 16.25H9.53125V13.75H10C10 13.75 10.5178 13.75 10.8839 14.1161C10.8839 14.1161 11.25 14.4822 11.25 15C11.25 15 11.25 15.5178 10.8839 15.8839C10.8839 15.8839 10.5178 16.25 10 16.25Z"
        fill="#265AA8"
      />
    </svg>
  )
}

export default SaveFileIcon
