import * as React from 'react'
import Box from '@mui/material/Box'
import './approvedmodal.css'
import CustomButton from '../../leastcomponent/custombutton/custombutton'
import CustomizedModal from '../../leastcomponent/custommodal/commonmodal'
import { Grid, useMediaQuery, useTheme } from '@mui/material'

const JobcardUpdateChange = (props) => {
  const theme = useTheme()
  const isMdScreen = useMediaQuery(theme.breakpoints.only('md'))
  const {
    open,
    handleClose,
    // id,
    dirty,
    handleUpdate,
    changedCount
    // number
  } = props
  console.log('changedCount11===>', changedCount)
  return (
    <React.Fragment>
      <CustomizedModal
        isHeadingName={false}
        open={open}
        onClick={handleClose}
        width={isMdScreen ? '40%' : '30%'}
      >
                <Box className="approved-padding">
                  <Box className="approved-job-card-box">

                   {dirty
                     ? <Box className="approved-job-card-main-box">
                    Changes have been made. Are you sure you want to  change {changedCount} field(s)?
                    {/* Changes have been made. Are you sure you want to change ${changedCount} ${changedCount > 1 ? 'fields' : 'field'}?` */}
                    </Box>
                     : <Box className="approved-job-card-main-box">
                    No changes have been made.
                    Are you sure you want to update the Job Card?
                    </Box>}
                  </Box>
                </Box>

              <Grid className="approved-yes-btn1">
                <CustomButton
                  className="button-box"
                  type="button"
                  title="Confirm"
                  onClick={() => {
                    handleUpdate()
                    handleClose()
                  }}
                />
              </Grid>

      </CustomizedModal>
    </React.Fragment>
  )
}
export default JobcardUpdateChange
