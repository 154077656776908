import { notificationApi } from '../api/notification'
import { notification } from '../querykeys/notification'
import { useQuery } from '@tanstack/react-query'

export const useGetNotifications = (isEnable = true, days = '') => {
  return useQuery(
    [notification.NOTIFICATIONS, days || ''],
    notificationApi.getUserNotifications,
    {
      enabled: isEnable,
      select: (data) => data?.data?.data,
      refetchOnWindowFocus: false
    }
  )
}
