import * as React from 'react'
import {
  Modal,
  Typography
  , Box, Grid
} from '@mui/material'
import { styled } from '@mui/material/styles'
import CloseIcon from '@mui/icons-material/Close'
import TableComponent from '../../leastcomponent/customtable/customtable'
import CustomButton from '../../leastcomponent/custombutton/custombutton'
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 1120,
  height: 409,
  overflow: 'auto',
  bgcolor: 'background.paper',
  borderRadius: '8px',
  boxShadow: 24,
  overFlow: 'auto'
}

const HeadBox = styled(Box)(() => ({
  '.btn-box': {
    display: 'flex',
    justifyContent: 'end',
    margin: '3px 20px 30px 0px'
  },
  '.paddingmain': {
    padding: '10px 25px'
  }
}))
const PlanModal = (props) => {
  const {
    open,
    DetailsHandleClose
  } = props
  const rows = [
    {
      id: 1,
      rawMaterial: 'Raw Material',
      dateNeeded: '10/10/10, 10:10',
      cardName: 'Job Card',
      cardNumber: '123-456-789',
      forPart: 'Part 1 ',
      partCode: '12345'
    },
    {
      id: 2,
      rawMaterial: 'Raw Material',
      dateNeeded: '10/10/10, 10:10',
      cardName: 'Job Card',
      cardNumber: '123-456-789',
      forPart: 'Part 1 ',
      partCode: '12345'
    },
    {
      id: 3,
      rawMaterial: 'Raw Material',
      dateNeeded: '10/10/10, 10:10',
      cardName: 'Job Card',
      cardNumber: '123-456-789',
      forPart: 'Part 1 ',
      partCode: '12345'
    },
    {
      id: 4,
      rawMaterial: 'Raw Material',
      dateNeeded: '10/10/10, 10:10',
      cardName: 'Job Card',
      cardNumber: '123-456-789',
      forPart: 'Part 1 ',
      partCode: '12345'
    },
    {
      id: 5,
      rawMaterial: 'Raw Material',
      dateNeeded: '10/10/10, 10:10',
      cardName: 'Job Card',
      cardNumber: '123-456-789',
      forPart: 'Part 1 ',
      partCode: '12345'
    }

  ]
  const columns = [
    { field: 'id', headerName: '#', flex: 0.2 },
    { field: 'rawMaterial', headerName: 'Job Card Number', flex: 1 },
    { field: 'dateNeeded', headerName: 'Part Name', flex: 1 },
    { field: 'cardName', headerName: 'Process Name', flex: 1 },
    { field: 'cardNumber', headerName: 'Start date and time', flex: 1 },
    { field: 'forPart', headerName: 'Allocated time', flex: 1 },
    { field: 'partCode', headerName: 'Drawing', flex: 1 }

  ]
  return (
    <Box>
      <Modal
        open={open}
        onClose={DetailsHandleClose}
        aria-labelledby=''
        aria-describedby='modal-modal-description'
      >
        <HeadBox sx={style}>
          <Box className='title-head-box'>
            <Typography className='top-up-name'>Machine 1 </Typography>
            <CloseIcon className='close-icon' onClick={DetailsHandleClose} />
          </Box>
          <Box paddingTop={'0px'}>
            <Grid container className='paddingmain'>
              <Grid lg={1.75}>
                <Box className="font-size-13" >Department</Box>
                <Box className="font-size-14-with-semibold-primary" >Department</Box>
              </Grid>
              <Grid lg={1.75}>
                <Box className="font-size-13">Timeline</Box>
                <Box className="font-size-14-with-semibold">11/11/11</Box>
              </Grid>
            </Grid>
            <Box className="form-perent">
              <Box className="form-child">
                <Grid container className="paddingmain">
                  <Grid lg={12} wrap="nowrap" spacing={2}>
                    <TableComponent rows={rows} columns={columns} />
                  </Grid>
                </Grid>
              </Box>
              <Box className="button-child">
                <CustomButton title="Save MRP Run" type="submit" />
              </Box>
            </Box>
          </Box>
        </HeadBox>
      </Modal>
    </Box>
  )
}
export default PlanModal
