import { Avatar, Box, styled, Typography } from '@mui/material'
import React, { useState } from 'react'
import ProfileModal from '../modals/profilemodal'
import BlackDownArrow from '../assets/svg/BlackDownArrow'
import BlackUpArrow from '../assets/svg/BlackUpArrow'

const HeadBox = styled(Box)(() => ({
  marginLeft: '24px',
  display: 'flex',
  alignItems: 'center',
  '.display-name': {
    color: '#1C1C1C',
    fontWeight: '600',
    fontSize: '14px',
    lineHeight: '21px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap'
  },
  '.role-name': {
    color: '#777676',
    fontSize: '12px',
    fontWeight: '400',
    lineHeight: '21px'
  },
  '.notification-box': {
    cursor: 'pointer'
  },
  '.avatar-box-width': {
    width: '25px'
  },
  '.css-2s90m6-MuiAvatar-root': {
    width: '30px !important',
    height: '30px !important',
    fontSize: '14px !important',
    '@media(min-width: 425px) and (max-width: 1024px)': {
      width: '24px !important',
      height: '24px !important'
    }
  }
}))

const UserInfo = ({ userData }) => {
  const [open, setOpen] = useState(false)
  const handleOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }
  // TODO:: future use userinfo
  // const [state, setState] = React.useState(false)
  // const toggleDrawer = () => {
  //   setState(!state)
  // }
  // console.log(toggleDrawer)

  return (
    <HeadBox>
      <Box className="avatar-box" onClick={() => handleOpen()}>
        <Box className="avatar-box-width">
          <Avatar
            className="avatar-box-new"
            sizes={'24px'}
            alt=""
            src={userData?.data?.data?.user?.profilePic}
          >
            {userData?.data?.data?.user?.firstName?.[0]?.toUpperCase()}
          </Avatar>
        </Box>
        <Box>
          <Typography className="display-name" ml={'10px'}>
            {userData?.data?.data?.user?.firstName || userData?.firstName}
          </Typography>
          {/* To do future add user role :  */}
          {/* <Typography className="role-name">
            {userData?.data?.data?.user?.userRoleType}
          </Typography> */}
        </Box>
        {open ? <BlackUpArrow /> : <BlackDownArrow />}
      </Box>
      {open && <ProfileModal open={open} handleClose={handleClose} />}
    </HeadBox>
  )
}

export default UserInfo
