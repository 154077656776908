import React, { useEffect, useState } from 'react'
// import { dateFormat } from 'highcharts';
import { Box, Card, Typography } from '@mui/material'
import styled from '@emotion/styled'
import LineChart from './LineChart'

const Content = styled(Typography)(() => ({
  fontSize: 18,
  fontWeight: '600',
  padding: 18
}))

const dataMonth = [
  { price: 0, ots: 1586217600000 },
  { price: 60, ots: 1586304000000 },
  { price: 14, ots: 1583625600000 },
  { price: 55, ots: 1583712000000 },
  { price: 45, ots: 1583798400000 },
  { price: 65, ots: 1583884800000 }
  // { price: 90, ots: 1583971200000 },
  // { price: 80, ots: 1584057600000 },
  // { price: 44, ots: 1584144000000 },
  // { price: 66, ots: 1584230400000 }
]

const WrapperLineChart = (props) => {
  const { title } = props

  const [data, setData] = useState([])

  const createLineChart = (graphData) => {
    const data = []
    const old = []
    const res1 = graphData
    res1.forEach((elem, i) => {
      old.push([elem.date, elem.price, elem?.week])
    })
    data.push({
      // name: "BASELINE",
      type: 'bellcurve',
      data: old
      // keys: ["x", "y", "ots"]
    })
    return data
  }

  useEffect(() => {
    const newArr = dataMonth.map((data, i) => {
      const date = new Date(Date.now()).setHours(i * 24 + 1, 4, 0, 0)
      return { price: data.price, date: new Date(date) }
    })
    const res = createLineChart(newArr)
    setData(res)
  }, [])

  return (
    <div>
      <Card variant="standerd" sx={{ border: '1px solid #DEDEDE' }}>
        <Content>{title}</Content>
        {/* <Box sx={{ paddingBottom: "50px" }}> */}
        <Box>
          <LineChart
            legendColor={['#422F8A', '#FF0000']}
            chartData={data}
            height={props?.height}
          />
        </Box>
      </Card>
    </div>
  )
}

export default WrapperLineChart
