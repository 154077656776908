import { useQuery } from '@tanstack/react-query'
import { jobslipsApi } from '../api/jobslips'
import { jobslips } from '../querykeys/jobslips'

export const useGetJobSlips = (isEnable, page, status) => {
  return useQuery([jobslips.JOBSLIPS, status, page], jobslipsApi.JobSlips, {
    enabled: isEnable,
    select: (res) => res?.data,
    // ! TODO: for future implementation toast
    // onSuccess: () => {
    //   // const data = res?.data;
    // },
    // onError: (err) => {
    //   return err;
    //   // const errData = err?.data;
    //   // toast.error(errData.message);
    // },
    refetchOnWindowFocus: false
  })
}

export const useGetJobSlipsFullList = (isEnable, page) => {
  return useQuery([jobslips.JOBSLIPS, page], jobslipsApi.GetJobSlipsFullList, {
    enabled: isEnable,
    select: (res) => res?.data,
    // ! TODO: for future implementation toast
    // onSuccess: () => {
    //   // const data = res?.data;
    // },
    // onError: (err) => {
    //   return err;
    //   // const errData = err?.data;
    //   // toast.error(errData.message);
    // },
    refetchOnWindowFocus: false
  })
}

export const useJobSlipsSingle = (id, isEnable = true, departmentId) => {
  return useQuery(
    [jobslips.SINGLE_JOBSLIPS_GET, id, departmentId],
    jobslipsApi.GetJobSlipsSingle,
    {
      enabled: isEnable,
      select: (data) => data?.data,
      // ! TODO: for future implementation toast
      // onSuccess: () => {
      //   // const data = res?.data;
      // },
      onError: (err) => {
        return err
        // const errData = err?.data;
        // toast.error(errData.message);
      }
    }
  )
}
