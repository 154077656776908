import { get } from '../utils'

export const getUserNotifications = async (data) => {
  const days = data?.queryKey[1]
  return get('/user-notifications/notification-list', { ...(days && { days }) })
}
export const notificationApi = {
  getUserNotifications

}
