import { Box, InputAdornment, useMediaQuery, useTheme } from '@mui/material'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import styled from '@emotion/styled'
import SearchIcon from '@mui/icons-material/Search'
import { useNavigate } from 'react-router-dom'
import { useSearchData } from '../hooks/searchoptionhook'
import React, { useEffect, useState } from 'react'
function SearchComponent () {
  const [touched, setTouched] = useState(false)
  const [data, setData] = useState([])
  const [enableSearchAPI, setenableSearchAPI] = useState(true)

  const { data: getAllSearchData, isLoading } = useSearchData(touched && data?.length === 0)

  const navigate = useNavigate()

  useEffect(() => {
    if (!isLoading) {
      if (enableSearchAPI) {
        setData(getAllSearchData)
        setenableSearchAPI(false)
      }
      setenableSearchAPI(false)
    }
  }, [data.length, getAllSearchData?.length])

  //! TODO:: static response for future use :
  // const autoCompleteOptions = [
  //   {
  //     title: 'Car Cleaning 1204-1244-1241-1241',
  //     value: 'Car Cleaning',
  //     category: 'Workorders',
  //   },
  //   {
  //     title: 'Car Cleaning 1204-1244-1241-1241',
  //     value: 'Car Cleaning',
  //     category: 'Workorders',
  //   },
  //   {
  //     title: 'Car Cleaning 1204-1244-1241-1241',
  //     value: 'Car Cleaning',
  //     category: 'Workorders',
  //   },
  //   {
  //     title: 'Clippers 1204-1244-1241-1241',
  //     value: 'Clippers',
  //     category: 'Parts',
  //   },
  //   {
  //     title: 'Clippers 1204-1244-1241-1241',
  //     value: 'Clippers',
  //     category: 'Parts',
  //   },
  //   {
  //     title: 'Clippers 1204-1244-1241-1241',
  //     value: 'Clippers',
  //     category: 'Parts',
  //   },
  //   {
  //     title: 'Ceat Tyre 1204-1244-1241-1241',
  //     value: 'Ceat Tyre',
  //     category: 'Products',
  //   },
  //   {
  //     title: 'Ceat Tyre 1204-1244-1241-1241',
  //     value: 'Ceat Tyre',
  //     category: 'Products',
  //   },
  //   {
  //     title: 'Ceat Tyre 1204-1244-1241-1241',
  //     value: 'Ceat Tyre',
  //     category: 'Products',
  //   },
  // ];

  const GroupHeader = styled('div')(() => ({
    position: 'sticky',
    top: '-8px',
    padding: '4px 10px',
    color: '#333333',
    backgroundColor: '#F4F9FF'
  }))

  // const options = autoCompleteOptions.map((option) => {
  //   const firstLetter = option.title[0].toUpperCase();
  //   return {
  //     firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
  //     ...option,
  //   };
  // });

  // const names = [
  //   "Workorders",
  //   "Part",
  //   "Product",
  //   "Raw Material",
  //   "Machine",
  //   "Process",
  //   "Workers",
  // ];

  //! all relative dynamic paths for particuler parts,parts Etc...

  const category = {
    Resource: '/resource-details',
    JobCard: '/job-card-details-view',
    'Desktop User': '/edit-user'
  }
  const theme = useTheme()
  const isMdScreen = useMediaQuery(theme.breakpoints.only('md'))
  const isLgScreen = useMediaQuery(theme.breakpoints.only('lg'))
  const BoxWrapper = styled(Box)(() => ({
    display: 'inline-flex',
    boxSizing: 'border-box',
    height: '40px',
    borderRadius: '4px',
    border: '1px solid rgba(205, 205, 205, 1)',
    background: 'rgba(246, 246, 246, 1)',
    // boxShadow: '0px 2px 10px rgba(40, 40, 40, 0.1)',
    '.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input': {
      padding: '0 !important'
    },
    '.dropdown': {
      background: '#F4F9FF',
      borderRadius: '0 4px 4px 0px',
      border: 'none !important',
      padding: '0px !important',
      // width: 'personName?.length' ? '160px' : '80px',
      width: '160px',
      height: '40px !important',
      '& .MuiOutlinedInput-notchedOutline': {
        border: 'none'
      }
    },
    '.autocomplete': {
      '& .MuiOutlinedInput-root': {
        padding: '0px !important'
      },
      '& .MuiOutlinedInput-notchedOutline': {
        border: 'none'
      },
      '& .MuiAutocomplete-inputFocused': {},
      // width: isMdScreen ? (isChangeWidth ? '220px' : '300px') : '300px',
      // width: (isMdScreen) ? '250px' : isLgScreen ? '285px' : '300px',
      width: (isMdScreen) ? '220px' : isLgScreen ? '250px' : '300px',
      '& .MuiAutocomplete-input': {
        padding: '8px 0px 0px 0px !important'
      }
    },

    '.search-input': {
      // width: isMdScreen ? (isChangeWidth ? '220px' : '300px') : '300px',
      width: (isMdScreen) ? '250px' : isLgScreen ? '285px' : '300px',
      fontSize: '16px'
    },
    '.search-icon': {
      padding: '8px 0.5px 0px 6.5px',
      color: 'gray'
    },
    '.custom-checkbox': {
      '& .MuiSvgIcon-root': {
        fontSize: '70px !important',
        borderRadius: '50px !important'
      }
    },
    '.all-set': {
      border: '1px solid red',
      PaddingBottom: '10px'
    }
  }))
  // return <Box>Coming Soon...</Box>;
  return (
    <BoxWrapper display={'flex'}>
      <Autocomplete
        className="autocomplete"
        forcePopupIcon={false}
        id="grouped-demo"
        options={data}
        groupBy={(option) => option?.category}
        getOptionLabel={(option) => {
          return option.name || ''
        }}
        // onChange={(e, value) => {
        //   // setHideLabel(true)
        //   navigate(`${category[value.category]}/${value?.value}`)
        //   // setVal(value)
        // }}
        onChange={(e, value) => {
          // setHideLabel(true)
          const path = category[value.category]
          if (value.category === 'JobCard') {
            navigate(`${path}/${value?.value}?sales`)
          } else {
            navigate(`${path}/${value?.value}`)
          }
          // setVal(value)
        }}
        open={touched}
        onBlur={() => setTouched(false)}
        onOpen={() => setTouched(true)}
        renderInput={(params) => (
          <TextField
            {...params}
            className="search-input"
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon className="search-icon" />
                </InputAdornment>
              )
            }}
            placeholder="Search"
          />
        )}
        renderGroup={(params) => (
          <li>
            <GroupHeader>{params.group}</GroupHeader>
            <ul>{params.children}</ul>
          </li>
        )}
      />
  {/* // TODO:: future use search */}
      {/* <FormControl>
        <InputLabel id='demo-multiple-checkbox-label' shrink={false}>
          {!personName.length ? 'All' : ''}
        </InputLabel>
        <Select
          labelId='demo-multiple-checkbox-label'
          id='demo-multiple-checkbox'
          multiple
          value={personName}
          onChange={handleChange}
          input={<OutlinedInput label='Tag' />}
          renderValue={(selected: any) => {
            if (!selected.length) {
              return selected.join(', ');
            } else {
              return `${countPerson} filters applied`;
            }
          }}
          MenuProps={MenuProps}
          className='dropdown'
        >
          {names.map((name: string, ind: number) => (
            <MenuItem key={ind} value={name}>
              <Checkbox
                // icon={<CheckBoxOutlineBlankOutlinedIcon />}
                icon={<CheckboxIcon />}
                // checkedIcon={<CheckBoxOutlinedIcon color='warning' />}
                checkedIcon={<CheckboxFilledIcon />}
                checked={personName?.indexOf(name) > -1}
                className='custom-checkbox'
              />
              <ListItemText primary={name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl> */}
    </BoxWrapper>
  )
}

export default SearchComponent
