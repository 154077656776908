import React from 'react'

const Kanban = () => {
  return (
    <div>
      <svg
        width="16"
        height="15"
        viewBox="0 0 16 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.5 13.25V1.375C0.5 1.02982 0.779822 0.75 1.125 0.75H14.875C15.2202 0.75 15.5 1.02982 15.5 1.375V10.75C15.5 10.75 15.5 11.2678 15.1339 11.6339C15.1339 11.6339 14.7678 12 14.25 12H11.125C11.125 12 10.6072 12 10.2411 11.6339C10.2411 11.6339 9.875 11.2678 9.875 10.75V9.5H6.125V13.25C6.125 13.25 6.125 13.7678 5.75888 14.1339C5.75888 14.1339 5.39277 14.5 4.875 14.5H1.75C1.75 14.5 1.23223 14.5 0.866117 14.1339C0.866117 14.1339 0.5 13.7678 0.5 13.25ZM1.75 13.25H4.875V8.875C4.875 8.52982 5.15482 8.25 5.5 8.25H10.5C10.8452 8.25 11.125 8.52982 11.125 8.875V10.75H14.25V2H1.75V13.25Z"
          fill="#265AA8"
        />
        <path
          d="M5.5 5.75H1.125C0.779822 5.75 0.5 6.02982 0.5 6.375C0.5 6.72018 0.779822 7 1.125 7H5.5C5.84518 7 6.125 6.72018 6.125 6.375C6.125 6.02982 5.84518 5.75 5.5 5.75Z"
          fill="#265AA8"
        />
        <path
          d="M4.875 1.375V8.875C4.875 9.22018 5.15482 9.5 5.5 9.5C5.84518 9.5 6.125 9.22018 6.125 8.875V1.375C6.125 1.02982 5.84518 0.75 5.5 0.75C5.15482 0.75 4.875 1.02982 4.875 1.375Z"
          fill="#265AA8"
        />
        <path
          d="M10.5 7H14.875C15.2202 7 15.5 6.72018 15.5 6.375C15.5 6.02982 15.2202 5.75 14.875 5.75H10.5C10.1548 5.75 9.875 6.02982 9.875 6.375C9.875 6.72018 10.1548 7 10.5 7Z"
          fill="#265AA8"
        />
        <path
          d="M9.875 1.375V8.875C9.875 9.22018 10.1548 9.5 10.5 9.5C10.8452 9.5 11.125 9.22018 11.125 8.875V1.375C11.125 1.02982 10.8452 0.75 10.5 0.75C10.1548 0.75 9.875 1.02982 9.875 1.375Z"
          fill="#265AA8"
        />
      </svg>
    </div>
  )
}

export default Kanban
