import * as React from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Modal from '@mui/material/Modal'
import styled from '@emotion/styled'
// import "./../../src/style/modal.css";
import '../assets/css/modal.css'
import { useQueryClient } from '@tanstack/react-query'
import moment from 'moment'
import { user } from '../querykeys/usermanagement'
import { useUpdateProfile } from '../hooks/authhooks'
import ErrorPopup from './errorpopup'
import ProfileCard from '../pages/commoncomponents/profilecard'
import ProfileAvatar from '../pages/commoncomponents/profileavtar'
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  // border: '2px solid #000',
  borderRadius: '32px',
  boxShadow: 24,
  p: 4
}

const BoxWrapper = styled(Box)(() => ({
  '.button-box': {
    padding: '10px 20px',
    fontSize: '16px !important',
    fontWeight: 500,
    borderRadius: '10px !important'
  }
}))
// interface propI {
//   open: boolean;
//   handleClose: () => void;
//   handleOpen: () => void;
// }

const ShowProfile = (props) => {
  const { open, handleOpen, handleClose } = props
  const queryClient = useQueryClient()
  const userData = queryClient.getQueryData([user.LOGGED_IN_USER])
  const userProfile = userData?.data?.data?.user

  const [name, setName] = React.useState(userProfile?.firstName)
  const [email, setEmail] = React.useState(userProfile?.email)
  const [dob, setDob] = React.useState(
    moment(userProfile?.dateOfBirth).format('YYYY-MM-DD')
  )
  const [isErrorModalOpen, setIsErrorModalOpen] = React.useState(false)
  const handleErrorModalClose = () => setIsErrorModalOpen(false)
  const [errorMessage, setErrorMessage] = React.useState('')
  const {
    mutate: updateProfile,
    isError: isUpdateProfileError,
    error: updateProfileError,
    isSuccess: isUpdateProfileSuccess
  } = useUpdateProfile()
  React.useEffect(() => {
    if (isUpdateProfileError) {
      setErrorMessage(updateProfileError?.data?.message)
      setIsErrorModalOpen(true)
    }
  }, [isUpdateProfileError])
  React.useEffect(() => {
    if (isUpdateProfileSuccess) {
      handleClose()
    }
  }, [isUpdateProfileSuccess])

  return (
    <div>
      {/* <Button onClick={handleOpen}>Open modal</Button> */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <BoxWrapper>
            <Box display={'flex'} justifyContent={'center'}>
              <ProfileAvatar handleOpen={handleOpen} />
            </Box>
            <Box>
              <Box m={2}>
                <ProfileCard field={'name'} value={name} setData={setName} />
              </Box>
              <Box m={2}>
                <ProfileCard field={'Email'} value={email} setData={setEmail} />
              </Box>
              <Box m={2}>
                <ProfileCard field={'D.O.B'} value={dob} setData={setDob} />
              </Box>
            </Box>
            <Box display={'flex'} justifyContent={'center'}>
              <Button
                variant="contained"
                className="button-box yellow-bg"
                onClick={() => {
                  const Profile = {
                    firstName: name,
                    email,
                    dateOfBirth: moment(dob).format('YYYY-MM-DD')
                  }
                  updateProfile({ ...Profile, id: userProfile?.id })
                }}
              >
                update Profile
              </Button>
            </Box>
          </BoxWrapper>
        </Box>
      </Modal>
      {isErrorModalOpen && (
        <ErrorPopup
          isOpen={isErrorModalOpen}
          handleClose={handleErrorModalClose}
          errorDetails={errorMessage}
        />
      )}
    </div>
  )
}
export default ShowProfile
