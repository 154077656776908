import React, { lazy, Suspense } from 'react'
import './App.css'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import UserLogin from './pages/login/Login'
import JobCards from './pages/jobcards/jobcards'
import AddJobCardForm from './pages/jobcards/addjobcardform'
import DashBoard from './pages/dashboard/DashBoard'
import ProductionAddTask from './pages/jobcards/productionaddtask'
import Resource from './pages/resource/resource'
import CreateResource from './pages/resource/createresource'
import ScheduleMachine from './pages/Schedule/ScheduleMachine'
import FactoryLayout from './pages/factorylayout/factoryLayout'
import AddDepartmentGroupForm from './pages/factorylayout/addDepartmentGroupForm'
import AddDepartmentForm from './pages/factorylayout/addDepartmentForm'
import UserManagement from './pages/usermanagement/userManagement'
import CreateUsers from './pages/usermanagement/createUsers'
import CreateRole from './pages/usermanagement/createRoleOld'
import Simulate from './pages/simulate/simulate'
import CreateScenario from './pages/simulate/createScenario'
import OnGoingJobCard from './pages/jobcards/onGoingJobCard'
import FactorySchedule from './pages/factorySchedule/factorySchedule'
import EditSchedule from './pages/factorySchedule/editSchedule'
import Reports from './pages/reports/reports'
import CreateReports from './pages/reports/createReports'
import JobDetails from './pages/jobcards/JobDetails'
import DeleteItems from './pages/deleteditems/deleteItems'
import OtherSetting from './pages/othersettings/otherSetting'
import MrpRunList from './pages/mrp/mrpRunList'
import NewMrp from './pages/mrp/newMrp'
import JobPlan from './pages/jobcards/jobPlan'
import ResourceDetails from './pages/resource/ResourceDetails'
import SingleJobcard from './pages/jobcards/singleJobcard'
import SingleJobcardNew from './pages/jobcards/singleJobcardNew'
import ReportsPage from './pages/reports/reportspage'
import CreateReport from './pages/reports/createreport'
import SelectReport from './pages/reports/selectreport'
import AddTaskProductionTeamMember from './pages/jobcards/addtaskproductionteammember'
import DetailsCard from './pages/jobcards/basicdetailsform'
import JobCardsDepartmentWise from './pages/jobcards/jobcardsdepartmentwise'
import JobSlips from './pages/jobslips/jobSlips'
// import ScheduleNew from './pages/Schedule/scheduleNew'
import TaskTypeFactoryLayout from './pages/tasktype/taskTypeFactoryLayout'
import CreateTaskType from './pages/tasktype/createTaskType'
import JobSlipsNumber from './pages/jobslips/jobslipsnumber'
import AddRole from './pages/usermanagement/createRole'
import ForgotPassword from './pages/login/ForgotPassword'
import SalesTeamMember from './pages/jobcards/salesTeamMember'
import SalesTeamHead from './pages/jobcards/salesteamhead'
import ProductionTeamMember from './pages/jobcards/productionteammember'
import ProductionTeamHead from './pages/jobcards/productionteamhead'
import UpdateStatus from './pages/jobcards/updatestatusoftask'
import MachineWiseKanbanView from './pages/Schedule/MachineWiseKanbanView'
import JobCardsDepartment from './pages/jobcards/jobcardsdepartment'
import RecentActivity from './pages/recentactivity'
import PrintJobSlipsNumber from './pages/jobslips/printJobslipsnumber'
import CloneJobCardForm from './pages/jobcards/clonejobcardform'
import ApproveJobcardform from './pages/jobcards/ApproveJobcardform'
import JobCardDetails from './pages/jobcards/Jobcarddetailsform'
import ScheduleNewOneNew from './pages/Schedule/scheduleOneNew'
import SystemHealthTable from './pages/systemhealth/systemHealth'
import CreateSetter from './pages/resource/createSetter'
import CreateOperator from './pages/resource/createOperator'
import SetterDetails from './pages/resource/setterDetails'
import OperatorDetails from './pages/resource/operatorDetails'

const ComponentWrapper = lazy(() => import('./pages/commoncomponents/commentwrapper'))
function App () {
  return (
    <Router>
      <Suspense fallback={<h1>Loading...</h1>}>
        <Routes>
          <Route path="/login" element={<UserLogin />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="health" element={<SystemHealthTable />} />
          <Route path="/" element={<ComponentWrapper />}>
            <Route path="job-card" element={<JobCards />} />
            <Route path="job-card-department" element={<JobCardsDepartment />} />
            <Route path="sales-team-member" element={<SalesTeamMember />} />
            <Route path="sales-team-head" element={<SalesTeamHead />} />
            <Route
              path="production-team-member"
              element={<ProductionTeamMember />}
            />
            <Route
              path="production-team-head"
              element={<ProductionTeamHead user="productionTeamHead" />}
            />
            <Route path="update-status" element={<UpdateStatus />} />
            <Route path="add-job-card" element={<AddJobCardForm />} />
            <Route path="clone-job-card/:id" element={<CloneJobCardForm />} />
            <Route path="approve-job-card/:id" element={<ApproveJobcardform />} />
            <Route path="edit-job-card/:id" element={<AddJobCardForm />} />
            {/* <Route path="details-card/:id" element={<DetailsCard />} /> */}
            <Route path="add-task-production" element={<ProductionAddTask />} />
            <Route path="job-dep-wise/:id/:partId" element={<JobCardsDepartmentWise />} />
            <Route path="job-details/:id/:partId" element={<DetailsCard />} />
            <Route path="job-card-details-view/:id" element={<JobCardDetails />} />
            <Route path="deleted-items/job-card-details-view/:id" element={<JobCardDetails />} />
            <Route path="job-card-details/:id/:partId" element={<DetailsCard />} />
            <Route path="deleted-items/job-card-details/:id" element={<DetailsCard />} />
            <Route
              path="add-task-production-team-member/:id/:partId"
              element={<AddTaskProductionTeamMember />}
            />
            <Route path="dashboard" element={<DashBoard />} />
            <Route path="resource" element={<Resource />} />
            <Route path="reports" element={<ReportsPage />} />
            <Route path="createreport" element={<CreateReport />} />
            <Route path="selectreport" element={<SelectReport />} />
            <Route path="create-resource" element={<CreateResource />} />
            <Route path="edit-resource/:id" element={<CreateResource />} />
            <Route path="deleted-items/edit-resource/:id" element={<CreateResource />} />
            <Route path="create-setter" element={<CreateSetter />} />
            <Route path="edit-setter/:id" element={<CreateSetter />} />
            <Route path="create-operator" element={<CreateOperator />} />
            <Route path="edit-operator/:id" element={<CreateOperator />} />
            {/* <Route path="schedule/:id" element={<ScheduleNew />} /> */}
            {/* <Route path="schedule/:id" element={<ScheduleNewOne />} /> */}
            <Route path="schedule/:id" element={<ScheduleNewOneNew />} />
            <Route path="department-wise-kanban-view" element={<ScheduleMachine />} />
            <Route path="machine-wise-kanban-view" element={<MachineWiseKanbanView />} />
            <Route path="factory-layout" element={<FactoryLayout />} />
            <Route path="task-type" element={<TaskTypeFactoryLayout />} />
            <Route path="create-task-type" element={<CreateTaskType />} />
            <Route path="edit-task-type/:id" element={<CreateTaskType />} />
            <Route path="deleted-items/edit-task-type/:id" element={<CreateTaskType />} />
            <Route
              path="create-department-group"
              element={<AddDepartmentGroupForm />}
            />
            <Route
              path="edit-department-group/:id"
              element={<AddDepartmentGroupForm />}
            />
             <Route
              path="deleted-items/edit-department-group/:id"
              element={<AddDepartmentGroupForm />}
            />
            <Route path="create-department" element={<AddDepartmentForm />} />
            <Route path="edit-department/:id" element={<AddDepartmentForm />} />
            <Route path="deleted-items/department/:id" element={<AddDepartmentForm />} />
            <Route path="user-management" element={<UserManagement />} />
            <Route path="add-user" element={<CreateUsers />} />
            <Route path="edit-user/:id" element={<CreateUsers />} />
            <Route path="deleted-items/edit-user/:id" element={<CreateUsers />} />
            <Route path="add-roles" element={<CreateRole />} />
            <Route path="add-role" element={<AddRole />} />
            <Route path="edit-role/:id" element={<AddRole />} />
            <Route path="deleted-items/edit-role/:id" element={<AddRole />} />
            <Route path="my-tickets" element={<Simulate />} />
            <Route path="create-scenario" element={<CreateScenario />} />
            <Route path="job-card-name" element={<OnGoingJobCard />} />
            <Route path="factory-schedule" element={<FactorySchedule />} />
            <Route
              path="edit-factory-schedule"
              element={<EditSchedule />}
            />{' '}
            <Route path="deleted-items" element={<DeleteItems />} />
            <Route path="other-seting" element={<OtherSetting />} />
            <Route path="reports" element={<Reports />} />
            <Route path="create-reports" element={<CreateReports />} />
            <Route path="jobcard-details" element={<JobDetails />} />
            <Route path="checklists" element={<MrpRunList />} />
            <Route path="add-mrp" element={<NewMrp />} />
            <Route path="plan" element={<JobPlan />} />
            <Route path="resource-details/:id" element={<ResourceDetails />} />
            <Route path="setter-details/:id" element={<SetterDetails />} />
            <Route path="operator-details/:id" element={<OperatorDetails />} />
            <Route path="deleted-items/resource-details/:id" element={<ResourceDetails />} />
            <Route path="single-jobcard/:id" element={<SingleJobcard />} />
            <Route path="single-jobcard-new" element={<SingleJobcardNew />} />
            <Route path="job-slips" element={<JobSlips />} />
            <Route path="job-slips-number/:id" element={<JobSlipsNumber />} />
            <Route path="print-job-slips-number/:id" element={<PrintJobSlipsNumber />} />
            <Route path="recent-activity" element={<RecentActivity />} />
          </Route>
        </Routes>
      </Suspense>
    </Router>
  )
}

export default App
