import React from 'react'
import { FieldArray, Field } from 'formik'
import CustomButton from '../../leastcomponent/custombutton/custombutton'
import Plus from '../../assets/svg/Plus'
import Required from '../commoncomponents/required'
import DeleteIconNew from '../../assets/svg/DeleteIconNew'
import './jobcard.css'

const AddPartForJobCardNew = ({ values, setDeletedPart, handleChange, touched, errors, icon, setActiveProcess, setOpen, columns, modifiedFields }) => (
<>
        <FieldArray
          name="bomDetails"
          render={
            ({ push, remove }) => (
            <div className='add-part-field-job-card'>
              {values.bomDetails?.map((bomDetails, index) => {
                const processData = JSON.parse(
                  localStorage.getItem(`processData-${index}`)
                )
                const rows = !processData
                  ? []
                  : Object.keys(processData)?.length
                    ? (processData?.map((item, index) => {
                        return {
                          id: index + 1,
                          name: item.name,
                          code: item.code
                          // department: item.department
                          // departmentGroup: item.departmentGroup
                        }
                      }))
                    : []
                return (
                <div key={index + 1} className='add-part-job-card-main'>
                  <div className='add-part-jobcard-flex'>
                    <div className='add-jobcard-margin-bottom'> Part {index + 1}</div>
                   <div className='add-jobcard-margin-bottom'
                   style={{ cursor: 'pointer' }}
                    onClick={(e) => {
                      if (values.bomDetails[index].id) {
                        setDeletedPart((prev) => [
                          ...prev,
                          values.bomDetails[index].id
                        ])
                      }

                      let allLocalItems = [...Array(values.bomDetails.length)].map((_, ind) => {
                        const data = localStorage.getItem(`processData-${ind}`)
                        if (data) {
                          try {
                            return JSON.parse(data)
                          } catch (error) {
                            console.error('Error parsing JSON from localStorage:', error)
                            return {}
                          }
                        }
                        return {}
                      })

                      allLocalItems = allLocalItems.filter((_, i) => i !== index)
                      localStorage.clear()
                      allLocalItems.forEach((val, ind) => {
                        localStorage.setItem(`processData-${ind}`, JSON.stringify(val))
                      })
                      remove(index)
                    }}> <DeleteIconNew /></div>
                   </div>
                  <div className='add-jobcard-field' >
                   <div className='add-jobcarfield-width' >
                   <div className='field-title'>
                   Item Code
                      <Required />
                    </div>
                      <Field placeholder="Enter Item Code" value={values?.bomDetails?.[index]?.itemCode} name={`bomDetails.${index}.itemCode`} className = 'add-part-default-input'/>

                     {errors?.bomDetails?.[index]?.itemCode && touched?.bomDetails?.[index]?.itemCode && <span className='validation-text'>
                        {errors?.bomDetails?.[index]?.itemCode}
                      </span>
}
                    {modifiedFields?.includes('bomDetails[0].itemCode')
                      ? <span className='modified-Fields-color'>
                        {'Item Code Updated'}
                      </span>
                      : ''}
                      </div>
                    <div className='add-jobcarfield-width'>
                    <div className='field-title'>
                    Part Name
                      <Required />
                    </div>
                      <Field placeholder="Enter Part Name" value={values?.bomDetails?.[index]?.partName} name={`bomDetails.${index}.partName`} className = 'add-part-default-input'
                      />
                     {errors?.bomDetails?.[index]?.partName && touched?.bomDetails?.[index]?.partName && <span className='validation-text'>
                        {errors?.bomDetails?.[index]?.partName}
                      </span>}
                      {modifiedFields?.includes('bomDetails[0].partName')
                        ? <span className='modified-Fields-color'>
                        {' Part Name Updated'}
                      </span>
                        : ''}
                      </div>
                        <div className='add-jobcarfield-width'>
                        <div className='field-title'>
                              Description
                      <Required />
                    </div>
                      <Field placeholder="Enter Description" value={values?.bomDetails?.[index]?.description} name={`bomDetails.${index}.description`} className = 'add-part-default-input'/>
                      {errors?.bomDetails?.[index]?.description && touched?.bomDetails?.[index]?.description && <span className='validation-text'>
                        {errors?.bomDetails?.[index]?.description}
                      </span>}
                      {modifiedFields?.includes('bomDetails[0].description')
                        ? <span className='modified-Fields-color'>
                        {'Description Updated'}
                      </span>
                        : ''}
                      </div>
                      </div>
                      <div className='add-jobcard-field'>
                   <div className='add-jobcarfield-width'>
                   <div className='field-title'>
                   Number Of
                      <Required />
                    </div>
                      <Field placeholder="Enter Number Of" value={values?.bomDetails?.[index]?.numberOf} name={`bomDetails.${index}.numberOf`} className = 'add-part-default-input'/>
                      {errors?.bomDetails?.[index]?.numberOf && touched?.bomDetails?.[index]?.numberOf && <span className='validation-text'>
                        {errors?.bomDetails?.[index]?.numberOf}
                      </span>}
                      {modifiedFields?.includes('bomDetails[0].numberOf')
                        ? <span className='modified-Fields-color'>
                        {' Number Of Updated'}
                      </span>
                        : ''}
                      </div>
                    <div className='add-jobcarfield-width'>
                    <div className='field-title'>
                    Drawing Number
                      <Required />
                    </div>
                      <Field placeholder="Enter Drawing Number" value={values?.bomDetails?.[index]?.drawingNumber} name={`bomDetails.${index}.drawingNumber`} className = 'add-part-default-input'/>
                      {errors?.bomDetails?.[index]?.drawingNumber && touched?.bomDetails?.[index]?.drawingNumber && <span className='validation-text'>
                        {errors?.bomDetails?.[index]?.drawingNumber}
                      </span>}
                      {modifiedFields?.includes('bomDetails[0].drawingNumber')
                        ? <span className='modified-Fields-color'>
                        {' Drawing Number Updated'}
                      </span>
                        : ''}
                      </div>
                        <div className='add-jobcarfield-width'>
                        <div className='field-title'>
                        Weight (gms)
                      <Required />
                    </div>
                      <Field placeholder="Enter Weight (gms)" value={values?.bomDetails?.[index]?.weight} name={`bomDetails.${index}.weight`} className = 'add-part-default-input' type="number"/>
                      {errors?.bomDetails?.[index]?.weight && touched?.bomDetails?.[index]?.weight && <span className='validation-text'>
                        {errors?.bomDetails?.[index]?.weight}
                      </span>}
                      {modifiedFields?.includes('bomDetails[0].weight')
                        ? <span className='modified-Fields-color'>
                        {' Weight (gms) Updated'}
                      </span>
                        : ''}
                      </div>
                      </div>
                       <div className='add-jobcard-field'>
                   <div className='add-jobcarfield-width'>
                   <div className='field-title'>
                   Final Size - OD (mm)
                      <Required />
                    </div>
                      <Field placeholder="Enter Final Size - OD (mm)" value={values?.bomDetails?.[index]?.sizeOd} name={`bomDetails.${index}.sizeOd`} className = 'add-part-default-input' type="number"/>
                      {errors?.bomDetails?.[index]?.sizeOd && touched?.bomDetails?.[index]?.sizeOd && <span className='validation-text'>
                        {errors?.bomDetails?.[index]?.sizeOd}
                      </span>}
                      {modifiedFields?.includes('bomDetails[0].sizeOd')
                        ? <span className='modified-Fields-color'>
                        {' Final Size - OD (mm) Updated'}
                      </span>
                        : ''}
                      </div>
                    <div className='add-jobcarfield-width'>
                    <div className='field-title'>
                    Final Size - ID (mm)
                      <Required />
                    </div>
                      <Field placeholder="Enter Final Size - ID (mm)" value={values?.bomDetails?.[index]?.sizeId} name={`bomDetails.${index}.sizeId`} className = 'add-part-default-input' type="number"/>
                      {errors?.bomDetails?.[index]?.sizeId && touched?.bomDetails?.[index]?.sizeId && <span className='validation-text'>
                        {errors?.bomDetails?.[index]?.sizeId}
                      </span>}
                      {modifiedFields?.includes('bomDetails[0].sizeId')
                        ? <span className='modified-Fields-color'>
                        {' Final Size - ID (mm) Updated'}
                      </span>
                        : ''}
                      </div>
                        <div className='add-jobcarfield-width'>
                        <div className='field-title'>
                        Final Size - L (mm)
                      <Required />
                    </div>
                      <Field placeholder="Enter Final Size - L (mm)" value={values?.bomDetails?.[index]?.sizeL} name={`bomDetails.${index}.sizeL`} className = 'add-part-default-input' type="number"/>
                      {errors?.bomDetails?.[index]?.sizeL && touched?.bomDetails?.[index]?.sizeL && <span className='validation-text'>
                        {errors?.bomDetails?.[index]?.sizeL}
                      </span>}
                      {modifiedFields?.includes('bomDetails[0].sizeL')
                        ? <span className='modified-Fields-color'>
                        {'Final Size - L (mm) Updated'}
                      </span>
                        : ''}
                      </div>
                      </div>
                      <div className='add-jobcard-field'>
                   <div className='add-jobcarfield-width'>
                   <div className='field-title'>
                   Quantity
                      <Required />
                    </div>
                      <Field placeholder="Enter Quantity" value={values?.bomDetails?.[index]?.quantity} name={`bomDetails.${index}.quantity`} className = 'add-part-default-input' type="number"/>
                      {errors?.bomDetails?.[index]?.quantity && touched?.bomDetails?.[index]?.quantity && <span className='validation-text'>
                        {errors?.bomDetails?.[index]?.quantity}
                      </span>}
                      {modifiedFields?.includes('bomDetails[0].quantity')
                        ? <span className='modified-Fields-color'>
                        {'Quantity Updated'}
                      </span>
                        : ''}
                      </div>
                      </div>
                      <div className='add-jobcard-field'>
                   <div className='add-jobcard-width-50'>
                    <div
                          className="job-form-heading-1 padding-x-20 add-part-jobcard-flex"
                          color={'#000000'}
                          fontWeight={'600'}
                        >
                          Process/Department Details
                          {rows?.length > 0 && (
                            <span
                              className="add-details-span"
                              onClick={() => {
                                setActiveProcess(index)
                                setOpen(true)
                              }}
                            >
                              Add Details{' '}
                              <Plus
                                fill={'#0160B9'}
                                height="12"
                              />
                            </span>
                          )}
                        </div>
                         <div style={{ marginTop: '20px' }}>
                          {' '}
                          {rows?.length <= 0
                            ? (
                            <div
                            className='add-part-jobcard-add-details'
                              onClick={() => {
                                setActiveProcess(index)
                                setOpen(true)
                              }}
                            >

                                Add Details  <Plus fill={'#0160B9'}
                                height="12" />
                                </div>
                              )
                            : (
                            <React.Fragment></React.Fragment>
                              )}
                        </div>
                      <div>
                      {!rows?.length <= 0
                        ? <table className='addpart-table-border-print' width={'100%'} >
                     <thead>
                      <tr >
                     {columns.map((column) => (
                     <th style={{ textAlign: 'center', padding: '5px' }} className='addpart-table-border-print-th' key={column}>{column}</th>
                     ))}
                     </tr>
                    </thead>

                       <tbody>
                       {rows.map((row) => (
                    <tr key={row.id || row.no} >
                     {Object.keys(row).map((key) => (
                      <td style={{ textAlign: 'center', padding: '5px' }} className='addpart-table-border-print-td' key={key}>{row[key]}</td>
                     ))}
                      </tr>
                       ))}
                       </tbody>
                      </table>
                        : <></>}
    </div>
                      </div>
                      </div>
                </div>
                )
              })}
              <div style={{ padding: '20px 0px' }}>
                   <CustomButton variant='outlined' title="Add Part" endIcon={<Plus fill={'#0160B9'} />} type="button"
                onClick={() =>
                  push({
                    itemCode: '',
                    partName: '',
                    description: '',
                    drawingNumber: '',
                    numberOf: '',
                    sizeOd: '',
                    sizeId: '',
                    sizeL: '',
                    weight: ''
                  })
                  }
                    />
              </div>
            </div>
            )}
        />
        </>
)

export default AddPartForJobCardNew
