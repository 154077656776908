import React, { useState } from 'react'
import {
  Box,
  Breadcrumbs,
  Divider,
  Grid,
  Link,
  // Stack,
  // Tooltip,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { useNavigate, useParams, useLocation } from 'react-router-dom'
import styled from '@emotion/styled'
import { useQueryClient } from '@tanstack/react-query'
import EditIconNew from '../../assets/svg/EditIconNew'
import DeleteIconNew from '../../assets/svg/DeleteIconNew'
import Plus from '../../assets/svg/Plus'
import ErrorPopup from '../../modals/errorpopup'
import { user } from '../../querykeys/usermanagement'
import DeleteWarningModal from '../../modals/deletewarningmodal'
import CustomButton from '../../leastcomponent/custombutton/custombutton'
import RestoreWarningModal from '../../modals/restorewarningmodal'
import SearchComponent from '../../generalcomponents/searchcomponent'
import UserInfo from '../../generalcomponents/userinfo'
import BlackKanban from '../../assets/svg/blackKanban'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'
import ShareIcon from '../../assets/svg/ShareIcon'
import SaveFileIcon from '../../assets/svg/SaveFileIcon'
import DateTimeComponent from '../../leastcomponent/DateTimeComponent'
import CustomSelect from '../../leastcomponent/customselect/customselect'
import PrintIcon from '../../assets/svg/PrintIcon'

// interface breadCrumbData {
//   title: string;
//   link: string;
//   activeTab?: string;
// }
// interface breadCrumbModel {
//   pageTitle: string;
//   backToLink: string | null;
//   breadCrumbData: breadCrumbData[] | null;
//   isDetailPage?: boolean;
//   detailPageData?: breadCrumbData[];
//   hideDivider?: boolean;
//   isStatusShow?: boolean;
//   isOnline?: boolean;
//   handleDelete?: any;
//   hideDelete?: boolean;
//   isDeleted?: boolean;
//   isRestore?: boolean;
//   handleRestore?: any;
//   activeTab?: string;
//   isDeleteEnable?: boolean;
//   isUpdateIconShow?: boolean;
//   isDeleteIconShow?: boolean;
//   isAddIconShow?: boolean;
//   errMsg?: string;
// }
const BreadcrumbDivider = styled(Divider)(() => ({
  borderColor: '#DEDEDE !important',
  margin: '0px !important'
}))
const BreadcrumbNavigation = (props) => {
  const {
    breadCrumbData,
    setfirst,
    first,
    pageTitle,
    backToLink,
    isDateTime = true,
    isSearch = true,
    isUserInfo = true,
    isDetailPage = false,
    detailPageData = [],
    hideDivider = false,
    hideDelete = false,
    handleDelete,
    handleRestore,
    activeTab,
    isDeleteEnable = true,
    errMsg = '',
    isUpdateIconShow = true,
    isDeleteIconShow = true,
    isAddIconShow = true,
    isHiii = false,
    isPendingStatus,
    onClick,
    isShareFile = false,
    isPrinter = false,
    isDepartment = false,
    status
  } = props
  let editLink = ''
  // let deleteLink: string | undefined = '';
  let variantLink = ''
  if (isDetailPage) {
    if (detailPageData?.length > 0) {
      editLink = detailPageData?.find((data) => data.title === 'edit')?.link
      // deleteLink = detailPageData?.find((data) => data.title === 'delete')?.link;
      variantLink = detailPageData?.find(
        (data) => data.title === '+ Create Variant'
      )?.link
    }
  }
  const location = useLocation()
  const isDeletedItemsPage = location.pathname.startsWith('/deleted-items')
  const theme = useTheme()
  const isMdScreen = useMediaQuery(theme.breakpoints.only('md'))
  const BreadCrumbWrapper = styled(Box)(() => ({
    '.title-text': {
      fontSize: '24px',
      fontWeight: '600',
      color: '#020202',
      paddingLeft: backToLink ? '20px' : '0px',
      textTransform: 'capitalize',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      // maxWidth: '420px',
      maxWidth: isMdScreen ? '180px' : '420px'
    },
    '.back-arrow': {
      cursor: 'pointer'
    },
    '.main-title': {
      fontSize: '24px',
      fontWeight: '600',
      color: '#020202',
      '@media(min-width: 1413px) and (max-width: 1540px)': {
        fontSize: '16px !important'
      },
      '@media(min-width: 0px) and (max-width: 1412px)': {
        fontSize: '14px !important'
      }
    },
    '.iconText': {
      fontSize: '14px',
      color: '#265AA8',
      fontWeight: 600
    },
    '.icon-text-wrapper': {
      alignItems: 'center',
      cursor: 'pointer',
      display: 'flex'
    },
    '.mr-23px': {
      marginRight: '23px'
    },
    '.icon': {
      color: '#265AA8',
      paddingRight: '8px',
      fontSize: '15px !important'
    },
    '.online-status': {
      paddingLeft: '12px',
      alignItems: 'center !important',
      '.css-1012bww-MuiChip-root': {
        borderRadius: '4px !important',
        height: '24px !important'
      }
    },
    '.ml-26': {
      marginLeft: '26px'
    },
    '.last-link': {
      color: 'grey'
    }
  }))
  const BreadCrumbSubWrapper = styled(Box)(() => ({
    '.title-text': {
      fontSize: '24px',
      fontWeight: '600',
      color: '#020202',
      paddingLeft: backToLink ? '20px' : '0px',
      textTransform: 'capitalize',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap'
    },
    '.back-arrow': {
      cursor: 'pointer'
    },
    '.iconText': {
      fontSize: '14px',
      color: '#265AA8',
      fontWeight: 600,
      paddingLeft: '8px'
    },
    '.icon-text-wrapper': {
      alignItems: 'center',
      cursor: 'pointer',
      display: 'flex'
    },
    '.mr-23px': {
      marginRight: '23px'
    },
    '.icon': {
      color: '#265AA8',
      // paddingRight: '8px',
      fontSize: '15px !important'
    },
    '.online-status': {
      paddingLeft: '12px'
    },
    '.ml-26': {
      marginLeft: '26px'
    },
    '.last-link': {
      color: 'grey'
    }
  }))
  const getSelectDepartmentid = localStorage.getItem('selectDepartmentid')
  const queryClient = useQueryClient()
  const userData = queryClient.getQueryData([user.LOGGED_IN_USER])
  const departmentData = userData?.data?.data?.user?.roleId?.departmentGroup
  const navigate = useNavigate()
  const breadCrumbs = breadCrumbData?.map((breadCrumb, index) => (
    <Link
      underline="hover"
      key={index}
      className={`breadcrumb-link ${
        breadCrumbData?.length - 1 === index ? 'last-link' : ''
      }`}
      color="#0160B9"
      onClick={() =>
        navigate(breadCrumb.link, {
          state: {
            activeTab: breadCrumb?.activeTab ? breadCrumb?.activeTab : '1'
          }
        })
      }
    >
      {breadCrumb.title}
    </Link>
  ))
  // Delete Modal
  const [deleteRecord, setDeleteRecord] = useState(false)
  const handleDeleteOpen = () => setDeleteRecord(true)
  const handleDeleteClose = () => setDeleteRecord(false)
  const [restoreRecord, setRestoreRecord] = useState(false)
  const handleRestoreOpen = () => setRestoreRecord(true)
  const handleRestoreClose = () => setRestoreRecord(false)
  const { id } = useParams()
  // Delete Error warning
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false)
  const [errorMessage, setErrorMessage] = React.useState('')
  const handleErrorModalOpen = () => setIsErrorModalOpen(true)
  const handleErrorModalClose = () => setIsErrorModalOpen(false)
  const enableDeleteWarning = () => {
    if (!isDeleteEnable) {
      setErrorMessage(errMsg)
      handleErrorModalOpen()
    } else {
      handleDeleteOpen()
    }
  }

  const options = departmentData?.departments?.map((item) => {
    return {
      value: item?.id,
      label: item?.name
    }
  })

  return (
    <React.Fragment>
      <BreadCrumbWrapper>
        <Box
          className="main-padding"
          display="flex"
          alignItems="center"
          gap='15px'
          // justifyContent={"flex-end"}
          justifyContent={'space-between'}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '15px' }}>
            {backToLink && (
              <ArrowBackIcon
                className="back-arrow"
                onClick={() => {
                  if (backToLink === 'back') {
                    navigate(-1)
                  } else if (backToLink === 'backTwoStep') {
                    navigate(-2)
                  } else {
                    navigate(backToLink, { state: { activeTab } })
                    // navigate(backToLink)
                  }
                }}
              />
            )}
            <Typography className="main-title">{pageTitle}</Typography>
     {isDepartment && <Box sx={{ width: isMdScreen ? '150px' : '200px' }}>
        <CustomSelect
        name="department"
        isRequired={false}
        islabel={false}
        placeholder="Select Department"
        className="default-select-new"
        options={options}
        value={first || getSelectDepartmentid}
        handleSelectChange={(e) => {
          setfirst(e.target.value)
          localStorage.setItem('selectDepartmentid', e.target.value)
        }}
      />
            </Box>}
          </Box>
          {/* <Box display={"flex"} alignItems="center">
            {backToLink && (
              <ArrowBackIcon
                className="back-arrow"
                onClick={() => {
                  if (backToLink === "back") {
                    navigate(-1);
                  } else if (backToLink === "backTwoStep") {
                    navigate(-2);
                  } else {
                    navigate(backToLink, { state: { activeTab } });
                  }
                }}
              />
            )}
            <Box display={"flex"}>
              <Tooltip title={pageTitle}>
                <Typography className="title-text">{pageTitle}</Typography>
              </Tooltip>
              {props.isStatusShow && (
                <Stack direction="row" spacing={1} className="online-status">
                  //! TODO : for future use
                  <Chip
                    avatar={<RxDotFilled color={`${props.isOnline ? 'green' : 'red'}`} />}
                    label={`${props.isOnline ? 'Online' : 'Offline'}`}
                    variant='outlined'
                  />
                </Stack>
              )}
            </Box>
          </Box> */}
         <Box
            display={'flex'}
            justifyContent="space-between"
            alignItems={'center'}
          >
            {/* <Box className="search-component-nav"> */}
            { isSearch && <SearchComponent />}
            {/* </Box> */}
            <Box>

              {' '}
              {isUserInfo && <UserInfo userData={userData} />}
            </Box>

            {/* Update date and time every second */}
            {isDateTime && (
             <DateTimeComponent />
            )}
            {/* //! TODO : for future use date and time */}
             {/* {isDateTime && (
              <Box className="date-box">
                {' '}
                <Box className="date-child-box">
                  {moment().format('dddd DD MMM')}
                </Box>
                <Box className="time-child-box">{moment().format('LT')}</Box>
              </Box>
             )} */}
          </Box>
          {/* //! TODO : for future use */}
          {/* {(isDetailPage || isDeletedItemsPage) && (
            <Box display={'flex'}>
              {!props.isDeleted && detailPageData.length > 2 && isAddIconShow && (
                <Box
                  className='icon-text-wrapper mr-23px'
                  onClick={() => navigate(variantLink || '')}
                >
                  <AddIcon className={'icon'} />
                  <Typography className={'iconText'}>Create Variant</Typography>
                </Box>
              )}
              {isUpdateIconShow && !props.isDeleted && (
                <Box className='icon-text-wrapper mr-23px' onClick={() => navigate(editLink || '')}>
                  <EditOutlinedIcon className={'icon'} />
                  <Typography className={'iconText'}>Edit</Typography>
                </Box>
              )}
              {!hideDelete && !props.isDeleted && isDeleteIconShow && (
                <Box className='icon-text-wrapper' onClick={() => enableDeleteWarning()}>
                  <DeleteOutlinedIcon className={'icon'} />
                  <Typography className={'iconText'}>Delete</Typography>
                </Box>
              )}
              {isErrorModalOpen && (
                <ErrorPopup
                  isOpen={isErrorModalOpen}
                  handleClose={handleErrorModalClose}
                  errorDetails={errorMessage}
                />
              )}
              {props.isRestore && (
                <CustomButton variant='outlined' onClick={() => handleRestoreOpen()}>
                  Restore
                </CustomButton>
              )}
              {props.isDeleted && (
                <CustomButton className='yellow-bg ml-26' onClick={() => handleDeleteOpen()}>
                  Delete
                </CustomButton>
              )}
            </Box>
          )} */}
        </Box>
        {deleteRecord && (
          <DeleteWarningModal
            open={deleteRecord}
            handleOpen={handleDeleteOpen}
            handleClose={handleDeleteClose}
            handleDelete={handleDelete}
            id={id}
          />
        )}
        {restoreRecord && (
          <RestoreWarningModal
            open={restoreRecord}
            handleOpen={handleRestoreOpen}
            handleClose={handleRestoreClose}
            handleDelete={handleRestore}
            id={id}
          />
        )}
      </BreadCrumbWrapper>
      {!hideDivider && <BreadcrumbDivider />}
      {breadCrumbData && (
        <BreadCrumbSubWrapper className="main-sub-padding">
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Breadcrumbs aria-label="breadcrumb">/ {breadCrumbs}</Breadcrumbs>
            {isHiii && <BlackKanban />}
            {isShareFile && (
              <Grid item lg={6} className="breadcrumb-share-save">
                <Box className="share-file-header-share">
                  Share <ShareIcon />
                </Box>
                <Box className="share-file-header-share">
                  Save <SaveFileIcon />
                </Box>
              </Grid>
            )}
            <Box>
            {isPendingStatus && (
              <Grid
                item
                lg={5}
                // onClick={() => {
                //   if (typeof onClick === 'function') {
                //     onClick()
                //   }
                // }}
                className="job-align-center-with-gap"
              >
                Status:
                               {status === 'approved' && <>
                <span className="align-center">
                  <FiberManualRecordIcon
                    sx={{ fontSize: '14px', color: '#B06BFF' }}
                  />
                </span>{' '}
                <span className="font-size-14-with-semibold">Approved</span>
                </>}
                {status === 'completed' && <>
                <span className="align-center">
                  <FiberManualRecordIcon
                    sx={{ fontSize: '14px', color: '#32B677' }}
                  />
                </span>{' '}
                <span className="font-size-14-with-semibold">Completed</span>
                </>}
                  {status === 'submitted' && <>
                <span className="align-center">
                  <FiberManualRecordIcon
                    sx={{ fontSize: '14px', color: '#24B8F7' }}
                  />
                </span>{' '}
                <span className="font-size-14-with-semibold">Submitted</span>
                </>}
              </Grid>
            )}
             {isPrinter && (
              <Grid item lg={6} className="breadcrumb-share-save" pt={3}>
                <Box className="share-file-header-share" onClick={() => {
                  if (typeof onClick === 'function') {
                    onClick()
                  }
                }}>
                  Print <PrintIcon />
                  {/* print-job-slips-number */}
                </Box>
              </Grid>
             )}
             </Box>
          </Box>
          <Box mt={3} display={'flex'} justifyContent={'end'}>
            {(isDetailPage || isDeletedItemsPage) && (
              <Box display={'flex'}>
                {isUpdateIconShow && !props.isDeleted && (
                  <Box
                    className="icon-text-wrapper mr-23px"
                    onClick={() => navigate(editLink || '')}
                  >
                    <EditIconNew />
                    <Typography className={'iconText'}>Edit</Typography>
                  </Box>
                )}
                {!hideDelete && !props.isDeleted && isDeleteIconShow && (
                  <Box
                    className="icon-text-wrapper mr-23px"
                    onClick={() => enableDeleteWarning()}
                  >
                    <DeleteIconNew />
                    <Typography className={'iconText'}>Delete</Typography>
                  </Box>
                )}
                {!props.isDeleted &&
                  detailPageData.length > 2 &&
                  isAddIconShow && (
                    <Box
                      className="icon-text-wrapper "
                      onClick={() => navigate(variantLink || '')}
                    >
                      {/* <AddIcon className={'icon'} /> */}
                      <Plus fill="#0160B9" />
                      <Typography className={'iconText'}>
                        Create Variant
                      </Typography>
                    </Box>
                )}
                {isErrorModalOpen && (
                  <ErrorPopup
                    isOpen={isErrorModalOpen}
                    handleClose={handleErrorModalClose}
                    errorDetails={errorMessage}
                  />
                )}
                {props.isRestore && (
                  <CustomButton
                    variant="outlined"
                    title="Restore"
                    onClick={() => handleRestoreOpen()}
                  >
                    Restore
                  </CustomButton>
                )}
                {props.isDeleted && (
                  <CustomButton
                    title="Delete"
                    className="blue-bg ml-26"
                    onClick={() => handleDeleteOpen()}
                  >
                    Delete
                  </CustomButton>
                )}
              </Box>
            )}
          </Box>
        </BreadCrumbSubWrapper>
      )}
    </React.Fragment>
  )
}
export default BreadcrumbNavigation
