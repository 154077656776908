import { deleteRequest, get, patch, post } from '../utils/index'

export const addTask = async (data) => {
  return post('/tasktype', data).then((res) => res.data)
}

export const getTask = async (data) => {
  // const page = data?.queryKey[1] || 1
  const payload = { }
  return get('/tasktype', payload).then((res) => res.data)
}
export const getSingleTask = async (data) => {
  const id = data?.queryKey[1]
  return get(`/tasktype/${id}`).then((res) => res.data)
}
export const updateTask = async (data) => {
  const id = data?.id
  delete data.id
  return patch(`/tasktype/${id}`, data).then((res) => res.data)
}
export const getFullTasks = async () => {
  return get('/tasktype/full-list')
}
export const deleteTaskType = async (id) => {
  return deleteRequest(`/tasktype/${id}`)
}
export const restoreTaskType = async (id) => {
  return patch(`/tasktype/restore/${id}`)
}

export const hardDeleteTaskType = async (id) => {
  return deleteRequest(`/tasktype/hard-delete/${id}`)
}
export const getSingleTaskTypeDelete = async (data) => {
  const id = data?.queryKey[1]
  return get(`/tasktype/deleted/${id}`)
}
export const taskApi = {
  addTask,
  getTask,
  getSingleTask,
  updateTask,
  getFullTasks,
  deleteTaskType,
  restoreTaskType,
  hardDeleteTaskType,
  getSingleTaskTypeDelete

}
