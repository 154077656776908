import React from 'react'
import { Box, Typography } from '@mui/material'
import './../cardInfo/cardInfo.css'

const Card = ({ title, data }) => {
  return (
        <React.Fragment>
            <Box className='parent-one'>
                <Typography className='info-title-card'>
                    {title}
                </Typography>
                <Typography className='card-value'>
                    {data}
                </Typography>
            </Box>
        </React.Fragment>
  )
}

export default Card
