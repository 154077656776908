/* eslint-disable react/jsx-key */
import { Box, Grid, useMediaQuery, useTheme } from '@mui/material'
import React, { useRef, useState } from 'react'
import TableComponent from '../../leastcomponent/customtable/customtable'
import DeleteIconNew from '../../assets/svg/DeleteIconNew'
import DeleteWarningModal from '../../modals/deletewarningmodal'
import EditIconNew from '../../assets/svg/EditIconNew'
import BreadcrumbNavigation from '../commoncomponents/breadcrumbnavigation'
import './jobslipsnumber.css'
import NewShareIcon from '../../assets/svg/NewShareicon'
import { useJobSlipsSingle } from '../../hooks/jobslipshooks'
import { useParams } from 'react-router-dom'
import JobCardTableModal from '../../modals/jobcardmodal/jobcardtablemodal'
import JobSlipsRadioWithSelectModal from '../../modals/jobcardmodal/jobslipsradiowithselect'
import ConfirmJobSlip from '../../modals/jobcardmodal/confirmjobslip'
import ConfirmOverdue from '../../modals/jobcardmodal/confirmoverdue'
import moment from 'moment'
import NoData from '../../leastcomponent/noAddedYet'
import { useReactToPrint } from 'react-to-print'
import PrintJobSlipsNumber from './printJobslipsnumber'
import JobSlipsDetailsModal from '../../modals/jobcardmodal/jobSlipsDetailsModal'

const JobSlipsNumber = () => {
  const [first, setfirst] = useState('')
  const selectDepartmentid = localStorage.getItem('selectDepartmentid')
  const [jobCardModel, setJobCardModel] = useState('')
  const [tasks, setTasks] = useState({})
  const handleDetailsClose = () => {
    setJobCardModel(false)
  }
  const { id } = useParams()
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up('lg'))
  const [deleteRecord, setDeleteRecord] = useState(false)
  const [jobName, setJobName] = useState({ name: '', code: '' })
  const [jobCardTableModal, setJobCardTableModal] = useState(false)
  const [currJobCardId, setCurrJobCardId] = useState('')
  const [jobId, setJobId] = useState('')
  const [jobIdValues, setJobIdValues] = useState({})
  const [jobSlipsRadioWithSelectModal, setJobSlipsRadioWithSelectModal] =
    useState(false)
  const [confirmJobSlip, setConfirmJobSlip] = React.useState(false)
  const [confirmOverdue, setConfirmOverdue] = React.useState(false)
  const handleDeleteOpen = () => setDeleteRecord(true)
  const handleDeleteClose = () => setDeleteRecord(false)
  const { data: getjobSlipsSingle } = useJobSlipsSingle(id, !!id, selectDepartmentid)
  const basicJobSlipsData = getjobSlipsSingle?.data?.basicDetail

  const breadCrumbData = [
    {
      title: 'Job Slips',
      link: '/job-slips'
    },
    {
      title: basicJobSlipsData?.status === 'ongoing'
        ? 'Ongoing Job Slips'
        : (basicJobSlipsData?.status === 'planned'
            ? 'Future Job Slips'
            : 'Past Job Slips'
          ),
      link: ''
    }
  ]
  const componentRef = useRef()
  const handlePrint = useReactToPrint({
    content: () => componentRef?.current
  })
  return (
    <React.Fragment>
      <Box className="production-team-head-overflow-hidden">
        <BreadcrumbNavigation
          breadCrumbData={breadCrumbData}
          pageTitle={`Job Slip Number: ${basicJobSlipsData?.jobSlipNumber ? basicJobSlipsData?.jobSlipNumber : ''}`}
          backToLink={'/job-slips'}
          setfirst={setfirst}
        first={first}
        isDepartment={true}
        selectedDepartment={selectDepartmentid}
          isPrinter={selectDepartmentid}
          onClick={() => {
            handlePrint()
          }}
        />
 {
        !selectDepartmentid
          ? <Box paddingTop={'350px'}><NoData label='Please select department'/></Box>
          : <Box
          sx={{
            typography: 'body1'
          }}
          className="job-slips-overflow-auto"
        >
                <Box className="job-slips-main-border">
                <Box className="job-slips-basic">Basic Details</Box>
                  <Grid container className="job-slips-grid-section">
                    <Grid item lg={3} md={3}>
                      <Box className="job-slips-add-task-header">
                        Job Slip number
                      </Box>
                      <Box className="job-slips-with-semibold">
                        {basicJobSlipsData?.jobSlipNumber}
                      </Box>
                    </Grid>
                    {/* TODO:: future use for department process */}
                    {/* <Grid item lg={3} md={3.5}>
                      <Box className="job-slips-add-task-header">
                        Process/Department
                      </Box>
                      <Box className="job-slips-with-semibold">
                        {basicJobSlipsData?.department}
                      </Box>
                    </Grid> */}
                    <Grid item lg={3} md={3.5}>
                      <Box className="job-slips-add-task-header">Timeline</Box>
                      <Box className="job-slips-with-semibold">
                        {basicJobSlipsData?.Timeline
                          ? moment.utc(basicJobSlipsData?.Timeline)?.format(
                            'DD/MM/YY'
                          )
                          : '-'}
                          {}
                      <span style={{ paddingLeft: '10px' }}>{basicJobSlipsData?.shift}</span>
                      </Box>
                    </Grid>
                    <Grid item lg={3} md={2}>
                      <Box className="job-slips-add-task-header">Status</Box>
                      <Box className="job-slips-with-semibold">
                        {' '}
                        {basicJobSlipsData?.status}
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
          {getjobSlipsSingle?.data?.taskList?.map((data, ind) => {
            const rows = data?.task?.map((data1, ind) => {
              return {
                no: ind + 1,
                id: data1?.id,
                name: data1?.taskNickName ? data1.taskNickName : '-',
                taskNumber: data1?.taskNumber ? data1.taskNumber : '-',
                taskType: data1?.taskType?.name ? data1?.taskType?.name : '-',
                partName: data1?.bompart?.partName
                  ? data1?.bompart?.partName
                  : '-',
                qty: data1?.jobcard?.quantity ? data1?.jobcard.quantity : '-',
                description: data1?.bompart?.description ? data1?.bompart?.description : '-',
                jobCardNo: data1?.jobcard?.jobCardNumber
                  ? data1?.jobcard?.jobCardNumber
                  : '-',
                allocatedTime: data1?.allocatedTime,
                jobName: data?.name,
                jobCode: data?.code,
                drawing: data1?.bompart?.drawingNumber
                  ? data1?.bompart?.drawingNumber
                  : '-'
              }
            })
            const col = [
              { field: 'no', headerName: '#', flex: 0.3 },
              {
                field: 'name',
                headerName: 'Task Name',
                flex: 1,
                cellClassName: 'blue-font',
                renderCell: (params) => {
                  return (
              <Box className="table-icon-new">
                  <span
                    className="process-delete"
                      onClick={() => {
                        setJobName({ ...jobName, name: params?.row?.jobName, code: params?.row?.jobCode })
                        // setTasks(finalDataOne?.find(item => item.partId === partId))
                        // if (partId) {
                        setTasks({ history: [{ taskId: params?.id, taskNickName: params?.row?.name }] })
                        // setProcessData({ })
                        setJobCardModel(true)
                        // }
                      }}
                  >
                    {params?.row?.name ? params?.row?.name : '-' }
                  </span>
              </Box>
                  )
                }
              },
              { field: 'taskNumber', headerName: 'Task Number', flex: 1 },
              { field: 'taskType', headerName: 'Task type', flex: 1 },
              { field: 'partName', headerName: 'Part Name ', flex: 1 },
              { field: 'qty', headerName: 'Qty ', flex: matches ? 1 : 0.7 },
              { field: 'description', headerName: 'Part Description ', flex: 1 },
              { field: 'jobCardNo', headerName: 'Job Card Number ', flex: 1 },
              {
                field: 'allocatedTime',
                headerName: 'Allocated time (mins)',
                flex: matches ? 1 : 0.7
              },
              {
                field: 'drawing',
                headerName: 'Drawing',
                flex: matches ? 1 : 0.7
              },
              {
                field: 'actions',
                headerName: 'Actions',
                sortable: false,
                disableClickEventBubbling: true,
                flex: 1,
                renderCell: (params) => {
                  return (
                    <Box className="resource-table-btn-main">
                      <span
                        className="process-delete"
                        onClick={() => {
                          setJobId(params?.id)
                          setJobSlipsRadioWithSelectModal(true)
                        }}
                      >
                        <EditIconNew />
                      </span>
                      <span
                        onClick={handleDeleteOpen}
                        className="process-delete"
                      >
                        <DeleteIconNew />
                      </span>
                    </Box>
                  )
                }
              }
            ]
            return (
              <React.Fragment>
                <>
                <Box className="job-slips-card-box">
                  {data?.name}
                  <Box
                    onClick={() => {
                      setJobCardTableModal(true)
                      setCurrJobCardId(data?.id)
                    }}
                    sx={{ cursor: 'pointer' }}
                  >
                    <NewShareIcon />
                  </Box>
                </Box>
                <Grid container className="job-slips-main-margin">
                  <Grid
                    item
                    lg={3}
                    md={3.5}
                    className="job-slips-task-assigned"
                  >
                    <Box className="job-slips-add-task-header">
                      No. of Tasks assigned:
                    </Box>
                    <Box className="job-slips-with-semibold">
                      {' '}
                      {data?.numberOfTasks}
                    </Box>
                  </Grid>
                  <Grid
                    item
                    lg={3}
                    md={3.5}
                    className="job-slips-task-assigned"
                  >
                    <Box className="job-slips-add-task-header">
                      Total Qty assigned:
                    </Box>
                    <Box className="job-slips-with-semibold">
                      {data?.totalQty}
                    </Box>
                  </Grid>
                  <Grid item lg={3} md={5} className="job-slips-task-assigned">
                    <Box className="job-slips-add-task-header">
                      Total time assigned:
                    </Box>
                    <Box className="job-slips-with-semibold">{data?.totalTime} minutes</Box>
                  </Grid>
                </Grid>
                <Box>
                  <TableComponent rows={rows} columns={col} ref={componentRef} />
                </Box>
                </>
              </React.Fragment>
            )
          })}
          {/* //! print  */}
          <Box
          className="job-slips-with-print"
          ref={componentRef}
          >
          <PrintJobSlipsNumber />
          </Box>
          {/* //! small modal */}
          {jobCardTableModal && (
            <JobCardTableModal
              open={jobCardTableModal}
              basicDetail={getjobSlipsSingle?.data?.basicDetail}
              handleClose={() => setJobCardTableModal(false)}
              mainData={getjobSlipsSingle?.data?.taskList?.filter((data) => {
                return data?.id === currJobCardId
              })}
              jobId={jobId}
            />
          )}
          {jobSlipsRadioWithSelectModal && (
            <JobSlipsRadioWithSelectModal
              open={jobSlipsRadioWithSelectModal}
              handleClose={() => setJobSlipsRadioWithSelectModal(false)}
              slipOpen={() => setConfirmJobSlip(true)}
              overdueOpen={() => setConfirmOverdue(true)}
              setJobIdValues = {setJobIdValues}
            />
          )}
          {/* //! new */}
          {confirmJobSlip && (
            <ConfirmJobSlip
              open={confirmJobSlip}
              handleClose={() => setConfirmJobSlip(false)}
              openJobSlip={() => setJobSlipsRadioWithSelectModal(true)}
              jobId={jobId}
              jobIdValues={jobIdValues}
            />
          )}
          {ConfirmOverdue && (
            <ConfirmOverdue
              open={confirmOverdue}
              handleClose={() => setConfirmOverdue(false)}
              openJobSlip={() => setJobSlipsRadioWithSelectModal(true)}
              jobId={jobId}
            />
          )}
          {/* //! end */}
          {deleteRecord && (
            <DeleteWarningModal
              open={deleteRecord}
              handleOpen={handleDeleteOpen}
              handleClose={handleDeleteClose}
            />
          )}
                {jobCardModel && (
          <JobSlipsDetailsModal
            tasks={tasks}
            jobName={jobName}
            processData={getjobSlipsSingle}
            open={jobCardModel}
            DetailsHandleClose={handleDetailsClose}
            setOpen={() => setJobCardModel(false)}
          />
                )}
        </Box>
}
      </Box>
    </React.Fragment>
  )
}

export default JobSlipsNumber
