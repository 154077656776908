import React, { useState } from 'react'
import './createreport.css'
import { Box, Grid, Typography } from '@mui/material'
// import BreadCrumb from "../../leastcomponent/breadcrumb/breadcrumb";
import { FieldArray, Form, Formik } from 'formik'
import dayjs from 'dayjs'
import CustomButton from '../../leastcomponent/custombutton/custombutton'
import * as Yup from 'yup'
import CustomDateTimePicker from '../../leastcomponent/customdatetimepicker/customdatetimepicker'
import Plus from '../../assets/svg/Plus'
import CustomInput from '../../leastcomponent/custominput/input'
import BreadcrumbNavigation from '../commoncomponents/breadcrumbnavigation'
const CreateReport = () => {
  const validationSchema = Yup.object().shape({
    startdatetime: Yup.string().required('Start Date & Time is required'),
    enddatetime: Yup.string().required('End Date & Time is required'),
    report: Yup.array().of(
      Yup.object().shape({
        email: Yup.string()
          .email('Invalid email address')
          .required('Email is required')
      })
    ),
    kpiValues: Yup.array().required('At least one KPI must be selected'),
    recurring: Yup.string().required('Recurring selection is required')
  })
  const [selectedBoxes, setSelectedBoxes] = useState([])

  const toggleBoxSelection = (index) => {
    setSelectedBoxes((prevSelected) => {
      if (prevSelected.includes(index)) {
        return prevSelected.filter((item) => item !== index)
      } else {
        return [...prevSelected, index]
      }
    })
  }

  return (
    <React.Fragment>
      {' '}
      <BreadcrumbNavigation
        breadCrumbData={null}
        pageTitle={'Reports'}
        backToLink={'/reports'}
      />
      <Box className="report-form-wrapper">
        {/* <Box className="padding-y-40">
          <BreadCrumb title="Reports11" />
        </Box> */}
        <Typography className="report-form-heading">
          Generate Report{' '}
        </Typography>
        <Formik
          enableReinitialize={true}
          initialValues={{
            enddatetime: dayjs(),
            startdatetime: dayjs(),
            report: [{ email: '' }],
            kpiValues: [],
            recurring: ''
          }}
          onSubmit={(values) =>
            console.log('values======', { ...values, kpiValues: selectedBoxes })
          }
          validationSchema={validationSchema}
        >
          {({
            errors,
            // handleBlur,
            handleChange,
            touched,
            values,
            setFieldValue
            //   handleSubmit,
          }) => (
            <React.Fragment>
              <Form className="report-form-perent">
                <Box className="report-form-child">
                  <Box className="padding-y-60">
                    <Box>
                      <Typography className="common-tabel-heading1">
                        Select KPI{' '}
                      </Typography>

                      <Grid container spacing={1} className="create-report-box">
                        {[...Array(8).keys()].map((index) => (
                          <Grid item xs={3} md={3} key={index}>
                            <Box
                              className={` ${
                                selectedBoxes.includes(index)
                                  ? 'create-report-kpi-selected'
                                  : 'create-report-kpi'
                              }`}
                              onClick={() => toggleBoxSelection(index)}
                            >
                              KPI {index + 1}
                            </Box>
                          </Grid>
                        ))}
                      </Grid>
                    </Box>
                    <Box>
                      <Typography className="common-tabel-heading">
                        Select Timeline{' '}
                      </Typography>
                      <Grid spacing={2} container paddingTop={2} gap={1}>
                        <Grid item xs={12} md={6} xl={4}>
                          <CustomDateTimePicker
                            label="Start Date & Time"
                            value={values?.startdatetime}
                            handleDateChange={(startdatetime) =>
                              setFieldValue('startdatetime', startdatetime)
                            }
                            className="default-date-picker"
                            errors={errors?.startdatetime}
                            touched={touched?.startdatetime}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          md={6}
                          xl={4}
                          className="create-report-date"
                        >
                          <CustomDateTimePicker
                            label="End Date & Time"
                            value={values?.enddatetime}
                            handleDateChange={(enddatetime) =>
                              setFieldValue('enddatetime', enddatetime)
                            }
                            className="default-date-picker"
                            errors={errors?.enddatetime}
                            touched={touched?.enddatetime}
                          />
                        </Grid>
                      </Grid>
                    </Box>
                    <Box>
                      <Typography className="common-tabel-heading">
                        Recurring
                      </Typography>
                      <Box className="report-yes-no">
                        <Box>
                          <CustomButton
                            variant="outlined"
                            className={`report-button-box ${
                              values?.recurring === 'yes' ? 'active' : ''
                            }`}
                            type="button"
                            onClick={() => setFieldValue('recurring', 'yes')}
                            title="Yes"
                          />
                        </Box>
                        <Box>
                          <CustomButton
                            variant="outlined"
                            className={`report-button-box ${
                              values?.recurring === 'no' ? 'active' : ''
                            }`}
                            type="button"
                            onClick={() => setFieldValue('recurring', 'no')}
                            title="No"
                          />
                        </Box>
                      </Box>
                    </Box>

                    <Box className="create-email-section">
                      <Typography className="common-tabel-heading">
                        Email{' '}
                      </Typography>
                      <FieldArray
                        name="report"
                        render={({ push }) => (
                          <React.Fragment>
                            {values?.report?.map((_, index) => (
                              <Box key={index} className="create-main-box">
                                <Box className="create-reoport-field">
                                  <CustomInput
                                    name={`report.${index}.email`}
                                    label="Email"
                                    placeholder="Enter Code"
                                    handleChange={handleChange}
                                    value={values?.report?.[index]?.email}
                                    errors={errors?.report?.[index]?.email}
                                    touched={touched?.report?.[index]?.email}
                                  />
                                </Box>
                                {values?.report?.length - 1 === index && (
                                  <Box className="create-add-btn">
                                    <CustomButton
                                      variant="outlined"
                                      className="report-add-btn"
                                      type="button"
                                      title="Add"
                                      endIcon={<Plus fill={'#0160B9'} />}
                                      onClick={() =>
                                        push({
                                          email: ''
                                        })
                                      }
                                    >
                                      Add
                                    </CustomButton>
                                  </Box>
                                )}
                              </Box>
                            ))}
                          </React.Fragment>
                        )}
                      />
                    </Box>
                  </Box>
                </Box>

                <Box className="report-button-child">
                  <CustomButton title="Publish Report" type="submit" />
                </Box>
              </Form>
            </React.Fragment>
          )}
        </Formik>
      </Box>
    </React.Fragment>
  )
}

export default CreateReport
