import { get, post, deleteRequest } from '../utils/index'

// export const getUser = async (data) => {
//   const page = data?.queryKey[1] || 1;
//   const payload = { page, limit: 10 };
//   return get(`/user`, payload).then((res) => res.data);
// };
export const getRole = async (
  data
) => {
  const page = data?.queryKey[1] || 1
  const payload = { page, limit: 10 }
  return get('/user-role', payload).then((res) => {
    return res.data
  })
}
export const addUser = async (data) => {
  return post('/user', data).then((res) => res.data)
}
export const deleteUser = async (id) => {
  return deleteRequest(`/user/${id}`)
}
export const getFullRoles = async () => {
  return get('/user-role/full-list')
}
export const deleteRole = async (id) => {
  return deleteRequest(`/user-role/${id}`)
}
export const roleApi = {
  getRole,
  addUser,
  deleteUser,
  getFullRoles,
  deleteRole
}
