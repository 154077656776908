import { Box, IconButton, InputAdornment, TextField } from '@mui/material'
import styled from '@emotion/styled'
import SearchIcon from '@mui/icons-material/Search'
import ClearIcon from '@mui/icons-material/Clear'
import React from 'react'

const BoxWrapper = styled(Box)(() => ({
  display: 'inline-flex',
  boxSizing: 'border-box',
  borderRadius: '4px',
  border: '1px solid #F6F6F6',
  background: '#F6F6F6',
  height: '40px',
  alignItems: 'center'
}))

function JobcardSearchComponent ({ setProductCode, productCode }) {
  const handleChange = (e) => {
    setProductCode(e.target.value)
  }

  const handleClear = () => {
    setProductCode('')
  }

  return (
    <BoxWrapper>
      <TextField
        value={productCode}
        onChange={handleChange}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon className="search-icon" />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={handleClear}>
               {productCode && <ClearIcon />}
              </IconButton>
            </InputAdornment>
          ),
          sx: { height: '40px' }
        }}
        placeholder="Search Product Code"
        sx={{ height: '40px', '.MuiInputBase-root': { height: '40px' } }}
      />
    </BoxWrapper>
  )
}

export default JobcardSearchComponent
